import { SearchBuilder } from "@/builder";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  FilterField,
  ReportInvControllingResponseDto,
} from "@/models/interface/invoice-controlling-report";
import { invoiceServices } from "@/services/invoice.service";
import store from "@/store";
import { useFindMasterType } from "./master-type";
import useDate from "./useDate";

const useInvoiceControllingReport = () => {
  const findAll = (
    params?: RequestQueryParams
  ): Promise<Pagination<ReportInvControllingResponseDto>> => {
    return invoiceServices.getListReportInvoiceControlling(params);
  };

  const download = (params?: RequestQueryParams) => {
    return invoiceServices.downloadReportInvoiceControlling(params);
  };

  const findAllStatus = () => {
    return useFindMasterType("INVOICE_CONTROLLING_REPORT_STATUS");
  };

  const filterBy = ({
    branch,
    customer,
    unitCode,
    internalContract,
    status,
    date,
    isInvoiced,
    isBackup,
  }: FilterField): string => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];
    const { toStartDay, toEndDay } = useDate();

    if (branch) {
      query.push(builder.push(["branchId", branch.key]).build());
    }

    if (customer) {
      query.push(builder.push(["customerId", customer.key]).build());
    }

    if (unitCode) {
      query.push(
        builder.push(["unitCode", unitCode], { like: "both" }).build()
      );
    }

    if (internalContract) {
      query.push(builder.push(["icId", internalContract.key]).build());
    }

    if (isInvoiced) {
      query.push(
        builder.push(["isInvoicing", String(isInvoiced === "YES")]).build()
      );
    }

    if (isBackup) {
      query.push(
        builder.push(["unitBackup", String(isBackup === "YES")]).build()
      );
    }

    if (date && date.length === 2) {
      const [start, end] = date;
      query.push(
        builder
          .push(["billingStartDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["billingStartDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    if (status && status.length > 0) {
      const queries: string[] = status.map(item => {
        return builder
          .push(["icStatus", trimSpaceToUnderscore(item)], {
            like: "both",
          })
          .build();
      });

      query.unshift(queries.join(SearchBuilder.OR));
    }

    return query.join(builder.AND);
  };

  const buildReportHeader = (form: FilterField): string => {
    const { toDefaultFormat } = useDate();
    const company: string =
      store.state["authStore"].authData.companyName || "ALL";
    const branch: string = form.branch?.label || "ALL";
    const customer: string = form.customer?.label || "ALL";
    const unitCode: string = form.unitCode || "ALL";
    const icNumber: string = form.internalContract?.label || "ALL";
    const byInvoice: string = form.isInvoiced || "ALL";
    const byBackupUnit: string = form.isBackup || "ALL";

    let status = "ALL";
    if (form.status && form.status.length > 0) {
      status = form.status.join(";");
    }

    let dateFrom = "ALL";
    let dateTo = "ALL";
    if (form.date && form.date.length === 2) {
      const [start, end] = form.date;
      dateFrom = toDefaultFormat(start);
      dateTo = toDefaultFormat(end);
    }

    const header: Array<string> = [
      company,
      branch,
      customer,
      dateFrom,
      dateTo,
      unitCode,
      icNumber,
      status,
      byInvoice,
      byBackupUnit,
    ];

    return header.join(",");
  };

  return { findAll, findAllStatus, filterBy, download, buildReportHeader };
};

export default useInvoiceControllingReport;
