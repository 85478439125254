








































































import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import {
  useCoa,
  useFindMasterType,
  useLocalFilter,
  useMapMasterTypeToOption,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Messages } from "@/models/enums/messages.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { FormMeta } from "@/store/ApPrepayment.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";

@Component({
  inject: {
    formTable: {
      from: "formTable",
    },
  },
  computed: {
    ...mapState({
      storeFormMeta: (st: any) => st.aPPrepaymentStore.formMeta,
      storeTaxRate: (st: any) => st.aPPrepaymentStore.taxRate,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    ...mapMutations({
      setSubtotal: "aPPrepaymentStore/setSubtotal",
      setTotalTax: "aPPrepaymentStore/setTotalTax",
      setTotal: "aPPrepaymentStore/setTotal",
      setFormMeta: "aPPrepaymentStore/setFormMeta",
    }),
    ...mapActions({
      doCalculation: "aPPrepaymentStore/doCalculation",
    }),
  },
})
export default class TableDetailsPrepayment extends Vue {
  useLocalFilter = useLocalFilter;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  @Prop() disabled!: Record<string, boolean>;

  formTable!: WrappedFormUtils;
  storeTaxRate!: number;
  setFormMeta!: (payload: FormMeta) => void;
  setSubtotal!: (payload: number) => void;
  setTotalTax!: (payload: number) => void;
  setTotal!: (payload: number) => void;
  doCalculation!: (payload: {
    amount: number;
    taxType: TAX_CALCULATION;
  }) => void;

  formRules = {
    amount: {
      label: "lbl_amount",
      name: "amount",
      placeholder: "lbl_amount",
      decorator: [
        "amount",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    termOfPayment: {
      label: "lbl_term_of_payment",
      name: "termOfPayment",
      placeholder: "lbl_term_of_payment",
      decorator: [
        "termOfPayment",
        {
          initialValue: 0,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    lineAccount: {
      label: "lbl_line_account",
      name: "lineAccount",
      placeholder: "lbl_line_account",
      decorator: [
        "lineAccount",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  optTop: Array<Option> = [];
  optLineAccount: Array<Option> = [];
  loading = {
    top: false,
    lineAccount: false,
  };

  @Watch("storeFormMeta.currencyCode")
  onChangeCurrency(): void {
    this.fetchLineAccount(new RequestQueryParams());
  }

  created(): void {
    this.onSearchLineAccount = debounceProcess(this.onSearchLineAccount);

    this.fetchLineAccount(new RequestQueryParams());
    this.fetchTop();
  }

  onChangeAmount(val: number): void {
    this.formTable.setFieldsValue({ amount: val });
    const field = this.formTable.getFieldsValue();
    this.doCalculation({ amount: val, taxType: field.taxType });
  }

  onSearchLineAccount(search = ""): void {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    if (search) {
      params.search = builder
        .push(["code", search], { like: "both" })
        .or()
        .push(["description", search], { like: "both" })
        .build();
    }
    this.fetchLineAccount(params);
  }

  fetchLineAccount(params: RequestQueryParamsModel): void {
    const { findAll, mapToOption } = useCoa();
    const builder = new SearchBuilder();
    const defaultQ: Array<string> = [
      builder.push(["parentAccount.code", "110501"], { like: "end" }).build(),
    ];

    if (this.storeFormMeta && this.storeFormMeta.currencyCode) {
      defaultQ.push(
        builder
          .push(["currency.currencyCode", this.storeFormMeta.currencyCode])
          .build()
      );
    }

    const copy = { ...params };
    if (copy.search) {
      defaultQ.unshift(copy.search);
    }
    params.search = defaultQ.join(builder.AND);
    this.loading.lineAccount = true;
    findAll(params)
      .then(response => {
        this.optLineAccount = mapToOption(response.data);
      })
      .finally(() => {
        this.loading.lineAccount = false;
      });
  }

  fetchTop(): void {
    this.loading.top = true;
    useFindMasterType("TOP")
      .then(response => {
        const sorted = [...response].sort((a, b) => +a.value - +b.value);
        this.optTop = useMapMasterTypeToOption(sorted);
      })
      .finally(() => {
        this.loading.top = false;
      });
  }
}
