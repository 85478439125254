import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DownloadRequestParam } from "@/models/interface/download";
import {
  TruckingSettlementApproveRequestDto,
  TruckingSettlementApproveResponseDto,
  TruckingSettlementDetailResponseDto,
  TruckingSettlementPostRequestDto,
  TruckingSettlementRejectRequestDto,
  TruckingSettlementResponseDto,
} from "@/models/interface/trucking-settlement";
import { truckingSettlementService } from "@/services/trucking-settlement.service";

export const useTruckingSettlement = () => {
  const findListSettlements = (
    params?: RequestQueryParams
  ): Promise<Pagination<TruckingSettlementResponseDto>> => {
    return truckingSettlementService.listOfSettlements(params);
  };

  const downloadListSettlements = (
    params: DownloadRequestParam
  ): Promise<Blob> => {
    return truckingSettlementService.downloadListSettlements(params);
  };

  const findSettlementDetail = (
    id: string
  ): Promise<TruckingSettlementDetailResponseDto> => {
    return truckingSettlementService.findSettlementDetail(id);
  };

  const approveSettlement = (
    id: string,
    dto: TruckingSettlementApproveRequestDto
  ): Promise<TruckingSettlementApproveResponseDto> => {
    return truckingSettlementService.approveSettlement(id, dto);
  };

  const rejectSettlement = (
    id: string,
    dto: TruckingSettlementRejectRequestDto
  ): Promise<void> => {
    return truckingSettlementService.rejectSettlement(id, dto);
  };

  const postSettlement = (
    dto: TruckingSettlementPostRequestDto
  ): Promise<void> => {
    return truckingSettlementService.postSettlement(dto);
  };

  return {
    findListSettlements,
    downloadListSettlements,
    findSettlementDetail,
    approveSettlement,
    rejectSettlement,
    postSettlement,
  };
};
