import { usePreferences, useTaxRate } from "@/hooks";
import {
  TruckingAccountReceivableFormPrepaymentLineState,
  TruckingAccountReceivableProductState,
} from "@interface/trucking-account-receivable";
import Decimal from "decimal.js-light";
import { RoundingUtils } from "./RoundingUtils";

export class TruckingAccountReceivableUtils {
  static countGross(product: TruckingAccountReceivableProductState): number {
    return RoundingUtils.round(
      new Decimal(product.price).times(product.quantity)
    ).toNumber();
  }

  static countTotalGross(
    products: TruckingAccountReceivableProductState[]
  ): number {
    return products.reduce(
      (a: number, b: TruckingAccountReceivableProductState) => {
        return new Decimal(a)
          .plus(TruckingAccountReceivableUtils.countGross(b))
          .toNumber();
      },
      0
    );
  }

  static countTotalBaseAmount(
    products: TruckingAccountReceivableProductState[]
  ): number {
    return products.reduce(
      (a: number, b: TruckingAccountReceivableProductState) => {
        return new Decimal(a).plus(b.baseAmount).toNumber();
      },
      0
    );
  }

  static countTotalTax(
    products: TruckingAccountReceivableProductState[]
  ): number {
    return products.reduce(
      (a: number, b: TruckingAccountReceivableProductState) => {
        return new Decimal(a).plus(b.taxAmount).toNumber();
      },
      0
    );
  }

  static countTotalUsedPrepayment(
    prepayments: TruckingAccountReceivableFormPrepaymentLineState[]
  ): number {
    return prepayments.reduce((a: number, b) => {
      return new Decimal(a).plus(b.appliedAmount).toNumber();
    }, 0);
  }

  static countDiscount(
    value: number,
    gross: number,
    isPercent = false
  ): { amount: number; percent: number } {
    let amount = 0;
    let percent = 0;

    if (isPercent) {
      const percentage = new Decimal(value || 0).dividedBy(100).toNumber();
      percent = value || 0;
      amount = new Decimal(gross || 0).times(percentage).toNumber();
    } else {
      amount = value || 0;
      percent = new Decimal(amount)
        .dividedBy(gross || 1)
        .times(100)
        .toNumber();
    }
    return {
      amount: amount,
      percent: percent,
    };
  }

  static setDefaultRevenueAccount(
    state: TruckingAccountReceivableProductState
  ): void {
    const preference = usePreferences().findByKey("trucking_revenue");
    if (preference && preference.value) {
      state.revenueAccount = { key: preference.value, label: preference.name };
    }
  }

  static async setDefaultTaxCode(
    state: TruckingAccountReceivableProductState
  ): Promise<void> {
    const preference = usePreferences().findByKey("trucking_tax");
    if (preference && preference.value) {
      state.taxCode = { key: preference.value, label: preference.name };
      state.taxRate = await useTaxRate(preference.value);
    }
  }
}
