import { DataInvoiceAp } from "@/models/interface/invoiceAp.interface";
import { invoiceAPServices } from "@/services/invoiceAp.service";

const useInvoiceAPFindListById = async (id: string): Promise<DataInvoiceAp> => {
  const { data } = await invoiceAPServices.listApInvoice({
    search: `secureId~${id}`,
  });
  const [item] = data;
  return item;
};

export { useInvoiceAPFindListById };
