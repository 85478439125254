// Core
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { Pagination } from "@/models/constant/interface/common.interface";
import { ApPaymentListResponseDto } from "@/models/interface/ap-payment";
import {
  DataResponseListApPaymentMethod,
  DataResponsePostApPayment,
  RequestApPayment,
  ResponseDetailApPayment,
  ResponsePPhSupplier,
  ResponseReportDebtCardLeasing,
  ResponseReportKartuHutang,
} from "@/models/interface/apPayment.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";

export class ApPaymentServices extends HttpClient {
  listApPayment(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ApPaymentListResponseDto>> {
    return this.get<Pagination<ApPaymentListResponseDto>>(Api.ApPayment, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listReportDebtCardLeasing = (
    params: RequestQueryParamsModel,
    lessorId: string
  ): Promise<ResponseReportDebtCardLeasing> => {
    return this.get<ResponseReportDebtCardLeasing>(
      Api.DebtCardLeasingReport + `/${lessorId}`,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listReportKartuHutang = (
    params: RequestQueryParamsModel
  ): Promise<ResponseReportKartuHutang> => {
    return this.get<ResponseReportKartuHutang>(Api.ReportKartuHutang, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listReportKartuHutangInvoiceNumber = (
    params: RequestQueryParamsModel
  ): Promise<ResponseReportKartuHutang> => {
    return this.get<ResponseReportKartuHutang>(
      Api.ReportKartuHutang + "/list/invoice",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadReportDebtCardLeasing = (
    params: RequestQueryParamsModel,
    lessorId: string
  ): Promise<Blob> => {
    return this.get<Blob>(Api.DebtCardLeasingReport + `/download/${lessorId}`, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadReportKartuHutang = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReportKartuHutang + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailApPayment = (id: string): Promise<ResponseDetailApPayment> => {
    return this.get<ResponseDetailApPayment>(Api.ApPayment + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listPPhSupplier = (
    params: RequestQueryParamsModel
  ): Promise<ResponsePPhSupplier> => {
    return this.get<ResponsePPhSupplier>(Api.PPhSupplier, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadPPhSupplier = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.PPhSupplier + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listPPhCustomer = (
    params: RequestQueryParamsModel
  ): Promise<ResponsePPhSupplier> => {
    return this.get<ResponsePPhSupplier>(Api.PPhCustomer, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadPPhCustomer = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.PPhCustomer + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listApPaymentMethod = (
    params: RequestQueryParamsModel
  ): Promise<DataResponseListApPaymentMethod[]> => {
    // prepayment/payment-method
    return this.get<DataResponseListApPaymentMethod[]>(
      Api.ApPayment + "/payment-method",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  postApPayment = (
    payload: RequestApPayment
  ): Promise<DataResponsePostApPayment> => {
    return this.post<DataResponsePostApPayment, RequestApPayment>(
      Api.ApPayment,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateApPayment = (
    payload: RequestApPayment,
    id: string
  ): Promise<DataResponsePostApPayment> => {
    return this.put<DataResponsePostApPayment, RequestApPayment>(
      Api.ApPayment + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  appliedApPayment = (
    payload: RequestApPayment,
    id: string
  ): Promise<DataResponsePostApPayment> => {
    return this.put<DataResponsePostApPayment, RequestApPayment>(
      Api.ApPayment + `/apply/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  voidApPayment = (
    payload: RequestApPayment,
    id: string
  ): Promise<DataResponsePostApPayment> => {
    return this.put<DataResponsePostApPayment, RequestApPayment>(
      Api.ApPayment + `/void/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printApPayment = (id: string): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(`${Api.ApPayment}/print/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export const apPaymentServices = new ApPaymentServices();
