import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import {
  INVOICE_AP_SOURCE,
  INVOICE_AP_STATUS,
} from "@/models/enums/invoice-ap.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataInvoiceAp,
  RequestParamDownloadPOGRInvoiceReport,
  RequestParamListPOGRInvoiceReport,
} from "@/models/interface/invoiceAp.interface";
import { InvoiceApSummaryResponseDto } from "@/models/interface/InvoiceApSummary.interface";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import store from "@/store";
import { Moment } from "moment";
import { useFindMasterType } from "./master-type";
import useDate from "./useDate";

type FilterField = Partial<{
  supplierType: string;
  invoiceDate: Moment[];
  branchName: string;
  supplierName: string;
  supplierId: string;
  invoiceNumber: string;
  invoiceSupplierNumber: string;
  invoiceStatus: string;
  invoiceSource: string;
}>;

const useInvoiceAP = () => {
  const initSummary = (): InvoiceApSummaryResponseDto => ({
    id: "",
    createdDate: "",
    modifiedDate: "",
    createdBy: "",
    modifiedBy: "",
    invoiceNumber: "",
    invoiceSupplierNo: "",
    invoiceDate: "",
    taxType: "" as TAX_CALCULATION,
    termOfPayment: 0,
    currencyId: "",
    currency: "",
    invoiceSource: "" as INVOICE_AP_SOURCE,
    taxInvoiceNumber: "",
    supplierName: "",
    supplierBillToAddress: "",
    status: "" as INVOICE_AP_STATUS,
    description: "",
    lines: [],
    invoiceSubtotal: 0,
    prepaymentUsed: 0,
    discountValue: 0,
    totalTax: 0,
    grandTotal: 0,
    totalAdditionalCost: 0,
  });

  const findSummary = (id: string) => {
    return invoiceAPServices.getSummary(id);
  };

  const download = (params: RequestQueryParamsModel) => {
    return invoiceAPServices.downloadApInvoice(params);
  };

  const buildParamDownload = (field: FilterField): string => {
    const { toDefaultFormat } = useDate();
    let invoiceDate = "ALL";
    if (field.invoiceDate && field.invoiceDate.length) {
      const [start, end] = field.invoiceDate;
      invoiceDate = toDefaultFormat(start) + " - " + toDefaultFormat(end);
    }

    const q: string[] = [
      store.state["authStore"].authData.companyName || "",
      field.branchName || "ALL",
      field.invoiceSource || "ALL",
      field.supplierName || "ALL",
      field.supplierType || "ALL",
      field.invoiceSupplierNumber || "ALL",
      invoiceDate,
      field.invoiceNumber || "ALL",
      field.invoiceStatus || "ALL",
    ];

    return q.join(",");
  };

  const print = (params: RequestQueryParamsModel) => {
    return invoiceAPServices.printApInvoice(params);
  };

  const findById = (id: string) => {
    return invoiceAPServices.detailApInvoice(id);
  };

  const findAll = (params?: RequestQueryParamsModel) => {
    return invoiceAPServices.listApInvoice(params);
  };

  const toOptions = (datas: DataInvoiceAp[]): Option<DataInvoiceAp>[] => {
    return datas.map<Option<DataInvoiceAp>>(item => ({
      label: item.documentNumber || "-",
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const toSupplierNoOptions = (
    datas: DataInvoiceAp[]
  ): Option<DataInvoiceAp>[] => {
    return datas.map<Option<DataInvoiceAp>>(item => ({
      label: item.invoiceSupplierNo,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const downloadPOGRInvoiceReport = (
    params?: Partial<RequestParamDownloadPOGRInvoiceReport>
  ) => {
    return invoiceAPServices.downloadPOGRInvoiceReport(params);
  };

  const findAllPOGRInvoice = (
    params?: Partial<RequestParamListPOGRInvoiceReport>
  ) => {
    return invoiceAPServices.getListPODetailReport(params);
  };

  const findApSource = () => {
    return useFindMasterType("INVOICE_AP_SOURCE");
  };

  const filterBy = (field: FilterField): string => {
    const { toStartDay, toEndDay } = useDate();
    const q: string[] = [];
    const builder = new SearchBuilder();

    if (field.invoiceNumber) {
      q.push(
        builder
          .push(["documentNumber", field.invoiceNumber], { like: "both" })
          .build()
      );
    }

    if (field.branchName) {
      q.push(
        builder
          .push(["branchWarehouse.name", field.branchName], { like: "both" })
          .build()
      );
    }

    if (field.invoiceSource) {
      q.push(
        builder
          .push(["invoiceSource", field.invoiceSource], { like: "both" })
          .build()
      );
    }

    if (field.supplierName) {
      q.push(
        builder
          .push(["supplier.firstName", field.supplierName], { like: "both" })
          .build()
      );
    }

    if (field.supplierId) {
      q.push(builder.push(["supplier.secureId", field.supplierId]).build());
    }

    if (field.invoiceSupplierNumber) {
      q.push(
        builder
          .push(["invoiceSupplierNo", field.invoiceSupplierNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (field.invoiceStatus) {
      q.push(
        builder.push(["status", field.invoiceStatus], { like: "both" }).build()
      );
    }

    if (field.invoiceDate && field.invoiceDate.length) {
      const [start, end] = field.invoiceDate;
      q.push(
        builder
          .push(["invoiceDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["invoiceDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    return q.join(builder.AND);
  };

  const findInvoiceApStatus = () => {
    return useFindMasterType("INVOICE_AP_STATUS");
  };

  const findAllInvoiceApType = () => {
    return useFindMasterType("INVOICE_AP_TYPE");
  };

  return {
    findInvoiceApStatus,
    filterBy,
    findApSource,
    downloadPOGRInvoiceReport,
    findAll,
    toOptions,
    toSupplierNoOptions,
    findAllPOGRInvoice,
    findById,
    download,
    print,
    buildParamDownload,
    findSummary,
    initSummary,
    findAllInvoiceApType,
  };
};

export default useInvoiceAP;
