import { getWithExpiry, setWithExpiry } from "@/helpers/localstorage";
import { Actions } from "@/models/constant/enums/actions.enum";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { StorageKeys } from "@/models/constant/enums/storage.enum";
import { LoginResponseModel } from "@/models/constant/interface/auth.interface";
import {
  PropsModel,
  StorageStateModel,
} from "@/models/constant/interface/common.interface";
import { config as RoutePath } from "@/models/constant/routes.constant";
import { INavMenu } from "@/models/interfaces/navigation.interface";
import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";
import invoiceApStore from "./account-payable/ap-invoice";
import leasingStore from "./account-payable/leasing";
import accountingAccountStore from "./accountingAccount.store";
import aPPrepaymentStore from "./ApPrepayment.store";
import aRPrepaymentStore from "./aRPrepayment.store";
import authStore from "./auth.store";
import companyStore from "./company.store";
import contactStore from "./contact.store";
import drawerStore from "./drawer.store";
import goodsReceiptChecklistStore from "./goodsReceiptChecklist.store";
import goodsReceiptPriceStore from "./goodsReceiptPrice.store";
import invoiceStore from "./invoice.store";
import preferenceStore from "./preference.store";
import productStore from "./product.store";
import { initListMenu } from "./resources/nav.resource";
import salesOrderStore from "./salesOrder.store";
import stockAdjStore from "./stockAdj.store";
import truckingMasterRateStore from "./truckingMasterRate.store";
import workOrderStore from "./work-order";

Vue.use(Vuex);

const state: StorageStateModel = {
  sidebarShow: "responsive",
  sidebarMinimize: true,
  access_token: getWithExpiry("access_token"),
  refresh_token: getWithExpiry("refresh_token"),
  emailForgotPassword: null,
  innerWidth: window.innerWidth,
  innerHeight: window.innerHeight,
  loadingComponent: false,
  listMenu: initListMenu(),
};

const mutations = {
  [Mutations.SET_LOADING_COMPONENT]: (
    stateVuex: PropsModel,
    payload: boolean
  ) => {
    stateVuex.loadingComponent = payload;
  },
  [Mutations.SET_TOGGLE_SIDEBAR_DEKSTOP]: (
    stateVuex: PropsModel,
    payload: boolean | string
  ) => {
    stateVuex.sidebarShow = payload;
  },
  [Mutations.SET_TOGGLE_SIDEBAR_MOBILE]: (
    stateVuex: PropsModel,
    payload: boolean | string
  ) => {
    stateVuex.sidebarShow = payload;
  },
  [Mutations.SET_ACCESS_TOKEN]: (
    stateVuex: PropsModel,
    access_token: string
  ) => {
    stateVuex.access_token = access_token;
  },
  [Mutations.SET_REFRESH_TOKEN]: (
    stateVuex: PropsModel,
    refresh_token: string
  ) => {
    stateVuex.refresh_token = refresh_token;
  },
  [Mutations.SET_EMAIL_FORGOT_PASSWORD]: (
    stateVuex: PropsModel,
    email: string
  ) => {
    stateVuex.emailForgotPassword = email;
  },
  [Mutations.SET_INNERWIDTH]: (
    stateVuex: PropsModel,
    width: string | number
  ) => {
    stateVuex.innerWidth = width;
  },
  [Mutations.SET_INNERHEIGHT]: (
    stateVuex: PropsModel,
    height: string | number
  ) => {
    stateVuex.innerHeight = height;
  },
  ["RESET_LIST_MENU"]: (stateVuex, value: INavMenu[]) => {
    stateVuex.listMenu = value;
  },
  set(stateVuex: PropsModel, [variable, value]: [string, boolean]) {
    stateVuex[variable] = value;
  },
};

const actions = {
  [Actions.ACTIONS_POST_LOGIN]: async (
    { commit }: PropsModel,
    payload: LoginResponseModel
  ) => {
    setWithExpiry(StorageKeys.ACCESS_TOKEN, payload.access_token, 500);
    setWithExpiry(StorageKeys.REFRESH_TOKEN, payload.refresh_token, 500);
    await commit(Mutations.SET_ACCESS_TOKEN, payload.access_token);
    await commit(Mutations.SET_REFRESH_TOKEN, payload.refresh_token);
    await commit("RESET_LIST_MENU", initListMenu());
    router.push(RoutePath.DASHBOARD_ROUTE.url);
  },
  [Actions.ACTIONS_REFRESH_TOKEN]: async (
    { commit }: PropsModel,
    payload: LoginResponseModel
  ) => {
    setWithExpiry(StorageKeys.ACCESS_TOKEN, payload.access_token, 500);
    setWithExpiry(StorageKeys.REFRESH_TOKEN, payload.refresh_token, 500);
    await commit(Mutations.SET_ACCESS_TOKEN, payload.access_token);
    await commit(Mutations.SET_REFRESH_TOKEN, payload.refresh_token);
    await commit("RESET_LIST_MENU", initListMenu());
  },
  [Actions.ACTIONS_TOGGLE_SIDEBAR_DEKSTOP]: ({ commit }: PropsModel) => {
    const sidebarOpened: boolean = [true, "responsive"].includes(
      state.sidebarShow
    );
    const sidebarShowed: boolean | string = sidebarOpened
      ? false
      : "responsive";
    commit(Mutations.SET_TOGGLE_SIDEBAR_DEKSTOP, sidebarShowed);
  },
  [Actions.ACTIONS_TOGGLE_SIDEBAR_MOBILE]: ({ commit }: PropsModel) => {
    const sidebarClosed: boolean = [false, "responsive"].includes(
      state.sidebarShow
    );
    const sidebarShowed: boolean | string = sidebarClosed ? true : "responsive";
    commit(Mutations.SET_TOGGLE_SIDEBAR_MOBILE, sidebarShowed);
  },
  [Actions.ACTIONS_LOGOUT]: async ({ commit }: PropsModel) => {
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(StorageKeys.BASE_CURRENCY);
    await commit(Mutations.SET_ACCESS_TOKEN, "");
    await commit(Mutations.SET_REFRESH_TOKEN, "");
    await commit("RESET_LIST_MENU", initListMenu());
    router.push(RoutePath.SIGNIN_ROUTE.url);
  },
  [Actions.ACTIONS_FORGOT_PASSWORD]: (
    { commit }: PropsModel,
    email: string
  ) => {
    commit(Mutations.SET_EMAIL_FORGOT_PASSWORD, email);
  },
};

export default new Vuex.Store({
  state,
  devtools: true,
  mutations,
  actions,
  modules: {
    contactStore,
    productStore,
    authStore,
    preferenceStore,
    invoiceStore,
    invoiceApStore,
    drawerStore,
    aPPrepaymentStore,
    leasingStore,
    salesOrderStore,
    goodsReceiptChecklistStore,
    goodsReceiptPriceStore,
    stockAdjStore,
    companyStore,
    accountingAccountStore,
    aRPrepaymentStore,
    workOrderStore,
    truckingMasterRateStore,
  },
});
