import { EnumFileBucket } from "@/models/constant/file-bucket.constant";
import { fileService } from "@/services/file.service";

const useUploader = () => {
  const uploadImage = (payload: FormData, module: string) => {
    return fileService.upload(payload, module);
  };

  const getFile = (bucket: EnumFileBucket, filename: string) => {
    return fileService.getFile(bucket, filename);
  };

  return { uploadImage, getFile };
};

export default useUploader;
