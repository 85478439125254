export enum Messages {
  // AUTH MESSAGES
  INVALID_TOKEN                 = "Access Token Is Invalid. Please Login!",
  SESSION_END                   = "Your Session Is Expired!",
  SIGNIN_SUCCESS                = "Login Succeed!",
  RESET_PASSWORD_SUCCESS        = "Your Password Has Been Reset!",
  FORGOT_PASSWORD_SUCCESS       = "Your Request Is Has Been Proceed. Please Check Your Email!",
  CHANGE_PASSWORD_SUCCESS       = "Your Password Has Been Changed!",

  // SUCCESS MESSAGES
  CREATE_SUCCESS                = "Data Successfully Created!",
  UPDATE_SUCCESS                = "Data Successfully Updated!",
  DELETE_SUCCESS                = "Data Successfully Deleted!",
  DOWNLOAD_FILE_SUCCESS         = "File Successfully Downloaded",
  UPLOAD_FILE_SUCCESS           = "File Successfully Uploaded!",
  SCHEDULER_ACTION_SUCCESS      = "Scheduler Started Successfully!",
  PROCESS_SUCCESS               = "Processing Data!",
  ORDER_SUBMIT_SUCCESS          = "Data Has Been Submitted!",
  CLAIM_ORDER_RELEASE_SUCCESS   = "Data Has Been Claimed!",
  CLAIM_DATA_RELEASE_SUCCESS    = "Data Has Been Ignored!",
  APPROVE_CERTIFICATE_SUCCESS   = "Data Has Been Approved!",
  REJECT_CERTIFICATE_SUCCESS    = "Data Has Been Rejected!",

  // FAIL MESSAGES
  CREATE_FAIL                   = "Data Failed to Created!",
  UPDATE_FAIL                   = "Data Failed to Updated!",
  DELETE_FAIL                   = "Data Failed to Deleted!",
  SCHEDULER_ACTION_FAIL         = "Scheduler Failed To Start!",
  PROCESS_FAIL                  = "Data Failed To Process!",
  ORDER_SUBMIT_FAIL             = "Data Failed To Submit!",
  CLAIM_ORDER_RELEASE_FAIL      = "Data Failed To Ignore",
  CLAIM_DATA_RELEASE_FAIL       = "Data Failed To Reject",
  APPROVE_CERTIFICATE_FAIL      = "Data Failed To Approve!",
  REJECT_CERTIFICATE_FAIL       = "Data Failed To Reject",
  
  // VALIDATION MESSAGES
  VALIDATION_SEARCH_DATA_REQUIRED         = "Not Allowing Empty Search!",
  VALIDATION_REQUIRED_FIELDS_WHEN_SUBMIT  = "Can Not Continue Proceed Until Certain Field Is Valid!",
  VALIDATION_REQUIRED_ERROR               = "lbl_validation_required_error",
  VALIDATION_EMAIL_FORMAT_ERROR           = "Email format not valid!",
  VALIDATION_PASSWORD_SIX_CHARACTER_ERROR = "Password must be six characters or longer!",
  VALIDATION_PASSWORD_CONTAINS_DIFFERENT_CHARACTER_ERROR  = "Password must contains lower and upper text and numeric!",
  VALIDATION_PASSWORD_OLD_AND_NEW_DIFFERENT_ERROR         = "Old and new passwords must be different!",
  VALIDATION_PASSWORD_NEW_AND_CONFIRM_MATCH_ERROR         = "New and confirm passwords didn't match!",
  VALIDATION_NUMBER_NOT_INTEGER                           = "Value is not valid. Required Number!",
  VALIDATION_NUMBER_NOT_DECIMAL                           = "Value not valid, Please insert a decimal number!",
  VALIDATION_EFFECTIVE_DATE_GREATER_THAN_TODAY            = "Effective date must greater than today!",
  VALIDATION_FULL_ACCESS                                  = "You dont have access to do this action!",
  VALIDATION_CONTACT_NUMBER_CONTAINS_13_DIGIT             = "Contact Number Should Have Minimum 13 Digit!",
  VALIDATION_MAX_DATE                                     = "lbl_message_error_max_date",

  // OTHER
  DATA_NOT_FOUND                          = "Data Not Found!",
  DATA_ORDER_NOTHING_WAS_SELECTED         = "Please Select At Least One Of These Options!",  
  UPLOAD_DEFAULT_ERROR_MESSAGE            = "There Is Problem While Uploading File. Please Make Sure You Have A Good Internet Connection!",
  SYSTEM_DEFAULT_ERROR_MESSAGE            = "There Is Problem With The Server. Please Contact Your Administrator!",
  
  // CONFIRM
  CONFIRM_CANCEL_TITLE                    = "Are You Sure To Cancel ?",
  CONFIRM_CANCEL_SUBTITLE                 = "If Yes, Your Previous Action Will Be Cancel.",
  CONFIRM_DELETE_TITLE                    = "Are You Sure Want To Delete This Data ?",
  CONFIRM_DELETE_SUBTITLE                 = "If Yes, this data will be deleted from the system.",
  CONFIRM_SCHEDULER_START_TITLE           = "Are You Sure To Continue ?",
  CONFIRM_SCHEDULER_START_SUBTITLE        = "If Yes, your current action will continue to process.",
  CONFIRM_DATA_ORDER_PROCESSED_TITLE      = "Are You Sure To Process This Order ?",
  CONFIRM_DATA_ORDER_PROCESSED_SUBTITLE   = "If Yes, your action will continue to process.",
  CONFIRM_CLAIM_TIMEOUT_TITLE             = "Process Timeout!",
  CONFIRM_CLAIM_TIMEOUT_SUBTITLE          = "If Ok, your data will be cancelled and you will redirect to certain page",
  CONFIRM_DATA_ORDER_REJECTED_TITLE       = "Are You Sure To Reject This Data ?",
  CONFIRM_DATA_ORDER_REJECTED_SUBTITLE    = "If Yes, your action will continue to process.",
  
}
