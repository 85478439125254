import {
  EnumTruckingCashInOutStatus,
  EnumTruckingCashInOutTransactionType,
} from "@/models/enums/TruckingCashInOut.enum";
import { TruckingCashInOutCreateRequestDto } from "@interface/trucking-cash-in-out";

export function buildTruckingCashInOutCreateDto(): TruckingCashInOutCreateRequestDto {
  return {
    branchId: "",
    transactionDate: "",
    currencyId: "",
    rates: 1,
    companyBankId: "",
    cashInOutTransfer: "NONE",
    supplierOrCustomerId: "",
    chequeNo: "",
    chequeBankName: "",
    description: "",
    attachment: "",
    journalNumber: "",
    status: "" as EnumTruckingCashInOutStatus,
    totalAmount: 0,
    customerSupplierBank: "",
    referenceNo: "",
    cashInOutLineDelete: [],
    cashInOutLineDTOS: [],
    transferSource: "",
    type: "" as EnumTruckingCashInOutTransactionType,
  };
}
