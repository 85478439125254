type SearchPushType = [string, string];
type SearchLikeType = "start" | "end" | "both";
type SearchOptionType = Partial<{
  /**
   * @description higher equal than
   */
  het: boolean;

  /**
   * @description lower equal than
   */
  let: boolean;

  /**
   * @description higher than
   */
  ht: boolean;

  /**
   * @description lower than
   */
  lt: boolean;

  like: SearchLikeType;
  not: boolean;
}>;

/**
 * @description search builder class
 * use this class to build search query for backend
 * @example
 * const builder = new SearchBuilder();
 * const querySearch = builder
 * .push(['name', value], { like: 'both' })
 * .or()
 * .push(['description', value], { like: 'start' })
 * .and()
 * .push(['category', value], { like: 'end' })
 * // use this to build query !
 * .build()
 * // querySearch = name~*beban*_OR_description~*beban_OR_category~beban*
 */
class SearchBuilder {
  readonly AND = "_AND_";
  readonly OR = "_OR_";
  readonly EQUAL = "~";
  readonly HIGHER_EQUAL_THAN = ">=";
  readonly LOWER_EQUAL_THAN = "<=";
  readonly LOWER_THAN = "<";
  readonly HIGHER_THAN = ">";
  readonly NOT = "!";
  readonly LIKE = "*";
  private query: string[] = [];
  static readonly AND = "_AND_";
  static readonly OR = "_OR_";

  and(): SearchBuilder {
    this.query.push(this.AND);
    return this;
  }

  or(): SearchBuilder {
    this.query.push(this.OR);
    return this;
  }

  /**
   * add query search into queries
   * pass opts on second paramater to
   * build likes query
   */
  push([key, value]: SearchPushType, opts?: SearchOptionType): SearchBuilder {
    let transformed = key + this.EQUAL + value;
    if (opts && opts.like) {
      transformed = key + this.EQUAL + this.buildLike(value, opts.like);
    }
    if (opts && opts.not) {
      transformed = key + this.NOT + value;
    }
    if (opts && opts.het) {
      transformed = key + this.HIGHER_EQUAL_THAN + value;
    }
    if (opts && opts.let) {
      transformed = key + this.LOWER_EQUAL_THAN + value;
    }
    if (opts && opts.ht) {
      transformed = key + this.HIGHER_THAN + value;
    }
    if (opts && opts.lt) {
      transformed = key + this.LOWER_THAN + value;
    }
    this.query.push(transformed);
    return this;
  }

  unshift(query: string): SearchBuilder {
    this.query.unshift(query);
    return this;
  }

  // reset query
  destroy(): void {
    this.query = [];
  }

  build(): string {
    const q = this.query.join("");
    this.destroy();
    return q;
  }

  /**
   * build like query
   * @param value query value
   * @param like likes on what place
   * @returns string
   */
  private buildLike(value: string, like: SearchLikeType): string {
    let returnVal = this.LIKE + value + this.LIKE;
    if (like === "start") {
      returnVal = this.LIKE + value;
    } else if (like === "end") {
      returnVal = value + this.LIKE;
    }
    return returnVal;
  }
}

export default SearchBuilder;
