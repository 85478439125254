import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ProductUomResponseDto } from "@/models/interface/master-uom";
import { settingsServices } from "@/services/settings.service";

type SearchField = Partial<{
  name: string;
}>;

const useProductUom = () => {
  const findAll = (
    params: RequestQueryParams
  ): Promise<Pagination<ProductUomResponseDto>> => {
    return settingsServices.getListProductUom(params);
  };

  const toOptions = (
    data: Array<ProductUomResponseDto>
  ): Array<Option<ProductUomResponseDto>> => {
    return data.map(item => ({
      label: item.unit,
      key: item.id,
      value: item.id,
      meta: item,
    }));
  };

  const searchBy = (field: SearchField): string => {
    const builder = new SearchBuilder();
    const query: string[] = [];

    if (field.name) {
      query.push(
        builder
          .push(["name", field.name], {
            like: "both",
          })
          .build()
      );
    }

    return query.join(builder.OR);
  };

  return { findAll, toOptions, searchBy };
};

export default useProductUom;
