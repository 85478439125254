import { Option } from "@/models/class/option.class";
import {
  DataInventory,
  ResponseListOfInventory,
} from "@/models/interface/logistic.interface";

export const useMapInventoryLineToOptions = (
  source: ResponseListOfInventory,
  to?: "code" | "name"
): Option<DataInventory>[] => {
  return source.data.map<Option<DataInventory>>((el, idx) => ({
    label: to === "code" ? el.product.code : el.product.name,
    value:
      to === "code"
        ? el.product.code
        : to === "name"
        ? el.product.name
        : el.product.id,
    meta: el,
    key: idx,
  }));
};
