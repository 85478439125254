import { findPercentage } from "@/helpers/common";
import { generateUUID } from "@/helpers/uuid";
import {
  INVOICE_AP_SOURCE,
  INVOICE_AP_STATUS,
} from "@/models/enums/invoice-ap.enum";
import { INVOICE_TYPE } from "@/models/enums/invoice.enum";
import {
  InvoiceApAdditionalCostDto,
  InvoiceApCreateDto,
  InvoiceApLineRequestDto,
  InvoiceApResponseDetailDto,
  InvoiceApUpdateDto,
} from "@/models/interface/ap-invoice";
import { PurchaseOrderLineResponseDto } from "@/models/interface/purchase-order";
import { ReceivingItemLineDraftResponseDto } from "@/models/interface/receive-item";
import {
  buildApInvoiceFormProductState,
  buildApInvoiceFormState,
} from "@/store/account-payable/ap-invoice/resource";
import {
  ApInvoiceFormProductState,
  ApInvoiceFormState,
} from "@/store/account-payable/ap-invoice/types";
import { Decimal } from "decimal.js-light";
import moment from "moment";

const isGoodsReceiptLine = (
  arg: any
): arg is ReceivingItemLineDraftResponseDto => {
  return typeof arg === "object" && "locationReceivedId" in arg;
};

const isPurchaseOrderLine = (arg: any): arg is PurchaseOrderLineResponseDto => {
  return typeof arg === "object" && "productLocationId" in arg;
};

export class ApInvoiceMapper {
  static toApInvoiceFormProductState(
    dto: PurchaseOrderLineResponseDto
  ): ApInvoiceFormProductState;

  static toApInvoiceFormProductState(
    dto: ReceivingItemLineDraftResponseDto
  ): ApInvoiceFormProductState;

  static toApInvoiceFormProductState(dto: unknown): ApInvoiceFormProductState {
    const state: ApInvoiceFormProductState = buildApInvoiceFormProductState();
    state.generated = true;

    if (isGoodsReceiptLine(dto)) {
      state.assetId = dto.assetId;
      state.unitCode = dto.unitCode;
      state.gross = new Decimal(dto.qty ?? 0).times(dto.price ?? 0).toNumber();
      state.brand = dto.merk;
      state.discountValue = dto.discountValue ?? 0;
      state.goodsReceiptLineId = dto.id;

      if (dto.productName && dto.productId) {
        state.partName = { key: dto.productId, label: dto.productName };
      }
      if (dto.productCode && dto.productId) {
        state.partNumber = { key: dto.productId, label: dto.productCode };
      }

      state.percentDiscount = findPercentage(dto.discountValue, state.gross);
      state.price = dto.price;
      state.qty = dto.qtyOutstanding;
      state.qtyAvailableForReturn = 0;
      state.taxCode = { label: dto.taxCode, key: dto.taxCodeId };
      state.taxRate = dto.taxRate;
      if (dto.inclusiveTaxRateId && dto.inclusiveTaxRateName) {
        state.inclusiveTax = {
          label: dto.inclusiveTaxRateName,
          key: dto.inclusiveTaxRateId,
        };
        state.inclusiveTaxRate = dto.inclusiveTaxRate;
      }
      state.subTotal = new Decimal(0);
      state.taxCode = { label: dto.taxCode, key: dto.taxCodeId };
      state.taxRate = dto.taxRate;
      state.taxValue = new Decimal(0);

      if (dto.productUom && dto.productUomId) {
        state.uom = {
          key: dto.productUomId,
          label: dto.productUom,
        };
      }
    }

    if (isPurchaseOrderLine(dto)) {
      state.gross = new Decimal(dto.qty ?? 0).times(dto.price ?? 0).toNumber();
      state.brand = dto.merk;
      state.description = dto.description;
      state.discountValue = dto.discountValue ?? 0;

      if (dto.productName && dto.productId) {
        state.partName = { key: dto.productId, label: dto.productName };
      }
      if (dto.productCode && dto.productId) {
        state.partNumber = { key: dto.productId, label: dto.productCode };
      }

      state.percentDiscount = findPercentage(dto.discountValue, state.gross);
      state.price = dto.price;
      state.purchaseOrderLineId = dto.id;
      state.qty = dto.qtyOutstanding;
      state.taxCode = { label: dto.taxCode, key: dto.taxCodeId };
      state.taxRate = dto.taxRate;
      if (dto.inclusiveTaxRateId && dto.inclusiveTaxRateName) {
        state.inclusiveTax = {
          label: dto.inclusiveTaxRateName,
          key: dto.inclusiveTaxRateId,
        };
      }
      state.inclusiveTaxRate = dto.inclusiveTaxRate ?? 0;

      if (dto.uom && dto.uomId) {
        state.uom = {
          key: dto.uomId,
          label: dto.uom,
        };
      }
    }

    return state;
  }

  static toCreateApLineDto(
    state: ApInvoiceFormProductState
  ): InvoiceApLineRequestDto {
    const lineDto: InvoiceApLineRequestDto = {
      additionalCostLine: state.additionalCosts.map(cost => {
        const costDto: InvoiceApAdditionalCostDto = {
          amount: cost.amount,
          notes: cost.notes || null,
        };
        if (cost.id) costDto.id = cost.id;
        return costDto;
      }),
      baseAmount: state.baseAmount.toString(),
      description: state.description ?? "",
      discountValue: String(state.discountValue),
      expenseAccountId: state.expenseAccount?.key ?? "",
      goodReceiptLineId: state.goodsReceiptLineId,
      incomeTaxId: state.incomeTax?.key ?? "",
      merk: state.brand ?? "",
      percentDiscount: state.percentDiscount,
      price: String(state.price),
      productId: state.partName?.key ?? "",
      purchaseOrderLineId: state.purchaseOrderLineId,
      qty: state.qty,
      secureId: state.id,
      subTotal: state.subTotal.toString(),
      taxCode: state.taxCode?.label ?? "",
      taxValue: state.taxValue.toString(),
      uomId: state.uom?.key ?? "",
      totalAdditionalCost: state.totalAdditionalCost,
      assetId: state.assetId || null,
      inclusiveTaxRateId: state.inclusiveTax?.key ?? null,
    };

    if (state.additionalCosts.length > 0) {
      lineDto.additionalCostLine = state.additionalCosts.map(item => {
        const costDto: InvoiceApAdditionalCostDto = {
          notes: item.notes ?? "",
          amount: item.amount,
        };

        if (item.id) {
          costDto.id = item.id;
        }

        return costDto;
      });
    }

    if (state.deletedAdditionalCostIds.length > 0) {
      lineDto.deletedAdditionalCostIds = state.deletedAdditionalCostIds;
    }

    return lineDto;
  }

  static toCreateDto(state: ApInvoiceFormState): InvoiceApCreateDto {
    const dto: InvoiceApCreateDto = {
      accountingDate: state.accountingDate?.format() ?? "",
      applyPrepayment: null,
      branchWarehouseId: state.branch?.key ?? "",
      currency: state.currency?.label.trim() ?? "",
      currencyRate: state.currencyRates ?? 1,
      description: state.invoiceDescription ?? "",
      discountValue: state.additionalDiscountAmount ?? 0,
      invoiceAPLines: state.products.map(product => {
        return this.toCreateApLineDto(product);
      }),
      invoiceDate: state.invoiceDate?.format() ?? "",
      invoiceReceivedDate: state.invoiceReceivedDate?.format() ?? "",
      invoiceSource: state.source ?? "",
      invoiceSupplierNo: state.invoiceSupplierNo,
      invoiceType: INVOICE_TYPE.INVOICE_AP,
      payablesAccountId: state.payablesAccount?.key ?? "",
      percentDiscount: state.additionalDiscountPercent,
      status: INVOICE_AP_STATUS.DRAFT,
      supplierBillToAddress: state.supplierBillAddress ?? "",
      supplierId: state.supplier?.key ?? "",
      supplierShipToAddress: state.supplierShipAddress ?? "",
      taxInvoiceDate: state.taxInvoiceDate?.format() ?? "",
      taxInvoiceNumber: state.taxInvoiceNumber ?? "",
      taxRegistrationName: state.taxRegistrationName ?? "",
      taxRegistrationNumber: state.taxRegistrationNumber ?? "",
      taxType: state.taxType ?? "",
      termOfPayment: Number(state.termOfPayment),
      isLuxuryGoods: state.isLuxuryGoods,
    };

    if (state.prepayments.length > 0 || state.deletedPrepaymentIds.length > 0) {
      dto.applyPrepayment = {
        prepaymentLines: [],
        deletedPrepaymentLineIds: null,
      };
      dto.applyPrepayment.prepaymentLines = state.prepayments.map(item => {
        return {
          appliedAmount: item.appliedAmount,
          description: item.description ?? "",
          invoicePrepaymentId: item.invoicePrepaymentId,
          secureId: item.secureId ?? null,
        };
      });
      dto.applyPrepayment.deletedPrepaymentLineIds = state.deletedPrepaymentIds;
    }
    return dto;
  }

  static toCreateDraftDto(state: ApInvoiceFormState): InvoiceApCreateDto {
    const dto: InvoiceApCreateDto = this.toCreateDto(state);
    dto.status = INVOICE_AP_STATUS.DRAFT;
    return dto;
  }

  static toCreateNeedApprovalDto(
    state: ApInvoiceFormState
  ): InvoiceApCreateDto {
    const dto: InvoiceApCreateDto = this.toCreateDto(state);
    dto.status = INVOICE_AP_STATUS.NEED_APPROVAL;
    return dto;
  }

  static toUpdateDto(state: ApInvoiceFormState): InvoiceApUpdateDto {
    const dto: InvoiceApUpdateDto = {
      ...this.toCreateDto(state),
      status: state.status as INVOICE_AP_STATUS,
      deletedInvoiceAPLineIds: null,
    };

    if (state.deletedLineIds.length > 0) {
      dto.deletedInvoiceAPLineIds = state.deletedLineIds;
    }

    return dto;
  }

  static toUpdateNeedApprovalDto(
    state: ApInvoiceFormState
  ): InvoiceApUpdateDto {
    const dto: InvoiceApUpdateDto = this.toUpdateDto(state);
    dto.status = INVOICE_AP_STATUS.NEED_APPROVAL;
    return dto;
  }

  static toUpdateUnpaidDto(state: ApInvoiceFormState): InvoiceApUpdateDto {
    const dto: InvoiceApUpdateDto = this.toUpdateDto(state);
    dto.status = INVOICE_AP_STATUS.UNPAID;
    return dto;
  }

  static toFormState(dto: InvoiceApResponseDetailDto): ApInvoiceFormState {
    const SOURCES_TO_GENERATE_FROM_PO: string[] = [INVOICE_AP_SOURCE.SERVICE];
    const SOURCES_TO_GENERATE_FROM_GR: string[] = [
      INVOICE_AP_SOURCE.RENT_TO_RENT,
      INVOICE_AP_SOURCE.SPAREPART,
      INVOICE_AP_SOURCE.OTHERS,
      INVOICE_AP_SOURCE.UNIT,
      INVOICE_AP_SOURCE.CAR,
    ];

    const state: ApInvoiceFormState = buildApInvoiceFormState();
    state.source = dto.invoiceSource;
    state.branch = {
      key: dto.branchWarehouseId,
      label: dto.branchWarehouseName,
    };
    state.currency = {
      key: dto.currency,
      label: dto.currency,
    };
    state.currencyRates = dto.currencyRate;
    state.supplier = {
      key: dto.supplierId,
      label: dto.supplierName,
    };
    state.supplierShipAddress = dto.supplierShipToAddress;
    state.supplierBillAddress = dto.supplierBillToAddress;
    state.termOfPayment = String(dto.termOfPayment);
    state.isLuxuryGoods = dto.isLuxuryGoods;

    if (
      SOURCES_TO_GENERATE_FROM_PO.includes(dto.invoiceSource) &&
      Array.isArray(dto.purchaseOrders)
    ) {
      state.documentReferences = dto.purchaseOrders.map(po => {
        return {
          key: po.id,
          label: po.documentNumber,
        };
      });
    } else if (
      SOURCES_TO_GENERATE_FROM_GR.includes(dto.invoiceSource) &&
      Array.isArray(dto.goodReceipts)
    ) {
      state.documentReferences = dto.goodReceipts.map(gr => {
        return {
          key: gr.id,
          label: gr.documentNumber,
        };
      });
    }

    state.taxType = dto.taxType;
    state.invoiceNumber = dto.documentNumber;
    state.invoiceSupplierNo = dto.invoiceSupplierNo;
    state.invoiceDate = dto.invoiceDate ? moment(dto.invoiceDate) : null;
    state.accountingDate = dto.accountingDate
      ? moment(dto.accountingDate)
      : null;
    state.invoiceReceivedDate = dto.invoiceReceivedDate
      ? moment(dto.invoiceReceivedDate)
      : null;
    state.journalNumber = dto.journalNo;
    state.journalId = dto.journalId;
    state.payablesAccount = {
      key: dto.payablesAccountId,
      label: dto.payablesAccount,
    };
    state.invoiceDescription = dto.description;
    state.status = dto.status as INVOICE_AP_STATUS;
    state.taxRegistrationNumber = dto.taxRegistrationNumber;
    state.taxRegistrationName = dto.taxRegistrationName;
    state.taxInvoiceDate = dto.taxInvoiceDate
      ? moment(dto.taxInvoiceDate)
      : null;
    state.taxInvoiceNumber = dto.taxInvoiceNumber;
    state.grandTotal = dto.grandTotal;
    state.remainingInvoiceAmount = dto.remainingInvoiceAmount;
    state.products = dto.invoiceAPLines.map((lineDto, index) => {
      const productState = buildApInvoiceFormProductState();
      productState.unitCode = lineDto.unitCode ?? "";
      productState.assetId = lineDto.assetId ?? "";
      productState.no = index + 1;
      productState.gross = new Decimal(lineDto.qty)
        .times(lineDto.price)
        .toNumber();
      productState.grossAfterDiscount = new Decimal(productState.gross)
        .minus(lineDto.discountValue || 0)
        .toNumber();
      productState.baseAmount = new Decimal(lineDto.baseAmount);
      productState.brand = lineDto.merk;
      productState.description = lineDto.description;
      productState.discountValue = lineDto.discountValue;
      productState.documentReference = lineDto.documentReference;
      productState.documentReferenceId = lineDto.documentReferenceId;
      productState.expenseAccount = {
        key: lineDto.expenseAccountId,
        label: lineDto.expenseAccount,
      };
      productState.goodsReceiptLineId = lineDto.goodReceiptLineId;
      productState.id = lineDto.id;
      if (lineDto.incomeTaxId) {
        productState.incomeTax = {
          label: lineDto.incomeTax,
          key: lineDto.incomeTaxId,
        };
      }
      productState.incomeTaxRate = lineDto.incomeTaxRate;
      productState.incomeTaxValue = String(lineDto.incomeTaxValue);
      productState.partName = {
        key: lineDto.productId,
        label: lineDto.partName,
      };
      productState.partNumber = {
        key: lineDto.productId,
        label: lineDto.partNumber,
      };
      productState.percentDiscount = lineDto.percentDiscount;
      productState.price = lineDto.price;
      productState.purchaseOrderLineId = lineDto.purchaseOrderLineId;
      productState.qty = lineDto.qty;
      productState.qtyAvailableForReturn = lineDto.qtyAvailableForReturn;
      productState.subTotal = new Decimal(lineDto.subTotal);
      productState.taxCode = {
        key: lineDto.taxCode,
        label: lineDto.taxCode,
      };
      productState.taxRate = lineDto.taxRate;
      productState.taxValue = new Decimal(lineDto.taxValue);
      productState.uom = {
        label: lineDto.uom,
        key: lineDto.uomId,
      };
      if (Array.isArray(lineDto.additionalCostLine)) {
        productState.additionalCosts = lineDto.additionalCostLine.map(cost => {
          return {
            amount: cost.amount,
            notes: cost.notes ?? undefined,
            id: cost.id,
            key: generateUUID(),
          };
        });
      }
      productState.totalAdditionalCost = lineDto.totalAdditionalCost ?? 0;
      productState.inclusiveTaxRate = lineDto.inclusiveTaxRate;
      if (lineDto.inclusiveTaxRateId) {
        productState.inclusiveTax = {
          label: lineDto.inclusiveTaxRateName,
          key: lineDto.inclusiveTaxRateId,
        };
      }
      return productState;
    });
    state.prePaymentUsedTotal = dto.prepaymentUsed;
    state.totalIncomeTax = dto.invoiceIncomeTaxAmount;

    if (dto.applyPrepayment && dto.applyPrepayment.prepaymentLines) {
      state.prePaymentUsedDetails = dto.applyPrepayment.prepaymentLines.map(
        prepayment => {
          return {
            amount: prepayment.appliedAmount,
            docNumber: prepayment.invoicePrepaymentNo,
            transactionDate: prepayment.invoicePrepaymentDate,
          };
        }
      );
      state.prepayments = dto.applyPrepayment.prepaymentLines.map(
        prepayment => {
          return {
            appliedAmount: prepayment.appliedAmount,
            description: prepayment.description,
            invoicePrepaymentId: prepayment.invoicePrepaymentId,
            invoicePrepaymentNumber: prepayment.invoicePrepaymentNo,
            secureId: prepayment.id,
            key: generateUUID(),
          };
        }
      );
    }

    if (dto.invoiceAPPaymentDetails) {
      state.paymentDetails = dto.invoiceAPPaymentDetails.map(payment => {
        return {
          amount: payment.paymentAmount,
          docNumber: payment.paymentNumber,
          transactionDate: payment.paymentDate,
        };
      });
    }
    state.paymentTotal = dto.paidAmount;
    state.additionalDiscountAmount = dto.discountValue;
    state.additionalDiscountPercent = dto.percentDiscount;
    state.totalAdditionalCost = dto.invoiceAPLines.reduce(
      (left, right) =>
        new Decimal(left).plus(right.totalAdditionalCost ?? 0).toNumber(),
      0
    );
    state.totalGross = dto.total;
    state.invoiceSubTotal = dto.invoiceSubtotal;
    state.totalTax = dto.totalTax;
    return state;
  }
}
