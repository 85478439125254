import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ApPaymentListResponseDto } from "@/models/interface/ap-payment";
import { apPaymentServices } from "@/services/apPayment.service";

type SearchField = Partial<{
  documentNo: string;
}>;

const useApPayment = () => {
  const findAll = (
    params?: RequestQueryParams
  ): Promise<Pagination<ApPaymentListResponseDto>> => {
    return apPaymentServices.listApPayment(params);
  };

  const toOptions = (
    data: Array<ApPaymentListResponseDto>
  ): Array<Option<ApPaymentListResponseDto>> => {
    return data.map(item => ({
      key: item.id,
      label: item.documentNo || "-",
      value: item.id,
      meta: item,
    }));
  };

  const searchBy = ({ documentNo = "" }: SearchField): string => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (documentNo) {
      query.push(
        builder
          .push(["documentNo", documentNo], {
            like: "both",
          })
          .build()
      );
    }

    return query.join(builder.OR);
  };

  return { findAll, toOptions, searchBy };
};

export default useApPayment;
