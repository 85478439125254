import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import {
  InvoiceArCreateDto,
  InvoiceArUpdateDto,
} from "@/models/interface/account-receivable";
import { ReqParamDownloadRecurring } from "@/models/interface/AccountReceivables.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ApproveInvoice,
  DataResponseListArInvoice,
  GenerateReceipt,
  ParamsPrintInvoiceAr,
  PrintTitleRequestDto,
} from "@/models/interface/invoice.interface";
import { arService } from "@/services/ar.service";
import { invoiceServices } from "@/services/invoice.service";
import { useFindMasterType, useMapMasterTypeToOption } from "./master-type";

const useInvoiceAR = () => {
  const approveInvoice = (payload: ApproveInvoice[], ids: string[]) => {
    return invoiceServices.approveInvoice(payload, ids.join(","));
  };

  const generateReceipt = (ids: string[]) => {
    return invoiceServices.generateReceipt(ids.join(","));
  };

  const printReceipt = (
    payload: GenerateReceipt,
    params: RequestQueryParamsModel
  ) => {
    return invoiceServices.printReceipt(payload, params);
  };

  const findInvoiceCode = (
    taxInvoiceCode: string,
    params: {
      taxHasBeenUploaded: string;
      invoiceDate: string;
      taxInvoiceSerialNumber: string;
    }
  ): Promise<string> => {
    return invoiceServices.getInvoiceCode(taxInvoiceCode, params);
  };

  const addPrintTitle = (payload: PrintTitleRequestDto) => {
    return invoiceServices.addPrintTitle(payload);
  };

  const cancelInvoiceAr = (invoiceId: string) => {
    return invoiceServices.cancelArInvoice(invoiceId);
  };

  const postJournalInvoiceAr = (invoiceId: string) => {
    return invoiceServices.postJournal(invoiceId);
  };

  const updateInvoiceAr = (invoiceId: string, payload: InvoiceArUpdateDto) => {
    return invoiceServices.updateInvoiceAr(invoiceId, payload);
  };

  const submitInvoiceAr = (invoiceId: string, payload: InvoiceArUpdateDto) => {
    return invoiceServices.submitInvoiceAr(invoiceId, payload);
  };

  const createInvoiceAr = (payload: InvoiceArCreateDto) => {
    return invoiceServices.createInvoiceAr(payload);
  };

  const rejectInvoiceAr = (invoiceId: string) => {
    return invoiceServices.rejectInvoiceAr(invoiceId);
  };

  const print = (id: string, params: ParamsPrintInvoiceAr) => {
    return invoiceServices.printInvoice(id, params);
  };

  const findAllPrintTitle = () => {
    return invoiceServices.getPrintTitle();
  };

  const findSummary = (invoiceId: string) => {
    return invoiceServices.getInvoiceDetailSummary(invoiceId);
  };

  const findAll = (params: RequestQueryParamsModel) => {
    return invoiceServices.getListInvoiceAR(params);
  };

  /**
   * @deprecated
   * use {@linkcode findOne}
   */
  const findById = (id: string) => {
    return invoiceServices.getDetailInvoiceAR(id);
  };

  const findOne = (id: string) => {
    return invoiceServices.getDetailInvoiceAr(id);
  };

  const toOptions = (
    datas: DataResponseListArInvoice[]
  ): Option<DataResponseListArInvoice>[] => {
    return datas.map<Option<DataResponseListArInvoice>>(item => ({
      label: item.documentNumber,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const downloadRecurring = (params: ReqParamDownloadRecurring) => {
    return arService.downloadRecurring(params);
  };

  const findMasterStatus = async () => {
    const response = await useFindMasterType("INVOICE_AR_STATUS");
    return useMapMasterTypeToOption(response);
  };

  const findStatusForReport = async () => {
    const res = await findMasterStatus();
    const exclude = ["CANCELLED", "REJECTED", "RETURNED"];
    return res.filter(item => !exclude.includes(item.value.toUpperCase()));
  };

  const findInvoiceArPaymentStatus = async () => {
    const response = await findMasterStatus();
    const status = ["PARTIAL_PAID", "FULLY_PAID", "UNPAID"];
    const filtered = response.filter(item =>
      status.includes(item.value.toUpperCase())
    );
    return filtered;
  };

  const findMasterSource = async () => {
    const response = await useFindMasterType("INVOICE_AR_SOURCE");
    return useMapMasterTypeToOption(response);
  };

  const findMasterStatusPphArReceipt = async () => {
    const response = await useFindMasterType("AR_RECEIPT_PPH_STATUS");
    return response;
  };

  const findAllReceivableMutation = (params?: RequestQueryParamsModel) => {
    return invoiceServices.getListReceivableMutation(params);
  };

  const findAllArReceiptReport = async (params?: RequestQueryParamsModel) => {
    return await invoiceServices.getAllArReceiptReport(params);
  };

  const downloadArReceiptReport = async (params?: RequestQueryParamsModel) => {
    return await invoiceServices.downloadArReceiptReport(params);
  };

  const filterBy = (field: { invoiceNo: string }): string => {
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (field.invoiceNo) {
      q.push(
        builder
          .push(["documentNumber", field.invoiceNo], { like: "both" })
          .build()
      );
    }

    return q.join(builder.OR);
  };

  return {
    print,
    filterBy,
    findById,
    downloadArReceiptReport,
    findMasterStatusPphArReceipt,
    findAllArReceiptReport,
    findAllReceivableMutation,
    findMasterSource,
    findAll,
    toOptions,
    downloadRecurring,
    findMasterStatus,
    findInvoiceArPaymentStatus,
    findAllPrintTitle,
    addPrintTitle,
    findStatusForReport,
    findSummary,
    findInvoiceCode,
    cancelInvoiceAr,
    postJournalInvoiceAr,
    updateInvoiceAr,
    submitInvoiceAr,
    createInvoiceAr,
    rejectInvoiceAr,
    findOne,
    approveInvoice,
    generateReceipt,
    printReceipt,
  };
};

export default useInvoiceAR;
