import { useArPrepayment } from "@/hooks";
import { ONE } from "@/models/constant/global.constant";
import { InvoiceArTypeEnum } from "@/models/enums/invoice.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { DetailForm, FormValue } from "@/store/aRPrepayment.store";

export const initForm = (): FormValue => ({
  accountingDate: null,
  amount: 0,
  branchWarehouseId: "",
  currency: "",
  currencyRate: ONE,
  customerBillToAddress: "",
  customerId: "",
  customerShipToAddress: "",
  customerTaxType: "",
  description: "",
  invoiceDate: null,
  invoiceType: "" as InvoiceArTypeEnum,
  lineAccountId: "",
  lineAccountName: "",
  operatorName: "",
  poNumber: "",
  receivableAccountId: "",
  receivableAccountName: "",
  salesReturnId: "",
  subTotal: 0,
  taxCode: "",
  taxInvoiceDate: null,
  taxInvoiceNumber: "",
  taxRate: 0,
  taxRegistrationName: "",
  taxRegistrationNumber: "",
  taxType: TAX_CALCULATION.EXCLUSIVE,
  termOfPayment: "",
  total: 0,
  totalTax: 0,
});

export const initDetail = (): DetailForm => {
  const { initDetailDto } = useArPrepayment();
  const dt = initDetailDto();

  return {
    ...dt,
    accountingDate: null,
    invoiceDate: null,
    taxInvoiceDate: null,
  };
};
