var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    { staticClass: "content" },
    [
      _c("AppBreadcrumb", {
        staticClass: "mb-3",
        attrs: { breadcrumbs: _vm.$route.meta.breadcrumb }
      }),
      _c("router-view", { key: _vm.$route.path })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }