// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const CONTACT_ROUTE: RoutePathModel = {
  path: "/contact",
  url: "/contact",
  name: "",
  meta: {
    breadcrumb: ["lbl_contact"],
    title_head: "lbl_contact",
    need_auth: true,
  },
};

export const CONTACT_ROUTE_LIST: RoutePathModel = {
  path: "/",
  url: "/",
  name: "settings.contact.list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_contact",
        href: "/",
      },
    ],
    title_head: "lbl_list_contact",
  },
};

export const CONTACT_ROUTE_CREATE: RoutePathModel = {
  path: `${CONTACT_ROUTE.url}/create`,
  url: `${CONTACT_ROUTE.url}/create`,
  name: "settings.contact.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_contact",
        icon: "idcard",
        href: CONTACT_ROUTE.url,
      },
      {
        name: "lbl_create_contact_data",
        href: `${CONTACT_ROUTE.url}/create`,
      },
    ],
    title_head: "lbl_create_contact_data",
    mode: Mode.CREATE,
  },
};

export const CONTACT_ROUTE_EDIT: RoutePathModel = {
  path: `${CONTACT_ROUTE.url}/:id/edit`,
  url: `${CONTACT_ROUTE.url}/:id/edit`,
  name: "settings.contact.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_contact",
        icon: "idcard",
        href: CONTACT_ROUTE.url,
      },
      {
        name: "lbl_edit_contact_data",
        href: `${CONTACT_ROUTE.url}/edit`,
      },
    ],
    title_head: "lbl_edit_contact_data",
    mode: Mode.EDIT,
  },
};

export const CONTACT_ROUTE_DETAIL: RoutePathModel = {
  path: `${CONTACT_ROUTE.url}/:id`,
  url: `${CONTACT_ROUTE.url}/:id`,
  name: "settings.contact.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_contact",
        icon: "idcard",
        href: CONTACT_ROUTE.url,
      },
      {
        name: "lbl_contact_data_detail",
        href: `${CONTACT_ROUTE.url}`,
      },
    ],
    title_head: "lbl_contact_data_detail",
    mode: Mode.DETAIL,
  },
};
