import { RouteConfig } from 'vue-router';

const router: RouteConfig = {
  path: "/error/not-found",
  alias: "*",
  name: "ErrorNotFound",
  meta: {
    title_head: "Page Not Found",
  },
  component: () => import(/* webpackPrefetch: true */"./Error404.vue")
};

export default router;