import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { InvoiceArListDto } from "@interface/account-receivable";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@interface/http.interface";
import {
  TruckingInvoiceArCreateDto,
  TruckingInvoiceArResponseDto,
  TruckingInvoiceArUpdateDto,
} from "@interface/trucking-account-receivable";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class TruckingAccountReceivableService extends HttpClient {
  constructor() {
    super();
  }

  getInvoiceList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<InvoiceArListDto>> {
    return this.get<Pagination<InvoiceArListDto>>(
      Api.TruckingAccountReceivableInvoiceList,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createInvoiceAr(
    payload: TruckingInvoiceArCreateDto
  ): Promise<TruckingInvoiceArResponseDto> {
    return this.post<TruckingInvoiceArResponseDto, TruckingInvoiceArCreateDto>(
      Api.InvoiceAR,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailInvoiceAr(id: string): Promise<TruckingInvoiceArResponseDto> {
    return this.get<TruckingInvoiceArResponseDto>(`${Api.InvoiceAR}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateInvoiceAr(
    id: string,
    payload: TruckingInvoiceArUpdateDto
  ): Promise<TruckingInvoiceArResponseDto> {
    return this.put<TruckingInvoiceArResponseDto, TruckingInvoiceArUpdateDto>(
      `${Api.InvoiceAR}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitInvoiceAr(
    id: string,
    payload: TruckingInvoiceArUpdateDto
  ): Promise<TruckingInvoiceArResponseDto> {
    return this.post<TruckingInvoiceArResponseDto, TruckingInvoiceArUpdateDto>(
      `${Api.InvoiceARSubmit}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const truckingAccountReceivableService =
  new TruckingAccountReceivableService();
