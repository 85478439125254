import { TruckingMasterRateUpdateRequestDto } from "@/models/interface/trucking-master-rate";
import { initForm } from "@/store/resources/truckingMasterDriver.resource";
import { LabelInValue } from "@/types";

export type FormValue = TruckingMasterRateUpdateRequestDto & {
  id: string;
  rateAccount: LabelInValue | undefined;
};
export type State = {
  form: FormValue;
};

const state: State = {
  form: initForm(),
};

const mutations = {
  setForm: (st: State, payload: FormValue): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
};

const getters = {
  getForm: (st: State): FormValue => {
    return st.form;
  },
};

const actions = {
  resetStore: ({ commit }): void => {
    const form = initForm();
    commit("setForm", form);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
