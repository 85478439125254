import { SearchBuilder } from "@/builder";
import { ProductFifoValuationReportDTO } from "@/models/interface/FifoValuationReport.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import { Moment } from "moment";
import { Row } from "./table";
import useDate from "./useDate";

type FilterField = Partial<{
  productCategory: string;
  fromDate: Moment;
  toDate: Moment;
  warehouse: string;
  branch: string;
}>;

type ParamField = {
  company: string;
  branch: string;
  fromDate: string;
  productCategory: string;
  dateTo: string;
  productName: string;
  productCode: string;
  warehouse: string;
};

const useFIfoValuationReport = () => {
  const findAll = (params: RequestQueryParamsModel) => {
    return logisticServices.getFifoReport(params);
  };

  const print = (params: RequestQueryParamsModel) => {
    return logisticServices.printFifoReport(params);
  };

  const download = (params: RequestQueryParamsModel) => {
    return logisticServices.downloadFifoReport(params);
  };

  const toTableRow = (
    data: ProductFifoValuationReportDTO[]
  ): Row<ProductFifoValuationReportDTO, number>[] => {
    return data.map<Row<ProductFifoValuationReportDTO, number>>((item, i) => ({
      ...item,
      key: i,
    }));
  };

  const buildParam = (field: ParamField): string => {
    const param: string[] = [
      field.company,
      field.branch,
      field.fromDate,
      field.productCategory,
      field.dateTo,
      field.productName,
      field.productCode,
      field.warehouse,
    ];
    return param.join(",");
  };

  const filterBy = (field: FilterField): string => {
    const { productCategory, fromDate, toDate, warehouse, branch } = field;
    const { toStartDay, toEndDay } = useDate();
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (productCategory) {
      q.push(builder.push(["categoryId", productCategory]).build());
    }

    if (fromDate) {
      q.push(
        builder
          .push(["transactionDate", toStartDay(fromDate).format()], {
            het: true,
          })
          .build()
      );
    }

    if (toDate) {
      q.push(
        builder
          .push(["transactionDate", toEndDay(toDate).format()], { let: true })
          .build()
      );
    }

    if (warehouse) {
      q.push(builder.push(["warehouseId", warehouse]).build());
    }

    if (branch) {
      q.push(builder.push(["branchId", branch]).build());
    }

    return q.join(builder.AND);
  };

  return { findAll, print, filterBy, download, buildParam, toTableRow };
};

export default useFIfoValuationReport;
