import { RouteConfig } from 'vue-router';

// Models
import { config } from '@/models/constant/routes.constant';

const router: RouteConfig = {
    path: config.GENERALLEDGER_ROUTE.path,
    name: config.GENERALLEDGER_ROUTE.name,
    meta: config.GENERALLEDGER_ROUTE.meta,
    component: {
        render(c) { return c("router-view") }
    },
    children: [
        {
            path: config.OPENCLOSE_ROUTE.path,
            name: config.OPENCLOSE_ROUTE.name,
            meta: config.OPENCLOSE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./OpenClose.vue")
        },
    ]
};

export default router;
