import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { ApInvoiceStoreState } from "./types";

export const getters = {
  ["TAX_CALCULATION_EXCLUSIVE"]: (state: ApInvoiceStoreState): boolean => {
    return state.formState.taxType === TAX_CALCULATION.EXCLUSIVE;
  },
  ["TAX_CALCULATION_INCLUSIVE"]: (state: ApInvoiceStoreState): boolean => {
    return state.formState.taxType === TAX_CALCULATION.INCLUSIVE;
  },
  ["GET_ALLOW_TO_EDIT"]: (state: ApInvoiceStoreState): boolean => {
    const ALLOW_EDIT_DOCUMENT: string[] = [
      INVOICE_AP_STATUS.DRAFT,
      INVOICE_AP_STATUS.NEED_APPROVAL,
    ];
    return (
      !!state.formState.status &&
      !ALLOW_EDIT_DOCUMENT.includes(state.formState.status)
    );
  },
  ["GET_DOCUMENT_REFERENCE_NUMBER"]: (state: ApInvoiceStoreState): string => {
    return state.formState.documentReferences
      ? state.formState.documentReferences.map(item => item.label).join(", ")
      : "";
  },
};
