export enum USER {
  USER_DATA = "USER_DATA",
  USER_ROLE = "USER_ROLE"
}

export enum ROLE {
  ADMINISTRATOR = "Administrator",
  OPERATIONAL = "Operational",
  PURCHASING = "Purchasing"
}
