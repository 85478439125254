import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { AccountingTaxResponseDto } from "@/models/interface/master-tax";
import { DataTax } from "@/models/interface/settings.interface";
import { invoiceServices } from "@/services/invoice.service";
import { settingsServices } from "@/services/settings.service";
import Decimal from "decimal.js-light";
import { useFindMasterType } from "./master-type";

const useTaxFindById = async (taxId: string): Promise<DataTax> => {
  const { data } = await settingsServices.listOfTax(
    { search: `secureId~${taxId}` },
    ""
  );
  const [item] = data;

  return item;
};

const useTaxRate = async (taxId: string): Promise<number> => {
  const { rate } = await useTaxFindById(taxId);
  return rate || 0;
};

const useTax = () => {
  /**
   * @deprecated
   * new hooks {@linkcode findCollections}
   */
  const findAll = (params: RequestQueryParamsModel) => {
    return settingsServices.listOfTax(params, "");
  };

  const findById = (id: string): Promise<AccountingTaxResponseDto> => {
    return settingsServices.getTaxDetail(id);
  };

  const findAllVatOut = (search?: string) => {
    const params = new RequestQueryParams();
    const queryVatOut = new SearchBuilder()
      .push(["taxType", "VAT_OUT"])
      .build();
    const query: string[] = [queryVatOut];
    if (search) {
      const queryCode = new SearchBuilder()
        .push(["code", search], { like: "both" })
        .build();
      query.push(queryCode);
    }
    params.search = query.join(new SearchBuilder().AND);
    return settingsServices.getListTax(params);
  };

  const findAllVatIn = (search?: string) => {
    const params = new RequestQueryParams();
    const queryVatIn = new SearchBuilder().push(["taxType", "VAT_IN"]).build();
    const query: string[] = [queryVatIn];
    if (search) {
      const queryCode = new SearchBuilder()
        .push(["code", search], { like: "both" })
        .build();
      query.push(queryCode);
    }
    params.search = query.join(new SearchBuilder().AND);
    return settingsServices.getListTax(params);
  };

  const findByTaxRateAndType = (
    rate: number,
    type: "VAT_OUT" | "VAT_IN"
  ): Promise<Pagination<AccountingTaxResponseDto>> => {
    const builder = new SearchBuilder();

    return settingsServices.getListTax({
      search: builder
        .push(["rate", rate.toString()])
        .and()
        .push(["taxType", type])
        .build(),
    });
  };

  const findCollections = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AccountingTaxResponseDto>> => {
    return settingsServices.getListTax(params);
  };

  const toOptions = (
    source: AccountingTaxResponseDto[]
  ): Option<AccountingTaxResponseDto>[] => {
    return source.map(item => ({
      key: item.id,
      label: item.code,
      value: item.id,
      meta: item,
    }));
  };

  const cancelPphByIds = async (ids: Array<string>) => {
    return await invoiceServices.cancelPphByIds(ids.join(","));
  };

  const findTaxIncomeStatus = async () => {
    return await useFindMasterType("INPUT_PPH_STATUS");
  };

  const findAllPph = (params: RequestQueryParamsModel) => {
    return invoiceServices.getListInputPph(params);
  };

  const filterBy = (
    field: Partial<{
      docNumber: string;
      taxType: string;
      code: string;
      description: string;
    }>
  ): string => {
    const builder = new SearchBuilder();
    const q: Array<string> = [];
    if (field.docNumber) {
      // TODO: refactor docNumber to pphNumber
      q.push(
        builder.push(["pphNumber", field.docNumber], { like: "both" }).build()
      );
    }

    if (field.code) {
      q.push(builder.push(["code", field.code], { like: "both" }).build());
    }

    if (field.taxType) {
      q.push(builder.push(["taxType", field.taxType]).build());
    }

    if (field.description) {
      q.push(
        builder
          .push(["description", field.description], {
            like: "both",
          })
          .build()
      );
    }

    return q.join(builder.OR);
  };

  const findPphBrutoTotal = (
    pphAmountTotal: number,
    taxRate: number
  ): number => {
    if (taxRate == 0) return 0;
    const val = new Decimal(pphAmountTotal || 0);
    const rate = new Decimal(taxRate || 0).dividedBy(100);

    return val.dividedBy(rate).toNumber();
  };

  return {
    cancelPphByIds,
    findPphBrutoTotal,
    findTaxIncomeStatus,
    findAllPph,
    filterBy,
    findAll,
    toOptions,
    findById,
    findCollections,
    findByTaxRateAndType,
    findAllVatOut,
    findAllVatIn,
  };
};

export { useTax, useTaxFindById, useTaxRate };
