import { ONE } from "@/models/constant/global.constant";
import { InvoiceArPrepaymentStatusEnum } from "@/models/enums/ArPrepayment.enum";
import { InvoiceArTypeEnum } from "@/models/enums/invoice.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  InvoicePrepaymentCreateDto,
  InvoicePrepaymentResponseDto,
} from "@/models/interface/ar-prepayment";

export function buildInvoicePrepaymentCreateDto(): InvoicePrepaymentCreateDto {
  return {
    inclusiveTaxRateId: null,
    baseAmount: "0",
    accountingDate: "",
    amount: 0,
    branchWarehouseId: "",
    currency: "",
    currencyRate: ONE,
    customerBillToAddress: "",
    customerId: "",
    customerShipToAddress: "",
    customerTaxType: "",
    description: "",
    invoiceDate: "",
    invoicePrepaymentStatus: null,
    invoiceType: "" as InvoiceArTypeEnum,
    lineAccountId: "",
    operatorName: "",
    poNumber: "",
    receivableAccountId: "",
    salesReturnId: "",
    subTotal: "0",
    taxCode: "",
    taxInvoiceDate: "",
    taxInvoiceNumber: "",
    taxRegistrationName: "",
    taxRegistrationNumber: "",
    taxType: "" as TAX_CALCULATION,
    termOfPayment: 0,
    total: "0",
    totalTax: "0",
    isLuxury: false,
  };
}

export function buildInvoicePrepaymentDetailDto(): InvoicePrepaymentResponseDto {
  return {
    baseAmount: 0,
    inclusiveTaxRate: 0,
    inclusiveTaxRateName: "",
    accountingDate: "",
    amount: 0,
    branchWarehouse: "",
    branchWarehouseId: "",
    createdBy: "",
    createdDate: "",
    currency: "",
    currencyRate: ONE,
    customerBillToAddress: "",
    customerId: "",
    customerName: "",
    customerShipToAddress: "",
    customerTaxType: "",
    description: "",
    documentNumber: "",
    grossAmount: 0,
    id: "",
    invoiceARDetails: [],
    invoiceARReceiptDetails: [],
    invoiceDate: "",
    invoicePrepaymentStatus: "" as InvoiceArPrepaymentStatusEnum,
    invoiceType: "" as InvoiceArTypeEnum,
    journalId: "",
    journalNumber: "",
    lineAccount: "",
    lineAccountId: "",
    modifiedBy: "",
    modifiedDate: "",
    operatorName: "",
    paidAmount: 0,
    poNumber: "",
    prepaymentPaid: 0,
    prepaymentUsed: 0,
    receivableAccount: "",
    receivableAccountId: "",
    remainingAmount: 0,
    remainingPrepayment: 0,
    returnNumber: "",
    subTotal: 0,
    taxCode: "",
    taxInvoiceDate: "",
    taxInvoiceNumber: "",
    taxRegistrationName: "",
    taxRegistrationNumber: "",
    taxType: "" as TAX_CALCULATION,
    termOfPayment: 0,
    total: 0,
    totalTax: 0,
    isLuxury: false,
  };
}
