import { cashManagementServices } from "@/services/cashmanagement.service";

const useBankTransfer = () => {
  const print = (id: string) => {
    return cashManagementServices.printBankTransfer(id);
  };

  return { print };
};

export default useBankTransfer;
