







































import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapMutations } from "vuex";
import { LangSwitcher } from "@/components/LangSwitcher";
import { Actions } from "@/models/constant/enums/actions.enum";

@Component({
  components: {
    LangSwitcher,
  },
  methods: {
    ...mapMutations({
      setStore: "set",
    }),
    ...mapActions({
      logout: Actions.ACTIONS_LOGOUT,
    }),
  },
})
export default class AppHeader extends Vue {
  setStore!: (payload: [state: string, value: any]) => void;
  logout!: () => void;

  get icon(): string {
    return this.$store.state.sidebarMinimize ? "menu-unfold" : "menu-fold";
  }

  toggleMenu(): void {
    this.setStore(["sidebarMinimize", !this.$store.state.sidebarMinimize]);
  }

  handleLogout(): void {
    this.$confirm({
      title: this.$t("lbl_confirmation"),
      content: this.$t("lbl_logout_confirmation"),
      onOk: () => {
        this.logout();
      },
    });
  }
}
