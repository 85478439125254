import useAccountingCalculation from "@/hooks/useAccountingCalculation";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { InvoiceApPrepaymentData } from "@/models/interface/InvoiceApPrepayment.interface";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import Decimal from "decimal.js-light";
import { Moment } from "moment";
import { initDetail, initFormMeta } from "./resources/ApPrepayment.resource";

export type FormMeta = Partial<{
  assetId: string;
  currencyCode: string;
  supplierId: string;
  poId: string;
  payableAccId: string;
  lineAccId: string;
  branchId: string;
  taxType: TAX_CALCULATION;
}>;
export type State = {
  formMeta: FormMeta;
  detail: InvoiceApPrepaymentData;
  subtotal: number;
  taxRate: number;
  totalTax: number;
  total: number;
  taxBase: number;
  inclusiveTaxRate: number;
  taxBaseVariable: number;
  isLuxuryGoods: boolean;
  invoiceDate: Moment | null;
};

const state: State = {
  formMeta: initFormMeta(),
  detail: initDetail(),
  subtotal: 0, // subtotal invoice prepayment
  taxBase: 0, // dpp
  taxRate: 0, // menyimpan tax rate dari tax code yang dipilih pada form
  totalTax: 0, // total tax invoice prepayment
  total: 0, // total payment
  inclusiveTaxRate: 0,
  taxBaseVariable: 1,
  invoiceDate: null,
  isLuxuryGoods: false,
};

const mutations = {
  setFormMeta: (st: State, payload: FormMeta): void => {
    const { formMeta } = st;
    st.formMeta = { ...formMeta, ...payload };
  },
  setDetail: (st: State, payload: InvoiceApPrepaymentData): void => {
    st.detail = payload;
  },
  /**
   * @description subtotal berubah ketika
   * - amount berubah
   * - tax type berubah
   */
  setSubtotal: (st: State, payload: number): void => {
    st.subtotal = payload;
  },
  setTaxBase: (st: State, payload: number): void => {
    st.taxBase = payload;
  },
  setTaxRate: (st: State, payload: number): void => {
    st.taxRate = payload;
  },
  setInclusiveTaxRate: (st: State, payload: number): void => {
    st.inclusiveTaxRate = payload;
  },
  setTotalTax: (st: State, payload: number): void => {
    st.totalTax = payload;
  },
  setTotal: (st: State, payload: number): void => {
    st.total = payload;
  },
  setLuxuryGoods: (st: State, payload: boolean): void => {
    st.isLuxuryGoods = payload;
  },
  setInvoiceDate: (st: State, payload: Moment | null): void => {
    st.invoiceDate = payload;
  },
  setTaxBaseVariable: (st: State, payload: number): void => {
    st.taxBaseVariable = payload;
  },
};

const actions = {
  doCalculation: (
    { commit, state },
    payload: { amount: number; taxType: TAX_CALCULATION }
  ): void => {
    if (!payload || !payload.amount || !payload.taxType) return;

    const {
      calculateGrossAfterDiscountBeforeTax,
      calculateTaxBase,
      calculateTaxValue,
      calculateTotal,
    } = useAccountingCalculation();

    const { amount, taxType } = payload;

    const grossAfterDiscountBeforeTax = calculateGrossAfterDiscountBeforeTax({
      grossAfterDiscount: new Decimal(amount || 0),
      purchaseInclusiveRate: state.inclusiveTaxRate,
      taxType: taxType as TAX_CALCULATION,
    });
    const taxBase = calculateTaxBase({
      grossAfterDiscountBeforeTax: grossAfterDiscountBeforeTax || 0,
      variable: state.taxBaseVariable,
    });
    const totalTax = calculateTaxValue({
      taxType,
      taxBase: taxBase || 0,
      taxRate: state.taxRate,
    });
    const total = calculateTotal({
      grossAfterDiscountBeforeTax: grossAfterDiscountBeforeTax || 0,
      taxValue: totalTax || 0,
    });

    commit("setSubtotal", grossAfterDiscountBeforeTax.toNumber());
    commit("setTaxBase", taxBase.toNumber());
    commit("setTotalTax", totalTax.toNumber());
    commit("setTotal", total.toNumber());
  },
  findTaxBaseVariable(context, { amount, taxType }): void {
    const { state, dispatch, commit } = context;

    if (!state.invoiceDate) {
      commit("setTaxBaseVariable", 1);
      dispatch("doCalculation", { amount, taxType });
      return;
    }

    taxBaseVariableService
      .getVariable({
        isLuxury: state.isLuxuryGoods,
        transactionDate: state.invoiceDate.format(),
      })
      .then(response => {
        commit("setTaxBaseVariable", response.value ?? 1);
        dispatch("doCalculation", { amount, taxType });
      });
  },
  resetStore: ({ commit }): void => {
    commit("setFormMeta", initFormMeta());
    commit("setDetail", initDetail());
    commit("setSubtotal", 0);
    commit("setTaxRate", 0);
    commit("setTotalTax", 0);
    commit("setTotal", 0);
    commit("setTaxBase", 0);
  },
};

const getters = {
  getInclusiveTaxRate: (st: State): number => {
    return st.inclusiveTaxRate;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
