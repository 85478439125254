import { RoutePathModel } from "../interface/common.interface";

export const MY_PROFILE_ROUTE: RoutePathModel = {
  path: "/me",
  url: "/me",
  name: "profile.me",
  meta: {
    title_head: "lbl_profile",
    breadcrumb: [
      {
        name: "lbl_profile",
        icon: "user",
      },
    ],
    need_auth: true,
  },
};
