import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ReportPayableMutationDTO } from "@/models/interface/ReportPayableMutation.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class ReportPayableMutationService extends HttpClient {
  list(params: RequestQueryParamsModel) {
    return this.get<ReportPayableMutationDTO>(Api.ReportPayableMutation, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params: RequestQueryParamsModel) {
    return this.get<Blob>(Api.ReportPayableMutation + "/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listInvoice(params: RequestQueryParamsModel) {
    return this.get<Pagination<string>>(
      Api.ReportPayableMutation + "/list/invoice",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listInvoiceSupplier(params: RequestQueryParamsModel) {
    return this.get<Pagination<string>>(
      Api.ReportPayableMutation + "/list/invoice/supplier",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const reportPayableMutationService = new ReportPayableMutationService();
