import vue from "vue";
// AP Prepayment
import TableDetailsPrepayment from "./invoices-prepayment-debit-memo/TableDetailsPrepayment.vue";

const TableStatusPrepayment = () =>
  import(
    /* webpackPrefetch: true */ "./invoices-prepayment-debit-memo/TableStatusPrepayment.vue"
  );
const TableTaxDetailsPrepayment = () =>
  import(
    /* webpackPrefetch: true */ "./invoices-prepayment-debit-memo/TableTaxDetailsPrepayment.vue"
  );
vue.component("TableDetailsPrepayment", TableDetailsPrepayment);
vue.component("TableStatusPrepayment", TableStatusPrepayment);
vue.component("TableTaxDetailsPrepayment", TableTaxDetailsPrepayment);
