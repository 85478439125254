import { EnumBooleanInString } from "@/models/enums/global.enum";
import {
  IPreferencesRequestDto,
  IPreferencesResponseDto,
} from "@/models/interface/preference";
import {
  PreferenceAccountArApState,
  PreferenceAccountBalanceSheetState,
  PreferenceAccountInventoryState,
  PreferenceAccountSalesPurchaseState,
  PreferenceAccountState,
  PreferenceAccountTaxState,
  PreferenceFeatureState,
  PreferenceInventoryState,
  PreferenceOptionType,
  PreferencePurchaseState,
  PreferenceSalesState,
  PreferenceTruckState,
} from "@/store/models/Preference.model";
import {
  initPreferenceAccountArApState,
  initPreferenceAccountBalanceSheetState,
  initPreferenceAccountInventoryState,
  initPreferenceAccountSalesPurchaseState,
  initPreferenceAccountState,
  initPreferenceAccountTaxState,
  initPreferenceFeatureState,
  initPreferenceInventoryState,
  initPreferencePurchaseState,
  initPreferenceSalesState,
  initPreferenceTruckState,
} from "@/store/resources/Preference.resource";
import { LabelInValue } from "@/types";

export class PreferenceMapper {
  static toPreferenceFeatureState(
    data: IPreferencesResponseDto[]
  ): PreferenceFeatureState {
    const state: PreferenceFeatureState = initPreferenceFeatureState();

    const multiBranchValue = data.find(
      line => line.key === "feature_multi_branch"
    );
    state.basicFeature.multiBranch = {
      id: multiBranchValue?.id ?? "",
      key: multiBranchValue?.key ?? "",
      value: (multiBranchValue?.value as EnumBooleanInString) ?? "false",
    };

    const multiCurrencyValue = data.find(
      line => line.key === "feature_multi_currency"
    );
    state.basicFeature.multiCurrency = {
      id: multiCurrencyValue?.id ?? "",
      key: multiCurrencyValue?.key ?? "",
      value: (multiCurrencyValue?.value as EnumBooleanInString) ?? "false",
    };

    const taxInclusiveValue = data.find(
      line => line.key === "feature_tax_inclusive"
    );
    state.basicFeature.taxInclusive = {
      id: taxInclusiveValue?.id ?? "",
      key: taxInclusiveValue?.key ?? "",
      value: (taxInclusiveValue?.value as EnumBooleanInString) ?? "false",
    };

    const approvalValue = data.find(line => line.key === "feature_approval");
    state.basicFeature.approval = {
      id: approvalValue?.id ?? "",
      key: approvalValue?.key ?? "",
      value: (approvalValue?.value as EnumBooleanInString) ?? "false",
    };

    const assetManagementValue = data.find(
      line => line.key === "feature_asset_management"
    );
    state.basicFeature.assetManagement = {
      id: assetManagementValue?.id ?? "",
      key: assetManagementValue?.key ?? "",
      value: (assetManagementValue?.value as EnumBooleanInString) ?? "false",
    };

    const baseCurrencyValue = data.find(
      line => line.key === "feature_base_currency"
    );
    state.currencyFormat.baseCurrency = {
      id: baseCurrencyValue?.id ?? "",
      key: baseCurrencyValue?.key ?? "",
      value: {
        label: baseCurrencyValue?.name ?? "",
        key: baseCurrencyValue?.value ?? "",
      },
    };

    const decimalFormatValue = data.find(
      line => line.key === "feature_decimal_format"
    );
    state.currencyFormat.decimalFormat = {
      id: decimalFormatValue?.id ?? "",
      key: decimalFormatValue?.key ?? "",
      value: {
        key: decimalFormatValue?.value ?? "",
        label: decimalFormatValue?.name ?? "",
      },
    };

    const decimalPointValue = data.find(
      line => line.key === "feature_decimal_point"
    );
    state.currencyFormat.decimalPoint = {
      id: decimalPointValue?.id ?? "",
      key: decimalPointValue?.key ?? "",
      value: decimalPointValue?.value ?? "",
    };

    const discountCalculationValue = data.find(
      line => line.key === "feature_discount_before_after_tax"
    );
    state.currencyFormat.discountCalculation = {
      id: discountCalculationValue?.id ?? "",
      key: discountCalculationValue?.key ?? "",
      value: {
        label: discountCalculationValue?.name ?? "",
        key: discountCalculationValue?.value ?? "",
      },
    };

    const prefSalesTax = data.find(
      item => item.key === "feature_sales_tax_rate"
    );
    if (prefSalesTax) {
      state.taxRate.salesTax = {
        id: prefSalesTax.id,
        key: prefSalesTax.key,
        value: {
          key: prefSalesTax.value,
          label: prefSalesTax.name,
        },
      };
    }

    const prefPurchaseTax = data.find(
      item => item.key === "feature_purchase_tax_rate"
    );
    if (prefPurchaseTax) {
      state.taxRate.purchaseTax = {
        id: prefPurchaseTax.id,
        key: prefPurchaseTax.key,
        value: {
          key: prefPurchaseTax.value,
          label: prefPurchaseTax.name,
        },
      };
    }

    const prefAssignee = data.find(
      item => item.key === "feature_default_assignee"
    );
    if (prefAssignee) {
      state.accountReceivable.invoiceAssignee = {
        id: prefAssignee.id,
        key: prefAssignee.key,
        value: {
          key: prefAssignee.value,
          label: prefAssignee.name,
        },
      };
    }

    return state;
  }

  static toPreferenceFeatureUpdateDto(
    state: PreferenceFeatureState
  ): IPreferencesRequestDto[] {
    const request: IPreferencesRequestDto[] = [];

    for (const k in state.basicFeature) {
      request.push({
        id: state.basicFeature[k].id,
        key: state.basicFeature[k].key,
        value: state.basicFeature[k].value,
        name: state.basicFeature[k].value,
      });
    }

    for (const k in state.taxRate) {
      request.push({
        id: state.taxRate[k].id,
        key: state.taxRate[k].key,
        value: state.taxRate[k].value.key,
        name: state.taxRate[k].value.label,
      });
    }

    const currencyFormatDto: IPreferencesRequestDto[] = [
      {
        id: state.currencyFormat.baseCurrency.id,
        key: "feature_base_currency",
        value: state.currencyFormat.baseCurrency.value.key,
        name: state.currencyFormat.baseCurrency.value.label,
      },
      {
        id: state.currencyFormat.decimalFormat.id,
        key: "feature_decimal_format",
        value: state.currencyFormat.decimalFormat.value.key,
        name: state.currencyFormat.decimalFormat.value.label,
      },
      {
        id: state.currencyFormat.decimalPoint.id,
        key: "feature_decimal_point",
        value: state.currencyFormat.decimalPoint.value,
        name: state.currencyFormat.decimalPoint.value,
      },
      {
        id: state.currencyFormat.discountCalculation.id,
        key: "feature_discount_before_after_tax",
        value: state.currencyFormat.discountCalculation.value.key,
        name: state.currencyFormat.discountCalculation.value.label,
      },
    ];

    const accountReceivableDto: IPreferencesRequestDto[] = [
      {
        id: state.accountReceivable.invoiceAssignee.id,
        key: "feature_default_assignee",
        value: state.accountReceivable.invoiceAssignee.value.key,
        name: state.accountReceivable.invoiceAssignee.value.label,
      },
    ];

    request.push(...currencyFormatDto, ...accountReceivableDto);

    return request;
  }

  static toPreferenceSalesState(
    data: IPreferencesResponseDto[]
  ): PreferenceSalesState {
    const state = initPreferenceSalesState();

    const prefInvoiceTerms = data.find(item => item.key === "sales_top");
    if (prefInvoiceTerms) {
      state.invoiceTerms = {
        id: prefInvoiceTerms.id,
        key: prefInvoiceTerms.key,
        value: {
          key: prefInvoiceTerms.value,
          label: prefInvoiceTerms.name,
        },
      };
    }

    const prefQuotationAndSales = data.find(
      item => item.key === "sales_quotation_and_sales_order"
    );
    if (prefQuotationAndSales) {
      state.salesAndQuotation = {
        key: prefQuotationAndSales.key,
        id: prefQuotationAndSales.id,
        value: prefQuotationAndSales.value as EnumBooleanInString,
      };
    }

    const prefSoReturn = data.find(item => item.key === "sales_order_return");
    if (prefSoReturn) {
      state.salesOrderReturn = {
        id: prefSoReturn.id,
        key: prefSoReturn.key,
        value: prefSoReturn.value as EnumBooleanInString,
      };
    }

    const prefJoinInvoice = data.find(
      item => item.key === "sales_join_invoice"
    );
    if (prefJoinInvoice) {
      state.invoiceJoin = {
        id: prefJoinInvoice.id,
        key: prefJoinInvoice.key,
        value: prefJoinInvoice.value as EnumBooleanInString,
      };
    }

    const prefDisabledSellQty = data.find(
      item => item.key === "sales_disable_sell_when_not_enough_qty"
    );
    if (prefDisabledSellQty) {
      state.disableSellQuantity = {
        id: prefDisabledSellQty.id,
        key: prefDisabledSellQty.key,
        value: prefDisabledSellQty.value as EnumBooleanInString,
      };
    }

    const prefSalesCommision = data.find(
      item => item.key === "sales_commision_counting_from"
    );
    if (prefSalesCommision) {
      state.salesCommision = {
        id: prefSalesCommision.id,
        key: prefSalesCommision.key,
        value: {
          label: prefSalesCommision.name,
          key: prefSalesCommision.value,
        },
      };
    }

    return state;
  }

  static toPreferenceSalesUpdateDto(
    state: PreferenceSalesState
  ): IPreferencesRequestDto[] {
    const request: IPreferencesRequestDto[] = [
      {
        id: state.invoiceTerms.id,
        key: "sales_top",
        value: state.invoiceTerms.value.key,
        name: state.invoiceTerms.value.label,
      },
      {
        id: state.salesAndQuotation.id,
        key: "sales_quotation_and_sales_order",
        value: state.salesAndQuotation.value,
        name: state.salesAndQuotation.value,
      },
      {
        id: state.salesOrderReturn.id,
        key: "sales_order_return",
        value: state.salesOrderReturn.value,
        name: state.salesOrderReturn.value,
      },
      {
        id: state.invoiceJoin.id,
        key: "sales_join_invoice",
        value: state.invoiceJoin.value,
        name: state.invoiceJoin.value,
      },
      {
        id: state.disableSellQuantity.id,
        key: "sales_disable_sell_when_not_enough_qty",
        value: state.disableSellQuantity.value,
        name: state.disableSellQuantity.value,
      },
      {
        id: state.salesCommision.id,
        key: "sales_commision_counting_from",
        value: state.salesCommision.value.key,
        name: state.salesCommision.value.label,
      },
    ];

    return request;
  }

  static toPreferencePurchaseState(
    data: IPreferencesResponseDto[]
  ): PreferencePurchaseState {
    const state: PreferencePurchaseState = initPreferencePurchaseState();

    const prefTop = data.find(item => item.key === "purchase_top");
    const prefQuotationAndPo = data.find(
      item => item.key === "purchase_quotation"
    );
    const prefPoReturn = data.find(
      item => item.key === "purchase_purchase_return"
    );

    if (prefTop) {
      state.invoiceTerms = {
        id: prefTop.id,
        key: prefTop.key,
        value: {
          key: prefTop.value,
          label: prefTop.value,
        },
      };
    }
    if (prefQuotationAndPo) {
      state.purchaseAndQuotation = {
        id: prefQuotationAndPo.id,
        key: prefQuotationAndPo.key,
        value: prefQuotationAndPo.value as EnumBooleanInString,
      };
    }

    if (prefPoReturn) {
      state.purchaseOrderReturn = {
        id: prefPoReturn.id,
        key: prefPoReturn.key,
        value: prefPoReturn.value as EnumBooleanInString,
      };
    }

    return state;
  }

  static toPreferencePurchaseUpdateDto(
    state: PreferencePurchaseState
  ): IPreferencesRequestDto[] {
    const request: IPreferencesRequestDto[] = [
      {
        id: state.invoiceTerms.id,
        key: "purchase_top",
        value: state.invoiceTerms.value.key,
        name: state.invoiceTerms.value.label,
      },
      {
        id: state.purchaseAndQuotation.id,
        key: "purchase_quotation",
        value: state.purchaseAndQuotation.value,
        name: state.purchaseAndQuotation.value,
      },
      {
        id: state.purchaseOrderReturn.id,
        key: "purchase_purchase_return",
        value: state.purchaseOrderReturn.value,
        name: state.purchaseOrderReturn.value,
      },
    ];

    return request;
  }

  static toPreferenceInventoryState(
    data: IPreferencesResponseDto[]
  ): PreferenceInventoryState {
    const state: PreferenceInventoryState = initPreferenceInventoryState();

    const prefCostMethod = data.find(
      item => item.key === "inventory_costing_method"
    );
    if (prefCostMethod) {
      state.costMethod = {
        id: prefCostMethod.id,
        key: prefCostMethod.key,
        value: {
          label: prefCostMethod.name,
          key: prefCostMethod.value,
        },
      };
    }

    const prefShowStock = data.find(
      item => item.key === "inventory_show_product_stock"
    );
    if (prefShowStock) {
      state.showStock = {
        id: prefShowStock.id,
        key: prefShowStock.key,
        value: prefShowStock.value as EnumBooleanInString,
      };
    }

    const prefMultiWarehouse = data.find(
      item => item.key === "inventory_multi_warehouse"
    );
    if (prefMultiWarehouse) {
      state.multiWarehouse = {
        id: prefMultiWarehouse.id,
        key: prefMultiWarehouse.key,
        value: prefMultiWarehouse.value as EnumBooleanInString,
      };
    }

    const prefMultiUom = data.find(item => item.key === "inventory_multi_uom");
    if (prefMultiUom) {
      state.multiUom = {
        id: prefMultiUom.id,
        key: prefMultiUom.key,
        value: prefMultiUom.value as EnumBooleanInString,
      };
    }

    const prefSn = data.find(item => item.key === "inventory_serial_number");
    if (prefSn) {
      state.sNProduct = {
        id: prefSn.id,
        key: prefSn.key,
        value: prefSn.value as EnumBooleanInString,
      };
    }

    return state;
  }

  static toPreferenceInventoryUpdateDto(
    state: PreferenceInventoryState
  ): IPreferencesRequestDto[] {
    const request: IPreferencesRequestDto[] = [
      {
        id: state.costMethod.id,
        key: "inventory_costing_method",
        value: state.costMethod.value.key,
        name: state.costMethod.value.label,
      },
      {
        id: state.multiUom.id,
        key: "inventory_multi_uom",
        value: state.multiUom.value,
        name: state.multiUom.value,
      },
      {
        id: state.multiWarehouse.id,
        key: "inventory_multi_warehouse",
        value: state.multiWarehouse.value,
        name: state.multiWarehouse.value,
      },
      {
        id: state.sNProduct.id,
        key: "inventory_serial_number",
        value: state.sNProduct.value,
        name: state.sNProduct.value,
      },
      {
        id: state.showStock.id,
        key: "inventory_show_product_stock",
        value: state.showStock.value,
        name: state.showStock.value,
      },
    ];

    return request;
  }

  static toPreferenceAccountKey(
    keys: Record<string, PreferenceOptionType<LabelInValue>>,
    data: IPreferencesResponseDto[]
  ): void {
    for (const k in keys) {
      const pref = data.find(item => item.key === k);
      if (!pref) continue;

      keys[k].id = pref.id;
      keys[k].key = pref.key;
      keys[k].value.key = pref.value;
      keys[k].value.label = pref.name ?? "";
    }
  }

  static toPreferenceAccountBalanceSheetState(
    data: IPreferencesResponseDto[]
  ): PreferenceAccountBalanceSheetState {
    const state: PreferenceAccountBalanceSheetState =
      initPreferenceAccountBalanceSheetState();

    const keys = {
      account_setup_opening_balance_equity: state.openingBalance,
      account_setup_retained_earning: state.retainedEarning,
      account_setup_current_year_profit: state.currentYearProfit,
      account_setup_current_month_profit: state.currentMonthProfit,
      account_setup_exchange_rate_variance_gain: state.exchangeRateVarianceGain,
      account_setup_exchange_rate_variance_loss: state.exchangeRateVarianceLoss,
    };

    PreferenceMapper.toPreferenceAccountKey(keys, data);

    return state;
  }

  static toPreferenceAccountSalesPurchaseState(
    data: IPreferencesResponseDto[]
  ): PreferenceAccountSalesPurchaseState {
    const state: PreferenceAccountSalesPurchaseState =
      initPreferenceAccountSalesPurchaseState();

    const keys = {
      account_setup_sales_revenue: state.salesRevenue,
      account_setup_sales_discount: state.salesDiscount,
      account_setup_sales_return: state.salesReturn,
      account_setup_sales_delivery: state.salesDelivery,
      account_setup_unearned_revenue: state.unearnedRevenue,
      account_setup_unbilled_sales: state.unbilledSales,
      account_setup_unbilled_receivables: state.unbilledReceivables,
      account_setup_sales_bad_debt: state.salesBadDebt,
      account_setup_purchase_bad_debt: state.purchaseBadDebt,
      account_setup_purchase_cogs: state.purchaseCogs,
      account_setup_purchase_shipping: state.purchaseShipping,
      account_setup_purchase_return: state.purchaseReturn,
      account_setup_unbilled_payables: state.unbilledPayable,
      account_setup_rounding_account_gain: state.roundingAccountGain,
      account_setup_rounding_account_loss: state.roundingAccountLoss,
      account_setup_purchase_price_variance: state.purchasePriceVariance,
      account_setup_purchase_discount: state.purchaseDiscount,
      account_setup_prepayment_dealer: state.prepaymentDealer,
    };

    PreferenceMapper.toPreferenceAccountKey(keys, data);

    return state;
  }

  static toPreferenceAccountArApState(
    data: IPreferencesResponseDto[]
  ): PreferenceAccountArApState {
    const state: PreferenceAccountArApState = initPreferenceAccountArApState();

    const keys = {
      account_setup_account_receiviables: state.accountReceivable,
      account_setup_account_payables: state.accountPayable,
      account_setup_invoice_price_variance: state.invoicePriceVariance,
      account_setup_prepayment_ap: state.prepaymentAp,
      account_setup_prepayment_ar: state.prepaymentAr,
    };

    PreferenceMapper.toPreferenceAccountKey(keys, data);

    return state;
  }

  static toPreferenceAccountInventoryState(
    data: IPreferencesResponseDto[]
  ): PreferenceAccountInventoryState {
    const state: PreferenceAccountInventoryState =
      initPreferenceAccountInventoryState();

    const keys = {
      account_setup_inventory: state.inventory,
      account_setup_inventory_non_stock: state.nonStock,
      account_setup_inventory_waste: state.waste,
      account_setup_stock_adjustment: state.stockAdjustment,
      account_setup_stock_variance: state.stockVariance,
      account_inventory_in_transit: state.inventoryInTransit,
    };

    PreferenceMapper.toPreferenceAccountKey(keys, data);

    return state;
  }

  static toPreferenceAccountTaxState(
    data: IPreferencesResponseDto[]
  ): PreferenceAccountTaxState {
    const state: PreferenceAccountTaxState = initPreferenceAccountTaxState();

    const keys = {
      account_setup_vat_in: state.vatIn,
      account_setup_vat_out: state.vatOut,
    };

    PreferenceMapper.toPreferenceAccountKey(keys, data);

    return state;
  }

  static toPreferenceAccountState(
    data: IPreferencesResponseDto[]
  ): PreferenceAccountState {
    const state: PreferenceAccountState = initPreferenceAccountState();

    state.balanceSheet =
      PreferenceMapper.toPreferenceAccountBalanceSheetState(data);
    state.salesPurchase =
      PreferenceMapper.toPreferenceAccountSalesPurchaseState(data);
    state.arAp = PreferenceMapper.toPreferenceAccountArApState(data);
    state.inventory = PreferenceMapper.toPreferenceAccountInventoryState(data);
    state.tax = PreferenceMapper.toPreferenceAccountTaxState(data);

    return state;
  }

  static toPreferenceAccountUpdateDto(
    state: PreferenceAccountState
  ): IPreferencesRequestDto[] {
    const request: IPreferencesRequestDto[] = [];

    for (const k in state.balanceSheet) {
      request.push({
        id: state.balanceSheet[k].id,
        key: state.balanceSheet[k].key,
        name: state.balanceSheet[k].value.label,
        value: state.balanceSheet[k].value.key,
      });
    }

    for (const k in state.salesPurchase) {
      request.push({
        id: state.salesPurchase[k].id,
        key: state.salesPurchase[k].key,
        name: state.salesPurchase[k].value.label,
        value: state.salesPurchase[k].value.key,
      });
    }

    for (const k in state.arAp) {
      request.push({
        id: state.arAp[k].id,
        key: state.arAp[k].key,
        name: state.arAp[k].value.label,
        value: state.arAp[k].value.key,
      });
    }

    for (const k in state.inventory) {
      if (state.inventory[k].id && state.inventory[k].key) {
        request.push({
          id: state.inventory[k].id,
          key: state.inventory[k].key,
          name: state.inventory[k].value.label,
          value: state.inventory[k].value.key,
        });
      }
    }

    for (const k in state.tax) {
      request.push({
        id: state.tax[k].id,
        key: state.tax[k].key,
        name: state.tax[k].value.label,
        value: state.tax[k].value.key,
      });
    }

    return request;
  }

  static toPreferenceTruckState(
    data: IPreferencesResponseDto[]
  ): PreferenceTruckState {
    const state: PreferenceTruckState = initPreferenceTruckState();

    data.forEach(pref => {
      if (pref.key === "trucking_tax") {
        state.tax.id = pref.id;
        state.tax.key = pref.key;
        if (pref.value && pref.name) {
          state.tax.value.key = pref.value;
          state.tax.value.label = pref.name;
        }
      }
      if (pref.key === "trucking_assignee") {
        state.assignee.id = pref.id;
        state.assignee.key = pref.key;
        if (pref.value && pref.name) {
          state.assignee.value.key = pref.value;
          state.assignee.value.label = pref.name;
        }
      }
      if (pref.key === "trucking_down_payment") {
        state.downPayment.id = pref.id;
        state.downPayment.key = pref.key;
        if (pref.value && pref.name) {
          state.downPayment.value.key = pref.value;
          state.downPayment.value.label = pref.name;
        }
      }
      if (pref.key === "trucking_revenue") {
        state.revenue.id = pref.id;
        state.revenue.key = pref.key;
        if (pref.value && pref.name) {
          state.revenue.value.key = pref.value;
          state.revenue.value.label = pref.name;
        }
      }
      if (pref.key === "trucking_receivable_account") {
        state.receivableAccount.id = pref.id;
        state.receivableAccount.key = pref.key;
        if (pref.value && pref.name) {
          state.receivableAccount.value.key = pref.value;
          state.receivableAccount.value.label = pref.name;
        }
      }
      if (pref.key === "trucking_default_branch") {
        state.defaultBranch.id = pref.id;
        state.defaultBranch.key = pref.key;
        if (pref.value && pref.name) {
          state.defaultBranch.value.key = pref.value;
          state.defaultBranch.value.label = pref.name;
        }
      }
    });

    return state;
  }

  static toPreferenceTruckUpdateDto(
    state: PreferenceTruckState
  ): IPreferencesRequestDto[] {
    const request: IPreferencesRequestDto[] = [];

    for (const key in state) {
      request.push({
        id: state[key].id,
        key: state[key].key,
        name: state[key].value.label,
        value: state[key].value.key,
      });
    }

    return request;
  }
}
