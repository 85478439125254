import Vue from "vue";
import currencyFilter from "./currency.filter";
import dateFormat from "./date.filter";
import ppnFilter from "./ppn.filter";
import quantityFilter from "./quantity.filter";

Vue.filter("date", dateFormat);
Vue.filter("currency", currencyFilter);
Vue.filter("qty", quantityFilter);
Vue.filter("ppn", ppnFilter);
