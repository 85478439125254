import { removeDuplicateObj } from "@/helpers/common";
import { Option } from "@/models/class/option.class";
import { State, WorkOrderUnitState } from "./types";

export const getters = {
  /**
   * @description
   * get all unit code from unit table
   */
  getAvailableUnits: ({
    form: { units },
  }: State): Array<Option<WorkOrderUnitState>> => {
    const unitAvail = units.map(item => ({
      key: item.asset?.key ?? "",
      label: item.unitCode,
      value: item.asset?.key ?? "",
      meta: item,
    }));
    return removeDuplicateObj(unitAvail, "value");
  },
};
