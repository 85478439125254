import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  SalesOrderResponseDto,
  SalesOrderSubmitResponseDto,
} from "@/models/interface/sales-order";
import { SalesOrderList } from "@/models/interface/salesOrder.interface";
import {
  TruckingSalesOrderRequestDto,
  TruckingSalesOrderResponseDto,
} from "@/models/interface/trucking-sales-order";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class TruckingSalesOrderService extends HttpClient {
  getListSalesOrder(params: RequestQueryParamsModel): Promise<SalesOrderList> {
    return this.get<SalesOrderList>(Api.TruckingSalesOrder, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListSalesOrderHeaders(
    params: RequestQueryParamsModel
  ): Promise<Pagination<TruckingSalesOrderResponseDto>> {
    return this.get<Pagination<TruckingSalesOrderResponseDto>>(
      Api.TruckingSalesOrderHeader,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createSalesOrderTrucking(
    payload: TruckingSalesOrderRequestDto
  ): Promise<SalesOrderResponseDto> {
    return this.post<SalesOrderResponseDto, TruckingSalesOrderRequestDto>(
      Api.Sales,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateSalesOrderTrucking(
    payload: TruckingSalesOrderRequestDto,
    id: string
  ): Promise<SalesOrderResponseDto> {
    return this.put<SalesOrderResponseDto, TruckingSalesOrderRequestDto>(
      Api.Sales + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitSalesOrderTrucking(
    payload: TruckingSalesOrderRequestDto,
    id: string
  ): Promise<SalesOrderSubmitResponseDto> {
    return this.put<SalesOrderSubmitResponseDto, TruckingSalesOrderRequestDto>(
      Api.SalesSubmit + "/" + id,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const truckingSalesOrderSevice = new TruckingSalesOrderService();
