import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "@/models/constant/global.constant";
import { UrlQueryKey } from "@/models/enums/global.enum";
import { SortField } from "./PaginationBuilder";

type FieldQueryList = Partial<{
  sort: SortField;
  search: string;
  limit: number;
  page: number;
}>;

export const buildUrlQueryList = (field: FieldQueryList) => ({
  [UrlQueryKey.SEARCH]: field.search || "",
  [UrlQueryKey.LIMIT]: (field.limit || DEFAULT_PAGE_SIZE).toString(),
  [UrlQueryKey.PAGE]: (field.page || DEFAULT_PAGE).toString(),
});
