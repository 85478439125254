import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";

const useAssetTag = () => {
  const findAllType = (params: RequestQueryParamsModel) => {
    return assetsServices.getAssetTag(params, "type");
  };

  const toOptions = (data: string[]): Option[] => {
    return data.map<Option>(item => ({
      label: item,
      key: item,
      value: item,
    }));
  };

  const filterBy = (field: Partial<{ value: string }>) => {
    const { value = "" } = field;
    const defaultQ: string[] = [];
    const builder = new SearchBuilder();

    if (value) {
      defaultQ.push(builder.push(["value", value], { like: "both" }).build());
    }

    return defaultQ.join(builder.OR);
  };

  return { findAllType, toOptions, filterBy };
};

export default useAssetTag;
