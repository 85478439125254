var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.loadingComponent ? _c("LottieLoader") : _vm._e(),
      _c("vue-headful", { attrs: { title: _vm.title } }),
      _c(
        "a-config-provider",
        { attrs: { locale: _vm.language } },
        [_c("router-view")],
        1
      ),
      _c("AppDrawer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }