import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  StockReportDownloadParams,
  StockReportParams,
} from "@/models/interface/stock-report";
import { StockCardReportResultDto } from "@/models/interface/StockReport.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class StockReportService extends HttpClient {
  public constructor() {
    super();
  }

  getListStockReportV2(
    params: StockReportParams
  ): Promise<StockCardReportResultDto> {
    return this.get<StockCardReportResultDto>(Api.StockCardReportV2, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadStockReportV2(params: StockReportDownloadParams): Promise<Blob> {
    return this.get<Blob>(Api.StockCardReportV2 + `/download`, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const stockReportService = new StockReportService();
