import { Api } from "@/models/class/api.class";
import {
  DataDetailDeliveryOrder,
  RequestDo,
  ResponseDataListDeliveryOrder,
  ResponsePostDO,
} from "@/models/interface/deliveryorder.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class DeliveryOrderServices extends HttpClient {
  postDeliveryOrder = (payload: RequestDo): Promise<ResponsePostDO> => {
    return this.post<ResponsePostDO, RequestDo>(Api.DeliveryOrder, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getListDeliveryOrder = (
    params: RequestQueryParamsModel
  ): Promise<ResponseDataListDeliveryOrder> => {
    return this.get<ResponseDataListDeliveryOrder>(Api.DeliveryOrder, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  getDetailDeliveryOrder(id: string): Promise<DataDetailDeliveryOrder> {
    return this.get<DataDetailDeliveryOrder>(Api.DeliveryOrder + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  getPrintDeliveryOrder = (id: string): Promise<Blob> => {
    return this.get<Blob>(Api.DeliveryOrderPrint + `/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export const deliveryOrderServices = new DeliveryOrderServices();
