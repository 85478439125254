import useCalculator from "@/hooks/useCalculator";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { InvoiceArProductState } from "@/models/interface/account-receivable";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import Decimal from "decimal.js-light";

type FormValue = {
  taxCalculation: TAX_CALCULATION | null;
  additionalDiscount: {
    amount: number;
    percent: number;
  };
  isLuxury: boolean;
  taxBaseVariable: number;
  assignee: string;
};

export interface State {
  tabDetailSource: any[];
  form: FormValue;
  tabPrepayment: any[];
}

const state: State = {
  tabDetailSource: [],
  form: {
    isLuxury: false,
    taxBaseVariable: 1,
    taxCalculation: null,
    additionalDiscount: {
      amount: 0,
      percent: 0,
    },
    assignee: "",
  },
  tabPrepayment: [],
};

const actions = {
  ["CALC_PRICING_INVOICE_PRODUCT"]: ({ state, getters, rootState }): void => {
    const MAX_PRECISION = 14;
    const { round } = useCalculator();
    let sumOfGrossValue = new Decimal(0); // alias Subtotal
    const discountHeader: number = state.form.additionalDiscount.amount;
    const isLuxury: boolean = state.form.isLuxury;
    const isInclusive: boolean = getters.TAX_CALCULATION_INCLUSIVE;
    const isExclusive: boolean = getters.TAX_CALCULATION_EXCLUSIVE;
    const totalAppliedPrepayment: Decimal = new Decimal(
      getters.CALC_SUM_PREPAYMENT.raw || 0
    );
    const taxBaseVariable: number = state.form.taxBaseVariable;

    sumOfGrossValue = state.tabDetailSource.reduce(
      (left: number, right: InvoiceArProductState) => {
        const gross = new Decimal(right.price).times(right.qty);
        return new Decimal(left).plus(gross).toNumber();
      },
      0
    );

    state.tabDetailSource.forEach((product: InvoiceArProductState) => {
      const gross = new Decimal(product.price ?? 0).times(product.qty ?? 0);
      product.grossValue = gross.toNumber();
      product.proRateRatio = gross
        .dividedBy(sumOfGrossValue)
        .todp(MAX_PRECISION, Decimal.ROUND_FLOOR)
        .toNumber();
      product.proRateAdditionalDiscountAmount = new Decimal(
        product.proRateRatio
      )
        .times(discountHeader)
        .toNumber();
      const proRatePrepaymentAmount: Decimal = totalAppliedPrepayment.times(
        product.proRateRatio
      );

      const grossAfterDiscount = new Decimal(product.grossValue)
        .minus(product.discountValue)
        .minus(product.proRateAdditionalDiscountAmount)
        .minus(proRatePrepaymentAmount);
      product.grossAfterDiscount = grossAfterDiscount.toNumber();

      if (isExclusive) {
        const baseAmount = new Decimal(taxBaseVariable).times(
          grossAfterDiscount
        );
        product.baseAmount = round(baseAmount, Decimal.ROUND_HALF_UP);
        const taxRate = new Decimal(product.taxRate ?? 0).dividedBy(100);
        product.taxValue = round(
          baseAmount.times(taxRate),
          Decimal.ROUND_HALF_UP
        );
        if (isLuxury) {
          product.subTotal = round(
            baseAmount.plus(product.taxValue),
            Decimal.ROUND_HALF_UP
          );
        } else {
          product.subTotal = round(
            new Decimal(product.grossAfterDiscount).plus(product.taxValue),
            Decimal.ROUND_HALF_UP
          );
        }
      } else if (isInclusive) {
        const inclusiveRate: number = isLuxury
          ? product.taxRate ?? 0
          : product.inclusiveTaxRate;
        const inclusiveRateDivider = new Decimal(inclusiveRate)
          .dividedBy(100)
          .plus(1);
        const grossAfterDiscountExcludeTax = round(
          grossAfterDiscount.dividedBy(inclusiveRateDivider),
          Decimal.ROUND_HALF_EVEN,
          MAX_PRECISION
        );
        const baseAmount = round(
          new Decimal(taxBaseVariable).times(grossAfterDiscountExcludeTax),
          Decimal.ROUND_HALF_EVEN,
          MAX_PRECISION
        );
        product.grossAfterDiscount = grossAfterDiscountExcludeTax.toNumber();
        product.baseAmount = baseAmount;

        const rate = new Decimal(product.taxRate ?? 0).dividedBy(100);
        product.taxValue = round(
          new Decimal(baseAmount).times(rate),
          Decimal.ROUND_HALF_EVEN,
          MAX_PRECISION
        );

        product.subTotal = round(
          grossAfterDiscountExcludeTax.plus(product.taxValue),
          Decimal.ROUND_HALF_EVEN
        );
      } else {
        product.taxValue = new Decimal(0);
        product.baseAmount = grossAfterDiscount;
        product.subTotal = product.baseAmount;
      }
    });
  },
  ["RESET_STORE"]: (context): void => {
    const form: FormValue = {
      taxCalculation: null,
      isLuxury: false,
      taxBaseVariable: 1,
      additionalDiscount: {
        amount: 0,
        percent: 0,
      },
      assignee: "",
    };
    context.commit("SET_FORM", form);
    context.commit("SET_DATA_SOURCE_DETAILS_TAB_PANE", []);
    context.commit("SET_PREPAYMENT", []);
  },
  disableTaxLines: (context): void => {
    const { state } = context;
    const local: State = state;
    local.tabDetailSource.forEach(item => {
      item.taxValue = currencyFormat(0);
      item.taxRate = 0;
      item.taxCode = "";

      item.disableByRow.push("taxCode");
    });
  },
  enableTaxLines: (context): void => {
    const { state } = context;
    const local: State = state;
    local.tabDetailSource.forEach(item => {
      const idx: number = item.disableByRow.indexOf("taxCode");
      if (idx !== -1) {
        item.disableByRow.splice(idx, 1);
      }
    });
  },
};

const getters = {
  ["GET_DATA_SOURCE_DETAILS_TAB_PANE"]: (st: State) => {
    return st.tabDetailSource;
  },
  ["TAX_CALCULATION_EXCLUSIVE"]: (st: State): boolean => {
    return (
      (st.form.taxCalculation || "").toUpperCase() ===
      TAX_CALCULATION.EXCLUSIVE.toUpperCase()
    );
  },
  ["TAX_CALCULATION_INCLUSIVE"]: (st: State): boolean => {
    return (
      (st.form.taxCalculation || "").toUpperCase() ===
      TAX_CALCULATION.INCLUSIVE.toUpperCase()
    );
  },
  ["TAX_CALCULATION_NONE"]: (st: State): boolean => {
    return (
      (st.form.taxCalculation || "").toUpperCase() ===
      TAX_CALCULATION.NONE.toUpperCase()
    );
  },
  ["CALC_SUM_PREPAYMENT"]: (st: State): { raw: number; format: string } => {
    const sum = st.tabPrepayment.reduce((curr, val) => {
      const amount = new Decimal(changeCurrencytoNumeric(val.amount) || 0);
      return amount.plus(curr).toNumber();
    }, 0);
    return {
      raw: sum,
      format: currencyFormat(sum),
    };
  },
  ["CALC_SUM_SUBTOTAL"]: (st): number => {
    return st.tabDetailSource.reduce((a: number, b: InvoiceArProductState) => {
      return b.subTotal.plus(a).toNumber();
    }, 0);
  },
};

const mutations = {
  ["SET_DATA_SOURCE_DETAILS_TAB_PANE"]: (st: State, payload: any): void => {
    st.tabDetailSource = payload;
  },
  ["SET_FORM"]: (st: State, payload: FormValue): void => {
    st.form = payload;
  },
  ["SET_PREPAYMENT"]: (st: State, payload): void => {
    st.tabPrepayment = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
