import { AP_PREPAYMENT_STATUS } from "@/models/enums/ApPrepayment.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { InvoiceApPrepaymentData } from "@/models/interface/InvoiceApPrepayment.interface";
import { FormMeta } from "../ApPrepayment.store";

export const initDetail = (): InvoiceApPrepaymentData => ({
  assetId: "",
  unitCode: "",
  accountingDate: "",
  amount: 0,
  branchWarehouse: "",
  branchWarehouseId: "",
  createdBy: "",
  createdDate: "",
  currency: "",
  currencyRate: 0,
  description: "",
  documentNumber: "",
  id: "",
  invoiceAPDetails: [],
  invoiceDate: "",
  invoiceType: "Prepayment",
  journalId: "",
  journalNo: "",
  lineAccount: "",
  lineAccountId: "",
  modifiedBy: "",
  modifiedDate: "",
  operatorName: "",
  paidAmount: 0,
  payablesAccount: "",
  payablesAccountId: "",
  paymentDetails: [],
  poType: "",
  prepaymentUsed: 0,
  purchaseOrderId: "",
  purchaseOrderNumber: "",
  remainingAmount: 0,
  returnId: "",
  returnNumber: "",
  status: "" as AP_PREPAYMENT_STATUS,
  subTotal: 0,
  supplierBillToAddress: "",
  supplierId: "",
  supplierName: "",
  taxCode: "",
  taxCodeId: "",
  taxInvoiceDate: "",
  taxInvoiceNumber: "",
  taxRate: 0,
  taxRegistrationName: "",
  taxRegistrationNumber: "",
  taxType: "" as TAX_CALCULATION,
  termOfPayment: 0,
  total: 0,
  totalTax: 0,
});

export const initFormMeta = (): FormMeta => ({
  assetId: "",
  branchId: "",
  currencyCode: "",
  lineAccId: "",
  payableAccId: "",
  poId: "",
  supplierId: "",
});
