import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  InvoiceArCreateDto,
  InvoiceArLineRequestDto,
  InvoiceArPrepaymentLineRequestDto,
  InvoiceArPrepaymentRequestDto,
  InvoiceArUpdateDto,
} from "@interface/account-receivable";

export function buildInvoiceArLineRequestDto(): InvoiceArLineRequestDto {
  return {
    grossValue: "0",
    id: "",
    productId: "",
    uomId: "",
    salesOrderLineId: "",
    deliveryOrderLineId: "",
    invoiceICBillingLineId: "",
    description: "",
    merk: "",
    qty: 0,
    price: 0,
    revenueAccountId: "",
    baseAmount: "0",
    taxId: "",
    taxValue: "0",
    subTotal: "0",
    percentDiscount: 0,
    discountValue: 0,
    incomeAccountTaxId: "",
    includePPh: false,
    customerLocation: "",
    salesId: "",
    internalContractBillingId: "",
    inclusiveTaxRateId: null,
  };
}

export function buildInvoiceArPrepaymentLineRequestDto(): InvoiceArPrepaymentLineRequestDto {
  return {
    id: "",
    invoicePrepaymentId: "",
    appliedAmount: 0,
    description: "",
  };
}

export function buildInvoiceArPrepaymentRequestDto(): InvoiceArPrepaymentRequestDto {
  return {
    deletedPrepaymentLineIds: [],
    prepaymentLines: [],
  };
}

export function buildInvoiceArUpdateDto(): InvoiceArUpdateDto {
  return {
    isLuxury: false,
    invoiceSource: "",
    salesOrderIds: [],
    deliveryOrderIds: [],
    branchWarehouseId: "",
    customerId: "",
    customerShipToAddress: "",
    customerBillToAddress: "",
    customerGoodReceiptNo: "",
    customerPurchaseOrderNo: "",
    taxType: TAX_CALCULATION.EXCLUSIVE,
    termOfPayment: 0,
    invoiceDate: "",
    accountingDate: "",
    invoiceReceivedDate: "",
    currency: "",
    currencyRate: 1,
    receivableAccountId: "",
    operatorName: "",
    description: "",
    customerTaxType: "",
    taxRegistrationNumber: "",
    taxRegistrationName: "",
    taxInvoiceDate: "",
    taxInvoiceNumber: "",
    taxIsUploaded: false,
    discountValue: 0,
    percentDiscount: 0,
    applyPrepayment: buildInvoiceArPrepaymentRequestDto(),
    invoiceARLines: [],
    deletedInvoiceARLineIds: [],
    picCustomerName: "",
    usageType: "",
    assignedBy: "",
  };
}

export function buildInvoiceArCreateDto(): InvoiceArCreateDto {
  return {
    invoiceType: "",
    invoiceSource: "",
    salesOrderIds: [],
    deliveryOrderIds: [],
    branchWarehouseId: "",
    customerId: "",
    customerShipToAddress: "",
    customerBillToAddress: "",
    customerGoodReceiptNo: "",
    customerPurchaseOrderNo: "",
    taxType: TAX_CALCULATION.EXCLUSIVE,
    termOfPayment: 0,
    invoiceDate: "",
    accountingDate: "",
    invoiceReceivedDate: "",
    currency: "",
    currencyRate: 1,
    receivableAccountId: "",
    operatorName: "",
    description: "",
    status: "Draft",
    customerTaxType: "",
    taxRegistrationNumber: "",
    taxRegistrationName: "",
    taxInvoiceDate: "",
    taxInvoiceNumber: "",
    taxIsUploaded: false,
    applyPrepayment: buildInvoiceArPrepaymentRequestDto(),
    discountValue: 0,
    percentDiscount: 0,
    invoiceARLines: [],
    picCustomerName: "",
    usageType: "",
    assignedBy: "",
    isLuxury: false,
  };
}
