import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import { PreferenceFieldFormState } from "@/models/interface/preference";
import { buildLabelInValue } from "../LabelInValueBuilder";

export function buildPreferenceFieldFormState<TName>(args: {
  name: TName;
  key: EnumPreferenceKey;
  testid: string;
  label: string;
}): PreferenceFieldFormState<TName> {
  const { name, key, label, testid } = args;
  const state: PreferenceFieldFormState<TName> = {
    key,
    label,
    name,
    testid,
    isSearching: false,
    loading: false,
    options: [],
    value: buildLabelInValue(),
  };
  return state;
}

export function buildPreferenceFieldFormStates<TName>(args: {
  names: TName[];
  keys: EnumPreferenceKey[];
  testids: string[];
  labels: string[];
}): PreferenceFieldFormState<TName>[] {
  const { names, keys, labels, testids } = args;
  return names.map((name, index) =>
    buildPreferenceFieldFormState({
      name,
      testid: testids[index],
      key: keys[index],
      label: labels[index],
    })
  );
}
