var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-sider",
    {
      staticClass: "sider",
      attrs: { theme: "light", collapsible: false, width: "25rem" },
      model: {
        value: _vm.$store.state.sidebarMinimize,
        callback: function($$v) {
          _vm.$set(_vm.$store.state, "sidebarMinimize", $$v)
        },
        expression: "$store.state.sidebarMinimize"
      }
    },
    [
      _c(
        "figure",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$store.state.sidebarMinimize,
              expression: "!$store.state.sidebarMinimize"
            }
          ],
          staticClass: "logo__container"
        },
        [
          _c("img", {
            attrs: {
              src: _vm.companyLogo,
              alt: "Logo Satria Piranti Perkasa",
              width: "60%",
              height: "auto"
            }
          })
        ]
      ),
      _c(
        "a-menu",
        { attrs: { mode: "inline", theme: "light" } },
        [
          _vm._l(_vm.menus, function(menu) {
            return [
              menu.show && !menu.children.length
                ? _c(
                    "a-menu-item",
                    { key: menu.key },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: menu.route } },
                        [
                          _c("a-icon", { attrs: { type: menu.icon } }),
                          _c("span", [_vm._v(_vm._s(_vm.$t(menu.title)))])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : menu.show
                ? _c("AppSubMenu", { key: menu.key, attrs: { menus: menu } })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }