export enum INVOICE_SOURCE {
  RECURING_INVOICE = "Recurring Invoice",
  UNIT_SALE = "Unit Sale",
  PRODUCT_SALE = "Product Sale",
  INVOICE_OTHER = "Invoice Other",
  ASSET_SALE = "Asset Sale",
  TRUCKING = "Trucking",
}

export enum INVOICE_TYPE {
  INVOICE_AP = "Invoice AP",
  INVOICE_PREPAYMENT = "Prepayment",
  INVOICE_DEBIT_MEMO = "Debit Memo",
}

export enum InvoiceArTypeEnum {
  INVOICE_AR = "INVOICE_AR",
  CREDIT_MEMO = "CREDIT_MEMO",
  PREPAYMENT = "PREPAYMENT",
}

const InvoiceArStatus = {
  DRAFT: "Draft",
  NEED_APPROVAL: "Need Approval",
  DELIVERED: "Delivered",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
  UNPAID: "Unpaid",
  PARTIAL_PAID: "Partial Paid",
  FULLY_PAID: "Fully Paid",
  RETURNED: "Returned",
} as const;
export type EnumInvoiceArStatus =
  (typeof InvoiceArStatus)[keyof typeof InvoiceArStatus];
