import { SearchBuilder } from "@/builder";
import { ReportAgingApResponseDto } from "@/models/interface/AgingApReport.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import authStore from "@/store/auth.store";

type FilterField = Partial<{
  currencyCode: string;
  branchId: string;
  branchName: string;
  supplierId: string;
  supplierName: string;
  supplierType: string;
}>;

const useAgingApReport = () => {
  const buildParamsDownload = (field: FilterField): string => {
    const q = [
      authStore.state.authData.companyName,
      field.branchName || "ALL",
      field.supplierName || "ALL",
      field.supplierType || "ALL",
      field.currencyCode || "ALL",
    ];
    return q.join(",");
  };

  const filterBy = (field: FilterField): string => {
    const q: string[] = [];
    const builder = new SearchBuilder();

    if (field.currencyCode) {
      q.push(
        builder
          .push(["currencyCode", field.currencyCode], { like: "both" })
          .build()
      );
    }

    if (field.branchId) {
      q.push(builder.push(["branchId", field.branchId]).build());
    }

    if (field.supplierId) {
      q.push(builder.push(["supplierId", field.supplierId]).build());
    }

    if (field.supplierType) {
      q.push(
        builder
          .push(["supplierType", field.supplierType], { like: "both" })
          .build()
      );
    }

    return q.join(builder.AND);
  };

  const findAll = (params?: RequestQueryParamsModel) => {
    return invoiceAPServices.getListAgingApReport(params);
  };

  const initReport = (): ReportAgingApResponseDto => ({
    reportAgingListDTOApList: [],
    totalCurrent: 0,
    totalOutstanding1: 0,
    totalOutstanding2: 0,
    totalOutstanding3: 0,
    totalOutstanding4: 0,
    grandTotal: 0,
  });

  const download = (params?: RequestQueryParamsModel) => {
    return invoiceAPServices.downloadAgingApReport(params);
  };

  return { findAll, initReport, filterBy, download, buildParamsDownload };
};

export default useAgingApReport;
