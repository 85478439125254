export enum AssetStateEnum {
  RETIRED = "Retired",
  RENTED = "Rented",
  READY = "Ready",
  NEED_TO_REPAIR = "Need To Repair",
  INACTIVE = "Inactive",
}

export enum AssetBookMethodEnum {
  STL = "STL",
  STL_FISCAL = "STL Fiscal",
}

export enum AssetBookTypeEnum {
  COMMERCIAL = "Commercial",
  FISCAL = "Fiscal",
}
