import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  TruckingPaymentDetailResponseDto,
  TruckingPaymentDownloadParam,
  TruckingPaymentHeaderList,
  TruckingPaymentListParam,
} from "@/models/interface/trucking-payment";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class TruckingPaymentService extends HttpClient {
  constructor() {
    super();
  }

  public listOfPayments(
    params?: TruckingPaymentListParam
  ): Promise<TruckingPaymentHeaderList> {
    return this.get<TruckingPaymentHeaderList>(Api.TruckingMasterPayment, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadListPayment(
    params: TruckingPaymentDownloadParam
  ): Promise<Blob> {
    return this.get<Blob>(Api.TruckingMasterPaymentDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public findPaymentDetail(
    id: string
  ): Promise<TruckingPaymentDetailResponseDto> {
    return this.get<TruckingPaymentDetailResponseDto>(
      `${Api.TruckingMasterPayment}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getPaymentListByIds(
    ids?: string[]
  ): Promise<TruckingPaymentDetailResponseDto[]> {
    return this.get<TruckingPaymentDetailResponseDto[]>(
      Api.TruckingMasterPaymentList,
      {
        params: {
          ids: ids?.join(",") ?? null,
        },
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const truckingPaymentService = new TruckingPaymentService();
