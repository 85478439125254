import { Api } from "@/models/class/api.class";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  PurchaseOrderCreateDto,
  PurchaseOrderResponseDto,
  PurchaseOrderUpdateDto,
} from "@/models/interface/purchase-order";
import {
  DataResponseLastPrice,
  RequestClosePurchaseOrder,
  ResponseDetailPurchaseOrder,
  ResponseGetListPurchaseOrder,
  ResponseGetListPurchaseOrderReport,
} from "@/models/interface/purchase.interface";

export class PurchaseServices extends HttpClient {
  /**
   * @deprecated
   * new service {@linkcode getListPurchaseOrderNew}
   */
  getListPurchaseOrder = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListPurchaseOrder> => {
    return this.get<ResponseGetListPurchaseOrder>(Api.PurchaseOrder, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListPurchaseOrderNew(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<PurchaseOrderResponseDto>> {
    return this.get<Pagination<PurchaseOrderResponseDto>>(Api.PurchaseOrder, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListPurchaseOrderExistPrepayment = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListPurchaseOrder> => {
    return this.get<ResponseGetListPurchaseOrder>(
      Api.PurchaseOrderExistPrepayment,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListPurchaseOrderReport = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListPurchaseOrderReport> => {
    return this.get<ResponseGetListPurchaseOrderReport>(
      Api.PurchaseOrder + "/list/report",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListLastPrice = (
    params: RequestQueryParamsModel
  ): Promise<DataResponseLastPrice> => {
    return this.get<DataResponseLastPrice>(Api.PurchaseOrder + "/last-price", {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printPurchaseOrder = (id: string): Promise<Blob> => {
    return this.get<Blob>(Api.PurchaseOrder + `/print/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadPurchaseOrder = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.PurchaseOrder + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * new service {@linkcode getDetailPurchaseOrderNew}
   */
  getDetailPurchaseOrder(id: string): Promise<ResponseDetailPurchaseOrder> {
    return this.get<ResponseDetailPurchaseOrder>(Api.PurchaseOrder + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailPurchaseOrderNew(id: string): Promise<PurchaseOrderResponseDto> {
    return this.get<PurchaseOrderResponseDto>(Api.PurchaseOrder + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createPurchaseOrder(
    payload: PurchaseOrderCreateDto
  ): Promise<PurchaseOrderResponseDto> {
    return this.post<PurchaseOrderResponseDto, PurchaseOrderCreateDto>(
      Api.PurchaseOrder,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  openClosePurchaseOrder = (
    payload: RequestClosePurchaseOrder,
    params: RequestQueryParamsModel
  ): Promise<boolean> => {
    return this.post<boolean, RequestClosePurchaseOrder>(
      Api.PurchaseOrder + "/close-period",
      payload,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  closePurchaseOrder = (
    payload: RequestClosePurchaseOrder,
    listId: string
  ): Promise<ResponseDetailPurchaseOrder[]> => {
    return this.post<ResponseDetailPurchaseOrder[], RequestClosePurchaseOrder>(
      Api.PurchaseOrder + `/close/${listId}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updatePurchaseOrder(
    payload: PurchaseOrderUpdateDto,
    id: string
  ): Promise<PurchaseOrderResponseDto> {
    return this.put<PurchaseOrderResponseDto, PurchaseOrderUpdateDto>(
      Api.PurchaseOrder + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitPurchaseOrder(
    payload: PurchaseOrderUpdateDto,
    id: string
  ): Promise<PurchaseOrderResponseDto> {
    return this.post<PurchaseOrderResponseDto, PurchaseOrderUpdateDto>(
      Api.PurchaseOrder + `/submit/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  approvePurchaseOrder(
    payload: PurchaseOrderUpdateDto,
    id: string
  ): Promise<PurchaseOrderResponseDto> {
    return this.post<PurchaseOrderResponseDto, PurchaseOrderUpdateDto>(
      Api.PurchaseOrder + `/approve/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  rejectPurchaseOrder(id: string): Promise<PurchaseOrderResponseDto> {
    return this.post<PurchaseOrderResponseDto, never>(
      Api.PurchaseOrder + `/reject/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelPurchaseOrder(id: string): Promise<PurchaseOrderResponseDto> {
    return this.post<PurchaseOrderResponseDto, never>(
      Api.PurchaseOrder + `/cancel/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListPurchaseOrderAvailableAP = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListPurchaseOrder> => {
    return this.get<ResponseGetListPurchaseOrder>(
      Api.PurchaseOrderAvailableAP,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * new service {@linkcode getAllPoToReceive}
   */
  getListPurchaseOrderAvailableReceive = (
    params: RequestQueryParamsModel
  ): Promise<ResponseGetListPurchaseOrder> => {
    return this.get<ResponseGetListPurchaseOrder>(Api.PurchaseOrderReceive, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getAllPoToReceive(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<PurchaseOrderResponseDto>> {
    return this.get<Pagination<PurchaseOrderResponseDto>>(
      Api.PurchaseOrderReceive,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const purchaseServices = new PurchaseServices();
