import { buildInvoicePrepaymentCreateDto } from "@/builder/account-receivable/AccountReceivablePrepaymentDtoBuilder";
import SearchBuilder from "@/builder/SearchBuilder";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { InvoiceArPrepaymentStatusEnum } from "@/models/enums/ArPrepayment.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { InvoiceSettingPrintParams } from "@/models/interface/invoice-setting-print-params.interface";
import { aRPrepaymentService } from "@/services/ArPrepayment.service";
import { DetailForm, FormValue } from "@/store/aRPrepayment.store";
import { initDetail, initForm } from "@/store/resources/ArPrepayment.resource";
import {
  InvoicePrepaymentCreateDto,
  InvoicePrepaymentListResponseDto,
  InvoicePrepaymentResponseDto,
} from "@interface/ar-prepayment";
import { RequestQueryParamsModel } from "@interface/http.interface";
import Decimal from "decimal.js-light";
import moment, { Moment } from "moment";
import useCalculator from "./useCalculator";
import useDate from "./useDate";

export interface FormFilter {
  invoiceType: string;
  branchName: string;
  customerName: string;
  invoiceDate: Array<Moment>;
  invoiceNumber: string;
  status: InvoiceArPrepaymentStatusEnum;
}

const useArPrepayment = () => {
  const MAX_PRECISION = 14;

  const findById = (id: string): Promise<InvoicePrepaymentResponseDto> => {
    return aRPrepaymentService.detail(id);
  };

  const cancel = (invoiceId: string): Promise<InvoicePrepaymentResponseDto> => {
    return aRPrepaymentService.cancel(invoiceId);
  };

  const print = (
    invoiceId: string,
    params: InvoiceSettingPrintParams
  ): Promise<Blob> => {
    return aRPrepaymentService.printInvoice(invoiceId, params);
  };

  const findTaxNumber = (
    taxCode: string,
    invoiceDate: string
  ): Promise<string> => {
    return aRPrepaymentService.getTaxInvoiceNumber(taxCode, invoiceDate);
  };

  const create = (
    body: InvoicePrepaymentCreateDto
  ): Promise<InvoicePrepaymentResponseDto> => {
    return aRPrepaymentService.create(body);
  };

  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<InvoicePrepaymentListResponseDto>> => {
    return aRPrepaymentService.getList(params);
  };

  const toOptions = (
    data: Array<InvoicePrepaymentListResponseDto>
  ): Array<Option<InvoicePrepaymentListResponseDto>> => {
    return data.map(e => ({
      label: e.invoiceNumber,
      key: e.id,
      value: e.id,
      meta: e,
    }));
  };

  const findAllBy = (field: Partial<FormFilter>) => {
    const {
      invoiceType = "",
      branchName = "",
      customerName = "",
      invoiceDate = [],
      invoiceNumber = "",
      status,
    } = field;
    const builder = new SearchBuilder();
    const queries: Array<string> = [];

    if (invoiceType) {
      queries.push(
        builder
          .push(["invoiceType", trimSpaceToUnderscore(invoiceType)], {
            like: "both",
          })
          .build()
      );
    }

    if (branchName) {
      queries.push(
        builder
          .push(["branchWarehouse.name", branchName], {
            like: "both",
          })
          .build()
      );
    }

    if (customerName) {
      queries.push(
        builder
          .push(["customer.firstName", customerName], {
            like: "both",
          })
          .build()
      );
    }

    if (invoiceNumber) {
      queries.push(
        builder
          .push(["documentNumber", invoiceNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (status) {
      queries.push(
        builder
          .push(["invoicePrepaymentStatus", trimSpaceToUnderscore(status)])
          .build()
      );
    }

    if (invoiceDate && invoiceDate.length) {
      const [start, end] = invoiceDate;
      const { toStartDay, toEndDay } = useDate();
      queries.push(
        builder
          .push(["invoiceDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["invoiceDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    const filter = queries.join(builder.AND);
    const search = queries.join(builder.OR);

    return { filter, search };
  };

  const countTaxBase = ({
    taxBaseVariable,
    subTotal,
    taxType,
  }: {
    taxBaseVariable: number;
    subTotal: Decimal;
    taxType: TAX_CALCULATION;
  }): Decimal => {
    const { round } = useCalculator();
    let val: Decimal;
    if (taxType === TAX_CALCULATION.EXCLUSIVE) {
      val = round(subTotal.times(taxBaseVariable), Decimal.ROUND_HALF_EVEN);
    } else {
      val = round(
        subTotal.times(taxBaseVariable),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
    }
    return val;
  };

  const calcTotalTax = ({
    amount,
    taxRate,
    taxType,
  }: {
    amount: Decimal;
    taxRate: number;
    taxType: TAX_CALCULATION;
  }): Decimal => {
    const { round } = useCalculator();
    const rate = new Decimal(taxRate || 0).dividedBy(100);
    let totalTax: Decimal;
    if (taxType === TAX_CALCULATION.INCLUSIVE) {
      totalTax = round(
        amount.times(rate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
    } else {
      totalTax = round(amount.times(rate), Decimal.ROUND_HALF_EVEN);
    }
    return totalTax;
  };

  const mapFormToCreateDto = (form: FormValue): InvoicePrepaymentCreateDto => {
    const req: InvoicePrepaymentCreateDto = buildInvoicePrepaymentCreateDto();

    req.baseAmount = form.baseAmount.toString();
    req.inclusiveTaxRateId = form.inclusiveTax?.key ?? null;
    req.isLuxury = form.isLuxury;
    req.accountingDate = form.accountingDate
      ? form.accountingDate.format()
      : "";
    req.amount = form.amount;
    req.branchWarehouseId = form.branchWarehouseId;
    req.currency = form.currency;
    req.currencyRate = form.currencyRate;
    req.customerBillToAddress = form.customerBillToAddress;
    req.customerId = form.customerId;
    req.customerShipToAddress = form.customerShipToAddress;
    req.customerTaxType = form.customerTaxType;
    req.description = form.description;
    req.invoiceDate = form.invoiceDate ? form.invoiceDate.format() : "";
    req.invoiceType = form.invoiceType;
    req.lineAccountId = form.lineAccountId;
    req.operatorName = form.operatorName;
    req.poNumber = form.poNumber;
    req.receivableAccountId = form.receivableAccountId;
    req.salesReturnId = form.salesReturnId;
    req.subTotal = form.subTotal.toString();
    req.taxCode = form.taxCode;
    req.taxInvoiceDate = form.taxInvoiceDate
      ? form.taxInvoiceDate.format()
      : "";
    req.taxInvoiceNumber = form.taxInvoiceNumber;
    req.taxRegistrationName = form.taxRegistrationName;
    req.taxRegistrationNumber = form.taxRegistrationNumber;
    req.taxType = form.taxType;
    req.termOfPayment = form.termOfPayment ? parseInt(form.termOfPayment) : 0;
    req.total = form.total.toString();
    req.totalTax = form.totalTax.toString();

    return req;
  };

  const mapDetailToForm = (detail: InvoicePrepaymentResponseDto): FormValue => {
    const form = initForm();

    form.isLuxury = detail.isLuxury;
    form.accountingDate = moment(detail.accountingDate);
    form.amount = detail.amount;
    form.branchWarehouseId = detail.branchWarehouseId;
    form.currency = detail.currency;
    form.currencyRate = detail.currencyRate;
    form.customerBillToAddress = detail.customerBillToAddress;
    form.customerId = detail.customerId;
    form.customerShipToAddress = detail.customerShipToAddress;
    form.customerTaxType = detail.customerTaxType;
    form.description = detail.description;
    form.invoiceDate = moment(detail.invoiceDate);
    form.invoiceType = detail.invoiceType;
    form.lineAccountId = detail.lineAccountId;
    form.lineAccountName = detail.lineAccount;
    form.operatorName = detail.operatorName;
    form.poNumber = detail.poNumber;
    form.receivableAccountId = detail.receivableAccountId;
    form.receivableAccountName = detail.receivableAccount;
    form.subTotal = new Decimal(detail.subTotal);
    form.taxCode = detail.taxCode;
    form.taxInvoiceDate = moment(detail.taxInvoiceDate);
    form.taxInvoiceNumber = detail.taxInvoiceNumber;
    form.taxRegistrationName = detail.taxRegistrationName;
    form.taxRegistrationNumber = detail.taxRegistrationNumber;
    form.taxType = detail.taxType;
    form.termOfPayment = detail.termOfPayment
      ? detail.termOfPayment.toString()
      : "";
    form.total = new Decimal(detail.total);
    form.totalTax = new Decimal(detail.totalTax);

    return form;
  };

  const mapDetailToFormDetail = (
    detail: InvoicePrepaymentResponseDto
  ): DetailForm => {
    const form = initDetail();

    form.inclusiveTaxRateName = detail.inclusiveTaxRateName;
    form.isLuxury = detail.isLuxury ?? false;
    form.baseAmount = detail.baseAmount;
    form.accountingDate = moment(detail.accountingDate);
    form.amount = detail.amount;
    form.branchWarehouse = detail.branchWarehouse;
    form.branchWarehouseId = detail.branchWarehouseId;
    form.createdBy = detail.createdBy;
    form.createdDate = detail.createdDate;
    form.currency = detail.currency;
    form.currencyRate = detail.currencyRate;
    form.customerBillToAddress = detail.customerBillToAddress;
    form.customerId = detail.customerId;
    form.customerName = detail.customerName;
    form.customerShipToAddress = detail.customerShipToAddress;
    form.customerTaxType = detail.customerTaxType;
    form.description = detail.description;
    form.documentNumber = detail.documentNumber;
    form.grossAmount = detail.grossAmount;
    form.id = detail.id;
    form.invoiceARDetails = detail.invoiceARDetails;
    form.invoiceARReceiptDetails = detail.invoiceARReceiptDetails;
    form.invoiceDate = moment(detail.invoiceDate);
    form.invoicePrepaymentStatus = detail.invoicePrepaymentStatus;
    form.invoiceType = detail.invoiceType;
    form.journalId = detail.journalId;
    form.journalNumber = detail.journalNumber;
    form.lineAccount = detail.lineAccount;
    form.lineAccountId = detail.lineAccountId;
    form.modifiedBy = detail.modifiedBy;
    form.modifiedDate = detail.modifiedDate;
    form.operatorName = detail.operatorName;
    form.paidAmount = detail.paidAmount;
    form.poNumber = detail.poNumber;
    form.prepaymentPaid = detail.prepaymentPaid;
    form.prepaymentUsed = detail.prepaymentUsed;
    form.receivableAccount = detail.receivableAccount;
    form.receivableAccountId = detail.receivableAccountId;
    form.remainingAmount = detail.remainingAmount;
    form.remainingPrepayment = detail.remainingPrepayment;
    form.returnNumber = detail.returnNumber;
    form.subTotal = detail.subTotal;
    form.taxCode = detail.taxCode;
    form.taxInvoiceDate = detail.taxInvoiceDate
      ? moment(detail.taxInvoiceDate)
      : null;
    form.taxInvoiceNumber = detail.taxInvoiceNumber;
    form.taxRegistrationName = detail.taxRegistrationName;
    form.taxRegistrationNumber = detail.taxRegistrationNumber;
    form.taxType = detail.taxType;
    form.termOfPayment = detail.termOfPayment;
    form.total = detail.total;
    form.totalTax = detail.totalTax;

    return form;
  };

  return {
    calcTotalTax,
    countTaxBase,
    cancel,
    create,
    findAll,
    findAllBy,
    findById,
    findTaxNumber,
    mapDetailToForm,
    mapDetailToFormDetail,
    mapFormToCreateDto,
    print,
    toOptions,
  };
};

export default useArPrepayment;
