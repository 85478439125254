var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    [
      _c("AppSidebar"),
      _c(
        "a-layout",
        {
          staticClass: "layout__container",
          class: {
            "layout__container--has-menu": !_vm.$store.state.sidebarMinimize
          }
        },
        [_c("AppHeader"), _c("AppContent"), _c("AppFooter")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }