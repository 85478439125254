// Interfaces
import { RoutePathModel } from "../interface/common.interface";

export const AUTH_ROUTE: RoutePathModel = {
  path: "/auth",
  url: "/auth",
  name: "Auth",
  redirect: "/auth/signin",
  meta: {
    breadcrumb: "Auth",
    title_head: "Auth"
  }
}

export const SIGNIN_ROUTE: RoutePathModel = {
  path: "signin",
  url: `${AUTH_ROUTE.url}/signin`,
  name: "SignIn",
  meta: {
    breadcrumb: "lbl_signin",
    title_head: "lbl_signin"
  }
}


export const SIGNUP_ROUTE: RoutePathModel = {
  path: "signup",
  url: `${AUTH_ROUTE.url}/signup`,
  name: "SignUp",
  meta: {
    breadcrumb: "Sign Up",
    title_head: "Sign Up"
  }
}

export const SIGNOUT_ROUTE: RoutePathModel = {
  path: "signout",
  url: `${AUTH_ROUTE.url}/signout`,
  name: "SignOut",
  meta: {
    breadcrumb: "lbl_profile_signout",
    title_head: "lbl_profile_signout"
  }
}

export const FORGOT_PASSWORD_ROUTE: RoutePathModel = {
  path: "forgot-password",
  url: `${AUTH_ROUTE.url}/forgot-password`,
  name: "ForgotPassword",
  meta: {
    breadcrumb: "lbl_forgot_password_title",
    title_head: "lbl_forgot_password_title"
  }
}

export const RESET_PASSWORD_ROUTE: RoutePathModel = {
  path: "reset-password/:token",
  url: `${AUTH_ROUTE.url}/reset-password`,
  name: "ResetPassword",
  meta: {
    breadcrumb: "Reset Password",
    title_head: "Reset Password"
  }
}

export const CHANGE_PASSWORD_ROUTE: RoutePathModel = {
  path: "change-password",
  url: `${AUTH_ROUTE.url}/change-password`,
  name: "ChangePassword",
  meta: {
    breadcrumb: "lbl_profile_change_password",
    title_head: "lbl_profile_change_password",
    need_auth: true
  }
}
