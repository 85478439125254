import { Api } from "@/models/class/api.class";
import { EnumUploadDataFeature } from "@/models/enums/UploadDataFeature.enum";

export class UploadDataMapper {
  static toDownloadTemplateEndpoint(feature: EnumUploadDataFeature): string {
    switch (feature) {
      case "CASH_IN_OUT":
        return Api.TemplateCashInOut;
      case "INTERNAL_CONTRACT":
        return Api.TemplateInternalContract;
      case "INVOICE_AR":
        return Api.TemplateInvoiceAR;
      case "TRUCKING_SETTLEMENT":
        return Api.TemplateTruckingSettlement;
      default:
        return "";
    }
  }

  static toUploadDataEndpoint(feature: EnumUploadDataFeature): string {
    switch (feature) {
      case "CASH_IN_OUT":
        return Api.UploaderCashInOut;
      case "INTERNAL_CONTRACT":
        return Api.UploaderInternalContract;
      case "INVOICE_AR":
        return Api.UploaderInvoiceAR;
      case "TRUCKING_SETTLEMENT":
        return Api.UploaderTruckingSettlement;
      default:
        return "";
    }
  }
}
