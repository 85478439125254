import { generateUUID } from "@/helpers/uuid";
import {
  EnumTruckingCashInOutStatus,
  EnumTruckingCashInOutTransactionType,
} from "@/models/enums/TruckingCashInOut.enum";
import {
  TruckingCashInOutFormLineState,
  TruckingCashInOutFormState,
} from "@interface/trucking-cash-in-out";

export function buildTruckingCashInOutFormState(): TruckingCashInOutFormState {
  return {
    journalId: "",
    journalNumber: "",
    status: "" as EnumTruckingCashInOutStatus,
    transferOthers: "",
    documentNo: "",
    branch: undefined,
    type: "" as EnumTruckingCashInOutTransactionType,
    transactionDate: null,
    currency: undefined,
    rates: 1,
    companyBank: undefined,
    cashInOutTransfer: "NONE",
    customerSupplierBank: undefined,
    referenceNo: "",
    supplierOrCustomer: undefined,
    chequeNo: "",
    chequeBankName: "",
    description: "",
    attachment: "",
    cashInOutLineDelete: [],
    lines: [],
    totalAmount: 0,
  };
}

export function buildTruckingCashInOutFormLineState(): TruckingCashInOutFormLineState {
  return {
    key: generateUUID(),
    account: undefined,
    amount: 0,
    notes: "",
    lineId: null,
    accountOptions: [],
    isAccountLoading: false,
    isSearching: false,
    truckingPaymentId: null,
  };
}
