import { useReceiveItem } from "@/hooks";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";

export const grChecklistLoader = async (to, _from, next): Promise<void> => {
  try {
    const { findDraftById } = useReceiveItem();
    const res = await findDraftById(to.params.id);
    store.dispatch("goodsReceiptChecklistStore/mapDetailDraftToForm", res);
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};

export const grChecklistCreateLoader = (_to, _from, next): void => {
  store.dispatch("goodsReceiptChecklistStore/resetStore");
  next();
};
