import {
  TruckingMasterRateResponseDto,
} from "@/models/interface/trucking-master-rate";

export function buildMasterRateResponseDto(): TruckingMasterRateResponseDto {
  return {
    id: "",
    createdBy: "",
    createdDate: "",
    modifiedBy: "",
    modifiedDate: "",
    rateAccountId: "",
    rateAccountName: "",
    rateId: "",
    rateName: "",
  };
}
