import { FormValue } from "@/store/company.store";

export const initForm = (): FormValue => ({
  address: "",
  code: "",
  email: "",
  fax: "",
  logo: "",
  name: "",
  phone: "",
  taxNumber: "",
  website: "",
  idtku: "000000",
  countryCode: undefined,
});
