import { StorageKeys } from "@/models/constant/enums/storage.enum";
import { Decimal } from "decimal.js-light";

const useCalculator = () => {
  const ZERO = 0;
  const ONE = 1;
  const ONE_HUNDRED = 100;
  const DEFAULT_DECIMAL_POINT = 2;
  const sum = (numbers: number[] = []): number => {
    return numbers.reduce((currValue, prevValue) => {
      const increment = new Decimal(currValue);
      return increment.add(prevValue || 0).toNumber();
    }, 0);
  };

  const toRoundHalfUp = (value: Decimal): Decimal => {
    const round: number = +(
      localStorage.getItem(StorageKeys.DECIMAL_POINT) || DEFAULT_DECIMAL_POINT
    );
    return value.toDecimalPlaces(round, Decimal.ROUND_HALF_UP);
  };

  const calcInclRate = (rate: number): Decimal => {
    return new Decimal(rate || ZERO).dividedBy(ONE_HUNDRED).plus(ONE);
  };

  return { sum, toRoundHalfUp, calcInclRate };
};

export default useCalculator;
