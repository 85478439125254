import { usePreferences, useUser } from "@/hooks";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";

const layoutLoader = async (_to, _from, next) => {
  try {
    store.commit(Mutations.SET_LOADING_COMPONENT, true);
    const { findAll } = usePreferences();
    const { findMe } = useUser();
    const me = await findMe();
    const res = await findAll();
    store.commit(`authStore/${Mutations.SET_AUTH}`, me);
    store.commit("preferenceStore/SET_PREFERENCE", res);
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};

export { layoutLoader };
