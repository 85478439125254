import { generateUUID } from "@/helpers/uuid";
import { ONE } from "@/models/constant/global.constant";
import {
  WorkOrderFormState,
  WorkOrderSparepartState,
  WorkOrderUnitState,
} from "./types";

export const buildWorkOrderFormState = (): WorkOrderFormState => ({
  branch: undefined,
  customerAddress: "",
  customer: undefined,
  description: "",
  mechanic: undefined,
  picName: "",
  scheduleOrder: null,
  spareParts: [],
  unitCode: undefined,
  units: [],
  workOrderDate: null,
});

export const buildWorkOrderSparepartState = (): WorkOrderSparepartState => ({
  key: generateUUID(),
  no: ONE,
  notes: "",
  partNumber: "",
  productCode: undefined,
  productLocation: undefined,
  productName: undefined,
  productUom: undefined,
  qtyAvailable: 0,
  qtyWorkOrder: 0,
  unitCode: undefined,
});

export const buildWorkOrderUnitState = (): WorkOrderUnitState => ({
  asset: undefined,
  capacity: "",
  assetCategory: "",
  key: generateUUID(),
  location: "",
  model: "",
  no: ONE,
  serialNumber: "",
  type: "",
  unitCode: "",
  unitSpecification: "",
});
