import { generateUUID } from "@/helpers/uuid";
import { ONE } from "@/models/constant/global.constant";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { SalesOrderTypeEnum } from "@/models/enums/SalesOrderType.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import { FormValue, SalesOrderLine } from "@/store/salesOrder.store";

export const initForm = (): FormValue => {
  return {
    documentNumber: "",
    salesType: "" as SalesOrderTypeEnum,
    internalContractId: "",
    internalContractNumber: "",
    branchId: "",
    branchName: "",
    date: null,
    customerId: "",
    customerName: "",
    shipTo: "",
    billTo: "",
    salesPerson: undefined,
    taxCalculation: TAX_CALCULATION.EXCLUSIVE,
    customerPoNumber: "",
    deliveryDate: null,
    currencyCode: "",
    currencyRate: ONE,
    top: 0,
    notes: "",
    status: "" as SALES_ORDER_STATUS,
    closeReason: "",
  };
};

export const initLine = (): SalesOrderLine => ({
  assetId: "",
  assetParentId: "",
  backupUnit: false,
  customerLocation: "",
  discountValue: 0, // use this one as discount calculation
  grossValue: 0,
  id: "",
  internalContractLineId: "",
  key: generateUUID(),
  locationId: "",
  locationName: "",
  percentDiscount: 0, // this one is not used rn
  price: 0,
  productCode: "",
  productId: "",
  productName: "",
  qty: 0,
  qtyAvailable: 0,
  serialNumber: "",
  subTotal: 0,
  taxableValue: 0,
  taxCode: undefined,
  taxRate: 0,
  taxValue: 0,
  unitCode: "",
  uomId: "",
  uomName: "",
});

export const initDetail = (): SalesOrderResponseDto => ({
  doResponseDTOS: [],
  branchWarehouse: "",
  branchWarehouseId: "",
  currency: "",
  currencyRate: 0,
  customerBillToAddress: "",
  customerId: "",
  customerLocation: "",
  customerName: "",
  customerPoNumber: "",
  customerShipToAddress: "",
  date: "",
  deliveryDate: "",
  documentNumber: "",
  endRent: "",
  grandTotal: 0,
  internalContractId: "",
  internalContractNo: "",
  notes: "",
  pickingList: [],
  reasonToClose: "",
  salesId: "",
  salesName: "",
  salesOrderLines: [],
  salesType: "" as SalesOrderTypeEnum,
  startRent: "",
  states: "" as SALES_ORDER_STATUS,
  taxCalculation: "" as TAX_CALCULATION,
  termOfPayment: 0,
  total: 0,
  totalDiscount: 0,
  totalTax: 0,
  createdBy: "",
  createdDate: "",
  id: "",
  modifiedBy: "",
  modifiedDate: "",
  documentReference: "",
});
