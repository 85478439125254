export enum TAX_CALCULATION {
  EXCLUSIVE = "Exclusive",
  INCLUSIVE = "Inclusive",
  NONE = "None",
}

export enum TAX_TYPE {
  VAT_OUT = "VAT Out",
  VAT_IN = "VAT In",
  INCOME_TAX_RECEIVABLES = "Income Tax Receivables",
  INCOME_TAX_PAYABLES = "Income Tax Payables",
}

export enum TAX_PPH_STATUS {
  SUBMITTED = "Submitted",
  CANCELLED = "Cancelled",
}
