import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/HttpClient.service";
import { AxiosError } from "axios";
import {
  StockAdjustmentRequestCreateDTO,
  StockAdjustmentResponseDTO,
} from "../types/stock-adjustment.types";

export class StockAdjustmentServices extends HttpClient {
  getStockAdjustmentById(id: string): Promise<StockAdjustmentResponseDTO> {
    return this.get<StockAdjustmentResponseDTO>(Api.StockAdjustment + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createStockAdjustment(
    payload: StockAdjustmentRequestCreateDTO
  ): Promise<StockAdjustmentResponseDTO> {
    return this.post<
      StockAdjustmentResponseDTO,
      StockAdjustmentRequestCreateDTO
    >(Api.StockAdjustment, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitStockAdjustment(
    payload: StockAdjustmentRequestCreateDTO,
    id: string | "-"
  ): Promise<StockAdjustmentResponseDTO> {
    return this.put<
      StockAdjustmentResponseDTO,
      StockAdjustmentRequestCreateDTO
    >(Api.StockAdjustment + `/submit/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelStockAdjustment(id: string): Promise<StockAdjustmentResponseDTO> {
    return this.put<StockAdjustmentResponseDTO, null>(
      Api.StockAdjustment + `/cancel/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateStockAdjustment(
    payload: StockAdjustmentRequestCreateDTO,
    id: string
  ): Promise<StockAdjustmentResponseDTO> {
    return this.put<
      StockAdjustmentResponseDTO,
      StockAdjustmentRequestCreateDTO
    >(Api.StockAdjustment + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const stockAdjustmentServices = new StockAdjustmentServices();
