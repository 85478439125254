import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { generalJournalServices } from "@/services/generaljournal.service";

const useGeneralJournal = () => {
  const print = (id: string) => {
    return generalJournalServices.printGeneralJournal(id);
  };

  const findAll = (params: RequestQueryParamsModel) => {
    return generalJournalServices.listGeneralJournal(params);
  };

  const deleteJournal = (secureId: string) => {
    return generalJournalServices.deleteGeneralJournal(secureId);
  };

  return { print, findAll, deleteJournal };
};

export default useGeneralJournal;
