import { useCoa } from "@/hooks";
import { FormValue } from "@/store/accountingAccount.store";

export const initForm = (): FormValue => {
  const { initCreateDto } = useCoa();
  const form = initCreateDto();
  return {
    accountTypeName: "",
    accountTypeId: form.accountTypeId,
    active: form.active,
    code: form.code,
    currencyId: form.currencyId,
    currencyName: "",
    description: form.description,
    normalBalance: form.normalBalance,
    parent: form.parent,
    parentId: form.parentId,
    parentName: "",
  };
};
