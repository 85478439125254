import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { InternalContractListDto } from "@/models/interface/internal-contract";
import {
  InternalContractCloseRequestDTO,
  InternalContractCloseResponseDTO,
} from "@/models/interface/internal-contract/InternalContractClose.interface";
import { InternalContractRequestDTO } from "@/models/interface/internal-contract/InternalContractCreate.interface";
import { InternalContractGetDTO } from "@/models/interface/internal-contract/InternalContractDetail.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class InternalContractService extends HttpClient {
  create(payload: InternalContractRequestDTO) {
    return this.post<string, InternalContractRequestDTO>(
      Api.InternalContract,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(id: string, payload: InternalContractRequestDTO) {
    return this.put<string, InternalContractRequestDTO>(
      Api.InternalContract + "/" + id,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detail(id: string) {
    return this.get<InternalContractGetDTO>(Api.InternalContract + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  list(params?: RequestQueryParamsModel) {
    return this.get<Pagination<InternalContractListDto>>(Api.InternalContract, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  closeBulk(body: InternalContractCloseRequestDTO) {
    return this.put<
      InternalContractCloseResponseDTO,
      InternalContractCloseRequestDTO
    >(Api.InternalContract + "/close", body)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

const internalContractService = new InternalContractService();

export { internalContractService };
