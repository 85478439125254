import { SearchBuilder } from "@/builder";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { invoiceServices } from "@/services/invoice.service";
import store from "@/store/auth.store";
import { Moment } from "moment";
import { useContactData } from "./contact";
import useAsset from "./useAsset";
import useAssetCategory from "./useAssetCategory";
import useBranch from "./useBranch";
import useDate from "./useDate";

type FilterField = Partial<{
  branchId: string | null;
  unitCodeId: string | null;
  invoiceDate: Array<Moment> | null;
  rwoDate: Array<Moment> | null;
  poDate: Array<Moment> | null;
  customerId: string | null;
  customerLocation: string | null;
  assetCategoryId: string | null;
}>;

const useUnitMargin = () => {
  const { toStartDay, toEndDay, toDefaultFormat } = useDate();
  const { findAll: findBranch } = useBranch();
  const { findById: findContactById } = useContactData();
  const { findAllMasterAsset } = useAsset();
  const { findAll: findAssetCat } = useAssetCategory();

  const findAll = (params: RequestQueryParamsModel) => {
    return invoiceServices.getListReportMarginUnit(params);
  };

  const download = (params: RequestQueryParamsModel) => {
    return invoiceServices.downloadReportMarginUnit(params);
  };

  const buildDownloadParam = async ({
    branchId,
    unitCodeId,
    invoiceDate,
    rwoDate,
    poDate,
    customerId,
    customerLocation,
    assetCategoryId,
  }: FilterField): Promise<string> => {
    const company = store.state.authData.companyName;
    const unitLocation = customerLocation || "ALL";

    let branch = "ALL";
    if (branchId) {
      const { data } = await findBranch({ search: "secureId~" + branchId });
      branch = data[0].name;
    }

    let custName = "ALL";
    if (customerId) {
      const response = await findContactById(customerId);
      custName = response.firstName;
    }

    let unitCode = "ALL";
    if (unitCodeId) {
      const response = await findAllMasterAsset({
        search: "secureId~" + unitCodeId,
      });
      unitCode = response.data[0].unitCode;
    }

    let category = "ALL";
    if (assetCategoryId) {
      const response = await findAssetCat({
        search: "secureId~" + assetCategoryId,
      });
      category = response.data[0].category.id;
    }

    let invoiceDateFrom = "ALL";
    let invoiceDateTo = "ALL";
    if (invoiceDate && invoiceDate.length) {
      const [start, end] = invoiceDate;
      invoiceDateFrom = toDefaultFormat(start);
      invoiceDateTo = toDefaultFormat(end);
    }

    let rwoDateFrom = "ALL";
    let rwoDateTo = "ALL";
    if (rwoDate && rwoDate.length) {
      const [start, end] = rwoDate;
      rwoDateFrom = toDefaultFormat(start);
      rwoDateTo = toDefaultFormat(end);
    }

    let poDateFrom = "ALL";
    let poDateTo = "ALL";
    if (poDate && poDate.length) {
      const [start, end] = poDate;
      poDateFrom = toDefaultFormat(start);
      poDateTo = toDefaultFormat(end);
    }

    const params: string[] = [
      company,
      branch,
      custName,
      category,
      unitCode,
      invoiceDateFrom,
      invoiceDateTo,
      rwoDateFrom,
      rwoDateTo,
      poDateFrom,
      poDateTo,
      unitLocation,
    ];

    return params.join(",");
  };

  const filterBy = ({
    branchId,
    unitCodeId,
    invoiceDate,
    rwoDate,
    poDate,
    customerId,
    customerLocation,
    assetCategoryId,
  }: FilterField): string => {
    const q: Array<string> = [];
    const builder = new SearchBuilder();

    if (customerId) {
      q.push(builder.push(["customerId", customerId]).build());
    }

    if (customerLocation) {
      q.push(
        builder
          .push(["customerLocation", customerLocation], { like: "both" })
          .build()
      );
    }

    if (assetCategoryId) {
      q.push(
        builder
          .push(["unitCategory", assetCategoryId], { like: "both" })
          .build()
      );
    }

    if (branchId) {
      q.push(builder.push(["branchId", branchId]).build());
    }

    if (unitCodeId) {
      q.push(builder.push(["assetId", unitCodeId]).build());
    }

    if (invoiceDate && invoiceDate.length) {
      const [start, end] = invoiceDate;
      q.push(
        builder
          .push(["invoiceDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["invoiceDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    if (rwoDate && rwoDate.length) {
      const [start, end] = rwoDate;
      q.push(
        builder
          .push(["rwoDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["rwoDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    if (poDate && poDate.length) {
      const [start, end] = poDate;
      q.push(
        builder
          .push(["poDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["poDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    return q.join(builder.AND);
  };

  return { findAll, download, filterBy, buildDownloadParam };
};

export default useUnitMargin;
