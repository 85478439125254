import { Api } from "@/models/class/api.class";
import { EnumFileBucket } from "@/models/constant/file-bucket.constant";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class FileService extends HttpClient {
  upload(payload: FormData, bucket: string): Promise<FileCreateResponseDto> {
    return this.post<FileCreateResponseDto, FormData>(
      `${Api.uploadFileModul}/${bucket}`,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getFile(bucket: EnumFileBucket, filename: string): Promise<Blob> {
    return this.get<Blob>(`${Api.uploadFileModul}/${bucket}/${filename}`, {
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const fileService = new FileService();
