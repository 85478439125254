import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { GetAgingReport } from "@/models/interface/aging.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  ReportAgingDashboardDetailDto,
  ReportAgingDashboardDetailParams,
  ReportAgingDashboardResponseDto,
} from "@interface/ar-aging-report/v2";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class AgingServices extends HttpClient {
  getAgingDashboard(): Promise<ReportAgingDashboardResponseDto> {
    return this.get<ReportAgingDashboardResponseDto>(Api.AgingDashboardV2)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAgingDetailDashboard(
    params?: ReportAgingDashboardDetailParams
  ): Promise<Pagination<ReportAgingDashboardDetailDto>> {
    return this.get<Pagination<ReportAgingDashboardDetailDto>>(
      Api.AgingDetailDashboardV2,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAgingReport(params: RequestQueryParamsModel): Promise<GetAgingReport> {
    return this.get<GetAgingReport>(Api.Aging, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAgingDownload(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.Aging + "/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const agingServices = new AgingServices();
