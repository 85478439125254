import { Row, useDeleteRows } from "@/hooks";
import { Option } from "@/models/class/option.class";
import {
  LogisticStockAdjustmentState,
  LogisticStockAdjustmentType,
} from "@/models/enums/stock-adjustment.enum";
import { ProductUomConversionDto } from "@/models/interface/master-product";
import {
  initDetail,
  initFormValue,
  initStockAdjRow,
} from "@/store/resources/StockAdj.resource";
import { LabelInValue } from "@/types";
import { useStockAdjustment } from "@/views/logistic/stock-adjustment/hooks";
import {
  StockAdjustmentLineRequestCreateDTO,
  StockAdjustmentRequestCreateDTO,
  StockAdjustmentResponseDTO,
} from "@/views/logistic/stock-adjustment/types";
import { Moment } from "moment";

export type CreateDtoWoLines = Omit<
  StockAdjustmentRequestCreateDTO,
  "stockAdjustmentLines" | "date" | "warehouseLocationId"
>;
export type DetailFormDto = Pick<
  StockAdjustmentResponseDTO,
  "state" | "documentNumber"
>;
export type StockAdjRow = Row<
  Omit<StockAdjustmentLineRequestCreateDTO, "uomId">,
  number
> & {
  productName: string;
  productCode: string;
  productMerk: string;
  differenceQty: number;
  uom: LabelInValue | undefined;
  uomOptions: Option<ProductUomConversionDto>[];
};

/**
 * @description
 * addition field that is used only for FE
 * use to filter product in inventory
 */
export type FieldAddition = {
  productCategoryId: string;
  brand: string;
  warehouseLocation: LabelInValue | undefined;
};

export type FormValue = CreateDtoWoLines &
  DetailFormDto &
  FieldAddition & {
    stockAdjustmentRows: Array<StockAdjRow>;
    date: Moment | null;
  };

export type State = {
  form: FormValue;
  detail: StockAdjustmentResponseDTO;
};

const state: State = {
  form: initFormValue(),
  detail: initDetail(),
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setDetail: (st: State, payload: StockAdjustmentResponseDTO): void => {
    st.detail = payload;
  },
};

const getters = {
  getForm: (st: State): FormValue => st.form,
  getDetail: (st: State): StockAdjustmentResponseDTO => st.detail,
  isOpname: (st: State): boolean => {
    return st.form.type === LogisticStockAdjustmentType.STOCK_OPNAME;
  },
  isAdjustment: (st: State): boolean => {
    return st.form.type === LogisticStockAdjustmentType.STOCK_ADJUSTMENT;
  },
  allowUpdate: (st: State): boolean => {
    return st.form.state === LogisticStockAdjustmentState.DRAFT;
  },
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    const form: FormValue = initFormValue();
    const detail: StockAdjustmentResponseDTO = initDetail();
    commit("setForm", form);
    commit("setDetail", detail);
  },
  addStockAdjRow: (context): void => {
    const { state } = context;
    const local: State = state;
    const row: StockAdjRow = initStockAdjRow();
    local.form.stockAdjustmentRows.push(row);
  },
  deleteStockAdjRow: (context, rowKeys: Array<number>): void => {
    const { state, commit } = context;
    const local: State = state;
    const { newSource, deletedRows } = useDeleteRows(
      local.form.stockAdjustmentRows,
      rowKeys
    );
    const deletedIds: Array<string> = deletedRows
      .filter(e => !!e.id)
      .map<string>(e => e.id as string);
    const form: Partial<FormValue> = {
      stockAdjustmentRows: newSource,
      deletedLineIds: deletedIds,
    };
    commit("setForm", form);
  },
  mapDetailToForm: (context, payload: StockAdjustmentResponseDTO): void => {
    const { commit } = context;
    const { mapDetailToForm: detailToForm } = useStockAdjustment();
    const form: FormValue = detailToForm(payload);
    commit("setForm", form);
    commit("setDetail", payload);
  },
  getDetailDoc: (context, id: string): void => {
    const { dispatch } = context;
    const { findById } = useStockAdjustment();
    findById(id).then((res: StockAdjustmentResponseDTO) => {
      dispatch("mapDetailToForm", res);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
