import { PropsModel } from "./interface/common.interface";

// Axios Setup
export const AXIOS_CREATE: PropsModel = {
  timeout: Number(process.env.VUE_APP_REQ_TIMEOUT),
  headers: {
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "Content-Security-Policy": "default-src https",
    "X-Frame-Options": "DENY",
    "X-Content-Type-Options": "nosniff always",
    "Referrer-Policy": "no-referrer",
    "Permissions-Policy":
      "accelerometer=(), ambient-light-sensor=(), autoplay=(), battery=(), camera=(), cross-origin-isolated=(), display-capture=(), document-domain=(), encrypted-media=(), execution-while-not-rendered=(), execution-while-out-of-viewport=(), fullscreen=(), geolocation=(), gyroscope=(), keyboard-map=(), magnetometer=(), microphone=(), midi=(), navigation-override=(), payment=(), picture-in-picture=(), publickey-credentials-get=(), screen-wake-lock=(), sync-xhr=(), usb=(), web-share=(), xr-spatial-tracking=()",
  },
};

export const DECIMAL_PLACES_CURRENCY = 2;

export const MAX_FILE_SIZE = 25000000;

export const PAGE_SIZE_OPTIONS = [
  "10",
  "25",
  "50",
  "100",
  "150",
  "250",
  "300",
  "500",
  "750",
  "1000",
] as const;

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const ONE = 1;

export const MAX_LENGTH_CHARACTER = 5000;
