import { useCompany } from "@/hooks";
import { ListCompanyDto } from "@/models/interface/company";
import { CompanyMutateForm } from "@/models/interface/company/CompanyMutateForm.interface";
import { initForm } from "@/store/resources/Company.resource";

export type FormValue = CompanyMutateForm;
export type State = {
  form: FormValue;
};

const state: State = {
  form: initForm(),
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): FormValue => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
    return st.form;
  },
};

const getters = {
  getForm: (st: State): FormValue => st.form,
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    const form: FormValue = initForm();
    commit("setForm", form);
  },
  mapOneToForm: (context, payload: ListCompanyDto): FormValue => {
    const { commit } = context;
    const { mapOneToForm } = useCompany();

    const form: FormValue = mapOneToForm(payload);
    commit("setForm", form);

    return form;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
