import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { InvoiceArListDto } from "@/models/interface/account-receivable";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  TruckingInvoiceArCreateDto,
  TruckingInvoiceArUpdateDto,
} from "@/models/interface/trucking-account-receivable";
import { invoiceServices } from "@/services/invoice.service";
import { truckingAccountReceivableService } from "@/services/trucking-account-receivable.service";
import { Moment } from "moment";
import useDate from "./useDate";

export const useTruckingAccountReceivable = () => {
  const findAll = (params?: RequestQueryParamsModel) => {
    return truckingAccountReceivableService.getInvoiceList(params);
  };

  const createInvoiceAr = (dto: TruckingInvoiceArCreateDto) => {
    return truckingAccountReceivableService.createInvoiceAr(dto);
  };

  const findById = (invoiceId: string) => {
    return truckingAccountReceivableService.getDetailInvoiceAr(invoiceId);
  };

  const updateInvoiceAr = (
    invoiceId: string,
    dto: TruckingInvoiceArUpdateDto
  ) => {
    return truckingAccountReceivableService.updateInvoiceAr(invoiceId, dto);
  };

  const submitInvoiceAr = (
    invoiceId: string,
    dto: TruckingInvoiceArUpdateDto
  ) => {
    return truckingAccountReceivableService.submitInvoiceAr(invoiceId, dto);
  };

  const findSummary = (invoiceId: string) => {
    return invoiceServices.getInvoiceDetailSummary(invoiceId);
  };

  const toOptions = (datas: InvoiceArListDto[]): Option<InvoiceArListDto>[] => {
    return datas.map<Option<InvoiceArListDto>>(item => ({
      label: item.documentNumber,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const buildSearch = (
    state: Partial<{
      branch: string | undefined;
      customer: string | undefined;
      invoiceDate: Moment[] | null;
      invoice: string | undefined;
      invoiceId: string | undefined;
      status: string | undefined;
    }>
  ): string => {
    const queries: string[] = [];
    const { toStartDay, toEndDay } = useDate();

    if (state.branch) {
      const findByBranch: string = new SearchBuilder()
        .push(["branchId", state.branch])
        .build();
      queries.push(findByBranch);
    }

    if (state.customer) {
      const findByCustomer: string = new SearchBuilder()
        .push(["customerId", state.customer])
        .build();
      queries.push(findByCustomer);
    }

    if (state.invoiceDate && state.invoiceDate.length > 1) {
      const [start, end] = state.invoiceDate;
      const findByDate: string = new SearchBuilder()
        .push(["invoiceDate", toStartDay(start).format()], { het: true })
        .and()
        .push(["invoiceDate", toEndDay(end).format()], { let: true })
        .build();
      queries.push(findByDate);
    }

    if (state.invoice) {
      const findByInvoice: string = new SearchBuilder()
        .push(["documentNumber", state.invoice], { like: "both" })
        .build();
      queries.push(findByInvoice);
    }

    if (state.invoiceId) {
      const findByInvoiceId: string = new SearchBuilder()
        .push(["secureId", state.invoiceId])
        .build();
      queries.push(findByInvoiceId);
    }

    if (state.status) {
      const findByStatus: string = new SearchBuilder()
        .push(["status", state.status], { like: "both" })
        .build();
      queries.push(findByStatus);
    }

    return queries.join(SearchBuilder.AND);
  };

  return {
    findAll,
    toOptions,
    buildSearch,
    findSummary,
    createInvoiceAr,
    findById,
    updateInvoiceAr,
    submitInvoiceAr,
  };
};
