import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ProductCategoryReportResponseDto } from "@/models/interface/ProductCategoryReport.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ProductCategoryResponseDto } from "@/models/interface/product-category";
import { RequestProductCategory } from "@/models/interface/product.interface";
import { productService } from "@/services/product.service";
import authStore from "@/store/auth.store";
import { Row } from "./table";

export type SearchField = Partial<{
  name: string;
}>;

const useProductCategory = () => {
  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ProductCategoryResponseDto>> => {
    return productService.getListProductCategory(params);
  };

  const create = (req: RequestProductCategory) => {
    return productService.createProductCategory(req);
  };

  const update = (id: string, req: RequestProductCategory) => {
    return productService.updateProductCategory(req, id);
  };

  const initReqCreateDto = (): RequestProductCategory => ({
    name: "",
    costOfSalesAccountId: "",
    inventoryAccountId: "",
    expensePurchaseAccountId: "",
    purchaseReturnAccountId: "",
    purchaseDiscountAccountId: "",
    assetCostAccountId: "",
    assetDepreciationAccountId: "",
    assetAccumulationAccountId: "",
    unbilledAccountId: "",
    salesAccountId: "",
    salesReturnAccountId: "",
    salesDiscountAccountId: "",
    assetClearingAccountId: "",
  });

  const findAllRemastered = (params: RequestQueryParamsModel) => {
    return productService.listProductCategoryRemastered(params);
  };

  const searchBy = (field: SearchField): string => {
    const q: string[] = [];
    const builder = new SearchBuilder();

    if (field.name) {
      q.push(builder.push(["name", field.name], { like: "both" }).build());
    }

    return q.join(builder.OR);
  };

  const toTableRows = (
    data: ProductCategoryReportResponseDto[]
  ): Row<ProductCategoryReportResponseDto, string>[] => {
    return data.map(item => ({
      ...item,
      key: item.id,
    }));
  };

  const download = (params: RequestQueryParamsModel) => {
    return productService.downloadProductCategory(params);
  };

  const buildParamDownload = (): string => {
    return authStore.state.authData.companyName;
  };

  const toOptions = (
    data: ProductCategoryResponseDto[]
  ): Array<Option<ProductCategoryResponseDto>> => {
    return data.map<Option<ProductCategoryResponseDto>>(item => ({
      label: item.name,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  return {
    findAll,
    toTableRows,
    searchBy,
    findAllRemastered,
    download,
    buildParamDownload,
    create,
    update,
    initReqCreateDto,
    toOptions,
  };
};

export default useProductCategory;
