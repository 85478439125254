import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { logisticServices } from "@/services/logistic.service";
import {
  BaseResponse,
  RequestQueryParamsModel,
} from "@interface/http.interface";
import {
  WarehouseLocationCreateRequestDto,
  WarehouseLocationResponseDto,
} from "@interface/location";

export interface FormValue extends WarehouseLocationCreateRequestDto {
  warehouseName: string;
}

type SearchField = Partial<{
  name: string;
  warehouseName: string;
  branchName: string;
}>;

const useLocation = () => {
  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<WarehouseLocationResponseDto>> => {
    return logisticServices.getListWarehouseLocation(params);
  };

  const findById = (id: string): Promise<WarehouseLocationResponseDto> => {
    return logisticServices.getDetailWarehouseLocation(id);
  };

  const create = (
    body: WarehouseLocationCreateRequestDto
  ): Promise<BaseResponse> => {
    return logisticServices.createWarehouseLocation(body);
  };

  const update = (
    rackId: string,
    body: WarehouseLocationCreateRequestDto
  ): Promise<WarehouseLocationResponseDto> => {
    return logisticServices.updateWarehouseLocation(body, rackId);
  };

  const toOptions = (
    data: WarehouseLocationResponseDto[],
    as: keyof WarehouseLocationResponseDto = "name"
  ): Option<WarehouseLocationResponseDto>[] => {
    return data.map<Option<WarehouseLocationResponseDto>>(item => ({
      label: `${item.warehouse.branchWarehouse.name}, ${item.warehouse.name}, ${item.name}`,
      value: item[`${as}`],
      key: item.id,
      meta: item,
    }));
  };

  const searchBy = (field: SearchField): string => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (field.name) {
      query.push(
        builder
          .push(["name", field.name], {
            like: "both",
          })
          .build()
      );
    }

    if (field.warehouseName) {
      query.push(
        builder
          .push(["warehouse.name", field.warehouseName], {
            like: "both",
          })
          .build()
      );
    }

    if (field.branchName) {
      query.push(
        builder
          .push(["warehouse.branchWarehouse.name", field.branchName], {
            like: "both",
          })
          .build()
      );
    }

    return query.join(builder.OR);
  };

  const mapFormToCreateDto = ({
    name,
    warehouseId,
  }: FormValue): WarehouseLocationCreateRequestDto => {
    return { name, warehouseId };
  };

  const mapDetailDtoToForm = (
    detail: WarehouseLocationResponseDto
  ): FormValue => {
    return {
      name: detail.name,
      warehouseId: detail.warehouse.id,
      warehouseName: detail.warehouse.name,
    };
  };

  return {
    findAll,
    findById,
    toOptions,
    searchBy,
    create,
    mapFormToCreateDto,
    update,
    mapDetailDtoToForm,
  };
};

export default useLocation;
