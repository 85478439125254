import { Api } from "@/models/class/api.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  AccountingAccountCreateRequestDto,
  AccountingAccountResponseDto,
  AccountingAccountTypeResponseDto,
  AccountingAccountUpdateRequestDto,
} from "@/models/interface/accounting-account";
import {
  BaseResponse,
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class AccountingAccountService extends HttpClient {
  getListAccountType(
    params?: RequestQueryParams
  ): Promise<Pagination<AccountingAccountTypeResponseDto>> {
    return this.get<Pagination<AccountingAccountTypeResponseDto>>(
      Api.AccountType,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AccountingAccountResponseDto>> {
    return this.get<Pagination<AccountingAccountResponseDto>>(Api.Coa, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detail(id: string): Promise<AccountingAccountResponseDto> {
    return this.get<AccountingAccountResponseDto>(Api.Coa + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  create(payload: AccountingAccountCreateRequestDto): Promise<BaseResponse> {
    return this.post<BaseResponse, AccountingAccountCreateRequestDto>(
      Api.Coa,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(
    id: string,
    payload: AccountingAccountUpdateRequestDto
  ): Promise<AccountingAccountResponseDto> {
    return this.put<
      AccountingAccountResponseDto,
      AccountingAccountUpdateRequestDto
    >(Api.Coa + "/" + id, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  deleteCoa(id: string): Promise<void> {
    return this.delete<void>(Api.Coa + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const accountingAccountService = new AccountingAccountService();
