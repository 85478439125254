import { LeasingDetailResponseDto } from "@/models/interface/leasing";
import { FormValue, LeasingInvoiceState, State } from "./types";

export const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setDetail: (st: State, payload: LeasingDetailResponseDto): void => {
    st.detail = payload;
  },
  setGeneratedInvoices: (st: State, payload: LeasingInvoiceState[]): void => {
    st.generatedInvoices = payload;
  },
  setMaxInvoiceDate: (st: State, payload: number | null): void => {
    st.maxInvoiceDate = payload;
  },
};
