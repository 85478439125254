import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";
import { NavigationGuard } from "vue-router";

export const leasingDetailLoader: NavigationGuard = async (
  to,
  _from,
  next
): Promise<void> => {
  try {
    const leasingId: string = to.params.id || "";
    await store.dispatch("leasingStore/fetchDetail", leasingId);

    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};

export const leasingCreateLoader: NavigationGuard = (
  _to,
  _from,
  next
): void => {
  store.dispatch("leasingStore/resetStore");
  next();
};
