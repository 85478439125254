import { usePreferences } from "@/hooks";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { Decimal } from "decimal.js-light";

export class RoundingUtils {
  static round(value: Decimal, mode = Decimal.ROUND_DOWN): Decimal {
    const response = usePreferences().findByKey("feature_decimal_point");
    if (response && response.value) {
      const point = Number(response.value);
      return value.toDecimalPlaces(point, mode);
    }
    return value.toDecimalPlaces(DECIMAL_PLACES_CURRENCY, mode);
  }

  static round14(value: Decimal): Decimal {
    const FOURTEEN = 14;
    return value.toDecimalPlaces(FOURTEEN, Decimal.ROUND_DOWN);
  }
}
