import { trimSpaceToUnderscore } from "@/helpers/common";

export class StringUtils {
  static objectToString(obj: Record<string, unknown>, delimiter = ""): string {
    const resultArray: string[] = [];

    for (const key in obj) {
      if (key && obj[key]) {
        resultArray.push(`${key} = ${obj[key]}`);
      }
    }

    return resultArray.join(delimiter);
  }

  static compare<TLeft extends string = string>(
    left: TLeft,
    right: TLeft
  ): boolean {
    return trimSpaceToUnderscore(left) === trimSpaceToUnderscore(right);
  }
}
