import { DrawerDataView } from "@/models/class/DrawerDataView.class";
import { Actions } from "@/models/constant/enums/actions.enum";
import { AXIOS_CREATE } from "@/models/constant/global.constant";
import { LoginResponseModel } from "@/models/interface/auth.interface";
import { HttpError, OAuthError } from "@/models/interface/http.interface";
import store from "@/store";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import nProgress from "nprogress";

const nprogress = nProgress.configure({ showSpinner: false });
const publicRoutes = [
  "/api/oauth/token",
  "/api/v1/user/forgot/password",
  "/api/v1/company/login/list",
];
const axiosInstance = axios.create({
  ...AXIOS_CREATE,
});

axiosInstance.interceptors.request.use(
  req => {
    const token = store.state.access_token;

    nprogress.start();

    if (req.url && !publicRoutes.includes(req.url) && token) {
      req.headers.Authorization = "Bearer " + token;
    }

    return req;
  },
  error => {
    nprogress.done();
    return errorHandler(error);
  }
);

axiosInstance.interceptors.response.use(
  req => {
    nprogress.done();
    return req;
  },
  error => {
    nprogress.done();
    return errorHandler(error);
  }
);

const refreshToken = async (err: AxiosError<HttpError>) => {
  if (err.response && err.response.status === 401) {
    const payload = {
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      grant_type: "refresh_token",
      refresh_token: store.state.refresh_token,
    };

    const formData = new FormData();
    formData.append("client_id", payload.client_id);
    formData.append("client_secret", payload.client_secret);
    formData.append("grant_type", payload.grant_type);
    formData.append("refresh_token", payload.refresh_token ?? "");

    try {
      const res = await axios.request<LoginResponseModel>({
        baseURL: "/",
        url: "/api/oauth/token",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const newToken = "Bearer " + res.data.access_token;
      const payload = {
        access_token: res.data.access_token,
        refresh_token: res.data.refresh_token,
      };
      store.dispatch("ACTIONS_REFRESH_TOKEN", payload);

      const { method, data, headers, ...resConfig } = err.response.config;
      const httpHeader = new Headers(headers);
      httpHeader.set("Authorization", newToken);

      if (method === "GET") {
        return Promise.resolve(
          axiosInstance.request({
            ...resConfig,
            headers: httpHeader,
          })
        );
      } else if (method === "POST") {
        return Promise.resolve(
          axiosInstance.request({
            ...resConfig,
            data,
            headers: httpHeader,
          })
        );
      } else if (method === "PUT") {
        return Promise.resolve(
          axiosInstance.request({
            ...resConfig,
            data,
            headers: httpHeader,
          })
        );
      } else if (method === "DELETE") {
        return Promise.resolve(
          axiosInstance.request({
            ...resConfig,
            headers: httpHeader,
          })
        );
      }
      return Promise.resolve();
    } catch (error: any) {
      // type guard
      if (
        "response" in error &&
        error.response &&
        error.response.status === 401
      ) {
        store.dispatch(Actions.ACTIONS_LOGOUT);
      }

      showDrawer(error);
      return Promise.reject(err);
    }
  }
};

const showDrawer = (err: AxiosError<HttpError | OAuthError>) => {
  let content = "";

  // type guard

  if (err.response && "details" in err.response.data) {
    content = err.response?.data.details?.join(". ") ?? "";
  } else if (err.response && "error" in err.response.data) {
    content = err.response.data.error;
  }

  const dataView = new DrawerDataView({
    content,
    title:
      "Error:" +
      " " +
      (err.response?.statusText ?? "-") +
      " " +
      (err.response?.status ?? "-"),
    visible: true,
    state: "error",
    metadata: err.response?.data || "",
  });
  store.commit("drawerStore/toggle", dataView);
};

const errorHandler = async (err: AxiosError<HttpError>) => {
  try {
    if (err.response && err.response.status === 401) {
      const res = await refreshToken(err);
      return Promise.resolve(res);
    } else {
      showDrawer(err);
      return Promise.reject(err);
    }
  } catch (error) {
    return Promise.reject(err);
  }
};

class HttpClient {
  private client: AxiosInstance = axiosInstance;

  protected success<TData = unknown>(res: AxiosResponse<TData>): TData {
    return res.data;
  }

  protected error<TData = unknown>(err: AxiosError<TData>): AxiosError<TData> {
    return err;
  }

  protected get<TReturn = unknown>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TReturn>> {
    return this.client.request<TReturn>({
      ...config,
      url,
      method: "GET",
    });
  }

  protected post<TReturn = unknown, TData = unknown>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TReturn>> {
    return this.client.request<TReturn>({
      ...config,
      url,
      data,
      method: "POST",
    });
  }

  protected put<TReturn = unknown, TData = unknown>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TReturn>> {
    return this.client.request<TReturn>({
      ...config,
      url,
      data,
      method: "PUT",
    });
  }

  protected delete<TReturn = unknown>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TReturn>> {
    return this.client.request<TReturn>({
      ...config,
      url,
      method: "DELETE",
    });
  }
}

export { HttpClient };
