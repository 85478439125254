import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  CreateInvoiceApPrepayment,
  InvoiceApPrepayment,
  InvoiceApPrepaymentData,
  ResponseCreateInvoiceApPrepayment,
} from "@/models/interface/InvoiceApPrepayment.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";

export class InvoiceApPrepaymentServices extends HttpClient {
  getListInvoiceApPrepayment(
    params: RequestQueryParamsModel
  ): Promise<Pagination<InvoiceApPrepayment>> {
    return this.get<Pagination<InvoiceApPrepayment>>(Api.InvoiceApPrepayment, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  createInvoiceApPrepayment(
    payload: CreateInvoiceApPrepayment
  ): Promise<ResponseCreateInvoiceApPrepayment> {
    return this.post<
      ResponseCreateInvoiceApPrepayment,
      CreateInvoiceApPrepayment
    >(Api.InvoiceApPrepayment, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  detailInvoiceApPrepayment(id: string): Promise<InvoiceApPrepaymentData> {
    return this.get<InvoiceApPrepaymentData>(Api.InvoiceApPrepayment + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  downloadInvoiceApPrepayment = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.InvoiceApPrepayment + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  approveInvoiceApPrepayment(
    payload: CreateInvoiceApPrepayment,
    id: string
  ): Promise<ResponseCreateInvoiceApPrepayment> {
    return this.post<
      ResponseCreateInvoiceApPrepayment,
      CreateInvoiceApPrepayment
    >(Api.InvoiceApPrepayment + "/approve/" + id, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  rejectInvoiceApPrepayment(
    id: string
  ): Promise<ResponseCreateInvoiceApPrepayment> {
    return this.post<
      ResponseCreateInvoiceApPrepayment,
      CreateInvoiceApPrepayment
    >(Api.InvoiceApPrepayment + "/reject/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelApPrepayment(id: string): Promise<ResponseCreateInvoiceApPrepayment> {
    return this.post<ResponseCreateInvoiceApPrepayment>(
      Api.InvoiceApPrepayment + "/cancel/" + id
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const invoiceApPrepayment = new InvoiceApPrepaymentServices();
