import { FormValue } from "@/store/truckingMasterRate.store";

export const initForm = (): FormValue => {
  return {
    id: "",
    rateAccount: undefined,
    rateAccountId: "",
    rateId: "",
    rateName: "",
  };
};
