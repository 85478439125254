// Class
import { Api } from "@/models/class/api.class";
import { ResponseUploadData } from "@/models/interface/assets.interface";
import {
  CreateBankTransfer,
  DataDetailBankTransfer,
  DetailCashOut,
  DetailSettlement,
  RequestCashOut,
  RequestParamsCashBankStatement,
  RequestSettlement,
  ResponseCashBankStatementReportDTO,
  ResponseCashInOutTypeDTO,
  ResponseListBankTransfer,
  ResponseListCashOut,
  ResponseUpdateCashInOut,
} from "@/models/interface/cashManagement.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class CashManagementServices extends HttpClient {
  getAllCashBankStatement(
    params: RequestParamsCashBankStatement
  ): Promise<ResponseCashBankStatementReportDTO> {
    return this.get<ResponseCashBankStatementReportDTO>(
      Api.CashBank + "/statement",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportCashFlow = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.CashBank + "/statement/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  printReportCashFlow = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.CashBank + "/statement/print", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadBankTransfer = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.BankTransfer + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  downloadCash(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.CashInOutListDownload, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode FileService.upload}
   */
  createUploadFile = (
    payload: FormData,
    modul: string
  ): Promise<ResponseUploadData> => {
    return this.post<ResponseUploadData, FormData>(
      Api.uploadFileModul + `/${modul}`,
      payload,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listBankTransfer = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListBankTransfer> => {
    return this.get<ResponseListBankTransfer>(Api.BankTransfer, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listBankTransferStatus = (params: RequestQueryParamsModel): Promise<any> => {
    return this.get<any>(Api.BankTransfer + "/status", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailBankTransfer = (id: string): Promise<DataDetailBankTransfer> => {
    return this.get<DataDetailBankTransfer>(Api.BankTransfer + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createBankTransfer = (
    payload: CreateBankTransfer
  ): Promise<DataDetailBankTransfer> => {
    return this.post<DataDetailBankTransfer, CreateBankTransfer>(
      Api.BankTransfer,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  draftBankTransfer = (
    payload: CreateBankTransfer
  ): Promise<DataDetailBankTransfer> => {
    return this.post<DataDetailBankTransfer, CreateBankTransfer>(
      Api.BankTransfer + "/draft",
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateBankTransfer = (
    payload: CreateBankTransfer,
    id: string,
    path: string
  ): Promise<DataDetailBankTransfer> => {
    return this.put<DataDetailBankTransfer, CreateBankTransfer>(
      Api.BankTransfer + path + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listCashStatus = (params: RequestQueryParamsModel): Promise<any> => {
    return this.get<any>(Api.CashOut + "/status", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listCashType = (
    params: RequestQueryParamsModel
  ): Promise<ResponseCashInOutTypeDTO[]> => {
    return this.get<ResponseCashInOutTypeDTO[]>(Api.CashOut + "/type", {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listCashOut(params: RequestQueryParamsModel): Promise<ResponseListCashOut> {
    return this.get<ResponseListCashOut>(Api.CashInOutList, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createCashOut(payload: RequestCashOut): Promise<any> {
    return this.post<any, RequestCashOut>(Api.CashOut, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitCashOut = (id: string, payload: RequestCashOut): Promise<any> => {
    return this.put<any, RequestCashOut>(Api.SubmitCashOut + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  updateCashOut = (
    id: string,
    payload: RequestCashOut
  ): Promise<ResponseUpdateCashInOut> => {
    return this.put<ResponseUpdateCashInOut, RequestCashOut>(
      Api.CashOut + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  cancelCashOut = (id: string): Promise<any> => {
    return this.put<any, RequestCashOut>(Api.CancelCashOut + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailCashOut = (id: string): Promise<DetailCashOut> => {
    return this.get<DetailCashOut>(Api.CashOut + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listSettlement = (params: RequestQueryParamsModel): Promise<any> => {
    return this.get<any>(Api.Settlement, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailSettlement = (id: string): Promise<DetailSettlement> => {
    return this.get<DetailSettlement>(Api.Settlement + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  transactionType = (): Promise<any> => {
    return this.get<any>(Api.TransactionType)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listBankMovement = (params: RequestQueryParamsModel): Promise<any> => {
    return this.get<any>(Api.BankMovement, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  createSettlement = (payload: RequestSettlement): Promise<any> => {
    return this.post<any, RequestSettlement>(Api.Settlement, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  cashManagementClosePeriod = (
    payload: string,
    params: RequestQueryParamsModel
  ): Promise<boolean> => {
    return this.post<boolean, string>(
      Api.BankTransfer + "/close-period",
      payload,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printSummary(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.CashInOutPrint}/${id}/summary`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printDetail(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.CashInOutPrint}/${id}/detail`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printBankTransfer(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.BankTransfer + "/print/" + id, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const cashManagementServices = new CashManagementServices();
