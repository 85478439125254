import { RouteConfig } from 'vue-router';

// Models
import { config } from '@/models/constant/routes.constant';

const router: RouteConfig = {
    path: config.AUTH_ROUTE.path,
    redirect: config.AUTH_ROUTE.redirect,
    name: config.AUTH_ROUTE.name,
    meta: config.AUTH_ROUTE.meta,
    component: {
        render(c) { return c("router-view") }
    },
    children: [
        {
            path: config.SIGNIN_ROUTE.path,
            name: config.SIGNIN_ROUTE.name,
            meta: config.SIGNIN_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./Signin.vue")
        },
        {
            path: config.FORGOT_PASSWORD_ROUTE.path,
            name: config.FORGOT_PASSWORD_ROUTE.name,
            meta: config.FORGOT_PASSWORD_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ForgotPassword.vue")
        }
    ]
};

export default router;
