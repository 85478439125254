import { useInvoiceAPPrepayment } from "@/hooks";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { InvoiceApPrepaymentData } from "@/models/interface/InvoiceApPrepayment.interface";
import { initDetail, initFormMeta } from "./resources/ApPrepayment.resource";

export type FormMeta = Partial<{
  assetId: string;
  currencyCode: string;
  supplierId: string;
  poId: string;
  payableAccId: string;
  lineAccId: string;
  branchId: string;
}>;
type State = {
  formMeta: FormMeta;
  detail: InvoiceApPrepaymentData;
  subtotal: number;
  taxRate: number;
  totalTax: number;
  total: number;
};

const state: State = {
  formMeta: initFormMeta(),
  detail: initDetail(),
  subtotal: 0, // subtotal invoice prepayment
  taxRate: 0, // menyimpan tax rate dari tax code yang dipilih pada form
  totalTax: 0, // total tax invoice prepayment
  total: 0, // total payment
};

const mutations = {
  setFormMeta: (st: State, payload: FormMeta): void => {
    const { formMeta } = st;
    st.formMeta = { ...formMeta, ...payload };
  },
  setDetail: (st: State, payload: InvoiceApPrepaymentData): void => {
    st.detail = payload;
  },
  /**
   * @description subtotal berubah ketika
   * - amount berubah
   * - tax type berubah
   */
  setSubtotal: (st: State, payload: number): void => {
    st.subtotal = payload;
  },
  setTaxRate: (st: State, payload: number): void => {
    st.taxRate = payload;
  },
  setTotalTax: (st: State, payload: number): void => {
    st.totalTax = payload;
  },
  setTotal: (st: State, payload: number): void => {
    st.total = payload;
  },
};

const actions = {
  doCalculation: (
    { commit, state },
    payload: { amount: number; taxType: TAX_CALCULATION }
  ): void => {
    const {
      calcSubtotalExc,
      calcSubtotalInc,
      calcTotalTaxExc,
      calcTotalTaxInc,
      calcTotalPayment,
    } = useInvoiceAPPrepayment();
    const { amount = 0, taxType } = payload;

    let subtotal = 0;
    let totalTax = 0;

    if (taxType === TAX_CALCULATION.EXCLUSIVE) {
      subtotal = calcSubtotalExc({ amount });
      totalTax = calcTotalTaxExc({ subtotal, taxRate: state.taxRate });
    } else if (taxType === TAX_CALCULATION.INCLUSIVE) {
      subtotal = calcSubtotalInc({ amount, taxRate: state.taxRate });
      totalTax = calcTotalTaxInc({ subtotal, amount });
    }

    const total = calcTotalPayment({ subtotal, totalTax });

    commit("setSubtotal", subtotal);
    commit("setTotalTax", totalTax);
    commit("setTotal", total);
  },
  resetStore: ({ commit }): void => {
    commit("setFormMeta", initFormMeta());
    commit("setDetail", initDetail());
    commit("setSubtotal", 0);
    commit("setTaxRate", 0);
    commit("setTotalTax", 0);
    commit("setTotal", 0);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
