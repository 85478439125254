import { WorkOrderDraftResponseDto } from "@/models/interface/work-order";
import { State, WorkOrderFormState } from "./types";

export const mutations = {
  setDetail: (st: State, payload: WorkOrderDraftResponseDto): void => {
    st.detail = payload;
  },
  setForm: (st: State, payload: Partial<WorkOrderFormState>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
};
