import { Api } from "@/models/class/api.class";
import {
  ReceivingItemCreateRequestDto,
  ReceivingItemDraftResponseDto,
  ReceivingItemResponseDto,
  ReceivingItemSubmitResponseDto,
  ReceivingItemUpdateRequestDto,
} from "@/models/interface/receive-item";
import { ErrorResponseModel } from "@/models/interfaces/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class ReceiveItemService extends HttpClient {
  getDetailDraftReceiveItem(
    id: string
  ): Promise<ReceivingItemDraftResponseDto> {
    return this.get<ReceivingItemDraftResponseDto>(
      `${Api.ReceiveItemDraft}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createReceiveItem(
    payload: ReceivingItemCreateRequestDto
  ): Promise<ReceivingItemSubmitResponseDto> {
    return this.post<
      ReceivingItemSubmitResponseDto,
      ReceivingItemCreateRequestDto
    >(Api.ReceiveItem, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateReceiveItem(
    payload: ReceivingItemUpdateRequestDto,
    id: string
  ): Promise<ReceivingItemResponseDto> {
    return this.put<ReceivingItemResponseDto, ReceivingItemUpdateRequestDto>(
      Api.ReceiveItem + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createReceiveItemAsDraft(
    payload: ReceivingItemCreateRequestDto
  ): Promise<ReceivingItemCreateRequestDto> {
    return this.post<
      ReceivingItemCreateRequestDto,
      ReceivingItemCreateRequestDto
    >(Api.ReceiveItemDraft, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitReceiveItem(
    id: string,
    payload: ReceivingItemUpdateRequestDto
  ): Promise<ReceivingItemSubmitResponseDto> {
    return this.post<
      ReceivingItemSubmitResponseDto,
      ReceivingItemUpdateRequestDto
    >(Api.ReceiveItemSubmit + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printGrChecklistReport(id: string): Promise<Blob> {
    return this.get<Blob>(`${Api.ReceiveItemChecklistPrint}/${id}`, {
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const receiveItemService = new ReceiveItemService();
