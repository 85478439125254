// eslint-disable-next-line
type DebounceProcess = <T = any>(
  fn: (...args: Array<T>) => void,
  delay?: number
) => (...args: Array<T>) => void;
export const debounceProcess: DebounceProcess = (fn, delay) => {
  let timeOutId;
  return function (...args) {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      fn(...args);
    }, delay || 500);
  };
};

/**
 * Call this function to enable debounce in Component Class model vue
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
let timeoutId;
export function debounce(func, delay?: number) {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    func();
  }, delay || 500);
}
