import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ListBankDto } from "@/models/interface/bank";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { bankService } from "@/services/Bank.service";
import { companyBankService } from "@/services/CompanyBank.service";
import {
  LastSyncResponseDto,
  ListCompanyBankDto,
} from "@interface/company-bank";

type SearchField = Partial<{
  currencyId: string;
  bankAccName: string;
  bankAccNumber: string;
  bankName: string;
}>;

const useBank = () => {
  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListCompanyBankDto>> => {
    return companyBankService.getList(params);
  };

  const syncAllCompanyBank = (): Promise<boolean> => {
    return companyBankService.syncAll();
  };

  const fetchLastSyncDate = (): Promise<LastSyncResponseDto> => {
    return companyBankService.getLastSyncDate();
  };

  const findAllMasterBank = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListBankDto>> => {
    return bankService.getMasterBankList(params);
  };

  const toOptions = (data: Array<ListCompanyBankDto>) => {
    return data.map<Option<ListCompanyBankDto>>(item => {
      return {
        value: item.id,
        label: `${item.bankName} - ${item.bankAccName} (${item.bankAccNumber})`,
        key: item.id,
        meta: item,
      };
    });
  };

  const searchBy = ({
    bankAccName = "",
    bankAccNumber = "",
    bankName = "",
    currencyId = "",
  }: SearchField): string => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (bankAccName) {
      query.push(
        builder
          .push(["bankAccName", bankAccName], {
            like: "both",
          })
          .build()
      );
    }

    if (bankAccNumber) {
      query.push(
        builder
          .push(["bankAccNumber", bankAccNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (bankName) {
      query.push(
        builder
          .push(["bankName", bankName], {
            like: "both",
          })
          .build()
      );
    }

    if (currencyId) {
      query.push(builder.push(["currency.secureId", currencyId]).build());
    }

    return query.join(builder.OR);
  };

  return {
    findAll,
    toOptions,
    searchBy,
    findAllMasterBank,
    syncAllCompanyBank,
    fetchLastSyncDate,
  };
};

export default useBank;
