

















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class AppBreadcrumb extends Vue {
  @Prop({ type: Array, required: false })
  breadcrumbs!: Array<Record<string, any>>;
}
