import { SearchBuilder } from "@/builder";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  StockReportDownloadParams,
  StockReportParams,
} from "@/models/interface/stock-report";
import { logisticServices } from "@/services/logistic.service";
import { stockReportService } from "@/services/stock-report.service";
import authStore from "@/store/auth.store";
import { Moment } from "moment";
import useDate from "./useDate";

type FilterField = Partial<{
  productId: string;
  productName: string;
  productCode: string;
  branchName: string;
  branchId: string;
  warehouseName: string;
  warehouseId: string;
  locationName: string;
  uom: string;
  uomId: string;
  locationId: string;
  date: Moment[];
}>;

const useStockReport = () => {
  /**
   * @deprecated
   * new method {@link findAllV2}
   */
  const findAll = (params: RequestQueryParams) => {
    return logisticServices.getListStockReport(params);
  };

  const findAllV2 = (params: StockReportParams) => {
    return stockReportService.getListStockReportV2(params);
  };

  const download = (params: RequestQueryParams) => {
    return logisticServices.downloadStockReport(params);
  };

  const downloadV2 = (params: StockReportDownloadParams) => {
    return stockReportService.downloadStockReportV2(params);
  };

  const filterBy = (field: FilterField): string => {
    const { toStartDay, toEndDay } = useDate();
    const { branchId, warehouseId, productId, uomId, locationId, date } = field;
    const q: string[] = [];
    const builder = new SearchBuilder();

    if (branchId) {
      q.push(builder.push(["branchId", branchId]).build());
    }

    if (warehouseId) {
      q.push(builder.push(["warehouseId", warehouseId]).build());
    }

    if (productId) {
      q.push(builder.push(["productId", productId]).build());
    }

    if (uomId) {
      q.push(builder.push(["uomId", uomId]).build());
    }

    if (locationId) {
      q.push(builder.push(["locationId", locationId]).build());
    }

    if (date && date.length) {
      const [start, end] = date;
      q.push(
        builder
          .push(["date", toStartDay(start).format()], { het: true })
          .and()
          .push(["date", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    return q.join(builder.AND);
  };

  const buildParamsDownload = (field: FilterField): string => {
    const {
      branchName,
      warehouseName,
      locationName,
      productCode,
      productName,
      uom,
      date,
    } = field;

    let startDate = "ALL";
    let endDate = "ALL";
    if (date && date.length > 0) {
      const [start, end] = date;
      startDate = start.format(DEFAULT_DATE_FORMAT);
      endDate = end.format(DEFAULT_DATE_FORMAT);
    }

    const params: string[] = [
      authStore.state.authData.companyName,
      branchName || "ALL",
      warehouseName || "ALL",
      locationName || "ALL",
      productCode || "ALL",
      productName || "ALL",
      uom || "ALL",
      startDate,
      endDate,
    ];

    return params.join(",");
  };

  return {
    findAll,
    download,
    filterBy,
    buildParamsDownload,
    findAllV2,
    downloadV2,
  };
};

export default useStockReport;
