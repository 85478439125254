import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

// import IndexPage from "./IndexPage.vue";

const index: RouteConfig = {
  path: config.MY_PROFILE_ROUTE.path,
  name: config.MY_PROFILE_ROUTE.name,
  meta: config.MY_PROFILE_ROUTE.meta,
  component: () => import("./IndexPage.vue"),
};

export default index;
