import { SearchBuilder } from "@/builder";

type Filter = Partial<{
  customerId: string;
  icId: string;
  unitCode: string;
  bapuNumber: string;
  isReceived: "true" | "false";
}>;

const useUnitReceiptMonitoring = () => {
  const filterBy = (state?: Filter): string => {
    if (!state) return "";

    const queries: string[] = [];

    if (state.customerId) {
      const findbyCustomer = new SearchBuilder()
        .push(["customerId", state.customerId])
        .build();
      queries.push(findbyCustomer);
    }
    if (state.icId) {
      const findbyIcId = new SearchBuilder().push(["icId", state.icId]).build();
      queries.push(findbyIcId);
    }
    if (state.unitCode) {
      const findbyUnitCode = new SearchBuilder()
        .push(["unitCode", state.unitCode], { like: "both" })
        .build();
      queries.push(findbyUnitCode);
    }
    if (state.bapuNumber) {
      const findbyBapuNumber = new SearchBuilder()
        .push(["bapuNumber", state.bapuNumber], { like: "both" })
        .build();
      queries.push(findbyBapuNumber);
    }
    if (state.isReceived) {
      const findbyIsReceived = new SearchBuilder()
        .push(["isReceive", state.isReceived])
        .build();
      queries.push(findbyIsReceived);
    }

    return queries.length > 0 ? queries.join(SearchBuilder.AND) : "";
  };

  return { filterBy };
};

export default useUnitReceiptMonitoring;
