import {
  buildSalesOrderCreateDto,
  buildSalesOrderDetailDto,
  buildSalesOrderLineRequestDto,
} from "@/builder/sales-order/SalesOrderDtoBuilder";
import {
  buildSalesOrderFormState,
  buildSalesOrderProductState,
} from "@/builder/sales-order/SalesOrderStateBuilder";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { SalesOrderTypeEnum } from "@/models/enums/SalesOrderType.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  SalesOrderCloseRequestDto,
  SalesOrderCreateRequestDto,
  SalesOrderLineRequestDto,
  SalesOrderResponseDto,
  SalesOrderUpdateRequestDto,
} from "@/models/interface/sales-order";
import { SalesOrderData } from "@/models/interface/salesOrder.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import store from "@/store";
import {
  SalesOrderLine,
  State as SalesOrderState,
} from "@/store/salesOrder.store";
import Decimal from "decimal.js-light";
import moment from "moment";
import { useFindMasterType } from "./master-type";

const useSalesOrder = () => {
  const findAvailableSalesOrder = (params?: RequestQueryParamsModel) => {
    return salesOrderServices.getListAvailableSalesOrder(params);
  };

  const findAll = (params: RequestQueryParamsModel) => {
    return salesOrderServices.getListSalesOrder(params);
  };

  const findById = (id: string) => {
    return salesOrderServices.getDetailSalesOrder(id);
  };

  const toOptions = (data: Array<SalesOrderData>): Option<SalesOrderData>[] => {
    return data.map<Option<SalesOrderData>>(item => ({
      label: item.salesOrderNo || "-",
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const findAllSoType = async () => {
    return await useFindMasterType("SALES_TYPE");
  };

  const create = (payload: SalesOrderCreateRequestDto) => {
    return salesOrderServices.createSalesOrder(payload);
  };

  const update = (id: string, payload: SalesOrderUpdateRequestDto) => {
    return salesOrderServices.updateSalesOrder(payload, id);
  };

  const submit = (id: string, payload: SalesOrderUpdateRequestDto) => {
    return salesOrderServices.submitSalesOrder(payload, id);
  };

  const cancel = (id: string) => {
    return salesOrderServices.cancelSalesOrder(id);
  };

  const closeSo = (id: string, payload: SalesOrderCloseRequestDto) => {
    return salesOrderServices.closeSalesOrder(payload, id);
  };

  const initUpdateDto = (): SalesOrderUpdateRequestDto => ({
    branchWarehouseId: "",
    currency: "",
    currencyRate: ONE,
    customerBillToAddress: "",
    customerId: "",
    customerPoNumber: "",
    customerShipToAddress: "",
    date: "",
    deletedSalesOrderLineIds: [],
    deliveryDate: "",
    internalContractId: "",
    notes: "",
    salesId: "",
    salesOrderLines: [],
    salesType: "" as SalesOrderTypeEnum,
    taxCalculation: "" as TAX_CALCULATION,
    termOfPayment: 0,
    isLuxury: false,
  });

  const buildCreateDto = (
    form: SalesOrderState["form"]
  ): SalesOrderCreateRequestDto => {
    const req: SalesOrderCreateRequestDto = buildSalesOrderCreateDto();
    const soLines: SalesOrderLine[] = store.getters[
      "salesOrderStore/getSoLines"
    ] as SalesOrderLine[];

    req.branchWarehouseId = form.branchId || "";
    req.currency = form.currencyCode || "";
    req.currencyRate = form.currencyRate || ONE;
    req.customerBillToAddress = form.billTo || "";
    req.customerId = form.customerId || "";
    req.customerPoNumber = form.customerPoNumber || "";
    req.customerShipToAddress = form.shipTo || "";
    req.date = form.date?.format() || "";
    req.deliveryDate = form.deliveryDate?.format() || "";
    req.grandTotal = store.getters["salesOrderStore/getGrandTotal"] || 0;
    req.internalContractId = form.internalContractId || "";
    req.notes = form.notes || "";
    req.salesId = form.salesPerson?.key || "";

    req.salesOrderLines =
      soLines.map<SalesOrderLineRequestDto>(buildSoLineRequest);

    req.salesType = form.salesType || ("" as SalesOrderTypeEnum);
    req.state = SALES_ORDER_STATUS.DRAFT;
    req.taxCalculation = form.taxCalculation || ("" as TAX_CALCULATION);
    req.termOfPayment = form.top || 0;
    req.total = (
      store.getters["salesOrderStore/getTotalDpp"] as Decimal
    ).toString();
    req.totalDiscount = store.getters["salesOrderStore/getTotalDiscount"] || 0;
    req.totalTax = (
      store.getters["salesOrderStore/getTotalTax"] as Decimal
    ).toString();
    req.isLuxury = form.isLuxury;

    return req;
  };

  const buildSoLineRequest = (
    line: SalesOrderLine
  ): SalesOrderLineRequestDto => {
    const req: SalesOrderLineRequestDto = buildSalesOrderLineRequestDto();

    if (line.inclusiveTax) {
      req.inclusiveTaxRateId = line.inclusiveTax.key;
    }

    req.assetId = line.assetId || "";
    req.assetParentId = line.assetParentId || "";
    req.backupUnit = line.backupUnit || false;
    req.customerLocation = line.customerLocation || "";
    req.discountValue = line.discountValue || 0;
    req.id = line.id || "";
    req.internalContractLineId = line.internalContractLineId || "";
    req.locationId = line.locationId || "";
    req.percentDiscount = line.percentDiscount;
    req.price = line.price || 0;
    req.productId = line.productId || "";
    req.qty = line.qty || 0;
    req.qtyAvailable = line.qtyAvailable || 0;
    req.serialNumber = line.serialNumber || "";
    req.subTotal = line.subTotal.toString();
    req.taxId = line.taxCode?.key || "";
    req.uomId = line.uomId || "";

    return req;
  };

  const buildUpdateDto = (
    form: SalesOrderState["form"]
  ): SalesOrderUpdateRequestDto => {
    const req: SalesOrderUpdateRequestDto = initUpdateDto();
    const soLines: SalesOrderLine[] = store.getters[
      "salesOrderStore/getSoLines"
    ] as SalesOrderLine[];
    const storeSo: SalesOrderState = store.state[
      "salesOrderStore"
    ] as SalesOrderState;

    req.branchWarehouseId = form.branchId || "";
    req.currency = form.currencyCode || "";
    req.currencyRate = form.currencyRate || ONE;
    req.customerBillToAddress = form.billTo || "";
    req.customerId = form.customerId || "";
    req.customerPoNumber = form.customerPoNumber || "";
    req.customerShipToAddress = form.shipTo || "";
    req.date = form.date?.format() || "";
    req.deletedSalesOrderLineIds = storeSo.form.deletedSalesOrderLineIds || [];
    req.deliveryDate = form.deliveryDate?.format() || "";
    req.internalContractId = form.internalContractId || "";
    req.notes = form.notes || "";
    req.salesId = form.salesPerson?.key || "";
    req.salesOrderLines =
      soLines.map<SalesOrderLineRequestDto>(buildSoLineRequest);
    req.salesType = form.salesType || ("" as SalesOrderTypeEnum);
    req.taxCalculation = form.taxCalculation || ("" as TAX_CALCULATION);
    req.termOfPayment = form.top || 0;
    req.isLuxury = form.isLuxury;

    return req;
  };

  const mapDetailToForm = (
    detail: SalesOrderResponseDto
  ): SalesOrderState["form"] => {
    const form = buildSalesOrderFormState();
    form.documentNumber = detail.documentNumber || "";
    form.salesType = detail.salesType || "";
    form.internalContractId = detail.internalContractId || "";
    form.internalContractNumber = detail.internalContractNo || "";
    form.branchId = detail.branchWarehouseId;
    form.branchName = detail.branchWarehouse;
    form.date = detail.date ? moment(detail.date) : null;
    form.customerId = detail.customerId || "";
    form.customerName = detail.customerName || "";
    form.shipTo = detail.customerShipToAddress || "";
    form.billTo = detail.customerBillToAddress || "";
    if (detail.salesId) {
      form.salesPerson = {
        key: detail.salesId,
        label: detail.salesName,
      };
    }
    form.taxCalculation = detail.taxCalculation || "";
    form.customerPoNumber = detail.customerPoNumber || "";
    form.deliveryDate = detail.deliveryDate
      ? moment(detail.deliveryDate)
      : null;
    form.currencyCode = detail.currency || "";
    form.currencyRate = detail.currencyRate || ONE;
    form.top = detail.termOfPayment || 0;
    form.notes = detail.notes || "";
    form.status = detail.states || "";
    form.closeReason = detail.reasonToClose || "";
    form.deletedSalesOrderLineIds = [];
    form.isLuxury = detail.isLuxury;

    const lines: SalesOrderLine[] = detail.salesOrderLines.map(item => {
      const line: SalesOrderLine = buildSalesOrderProductState();
      line.assetId = item.assetId || "";
      line.assetParentId = item.assetParentId || "";
      line.backupUnit = item.backupUnit || false;
      line.customerLocation = item.customerLocation || "";
      line.discountValue = item.discountValue || 0;
      line.id = item.id;
      line.internalContractLineId = item.internalContractLineId || "";
      line.locationId = item.locationId || "";
      line.percentDiscount = item.percentDiscount || 0;
      line.price = item.price || 0;
      line.productId = item.productId || "";
      line.qty = item.qty || 0;
      line.qtyAvailable = item.qtyAvailable || 0;
      line.serialNumber = item.serialNumber || "";
      line.subTotal = new Decimal(item.subTotal);
      line.taxCode = { label: item.taxCode, key: item.taxCodeId };
      line.uomId = item.uomId || "";
      line.unitCode = item.unitCode || "";
      line.productName = item.productName || "";
      line.productCode = item.productCode || item.unitCode || "";
      line.locationName = item.location || "";
      line.uomName = item.uomName || "";
      line.taxRate = item.taxRate || 0;
      line.taxValue = new Decimal(item.taxValue ?? 0);
      line.taxableValue = new Decimal(item.taxableValue ?? 0);
      line.grossValue = item.grossValueAfterDiscount || 0;
      if (item.inclusiveTaxRateId && item.inclusiveTaxRateName) {
        line.inclusiveTaxRate = item.inclusiveTaxRate || 0;
        line.inclusiveTax = {
          label: item.inclusiveTaxRateName,
          key: item.inclusiveTaxRateId,
        };
      }

      return line;
    });

    /**
     * set form header on store
     */
    store.commit("salesOrderStore/setForm", {
      salesType: form.salesType || "",
      taxCalculation: form.taxCalculation || "",
      branchId: form.branchId || "",
      shipTo: form.shipTo || "",
    });

    store.commit("salesOrderStore/setSoLines", lines);

    return form;
  };

  const initCloseSoDto = (): SalesOrderCloseRequestDto => ({
    reasonToClose: "",
  });

  const initDetailDto = (): SalesOrderResponseDto => buildSalesOrderDetailDto();

  return {
    findAvailableSalesOrder,
    findAll,
    toOptions,
    findById,
    findAllSoType,
    create,
    update,
    submit,
    buildCreateDto,
    initUpdateDto,
    buildUpdateDto,
    buildSoLineRequest,
    cancel,
    mapDetailToForm,
    closeSo,
    initCloseSoDto,
    initDetailDto,
  };
};

export default useSalesOrder;
