import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { PurchaseOrderResponseDto } from "@/models/interface/purchase-order";
import { DataResponseGetListPurchaseOrder } from "@/models/interface/purchase.interface";
import { purchaseServices } from "@/services/purchase.service";

type SearchField = Partial<{
  docNumber: string;
}>;

const usePurchaseOrder = () => {
  /**
   * @deprecated
   * new hooks {@linkcode findAllNew}
   */
  const findAll = (params: RequestQueryParamsModel) => {
    return purchaseServices.getListPurchaseOrder(params);
  };

  /**
   * @deprecated
   * new hooks {@linkcode toOptionsNew}
   */
  const toOptions = (
    data: DataResponseGetListPurchaseOrder[]
  ): Option<DataResponseGetListPurchaseOrder>[] => {
    return data.map<Option<DataResponseGetListPurchaseOrder>>(item => ({
      label: item.documentNumber || "-",
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const findAllNew = (
    params?: RequestQueryParams
  ): Promise<Pagination<PurchaseOrderResponseDto>> => {
    return purchaseServices.getListPurchaseOrderNew(params);
  };

  const findById = (id: string): Promise<PurchaseOrderResponseDto> => {
    return purchaseServices.getDetailPurchaseOrderNew(id);
  };

  const toOptionsNew = (
    data: Array<PurchaseOrderResponseDto>
  ): Array<Option<PurchaseOrderResponseDto>> => {
    return data.map<Option<PurchaseOrderResponseDto>>(item => ({
      label: item.documentNumber || "-",
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const searchBy = (field: SearchField): string => {
    const builder = new SearchBuilder();
    const query: Array<string> = [];

    if (field.docNumber) {
      query.push(
        builder
          .push(["documentNumber", field.docNumber], {
            like: "both",
          })
          .build()
      );
    }

    return query.join(builder.OR);
  };

  const findAllAvailableReceive = (
    params?: RequestQueryParams
  ): Promise<Pagination<PurchaseOrderResponseDto>> => {
    return purchaseServices.getAllPoToReceive(params);
  };

  return {
    findAll,
    toOptions,
    findAllNew,
    toOptionsNew,
    searchBy,
    findAllAvailableReceive,
    findById,
  };
};

export default usePurchaseOrder;
