import { SearchBuilder } from "@/builder";
import { generateUUID } from "@/helpers/uuid";
import { Option } from "@/models/class/option.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import { workOrderService } from "@/services/work-order.service";
import { buildWorkOrderFormState } from "@/store/work-order/resource";
import {
  WorkOrderFormState,
  WorkOrderSparepartState,
  WorkOrderUnitState,
} from "@/store/work-order/types";
import {
  WorkOrderCreateRequestDto,
  WorkOrderDraftResponseDto,
  WorkOrderResponseDto,
  WorkOrderSparePartRequestDto,
  WorkOrderUnitRequestDto,
} from "@interface/work-order";
import moment, { Moment } from "moment";
import useDate from "./useDate";

const useWorkOrder = () => {
  const findAllWoHeader = (params: RequestQueryParamsModel) => {
    return logisticServices.getAllWorkOrderHeader(params);
  };

  const findAll = (params?: RequestQueryParamsModel) => {
    return logisticServices.getAllWorkOrder(params);
  };

  const findList = (params?: RequestQueryParamsModel) => {
    return workOrderService.getList(params);
  };

  const create = (body: WorkOrderCreateRequestDto) => {
    return logisticServices.createWorkOrder(body);
  };

  const findById = (secureId: string): Promise<WorkOrderResponseDto> => {
    return logisticServices.getDetailWorkOrder(secureId);
  };

  const findByIdDraft = (
    secureId: string
  ): Promise<WorkOrderDraftResponseDto> => {
    return logisticServices.getDetailDrafWo(secureId);
  };

  const printCsf = (secureId: string): Promise<Blob> => {
    return logisticServices.printCsfFromWO(secureId);
  };

  const initCreateWoSparePartDto = (): WorkOrderSparePartRequestDto => ({
    notes: "",
    partNumber: "",
    productId: "",
    productLocationId: "",
    productUomId: "",
    qtyWorkOrder: 0,
    unitCode: "",
  });

  const initCreateWoUnitDto = (): WorkOrderUnitRequestDto => ({
    assetId: "",
    unitCode: "",
    unitSpecification: "",
  });

  const initCreateDto = (): WorkOrderCreateRequestDto => ({
    branchWarehouseId: "",
    customerAddress: "",
    customerId: "",
    description: "",
    mechanicId: "",
    picName: "",
    scheduleOrder: "",
    spareParts: [],
    units: [],
    workOrderDate: "",
    docReference: "",
    sparePartRequestId: null,
  });

  const mapFormWoSparepartToCreateDto = (
    row: WorkOrderSparepartState
  ): WorkOrderSparePartRequestDto => {
    const req: WorkOrderSparePartRequestDto = initCreateWoSparePartDto();

    req.notes = row.notes;
    req.partNumber = row.partNumber;
    req.productId = row.productCode?.key ?? "";
    req.productLocationId = row.productLocation?.key ?? "";
    req.productUomId = row.productUom?.key ?? "";
    req.qtyWorkOrder = row.qtyWorkOrder;
    req.unitCode = row.unitCode ?? "";

    return req;
  };

  const mapFormWoUnitToCreateDto = (
    row: WorkOrderUnitState
  ): WorkOrderUnitRequestDto => {
    const req: WorkOrderUnitRequestDto = initCreateWoUnitDto();

    req.assetId = row.asset?.key ?? "";
    req.unitCode = row.unitCode;
    req.unitSpecification = row.unitSpecification;

    return req;
  };

  const mapFormToCreateDto = (
    form: WorkOrderFormState
  ): WorkOrderCreateRequestDto => {
    const req: WorkOrderCreateRequestDto = initCreateDto();

    req.branchWarehouseId = form.branch?.key ?? "";
    req.customerAddress = form.customerAddress;
    req.customerId = form.customer?.key ?? "";
    req.description = form.description;
    req.mechanicId = form.mechanic?.key ?? "";
    req.picName = form.picName;
    req.scheduleOrder = form.scheduleOrder?.format() ?? "";
    req.spareParts = form.spareParts.map(mapFormWoSparepartToCreateDto);
    req.units = form.units.map(mapFormWoUnitToCreateDto);
    req.workOrderDate = form.workOrderDate?.format() ?? "";

    return req;
  };

  /**
   * @description
   * map detail draft dto to detail page form
   */
  const mapDetailDraftDtoToDetail = (
    dto: WorkOrderDraftResponseDto
  ): WorkOrderFormState => {
    const detail: WorkOrderFormState = buildWorkOrderFormState();

    detail.branch = {
      key: dto.branchWarehouseId,
      label: dto.branchWarehouse,
    };
    detail.customerAddress = dto.customerAddress;
    detail.customer = {
      key: dto.customerId,
      label: dto.customerName,
    };
    detail.description = dto.description;
    detail.documentNumber = dto.documentNumber;
    detail.mechanic = {
      key: dto.mechanicId,
      label: dto.mechanicName,
    };
    detail.picName = dto.picName;
    detail.scheduleOrder = dto.scheduleOrder ? moment(dto.scheduleOrder) : null;
    detail.spareParts = dto.spareParts.map<WorkOrderSparepartState>(
      (item, index) => ({
        key: generateUUID(),
        no: index + 1,
        productCode: { label: item.productCode, key: item.productId },
        productName: { label: item.productName, key: item.productId },
        productUom: { label: item.productUom, key: item.productUomId },
        productLocation: {
          label: item.productLocation,
          key: item.productLocationId,
        },
        qtyAvailable: item.qtyAvailable,
        notes: item.notes,
        qtyWorkOrder: item.qtyWorkOrder,
        partNumber: item.partNumber,
        unitCode: item.unitCode,
      })
    );
    detail.units = dto.units.map<WorkOrderUnitState>((item, index) => ({
      asset: { key: item.assetId, label: item.unitCode },
      unitCode: item.unitCode,
      unitSpecification: item.unitSpecification,
      serialNumber: item.serialNumber,
      assetCategory: item.assetCategory,
      capacity: item.capacity,
      type: item.type,
      model: item.model,
      location: item.assetLocation,
      key: generateUUID(),
      no: index + 1,
    }));
    detail.workOrderDate = dto.workOrderDate ? moment(dto.workOrderDate) : null;
    detail.workOrderId = dto.workOrderId;

    return detail;
  };

  const toOption = (
    data: WorkOrderResponseDto[]
  ): Option<WorkOrderResponseDto>[] => {
    return data.map(item => ({
      key: item.id,
      label: item.documentNumber,
      value: item.id,
      meta: item,
    }));
  };

  const findBy = (field: Partial<{ documentNumber: string }>): string[] => {
    const query: string[] = [];

    if (field.documentNumber) {
      query.push(
        new SearchBuilder()
          .push(["documentNumber", field.documentNumber], { like: "both" })
          .build()
      );
    }

    return query;
  };

  const findByWoList = (
    params: Partial<{
      assetId: string;
      workOrderDate: null | Moment[];
      workOrderId: string;
      customerId: string;
      branchId: string;
      productCategoryId: string;
      referenceId: string | undefined;
    }>
  ): string[] => {
    const { toStartDay, toEndDay } = useDate();
    const queries: string[] = [];

    Object.entries(params).forEach(([field, value]) => {
      if (field !== "workOrderDate" && value) {
        queries.push(
          new SearchBuilder().push([field, value as string]).build()
        );
      }
    });

    if (params.workOrderDate && params.workOrderDate.length) {
      const [start, end] = params.workOrderDate;

      queries.push(
        new SearchBuilder()
          .push(["workOrderDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["workOrderDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    return queries;
  };

  const printList = (params: RequestQueryParamsModel) => {
    return logisticServices.printWorkOrder(params);
  };

  const downloadList = (params: RequestQueryParamsModel) => {
    return logisticServices.downloadWorkOrder(params);
  };

  return {
    create,
    findById,
    findByIdDraft,
    initCreateDto,
    initCreateWoSparePartDto,
    initCreateWoUnitDto,
    mapDetailDraftDtoToDetail,
    mapFormToCreateDto,
    mapFormWoSparepartToCreateDto,
    mapFormWoUnitToCreateDto,
    printCsf,
    findAllWoHeader,
    toOption,
    findBy,
    findAll,
    findByWoList,
    printList,
    downloadList,
    findList,
  };
};

export default useWorkOrder;
