import { SearchBuilder } from "@/builder";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { InvoiceArPrepaymentStatusEnum } from "@/models/enums/ArPrepayment.enum";
import { InvoiceArTypeEnum } from "@/models/enums/invoice.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { aRPrepaymentService } from "@/services/ArPrepayment.service";
import { DetailForm, FormValue } from "@/store/aRPrepayment.store";
import { initDetail, initForm } from "@/store/resources/ArPrepayment.resource";
import {
  InvoicePrepaymentCreateDto,
  InvoicePrepaymentListResponseDto,
  InvoicePrepaymentResponseDto,
} from "@interface/ar-prepayment";
import { RequestQueryParamsModel } from "@interface/http.interface";
import Decimal from "decimal.js-light";
import moment, { Moment } from "moment";
import useCalculator from "./useCalculator";
import useDate from "./useDate";

export interface FormFilter {
  invoiceType: string;
  branchName: string;
  customerName: string;
  invoiceDate: Array<Moment>;
  invoiceNumber: string;
  status: InvoiceArPrepaymentStatusEnum;
}

export type CalcSubTotalArgs = {
  amount: number;
  taxRate: number;
  taxCalc: TAX_CALCULATION;
};

export type CalcTotalTaxArgs = {
  taxAbleValue: number;
} & CalcSubTotalArgs;

const useArPrepayment = () => {
  const findById = (id: string): Promise<InvoicePrepaymentResponseDto> => {
    return aRPrepaymentService.detail(id);
  };

  const cancel = (invoiceId: string): Promise<InvoicePrepaymentResponseDto> => {
    return aRPrepaymentService.cancel(invoiceId);
  };

  const print = (invoiceId: string): Promise<Blob> => {
    return aRPrepaymentService.printInvoice(invoiceId);
  };

  const findTaxNumber = (
    taxCode: string,
    invoiceDate: string
  ): Promise<string> => {
    return aRPrepaymentService.getTaxInvoiceNumber(taxCode, invoiceDate);
  };

  const create = (
    body: InvoicePrepaymentCreateDto
  ): Promise<InvoicePrepaymentResponseDto> => {
    return aRPrepaymentService.create(body);
  };

  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<InvoicePrepaymentListResponseDto>> => {
    return aRPrepaymentService.getList(params);
  };

  const toOptions = (
    data: Array<InvoicePrepaymentListResponseDto>
  ): Array<Option<InvoicePrepaymentListResponseDto>> => {
    return data.map(e => ({
      label: e.invoiceNumber,
      key: e.id,
      value: e.id,
      meta: e,
    }));
  };

  const findAllBy = (field: Partial<FormFilter>) => {
    const {
      invoiceType = "",
      branchName = "",
      customerName = "",
      invoiceDate = [],
      invoiceNumber = "",
      status,
    } = field;
    const builder = new SearchBuilder();
    const queries: Array<string> = [];

    if (invoiceType) {
      queries.push(
        builder
          .push(["invoiceType", trimSpaceToUnderscore(invoiceType)], {
            like: "both",
          })
          .build()
      );
    }

    if (branchName) {
      queries.push(
        builder
          .push(["branchWarehouse.name", branchName], {
            like: "both",
          })
          .build()
      );
    }

    if (customerName) {
      queries.push(
        builder
          .push(["customer.firstName", customerName], {
            like: "both",
          })
          .build()
      );
    }

    if (invoiceNumber) {
      queries.push(
        builder
          .push(["documentNumber", invoiceNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (status) {
      queries.push(
        builder
          .push(["invoicePrepaymentStatus", trimSpaceToUnderscore(status)])
          .build()
      );
    }

    if (invoiceDate && invoiceDate.length) {
      const [start, end] = invoiceDate;
      const { toStartDay, toEndDay } = useDate();
      queries.push(
        builder
          .push(["invoiceDate", toStartDay(start).format()], { het: true })
          .and()
          .push(["invoiceDate", toEndDay(end).format()], { let: true })
          .build()
      );
    }

    const filter = queries.join(builder.AND);
    const search = queries.join(builder.OR);

    return { filter, search };
  };

  const initCreateDto = (): InvoicePrepaymentCreateDto => ({
    accountingDate: "",
    amount: 0,
    branchWarehouseId: "",
    currency: "",
    currencyRate: ONE,
    customerBillToAddress: "",
    customerId: "",
    customerShipToAddress: "",
    customerTaxType: "",
    description: "",
    invoiceDate: "",
    invoicePrepaymentStatus: null,
    invoiceType: "" as InvoiceArTypeEnum,
    lineAccountId: "",
    operatorName: "",
    poNumber: "",
    receivableAccountId: "",
    salesReturnId: "",
    subTotal: 0,
    taxCode: "",
    taxInvoiceDate: "",
    taxInvoiceNumber: "",
    taxRegistrationName: "",
    taxRegistrationNumber: "",
    taxType: "" as TAX_CALCULATION,
    termOfPayment: 0,
    total: 0,
    totalTax: 0,
  });

  const calcSubtotal = ({
    amount,
    taxCalc,
    taxRate,
  }: CalcSubTotalArgs): number => {
    const { calcInclRate } = useCalculator();
    let subtotal = 0;
    const isExcl = taxCalc === TAX_CALCULATION.EXCLUSIVE;
    const isIncl = taxCalc === TAX_CALCULATION.INCLUSIVE;

    if (isExcl) {
      subtotal = amount;
    } else if (isIncl) {
      const rate = calcInclRate(taxRate);
      subtotal = new Decimal(amount || 0).dividedBy(rate).toNumber();
    }

    return subtotal;
  };

  const calcTotalTax = ({
    amount,
    taxCalc,
    taxRate,
    taxAbleValue,
  }: CalcTotalTaxArgs) => {
    let totalTax = 0;
    const isExcl = taxCalc === TAX_CALCULATION.EXCLUSIVE;
    const isIncl = taxCalc === TAX_CALCULATION.INCLUSIVE;

    if (isExcl) {
      const rate = new Decimal(taxRate || 0).dividedBy(100);
      totalTax = new Decimal(amount || 0).times(rate).toNumber();
    } else if (isIncl) {
      totalTax = new Decimal(amount || 0).minus(taxAbleValue || 0).toNumber();
    }

    return totalTax;
  };

  const mapFormToCreateDto = (form: FormValue): InvoicePrepaymentCreateDto => {
    const req: InvoicePrepaymentCreateDto = initCreateDto();

    req.accountingDate = form.accountingDate
      ? form.accountingDate.format()
      : "";
    req.amount = form.amount;
    req.branchWarehouseId = form.branchWarehouseId;
    req.currency = form.currency;
    req.currencyRate = form.currencyRate;
    req.customerBillToAddress = form.customerBillToAddress;
    req.customerId = form.customerId;
    req.customerShipToAddress = form.customerShipToAddress;
    req.customerTaxType = form.customerTaxType;
    req.description = form.description;
    req.invoiceDate = form.invoiceDate ? form.invoiceDate.format() : "";
    req.invoiceType = form.invoiceType;
    req.lineAccountId = form.lineAccountId;
    req.operatorName = form.operatorName;
    req.poNumber = form.poNumber;
    req.receivableAccountId = form.receivableAccountId;
    req.salesReturnId = form.salesReturnId;
    req.subTotal = form.subTotal;
    req.taxCode = form.taxCode;
    req.taxInvoiceDate = form.taxInvoiceDate
      ? form.taxInvoiceDate.format()
      : "";
    req.taxInvoiceNumber = form.taxInvoiceNumber;
    req.taxRegistrationName = form.taxRegistrationName;
    req.taxRegistrationNumber = form.taxRegistrationNumber;
    req.taxType = form.taxType;
    req.termOfPayment = form.termOfPayment ? parseInt(form.termOfPayment) : 0;
    req.total = form.total;
    req.totalTax = form.totalTax;

    return req;
  };

  const mapDetailToForm = (detail: InvoicePrepaymentResponseDto): FormValue => {
    const form = initForm();

    form.accountingDate = moment(detail.accountingDate);
    form.amount = detail.amount;
    form.branchWarehouseId = detail.branchWarehouseId;
    form.currency = detail.currency;
    form.currencyRate = detail.currencyRate;
    form.customerBillToAddress = detail.customerBillToAddress;
    form.customerId = detail.customerId;
    form.customerShipToAddress = detail.customerShipToAddress;
    form.customerTaxType = detail.customerTaxType;
    form.description = detail.description;
    form.invoiceDate = moment(detail.invoiceDate);
    form.invoiceType = detail.invoiceType;
    form.lineAccountId = detail.lineAccountId;
    form.lineAccountName = detail.lineAccount;
    form.operatorName = detail.operatorName;
    form.poNumber = detail.poNumber;
    form.receivableAccountId = detail.receivableAccountId;
    form.receivableAccountName = detail.receivableAccount;
    form.subTotal = detail.subTotal;
    form.taxCode = detail.taxCode;
    form.taxInvoiceDate = moment(detail.taxInvoiceDate);
    form.taxInvoiceNumber = detail.taxInvoiceNumber;
    form.taxRegistrationName = detail.taxRegistrationName;
    form.taxRegistrationNumber = detail.taxRegistrationNumber;
    form.taxType = detail.taxType;
    form.termOfPayment = detail.termOfPayment
      ? detail.termOfPayment.toString()
      : "";
    form.total = detail.total;
    form.totalTax = detail.totalTax;

    return form;
  };

  const mapDetailToFormDetail = (
    detail: InvoicePrepaymentResponseDto
  ): DetailForm => {
    const form = initDetail();

    form.accountingDate = moment(detail.accountingDate);
    form.amount = detail.amount;
    form.branchWarehouse = detail.branchWarehouse;
    form.branchWarehouseId = detail.branchWarehouseId;
    form.createdBy = detail.createdBy;
    form.createdDate = detail.createdDate;
    form.currency = detail.currency;
    form.currencyRate = detail.currencyRate;
    form.customerBillToAddress = detail.customerBillToAddress;
    form.customerId = detail.customerId;
    form.customerName = detail.customerName;
    form.customerShipToAddress = detail.customerShipToAddress;
    form.customerTaxType = detail.customerTaxType;
    form.description = detail.description;
    form.documentNumber = detail.documentNumber;
    form.grossAmount = detail.grossAmount;
    form.id = detail.id;
    form.invoiceARDetails = detail.invoiceARDetails;
    form.invoiceARReceiptDetails = detail.invoiceARReceiptDetails;
    form.invoiceDate = moment(detail.invoiceDate);
    form.invoicePrepaymentStatus = detail.invoicePrepaymentStatus;
    form.invoiceType = detail.invoiceType;
    form.journalId = detail.journalId;
    form.journalNumber = detail.journalNumber;
    form.lineAccount = detail.lineAccount;
    form.lineAccountId = detail.lineAccountId;
    form.modifiedBy = detail.modifiedBy;
    form.modifiedDate = detail.modifiedDate;
    form.operatorName = detail.operatorName;
    form.paidAmount = detail.paidAmount;
    form.poNumber = detail.poNumber;
    form.prepaymentPaid = detail.prepaymentPaid;
    form.prepaymentUsed = detail.prepaymentUsed;
    form.receivableAccount = detail.receivableAccount;
    form.receivableAccountId = detail.receivableAccountId;
    form.remainingAmount = detail.remainingAmount;
    form.remainingPrepayment = detail.remainingPrepayment;
    form.returnNumber = detail.returnNumber;
    form.subTotal = detail.subTotal;
    form.taxCode = detail.taxCode;
    form.taxInvoiceDate = detail.taxInvoiceDate
      ? moment(detail.taxInvoiceDate)
      : null;
    form.taxInvoiceNumber = detail.taxInvoiceNumber;
    form.taxRegistrationName = detail.taxRegistrationName;
    form.taxRegistrationNumber = detail.taxRegistrationNumber;
    form.taxType = detail.taxType;
    form.termOfPayment = detail.termOfPayment;
    form.total = detail.total;
    form.totalTax = detail.totalTax;

    return form;
  };

  const initDetailDto = (): InvoicePrepaymentResponseDto => ({
    accountingDate: "",
    amount: 0,
    branchWarehouse: "",
    branchWarehouseId: "",
    createdBy: "",
    createdDate: "",
    currency: "",
    currencyRate: ONE,
    customerBillToAddress: "",
    customerId: "",
    customerName: "",
    customerShipToAddress: "",
    customerTaxType: "",
    description: "",
    documentNumber: "",
    grossAmount: 0,
    id: "",
    invoiceARDetails: [],
    invoiceARReceiptDetails: [],
    invoiceDate: "",
    invoicePrepaymentStatus: "" as InvoiceArPrepaymentStatusEnum,
    invoiceType: "" as InvoiceArTypeEnum,
    journalId: "",
    journalNumber: "",
    lineAccount: "",
    lineAccountId: "",
    modifiedBy: "",
    modifiedDate: "",
    operatorName: "",
    paidAmount: 0,
    poNumber: "",
    prepaymentPaid: 0,
    prepaymentUsed: 0,
    receivableAccount: "",
    receivableAccountId: "",
    remainingAmount: 0,
    remainingPrepayment: 0,
    returnNumber: "",
    subTotal: 0,
    taxCode: "",
    taxInvoiceDate: "",
    taxInvoiceNumber: "",
    taxRegistrationName: "",
    taxRegistrationNumber: "",
    taxType: "" as TAX_CALCULATION,
    termOfPayment: 0,
    total: 0,
    totalTax: 0,
  });

  return {
    calcSubtotal,
    calcTotalTax,
    cancel,
    create,
    findAll,
    findAllBy,
    findById,
    findTaxNumber,
    initCreateDto,
    initDetailDto,
    mapDetailToForm,
    mapDetailToFormDetail,
    mapFormToCreateDto,
    print,
    toOptions,
  };
};

export default useArPrepayment;
