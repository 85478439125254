
















import moment from "moment";
import { version } from "../../../package.json";
import Vue from "vue";

export default Vue.extend({
  name: "AppFooter",
  data: () => ({
    appVersion: version + `-` + process.env.NODE_ENV,
    appName: process.env.VUE_APP_NAME,
  }),
  computed: {
    currentYear(): string {
      return moment().year().toString();
    },
  },
});
