// Models
import { config } from "@/models/constant/routes.constant";
import { RouteConfig } from "vue-router";

const index: RouteConfig = {
  path: config.ASSETS_ROUTE.path,
  name: config.ASSETS_ROUTE.name,
  meta: config.ASSETS_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.INQUIRY_FIND.path,
      name: config.INQUIRY_FIND.name,
      meta: config.INQUIRY_FIND.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Inquiry/InquiryFind.vue"),
    },
    {
      path: config.INQUIRY_OPEN_ROUTE.path,
      name: config.INQUIRY_OPEN_ROUTE.name,
      meta: config.INQUIRY_OPEN_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Inquiry/InquiryOpen.vue"),
    },
    {
      path: config.INQUIRY_ADJUSTMENT_VIEW.path,
      name: config.INQUIRY_ADJUSTMENT_VIEW.name,
      meta: config.INQUIRY_ADJUSTMENT_VIEW.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Inquiry/InquiryAdjustment.vue"),
    },
    {
      path: config.INQUIRY_ADJUSTMENT_UPDATE_ROUTE.path,
      name: config.INQUIRY_ADJUSTMENT_UPDATE_ROUTE.name,
      meta: config.INQUIRY_ADJUSTMENT_UPDATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Inquiry/InquiryAdjustment.vue"),
    },
    {
      path: config.INQUIRY_RETIREMENT_VIEW.path,
      name: config.INQUIRY_RETIREMENT_VIEW.name,
      meta: config.INQUIRY_RETIREMENT_VIEW.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Inquiry/InquiryRetirement.vue"),
    },
    {
      path: config.INQUIRY_RETIREMENT_UPDATE_ROUTE.path,
      name: config.INQUIRY_RETIREMENT_UPDATE_ROUTE.name,
      meta: config.INQUIRY_RETIREMENT_UPDATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Inquiry/InquiryRetirement.vue"),
    },
    {
      path: config.RECLASS.path,
      name: config.RECLASS.name,
      meta: config.RECLASS.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Inquiry/InquiryReclass.vue"),
    },
    {
      path: config.ADDITIONS_ROUTE.path,
      name: config.ADDITIONS_ROUTE.name,
      meta: config.ADDITIONS_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Additions/AdditionsImport.vue"),
    },
    {
      path: config.ADDITIONS_SINGLE_ROUTE.path,
      name: config.ADDITIONS_SINGLE_ROUTE.name,
      meta: config.ADDITIONS_SINGLE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Additions/AdditionSingle.vue"),
    },
    {
      path: config.VIEW_ADDITIONS_SINGLE_ROUTE.path,
      name: config.VIEW_ADDITIONS_SINGLE_ROUTE.name,
      meta: config.VIEW_ADDITIONS_SINGLE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Additions/AdditionSingle.vue"),
    },
    {
      path: config.ADDITIONS_CREATE_ROUTE.path,
      name: config.ADDITIONS_CREATE_ROUTE.name,
      meta: config.ADDITIONS_CREATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./Additions/AssetAdditionsCreate.vue"
        ),
    },
    {
      path: config.ADDITIONS_UPDATE_ROUTE.path,
      name: config.ADDITIONS_UPDATE_ROUTE.name,
      meta: config.ADDITIONS_UPDATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Additions/AdditionSingle.vue"), // di komponennya tinggal tambahin kondisi klo ada penambahan
    },
    {
      path: config.CATEGORY_ROUTE.path,
      name: config.CATEGORY_ROUTE.name,
      meta: config.CATEGORY_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Setup/Category.vue"),
    },
    {
      path: config.DEPRESIASICLOSEPERIODE_ROUTE.path,
      name: config.DEPRESIASICLOSEPERIODE_ROUTE.name,
      meta: config.DEPRESIASICLOSEPERIODE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./DepresiasiClosePeriode.vue"),
    },
    {
      path: config.ASSETBOOK_ROUTE.path,
      name: config.ASSETBOOK_ROUTE.name,
      meta: config.ASSETBOOK_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "../assets/Setup/AssetBook.vue"),
    },
    {
      path: config.REPORTS_ASSET_ADJUSTMENT_ROUTE.path,
      name: config.REPORTS_ASSET_ADJUSTMENT_ROUTE.name,
      meta: config.REPORTS_ASSET_ADJUSTMENT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Reports/AssetAdjustment.vue"),
    },
    {
      path: config.REPORTS_LIST_OF_ASSET_ROUTE.path,
      name: config.REPORTS_LIST_OF_ASSET_ROUTE.name,
      meta: config.REPORTS_LIST_OF_ASSET_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Reports/ListOfAssets.vue"),
    },
    {
      path: config.REPORTS_LIST_ASSET_ADDITIONS_ROUTE.path,
      name: config.REPORTS_LIST_ASSET_ADDITIONS_ROUTE.name,
      meta: config.REPORTS_LIST_ASSET_ADDITIONS_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Reports/AssetAdditions.vue"),
    },
    {
      path: config.REPORTS_ASSET_RETIREMENT_ROUTE.path,
      name: config.REPORTS_ASSET_RETIREMENT_ROUTE.name,
      meta: config.REPORTS_ASSET_RETIREMENT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Reports/AssetRetirement.vue"),
    },
    {
      path: config.REPORTS_RECLASS_ROUTE.path,
      name: config.REPORTS_RECLASS_ROUTE.name,
      meta: config.REPORTS_RECLASS_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Reports/Reclass.vue"),
    },
    {
      path: config.REPORTS_ASSET_DEPRECIATION_PROJECTION.path,
      name: config.REPORTS_ASSET_DEPRECIATION_PROJECTION.name,
      meta: config.REPORTS_ASSET_DEPRECIATION_PROJECTION.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./Reports/AssetDepreciationProjection.vue"
        ),
    },
    {
      path: config.REPORTS_ASSET_HISTORY_ROUTE.path,
      name: config.REPORTS_ASSET_HISTORY_ROUTE.name,
      meta: config.REPORTS_ASSET_HISTORY_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Reports/AssetHistory.vue"),
    },
    {
      path: config.ASSET_TRANSFER_ROUTE.path,
      name: config.ASSET_TRANSFER_ROUTE.name,
      meta: config.ASSET_TRANSFER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./Transactions/AssetTransfer.vue"),
    },
    {
      path: config.CREATE_ASSET_TRANSFER_ROUTE.path,
      name: config.CREATE_ASSET_TRANSFER_ROUTE.name,
      meta: config.CREATE_ASSET_TRANSFER_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./Transactions/CreateAssetTransfer.vue"
        ),
    },
    {
      path: config.ASSET_TRANSFER_DETAIL_ROUTE.path,
      name: config.ASSET_TRANSFER_DETAIL_ROUTE.name,
      meta: config.ASSET_TRANSFER_DETAIL_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./Transactions/AssetTransferDetail.vue"
        ),
    },
  ],
};

export default index;
