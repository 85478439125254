// Core
// Class
import { Api } from "@/models/class/api.class";
// Interfaces
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  CreateUserDto,
  DetailUserDto,
  UpdateUserDto,
} from "@/models/interface/user";
import {
  RequestAddUsers,
  RequestChangePassword,
  RequestChangePasswordAdmin,
  RequestMenu,
  RequestPrivilege,
  RequestPrivilegeUpdate,
  RequestRole,
  RequestUpdateUsers,
  ResponseDetailRole,
  ResponseDetailUser,
  ResponseDetailUserByAuth,
  ResponseListOfMenus,
  ResponseListOfPrivilege,
  ResponseListOfRole,
  ResponseListOfUsers,
} from "@/models/interface/user.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class UserServices extends HttpClient {
  listOfUsers = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfUsers> => {
    return this.get<ResponseListOfUsers>(Api.Users, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * to get detail use {@linkcode MenuService.findMenuDetail}
   * to get list use {@linkcode MenuService.listMenus}
   */
  listOfMenus = (
    params: RequestQueryParamsModel,
    path: string
  ): Promise<ResponseListOfMenus> => {
    return this.get<ResponseListOfMenus>(Api.Menus + path, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode MenuService.createMenu}
   */
  createMenu = (payload: RequestMenu): Promise<boolean> => {
    return this.post<boolean, RequestMenu>(Api.Menus, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode MenuService.createMenu}
   */
  updateMenu = (payload: RequestMenu, path: string): Promise<boolean> => {
    return this.put<boolean, RequestMenu>(Api.Menus + path, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteMenu = (path: string): Promise<any> => {
    return this.delete<any>(Api.Menus + path)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listOfPrivilege(
    params?: RequestQueryParamsModel
  ): Promise<ResponseListOfPrivilege> {
    return this.get<ResponseListOfPrivilege>(Api.Privilege, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createPrivilege(payload: RequestPrivilege): Promise<boolean> {
    return this.post<boolean, RequestPrivilege>(Api.Privilege, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updatePrivilege(
    id: string,
    payload: RequestPrivilegeUpdate
  ): Promise<boolean> {
    return this.put<boolean, RequestPrivilegeUpdate>(
      Api.Privilege + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode UserRoleService.getList}
   */
  listOfRole = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfRole> => {
    return this.get<ResponseListOfRole>(Api.Role, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  detailRole = (id: string): Promise<ResponseDetailRole> => {
    return this.get<ResponseDetailRole>(Api.Role + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createRole = (payload: RequestRole): Promise<boolean> => {
    return this.post<boolean, RequestRole>(Api.Role, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateRole = (payload: RequestRole, path: string): Promise<boolean> => {
    return this.put<boolean, RequestRole>(Api.Role + path, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteRole = (path: string): Promise<any> => {
    return this.delete<any>(Api.Role + path)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode create}
   */
  addUser = (payload: RequestAddUsers): Promise<string> => {
    return this.post<string, RequestAddUsers>(Api.Users, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode update}
   */
  updateUser = (payload: RequestUpdateUsers, id: string): Promise<string> => {
    return this.put<string, RequestUpdateUsers>(Api.Users + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteUser = (id: string): Promise<boolean> => {
    return this.delete<boolean>(Api.Users + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode getDetailUser}
   */
  detailUser = (id: string): Promise<ResponseDetailUser> => {
    return this.get<ResponseDetailUser>(Api.Users + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  changePassword = (payload: RequestChangePassword): Promise<boolean> => {
    return this.put<boolean, RequestChangePassword>(Api.ChangePassword, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  userDetailAuth(): Promise<ResponseDetailUserByAuth> {
    return this.get<ResponseDetailUserByAuth>(Api.UserDetailAuth)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  changePasswordAdmin = (
    payload: RequestChangePasswordAdmin
  ): Promise<boolean> => {
    return this.put<boolean, RequestChangePasswordAdmin>(
      Api.ChangePasswordAdmin,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  create(payload: CreateUserDto): Promise<boolean> {
    return this.post<boolean, CreateUserDto>(Api.Users, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailUser(userId: string): Promise<DetailUserDto> {
    return this.get<DetailUserDto>(`${Api.Users}/${userId}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(payload: UpdateUserDto, userId: string): Promise<boolean> {
    return this.put<boolean, UpdateUserDto>(`${Api.Users}/${userId}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const userServices = new UserServices();
