import {
  LeasingInvoiceApLineResponseDto,
  LeasingInvoiceApResponseDto,
} from "@/models/interface/leasing";
import { initRowAsset } from "@/store/account-payable/leasing/resource";
import {
  LeasingInvoiceState,
  RowAsset,
} from "@/store/account-payable/leasing/types";
import moment from "moment";

export class LeasingMapper {
  static toRowAsset(lineDto: LeasingInvoiceApLineResponseDto): RowAsset {
    const asset: RowAsset = initRowAsset();
    asset.assetId = lineDto.assetId;
    asset.assetUnitCode = lineDto.unitCode;
    asset.costPricePerUnit = lineDto.subTotal ?? 0;
    asset.paymentTaxInvoiceDate = lineDto.invoiceDate;
    asset.paymentTaxInvoiceNumber = lineDto.taxInvoiceNumber;
    asset.brand = lineDto.brand;
    asset.type = lineDto.type;
    asset.serialNumber = lineDto.serialNumber;
    asset.invoiceApLineId = lineDto.id;

    if (lineDto.dealerId) {
      asset.dealer = {
        key: lineDto.dealerId,
        label: lineDto.dealerName,
      };
    }

    return asset;
  }

  static toLeasingInvoiceState(
    arg: LeasingInvoiceApResponseDto
  ): LeasingInvoiceState {
    const state: LeasingInvoiceState = {
      invoiceId: arg.id,
      otr: arg.otr,
      invoiceDate: 0,
    };
    if (arg.line[0]) {
      state.invoiceDate = moment(arg.line[0].invoiceDate).valueOf();
    }

    return state;
  }
}
