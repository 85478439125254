import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  CreateCompanyDto,
  ListCompanyDto,
  UpdateCompanyDto,
} from "@/models/interface/company";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ErrorResponseModel } from "@/models/interfaces/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class CompanyService extends HttpClient {
  getList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListCompanyDto>> {
    return this.get<Pagination<ListCompanyDto>>(Api.Company, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getLoginList() {
    return this.get<ListCompanyDto[]>(Api.CompanyLoginList)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListAnother(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListCompanyDto>> {
    return this.get<Pagination<ListCompanyDto>>(Api.CompanyListAnother, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  create(payload: CreateCompanyDto): Promise<boolean> {
    return this.post<boolean, CreateCompanyDto>(Api.Company, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(id: string, payload: UpdateCompanyDto): Promise<boolean> {
    return this.put<boolean, UpdateCompanyDto>(Api.Company + "/" + id, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const companyService = new CompanyService();
