







import { AppBreadcrumb } from "@/components/AppBreadcrumb";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    AppBreadcrumb,
  },
})
export default class AppContent extends Vue {}
