import {
  ApInvoiceFormState,
  ApInvoicePrepaymentDataState,
  ApInvoiceStoreState,
} from "./types";

export const mutations = {
  setFormState: (
    state: ApInvoiceStoreState,
    payload: Partial<ApInvoiceFormState>
  ): void => {
    const clone: ApInvoiceFormState = {
      ...state.formState,
    };
    state.formState = {
      ...clone,
      ...payload,
    };
  },
  setPrepaymentList: (
    state: ApInvoiceStoreState,
    payload: Partial<ApInvoicePrepaymentDataState>
  ): void => {
    state.prepaymentData = {
      ...state.prepaymentData,
      ...payload,
    };
  },
};
