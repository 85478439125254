import { removeDuplicateObj } from "@/helpers/common";
import { useLeasing, usePreferences } from "@/hooks";
import { LeasingMapper } from "@/mapper/Leasing.mapper";
import { ONE } from "@/models/constant/global.constant";
import { LeasingPaymentTypeEnum } from "@/models/enums/Leasing.enum";
import { LeasingInvoiceApResponseDto } from "@/models/interface/leasing";
import { leasingServices } from "@/services/leasing.service";
import { StringUtils } from "@/utils";
import { LeasingUtils } from "@/utils/LeasingUtils";
import moment, { Moment } from "moment";
import { initForm, initRowPeriod } from "./resource";
import {
  FormValue,
  LeasingInvoiceState,
  RowAsset,
  RowPeriod,
  State,
} from "./types";

export const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    const form: FormValue = initForm();
    const { initDetailDto } = useLeasing();
    commit("setForm", form);
    commit("setDetail", initDetailDto());
    commit("setGeneratedInvoices", []);
    commit("setMaxInvoiceDate", null);
  },
  getDefaultCurrency: (context): void => {
    const { commit } = context;
    const preference = usePreferences().findBaseCurrency();
    if (!preference || !preference.value) return;
    const form: Partial<FormValue> = {
      currency: {
        key: preference.value,
        label: preference.name,
      },
    };
    commit("setForm", form);
  },
  calcUnitPricing: (context): void => {
    const { state } = context;
    const {
      calcUnitNettFinance,
      calcUnitInterest,
      calcUnitProvision,
      calcUnitInsurance,
      calcUnitResidue,
    } = useLeasing();
    const local: State = state;
    local.form.assetList.forEach(unit => {
      unit.nettFinance = calcUnitNettFinance({
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        nettFinanceLeasing: local.form.nettFinance,
        taxTotalAmount: local.form.taxTotalAmount,
      });
      unit.interest = calcUnitInterest({
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        interestLeasing: local.form.interest,
        taxTotalAmount: local.form.taxTotalAmount,
      });
      unit.provision = calcUnitProvision({
        taxTotalAmount: local.form.taxTotalAmount,
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        provisionLeasing: local.form.provision,
      });
      unit.insurancePremium = calcUnitInsurance({
        taxTotalAmount: local.form.taxTotalAmount,
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        premiumLeasing: local.form.insurancePremium,
      });
      unit.residue = calcUnitResidue({
        taxTotalAmount: local.form.taxTotalAmount,
        costPriceLeasing: local.form.costPrice,
        costPriceUnit: unit.costPricePerUnit,
        residueLeasing: local.form.residue,
      });
    });
  },
  calcCarPricing: ({ state }): void => {
    const local: State = state;
    local.form.assetList.forEach(unit => {
      unit.nettFinance = LeasingUtils.calcCarNettFinance({
        nettFinanceLeasing: local.form.nettFinance,
        totalOtr: local.form.otr,
        costPriceUnit: unit.costPricePerUnit,
      });
      unit.interest = LeasingUtils.calcCarInterest({
        interestLeasing: local.form.interest,
        totalOtr: local.form.otr,
        costPriceUnit: unit.costPricePerUnit,
      });
      unit.provision = LeasingUtils.calcCarProvision({
        provisionLeasing: local.form.provision,
        totalOtr: local.form.otr,
        costPriceUnit: unit.costPricePerUnit,
      });
      unit.insurancePremium = LeasingUtils.calcCarInsurance({
        premiumLeasing: local.form.insurancePremium,
        totalOtr: local.form.otr,
        costPriceUnit: unit.costPricePerUnit,
      });
      unit.residue = LeasingUtils.calcCarResidue({
        residueLeasing: local.form.residue,
        totalOtr: local.form.otr,
        costPriceUnit: unit.costPricePerUnit,
      });
    });
  },
  countAssetPricing: ({ state, dispatch }): void => {
    const local: State = state;
    if (local.form.source === undefined) return;

    const isCar = StringUtils.compare(local.form.source, "Car");
    const isUnit = StringUtils.compare(local.form.source, "Unit");
    if (isCar) {
      dispatch("calcCarPricing");
    } else if (isUnit) {
      dispatch("calcUnitPricing");
    }
  },
  calcLeasingResidue: (context): void => {
    const { state, commit } = context;
    const { calcLeasingResidue } = useLeasing();
    const local: State = state;
    if (local.form.source === undefined) return;
    const isCar = StringUtils.compare(local.form.source, "Car");
    const isUnit = StringUtils.compare(local.form.source, "Unit");
    let residue = 0;

    if (isUnit) {
      residue = calcLeasingResidue({
        costPrice: local.form.costPrice,
        nettFinance: local.form.nettFinance,
        taxTotalAmount: local.form.taxTotalAmount,
      });
    } else if (isCar) {
      residue = LeasingUtils.calcCarLeasingResidue({
        totalOtr: local.form.otr,
        nettFinanceLeasing: local.form.nettFinance,
      });
    }

    const form: Partial<FormValue> = {
      residue,
    };

    commit("setForm", form);
  },
  calcTotalInstallment: (context): void => {
    const { state, commit } = context;
    const local: State = state;
    const { calcTotalInstallment } = useLeasing();
    const form: Partial<FormValue> = {
      total: calcTotalInstallment({
        nettFinance: local.form.nettFinance,
        interest: local.form.interest,
      }),
    };
    commit("setForm", form);
  },
  calcTotalInvoiceOtr: (
    { commit },
    arg: { invoices: LeasingInvoiceState[] }
  ): void => {
    const form: Partial<FormValue> = {
      otr: LeasingUtils.calcTotalInvoiceOtr(arg.invoices),
    };
    commit("setForm", form);
  },
  generatePeriod: (context): void => {
    const { state } = context;
    const local: State = state;

    const {
      startPaymentDate,
      periodList,
      leasingPeriod,
      insurancePremium,
      useCheque,
      chequeNumber,
    } = local.form;
    const FIRST_INDEX = 0;
    const { calcPaymentAmortize } = useLeasing();

    for (let index = 0; index < leasingPeriod; index++) {
      const row: RowPeriod = initRowPeriod();

      row.amortize = calcPaymentAmortize({
        insurancePremium,
        leasingPeriod,
      });

      if (useCheque && chequeNumber) {
        const increment = parseInt(chequeNumber) + index + 1;
        row.chequeNumber = increment.toString();
      }

      row.installment = index + 1;

      row.paymentType = LeasingPaymentTypeEnum.BANK_TRANSFER;
      if (useCheque) {
        row.paymentType = LeasingPaymentTypeEnum.CHEQUE;
      }

      periodList.push(row);
    }

    //#region fixing gap date
    periodList[FIRST_INDEX].paymentDate = moment(startPaymentDate);

    for (let index = 1; index < periodList.length; index++) {
      const row = periodList[index];
      const prevMonth: Moment | null = moment(
        periodList[index - ONE].paymentDate
      );
      if (prevMonth === null) continue;
      const oneMonth = moment.duration({ months: 1 });
      const nextMonth = moment(prevMonth.add(oneMonth));
      row.paymentDate = moment(nextMonth);

      // #1 the date from the first payment
      const dateFirstPayment = moment(startPaymentDate).date();

      // #2 the date after we added one month
      const dateAfterAdd = moment(row.paymentDate).date();

      // #3 the maximum date from #2
      const lastDateAfterAdd = moment(row.paymentDate).endOf("month").date();

      /**
       * if the date after we added one month is less than
       * the date of first payment and the last date after we
       * added one month is higher equal than the date of
       * first payment
       *
       * @example
       * date of first payment = 30 / 31
       * date after added = 28 (the maximum date is 28 / 29)
       * last date after added = 30 / 31
       *
       * we sould use the date of first payment
       *
       * @see https://momentjs.com/docs/#/manipulating/add/
       */
      if (
        dateAfterAdd < dateFirstPayment &&
        lastDateAfterAdd >= dateFirstPayment
      ) {
        row.paymentDate = moment().set({
          year: row.paymentDate.year(),
          month: row.paymentDate.month(),
          date: dateFirstPayment,
        });
      }
      //#endregion
    }
  },
  fetchDetail: async (context, leasingId: string): Promise<void> => {
    const { commit, dispatch, state } = context;
    const { findById, mapDetailToForm } = useLeasing();
    const res = await findById(leasingId);
    const form: FormValue = mapDetailToForm(res);
    commit("setForm", form);
    commit("setDetail", res);

    const invoiceStates: LeasingInvoiceState[] = [];
    res.assetList.forEach(item => {
      invoiceStates.push({
        invoiceId: item.invoiceAPId,
        otr: 0,
        invoiceDate: moment(item.paymentTaxInvoiceDate).valueOf(),
      });
    });

    commit(
      "setGeneratedInvoices",
      removeDuplicateObj(invoiceStates, "invoiceId")
    );

    const invoiceDate = await dispatch(
      "countMaxInvoiceDate",
      state.generatedInvoices
    );
    commit("setMaxInvoiceDate", invoiceDate);
  },
  generateInvoice: async (
    { commit, dispatch, state },
    params: { invoiceIds: string[] }
  ): Promise<LeasingInvoiceApResponseDto[]> => {
    const { invoiceIds } = params;
    try {
      const response = await leasingServices.getInvoiceLeasing(invoiceIds);
      if (!Array.isArray(response)) return [];

      const deletedAssetLineIds: string[] = (state as State).form.assetList
        .filter(asset => !!asset.id)
        .map(asset => asset.id);

      const assetList: RowAsset[] = [];
      const invoices: LeasingInvoiceState[] = [];
      response.forEach(doc => {
        const row: RowAsset[] = doc.line.map(line => {
          const record: RowAsset = LeasingMapper.toRowAsset(line);
          record.taxInvoiceNumber = doc.prepaymentTaxNumbers;
          record.prepaymentDate = doc.prepaymentDates;
          record.invoiceAPId = doc.id;
          return record;
        });
        assetList.push(...row);
        invoices.push(LeasingMapper.toLeasingInvoiceState(doc));
      });

      const payload: Partial<FormValue> = { assetList, deletedAssetLineIds };
      commit("setForm", payload);
      commit("setGeneratedInvoices", invoices);

      const invoiceDate: number | null = await dispatch(
        "countMaxInvoiceDate",
        invoices
      );
      commit("setMaxInvoiceDate", invoiceDate);

      const isCar = StringUtils.compare(
        (state as State).form.source ?? "",
        "Car"
      );
      if (isCar) {
        dispatch("calcTotalInvoiceOtr", { invoices });
      }
      dispatch("countAssetPricing");

      return response;
    } catch (error) {
      return [];
    }
  },
  removeUnits: (
    { commit, state, dispatch },
    params: { invoiceId: string }
  ): void => {
    const { invoiceId } = params;
    const local: State = state;
    const newList: RowAsset[] = [];
    const deletedIds: string[] = [];

    for (const asset of local.form.assetList) {
      if (!!asset.id && asset.invoiceAPId === invoiceId) {
        deletedIds.push(asset.id);
      } else if (asset.invoiceAPId !== invoiceId) {
        newList.push(asset);
      }
    }

    const payload: Partial<FormValue> = {
      assetList: newList,
      deletedAssetLineIds: [...local.form.deletedAssetLineIds, ...deletedIds],
    };
    commit("setForm", payload);
    dispatch("countAssetPricing");
  },
  countMaxInvoiceDate: (
    context,
    list: LeasingInvoiceState[]
  ): number | null => {
    if (list.length === 0) {
      return null;
    }

    list.sort((left, right) => {
      return right.invoiceDate - left.invoiceDate;
    });

    return list[0].invoiceDate;
  },
};
