// Interfaces
import { RoutePathModel } from "../interface/common.interface";

export const DASHBOARD_ROUTE: RoutePathModel = {
  path: "dashboard",
  url: "/dashboard",
  name: "dashboard",
  meta: {
    need_auth: true,
    title_head: "lbl_dashboard",
    breadcrumb: [
      {
        name: "lbl_dashboard",
        icon: "dashboard",
        href: "/dashboard",
      },
    ],
  },
};
