import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  BranchWarehouseCreateRequestDto,
  BranchWarehouseResponseDto,
} from "@/models/interface/branch";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";

type FilterField = Partial<{
  name: string;
  id: string;
}>;

const useBranch = () => {
  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<BranchWarehouseResponseDto>> => {
    return logisticServices.getListWarehouseBranch(params);
  };

  const create = (
    body: BranchWarehouseCreateRequestDto
  ): Promise<BranchWarehouseResponseDto> => {
    return logisticServices.createWarehouseBranch(body);
  };

  const update = (
    branchId: string,
    body: BranchWarehouseCreateRequestDto
  ): Promise<BranchWarehouseResponseDto> => {
    return logisticServices.updateWarehouseBranch(body, branchId);
  };

  const findById = (id: string) => {
    return logisticServices.getDetailWarehouseBranch(id);
  };

  const initCreateDto = (): BranchWarehouseCreateRequestDto => ({
    address: "",
    code: "",
    name: "",
  });

  const toOptions = (
    data: Array<BranchWarehouseResponseDto>
  ): Array<Option<BranchWarehouseResponseDto>> => {
    return data.map<Option<BranchWarehouseResponseDto>>(item => ({
      label: item.name,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const searchBy = (field: FilterField): string => {
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (field.name) {
      q.push(builder.push(["name", field.name], { like: "both" }).build());
    }

    if (field.id) {
      q.push(builder.push(["secureId", field.id]).build());
    }

    return q.join(builder.OR);
  };

  return {
    create,
    findAll,
    findById,
    initCreateDto,
    searchBy,
    toOptions,
    update,
  };
};

export default useBranch;
