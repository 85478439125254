enum SALES_ORDER_STATUS {
  DRAFT = "Draft",
  SUBMITTED = "Submitted",
  RETURNED = "Returned",
  CANCELLED = "Cancelled",
  CLOSED = "Closed",
  PARTIAL_DELIVERED = "Partial Delivered",
  DELIVERED = "Delivered",
  INVOICING = "Invoicing",
  PAID = "Paid",
}

export default SALES_ORDER_STATUS;
