




















import { useCurrency, useStorage } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { StorageKeys } from "@/models/constant/enums/storage.enum";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import {
  PREFERENCE_ACCOUNT_SETUP_KEY,
  PREFERENCE_FEATURE_KEY,
} from "@/models/enums/preference.enum";
import { ResponsePreference } from "@/models/interface/settings.interface";
import { defineUserPermissions, MenuAccesses } from "@/plugins/Casl";
import LocalStorageService from "@/services/LocalStorage.service";
import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
import AppContent from "./AppContent.vue";
import AppFooter from "./AppFooter.vue";
import AppHeader from "./AppHeader.vue";
import AppSidebar from "./AppSidebar.vue";

@Component({
  components: {
    AppContent,
    AppFooter,
    AppHeader,
    AppSidebar,
  },
  computed: {
    ...mapGetters({
      getPreferenceByKey: "preferenceStore/GET_PREFERENCE_BY_KEY",
    }),
  },
  methods: {
    ...mapMutations({
      setBaseDecimalPlace: "preferenceStore/SET_BASE_DECIMAL_PLACES",
      setBaseCurrency: "preferenceStore/SET_BASE_CURRENCY",
      setBaseARReceivableAccount:
        "preferenceStore/SET_BASE_AR_RECEIVABLE_ACCOUNT",
    }),
  },
})
export default class AppContainer extends Mixins(MNotificationVue) {
  getPreferenceByKey!: (key: string) => ResponsePreference | undefined;
  setBaseDecimalPlace!: (val: number) => void;
  setBaseCurrency!: (val: string) => void;
  setBaseARReceivableAccount!: (val: string) => void;

  mounted(): void {
    this.initUserPrivilege();
    this.initAppDecimalPoint();
    this.initBaseCurrency();
    this.initReceivableAccount();
  }

  initUserPrivilege(): void {
    const { getPrivileges } = useStorage();
    const { rules } = defineUserPermissions(getPrivileges() as MenuAccesses[]);
    this.$ability.update(rules);
  }

  /**
   * @description
   * set storage from preference
   */
  initAppDecimalPoint(): void {
    const val = this.getPreferenceByKey(
      PREFERENCE_FEATURE_KEY.FEATURE_DECIMAL_POINT
    );
    const dp: number = val ? +val.value : DECIMAL_PLACES_CURRENCY;
    LocalStorageService.save(StorageKeys.DECIMAL_POINT, dp.toString());
    this.setBaseDecimalPlace(dp);
  }

  async initBaseCurrency(): Promise<void> {
    const { findById } = useCurrency();
    const curr = this.getPreferenceByKey(
      PREFERENCE_FEATURE_KEY.FEATURE_BASE_CURRENCY
    );
    try {
      const res = await findById(curr?.value || "");
      LocalStorageService.save(StorageKeys.BASE_CURRENCY, res.currencyCode);
      this.setBaseCurrency(res.currencyCode);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  initReceivableAccount(): void {
    const account = this.getPreferenceByKey(
      PREFERENCE_ACCOUNT_SETUP_KEY.ACCOUNT_RECEIVABLE
    );
    LocalStorageService.save(
      StorageKeys.RECEIVABLE_ACCOUNT,
      account?.value || ""
    );
    this.setBaseARReceivableAccount(account?.value || "");
  }
}
