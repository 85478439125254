import { RouteConfig } from 'vue-router';

// Models
import { config } from '@/models/constant/routes.constant';

const router: RouteConfig = {
  path: config.DASHBOARD_ROUTE.path,
  name: config.DASHBOARD_ROUTE.name,
  meta: config.DASHBOARD_ROUTE.meta,
  component: () => import(/* webpackPrefetch: true */"./Dashboard.vue")
};

export default router;