import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ListBankDto } from "@/models/interface/bank";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class BankService extends HttpClient {
  public constructor() {
    super();
  }

  getMasterBankList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListBankDto>> {
    return this.get<Pagination<ListBankDto>>(Api.Bank, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const bankService = new BankService();
