import { RouteConfig } from 'vue-router';

const router: RouteConfig = {
  path: "/error/not-authorized",
  alias: "*",
  name: "ErrorNotAuthorized",
  meta: {
    title_head: "Page Not Authorized",
  },
  component: () => import(/* webpackPrefetch: true */"./Notauthorized.vue")
};

export default router;