import { TruckingSalesOrderRequestDto } from "@/models/interface/trucking-sales-order";
import { truckingSalesOrderSevice } from "@/services/trucking-sales-order.service";

const useTruckingSalesOrder = () => {
  const create = (payload: TruckingSalesOrderRequestDto) => {
    return truckingSalesOrderSevice.createSalesOrderTrucking(payload);
  };

  const update = (id: string, payload: TruckingSalesOrderRequestDto) => {
    return truckingSalesOrderSevice.updateSalesOrderTrucking(payload, id);
  };

  const submit = (id: string, payload: TruckingSalesOrderRequestDto) => {
    return truckingSalesOrderSevice.submitSalesOrderTrucking(payload, id);
  };

  return {
    create,
    update,
    submit,
  };
};

export default useTruckingSalesOrder;
