import { buildInvoicePrepaymentDetailDto } from "@/builder/account-receivable/AccountReceivablePrepaymentDtoBuilder";
import { ONE } from "@/models/constant/global.constant";
import { InvoiceArTypeEnum } from "@/models/enums/invoice.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { DetailForm, FormValue } from "@/store/aRPrepayment.store";
import Decimal from "decimal.js-light";

export const initForm = (): FormValue => ({
  baseAmount: new Decimal(0),
  taxBaseVariable: 1,
  inclusiveTaxRate: 0,
  inclusiveTax: undefined,
  accountingDate: null,
  amount: 0,
  branchWarehouseId: "",
  currency: "",
  currencyRate: ONE,
  customerBillToAddress: "",
  customerId: "",
  customerShipToAddress: "",
  customerTaxType: "",
  description: "",
  invoiceDate: null,
  invoiceType: "" as InvoiceArTypeEnum,
  lineAccountId: "",
  lineAccountName: "",
  operatorName: "",
  poNumber: "",
  receivableAccountId: "",
  receivableAccountName: "",
  salesReturnId: "",
  subTotal: new Decimal(0),
  taxCode: "",
  taxInvoiceDate: null,
  taxInvoiceNumber: "",
  taxRate: 0,
  taxRegistrationName: "",
  taxRegistrationNumber: "",
  taxType: TAX_CALCULATION.EXCLUSIVE,
  termOfPayment: "",
  total: new Decimal(0),
  totalTax: new Decimal(0),
  isLuxury: false,
});

export const initDetail = (): DetailForm => {
  const dt = buildInvoicePrepaymentDetailDto();

  return {
    ...dt,
    accountingDate: null,
    invoiceDate: null,
    taxInvoiceDate: null,
  };
};
