import { Option } from "@/models/class/option.class";
import { AP_PAYMENT_TYPE_ENUM } from "@/models/enums/ap-payment.enum";
import {
  AP_PREPAYMENT_STATUS,
  AP_PREPAYMENT_TYPE,
} from "@/models/enums/ApPrepayment.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  CreateInvoiceApPrepayment,
  InvoiceApPrepayment,
  InvoiceApPrepaymentData,
} from "@/models/interface/InvoiceApPrepayment.interface";
import { invoiceApPrepayment as invoiceApPrepaymentService } from "@/services/InvoiceApPrepayment.service";
import { invoicePrepayment as invoicePrepaymentService } from "@/services/invoicePrepayment";
import Decimal from "decimal.js-light";

export type FormValue = {
  accountingDate: string;
  amount: number;
  assetCode: string;
  branch: string;
  currency: string; // currency code
  currencyRate: number;
  description: string;
  invoiceDate: string;
  invoiceType: AP_PREPAYMENT_TYPE;
  lineAccount: string;
  payableAccount: string;
  poNumber: string;
  supplierBillTo: string;
  supplierName: string;
  taxCode: string;
  taxInvoiceDate: string;
  taxInvoiceNumber: string;
  taxRegistrationName: string;
  taxRegistrationNumber: string;
  taxType: TAX_CALCULATION;
  termOfPayment: number;
  status: AP_PREPAYMENT_STATUS;
  isLuxuryGoods: boolean;
  inclusiveTaxCode: string;
};

const useInvoiceAPPrepayment = () => {
  const toOptions = (
    datas: InvoiceApPrepaymentData[]
  ): Option<InvoiceApPrepaymentData>[] => {
    return datas.map<Option<InvoiceApPrepaymentData>>(item => ({
      label: item.documentNumber || "-",
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const buildCreateDto = (field: FormValue): CreateInvoiceApPrepayment => {
    return {
      accountingDate: field.accountingDate,
      amount: String(field.amount),
      branchWarehouseId: field.branch,
      currency: field.currency,
      currencyRate: field.currencyRate,
      description: field.description,
      invoiceDate: field.invoiceDate,
      invoiceType: field.invoiceType,
      lineAccountId: field.lineAccount,
      payablesAccountId: field.payableAccount,
      purchaseOrderId: field.poNumber,
      status: field.status,
      supplierBillToAddress: field.supplierBillTo,
      supplierId: field.supplierName,
      taxCode: field.taxCode,
      taxInvoiceDate: field.taxInvoiceDate,
      taxInvoiceNumber: field.taxInvoiceNumber,
      taxRegistrationName: field.taxRegistrationName,
      taxRegistrationNumber: field.taxRegistrationNumber,
      taxType: field.taxType,
      termOfPayment: field.termOfPayment,
      assetId: field.assetCode,
      isLuxuryGoods: field.isLuxuryGoods,
      inclusiveTaxCode: field.inclusiveTaxCode,
    };
  };

  const cancelInvoice = (id: string) => {
    return invoiceApPrepaymentService.cancelApPrepayment(id);
  };

  const createInvoice = (payload: CreateInvoiceApPrepayment) => {
    return invoiceApPrepaymentService.createInvoiceApPrepayment(payload);
  };

  const rejectInvoice = (id: string) => {
    return invoiceApPrepaymentService.rejectInvoiceApPrepayment(id);
  };

  const approveInvoice = (id: string, payload: CreateInvoiceApPrepayment) => {
    return invoiceApPrepaymentService.approveInvoiceApPrepayment(payload, id);
  };

  const findById = (id: string) => {
    return invoiceApPrepaymentService.detailInvoiceApPrepayment(id);
  };

  const findAll = (params: RequestQueryParamsModel) => {
    return invoiceApPrepaymentService.getListInvoiceApPrepayment(params);
  };

  /**
   * @description immediate return amount
   * @param field field amount
   */
  const calcSubtotalExc = (field: { amount: number }): number => {
    const { amount = 0 } = field;
    return amount;
  };

  const calcSubtotalInc = (field: {
    amount: number;
    taxRate: number;
  }): number => {
    const { amount = 0, taxRate = 0 } = field;
    const ONE = 1;
    const rate = new Decimal(taxRate).dividedBy(100).plus(ONE);
    const returnVal = new Decimal(amount).dividedBy(rate);
    return returnVal.toNumber();
  };

  /**
   * @description formula
   * subtotal x (tax rate / 100)
   */
  const calcTotalTaxExc = (field: {
    subtotal: number;
    taxRate: number;
  }): number => {
    const { subtotal = 0, taxRate = 0 } = field;
    const val = new Decimal(taxRate).dividedBy(100).times(subtotal);
    return val.toNumber();
  };

  /**
   * @description formula
   * amount - subtotal
   */
  const calcTotalTaxInc = (field: {
    subtotal: number;
    amount: number;
  }): number => {
    const { subtotal = 0, amount = 0 } = field;
    const val = new Decimal(amount).minus(subtotal);
    return val.toNumber();
  };

  /**
   * @description formula
   * subtotal + total tax
   */
  const calcTotalPayment = (field: {
    subtotal: number;
    totalTax: number;
  }): number => {
    const { subtotal = 0, totalTax = 0 } = field;
    const val = new Decimal(subtotal).plus(totalTax).toNumber();
    return val;
  };

  const OPT_PAYMENT_TYPE: Option[] = [
    {
      key: 0,
      value: "Applied" as AP_PAYMENT_TYPE_ENUM,
      label: "lbl_applied",
    },
    {
      key: 1,
      value: "Unidentified" as AP_PAYMENT_TYPE_ENUM,
      label: "lbl_unidentified",
    },
  ];

  const findAllPrepaymentLeasing = (
    assetId: string
  ): Promise<Array<InvoiceApPrepayment>> => {
    return invoicePrepaymentService.getListInvoicePrepaymentLeasing(assetId);
  };

  return {
    OPT_PAYMENT_TYPE,
    approveInvoice,
    buildCreateDto,
    calcSubtotalExc,
    calcTotalPayment,
    calcSubtotalInc,
    calcTotalTaxExc,
    calcTotalTaxInc,
    cancelInvoice,
    createInvoice,
    findAll,
    findById,
    rejectInvoice,
    toOptions,
    findAllPrepaymentLeasing,
  };
};

export default useInvoiceAPPrepayment;
