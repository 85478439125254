import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  AccountingCurrencyResponseDTO,
  DataMasterCurrency,
  ResponseListOfCurrency,
  ResponseListOfMasterCurrency,
} from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";

type SearchField = Partial<{
  code: string;
}>;

const useCurrency = () => {
  /**
   * @deprecated
   * new hooks {@linkcode findList}
   */
  const findAll = (params: RequestQueryParamsModel) => {
    return settingsServices.listOfMasterCurrency(params, "");
  };

  const findList = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AccountingCurrencyResponseDTO>> => {
    return settingsServices.getListMasterCurrency(params);
  };

  const findById = (id: string): Promise<AccountingCurrencyResponseDTO> => {
    return settingsServices.getDetailMasterCurrency(id);
  };

  const searchBy = (field: SearchField): string => {
    const q: string[] = [];
    const builder = new SearchBuilder();

    if (field.code) {
      q.push(
        builder.push(["currencyCode", field.code], { like: "both" }).build()
      );
    }

    return q.join(builder.OR);
  };

  /**
   * find currency rates from 2 currency
   * @param from currency code base
   * @param to currency code to
   * @returns list master currency
   */
  const findConversion = (
    from: string,
    to: string
  ): Promise<ResponseListOfCurrency> => {
    const builder = new SearchBuilder();
    const q = builder
      .push(["fromCurrency.currencyCode", from])
      .and()
      .push(["toCurrency.currencyCode", to])
      .build();
    return settingsServices.listOfCurrency({ search: q }, "");
  };

  /**
   * @deprecated
   * new hooks {@linkcode toOptionsNew}
   */
  const toOptions = (data: ResponseListOfMasterCurrency) => {
    return data.data.map<Option<DataMasterCurrency>>((item, i) => ({
      label: item.currencyCode || "",
      value: item.id || "",
      key: item.id || i,
      meta: item,
    }));
  };

  const toOptionsNew = (
    data: Array<AccountingCurrencyResponseDTO>
  ): Array<Option<AccountingCurrencyResponseDTO>> => {
    return data.map<Option<AccountingCurrencyResponseDTO>>(item => ({
      label: item.currencyCode,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  return {
    findAll,
    findById,
    toOptions,
    findConversion,
    searchBy,
    findList,
    toOptionsNew,
  };
};

export default useCurrency;
