import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const index: RouteConfig = {
  path: config.QR_ROUTE.path,
  name: config.QR_ROUTE.name,
  meta: config.QR_ROUTE.meta,
  redirect: config.QR_ROUTE.redirect,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.SCAN_QR.path,
      name: config.SCAN_QR.name,
      meta: config.SCAN_QR.meta,
      component: () => import(/* webpackPrefetch: true */ "./ScanQr.vue"),
    },
    {
      path: config.DETAIL_QR.path,
      name: config.DETAIL_QR.name,
      meta: config.DETAIL_QR.meta,
      component: () => import(/* webpackPrefetch: true */ "./DetailQr.vue"),
    },
  ],
};

export default index;
