import { Api } from "@/models/class/api.class";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  IPreferencesRequestDto,
  IPreferencesResponseDto,
} from "@/models/interface/preference";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class PreferenceService extends HttpClient {
  public constructor() {
    super();
  }

  getList(params?: { name: string }): Promise<IPreferencesResponseDto[]> {
    return this.get<IPreferencesResponseDto[]>(Api.Preference, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(body: IPreferencesRequestDto[]): Promise<void> {
    return this.put<void, IPreferencesRequestDto[]>(Api.Preference, body)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const preferenceService = new PreferenceService();
