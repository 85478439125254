import { SearchBuilder } from "@/builder";
import { useBranch, useLocation, useWarehouse } from "@/hooks";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import store from "@/store/auth.store";
import { Moment } from "moment";

export type FilterField = { asOfDate: Moment } & Partial<{
  productCategory: string;
  productCategoryId: string;
  productCode: string;
  productName: string;
  branchId: string;
  warehouseId: string;
  rackId: string;
}>;

const useReportValuation = () => {
  const findAll = (asOfDate: string, params: RequestQueryParamsModel) => {
    return logisticServices.getListReportValuation(asOfDate, params);
  };

  const download = (asOfDate: string, params: RequestQueryParamsModel) => {
    return logisticServices.getDownloadValuation(params, asOfDate);
  };

  const print = (asOfDate: string, params: RequestQueryParamsModel) => {
    return logisticServices.getPrintValuation(params, asOfDate);
  };

  const buildParam = async (field: FilterField): Promise<string> => {
    const { findById: findBranchById } = useBranch();
    const { findById: findWarehouseById } = useWarehouse();
    const { findById: findLocationById } = useLocation();

    const company = store.state.authData.companyName;
    const partCategory = field.productCategory?.trim() || "ALL";
    const partNumber = field.productCode || "ALL";
    const partName = field.productName || "ALL";

    let branch = "ALL";
    if (field.branchId) {
      const res = await findBranchById(field.branchId);
      branch = res.name;
    }

    let warehouse = "ALL";
    if (field.warehouseId) {
      const res = await findWarehouseById(field.warehouseId);
      warehouse = res.name;
    }

    let rack = "ALL";
    if (field.rackId) {
      const res = await findLocationById(field.rackId);
      rack = res.name;
    }

    const header = [
      company,
      partCategory,
      partNumber,
      partName,
      branch,
      warehouse,
      rack,
    ];

    return header.join(",");
  };

  const filterBy = (field: FilterField): string => {
    const {
      productCategoryId = "",
      productCategory = "",
      productCode = "",
      productName = "",
      branchId = "",
      warehouseId = "",
      rackId = "",
    } = field;
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (productCategory) {
      q.push(
        builder
          .push(["categoryName", productCategory], { like: "both" })
          .build()
      );
    }

    if (productCategoryId) {
      q.push(builder.push(["categoryId", productCategoryId]).build());
    }

    if (productCode) {
      q.push(
        builder.push(["productCode", productCode], { like: "both" }).build()
      );
    }

    if (productName) {
      q.push(
        builder.push(["productName", productName], { like: "both" }).build()
      );
    }

    if (branchId) {
      q.push(builder.push(["branchId", branchId]).build());
    }

    if (warehouseId) {
      q.push(builder.push(["warehouseId", warehouseId]).build());
    }

    if (rackId) {
      q.push(builder.push(["locationId", rackId]).build());
    }

    return q.join(builder.AND);
  };

  return { findAll, filterBy, download, print, buildParam };
};

export default useReportValuation;
