import { useRemoveRows } from "@/hooks";
import { ONE } from "@/models/constant/global.constant";
import {
  buildWorkOrderFormState,
  buildWorkOrderSparepartState,
  buildWorkOrderUnitState,
} from "./resource";
import { State, WorkOrderFormState, WorkOrderUnitState } from "./types";

export const actions = {
  resetStore: (ctx): void => {
    const { commit } = ctx;
    commit("setForm", buildWorkOrderFormState());
  },
  addUnit: ({ state }): void => {
    const unit: WorkOrderUnitState = buildWorkOrderUnitState();
    const local: State = state;
    unit.no = local.form.units.length + ONE;
    local.form.units.push(unit);
  },
  removeUnits: ({ state, commit }, keys: Array<string>): void => {
    const {
      form: { units },
    }: State = state;
    const { newSource } = useRemoveRows(units, keys);

    const form: Partial<WorkOrderFormState> = {
      units: newSource.map((item, i) => ({ ...item, no: i + ONE })),
    };
    commit("setForm", form);
  },
  addSparepart: ({ state }): void => {
    const { form }: State = state;
    const sparepart = buildWorkOrderSparepartState();
    sparepart.no = form.spareParts.length + ONE;
    form.spareParts.push(sparepart);
  },
  removeSpareParts: ({ state, commit }, keys: Array<string>): void => {
    const {
      form: { spareParts },
    }: State = state;
    const { newSource } = useRemoveRows(spareParts, keys);

    const form: Partial<WorkOrderFormState> = {
      spareParts: newSource.map((item, i) => ({ ...item, no: i + ONE })),
    };
    commit("setForm", form);
  },
};
