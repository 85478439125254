import { Option } from "@/models/class/option.class";
import { ResponseCashInOutTypeDTO } from "@/models/interface/cashManagement.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { cashManagementServices } from "@/services/cashmanagement.service";

const useCashInOut = () => {
  const findAllTypes = (params: RequestQueryParamsModel) => {
    return cashManagementServices.listCashType(params);
  };

  const toTypeOptions = (datas: ResponseCashInOutTypeDTO[]): Option[] => {
    return datas.map<Option>((item, i) => ({
      label: item.value,
      key: item.key,
      value: item.key,
    }));
  };

  return { findAllTypes, toTypeOptions };
};

export default useCashInOut;
