import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ReportAgingApResponseDto } from "@/models/interface/AgingApReport.interface";
import {
  InvoiceApCreateDto,
  InvoiceApResponseDetailDto,
  InvoiceApUpdateDto,
} from "@/models/interface/ap-invoice";
import { ApPaymentReportDto } from "@/models/interface/ApPaymentReport.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  RequestParamDownloadPOGRInvoiceReport,
  RequestParamListPOGRInvoiceReport,
  ResponseDataInvoiceAp,
  ResponseDataInvoiceApAvailable,
} from "@/models/interface/invoiceAp.interface";
import { InvoiceApSummaryResponseDto } from "@/models/interface/InvoiceApSummary.interface";
import { DetailPoListDTO } from "@/models/interface/IPurchaseOrderReportDetailDTO";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class InvoiceAPServices extends HttpClient {
  constructor() {
    super();
  }
  getSummary(id: string): Promise<InvoiceApSummaryResponseDto> {
    return this.get<InvoiceApSummaryResponseDto>(
      Api.InvoiceAp + "/summary/" + id
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listApInvoice(
    params?: RequestQueryParamsModel
  ): Promise<ResponseDataInvoiceAp> {
    return this.get<ResponseDataInvoiceAp>(Api.InvoiceAp, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  listApInvoiceAvailable(
    params: RequestQueryParamsModel
  ): Promise<ResponseDataInvoiceApAvailable[]> {
    return this.get<ResponseDataInvoiceApAvailable[]>(Api.InvoiceApAvailable, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadApInvoice(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.InvoiceAp + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadPOGRInvoiceReport(
    params?: Partial<RequestParamDownloadPOGRInvoiceReport>
  ): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.InvoiceAp + "/report/detail-po/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printApInvoice(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.InvoiceAp + "/report/print", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detailApInvoice(id: string): Promise<InvoiceApResponseDetailDto> {
    return this.get<InvoiceApResponseDetailDto>(`${Api.InvoiceAp}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detailApInvoiceReturn(id: string): Promise<InvoiceApResponseDetailDto> {
    return this.get<InvoiceApResponseDetailDto>(
      Api.InvoiceAp + `/${id}?returnToSupplier=true`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createApInvoice(
    payload: InvoiceApCreateDto
  ): Promise<InvoiceApResponseDetailDto> {
    return this.post<InvoiceApResponseDetailDto, InvoiceApCreateDto>(
      Api.InvoiceAp,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateApInvoice(
    id: string,
    payload: InvoiceApUpdateDto
  ): Promise<InvoiceApResponseDetailDto> {
    return this.put<InvoiceApResponseDetailDto, InvoiceApUpdateDto>(
      Api.InvoiceAp + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitApInvoice(
    id: string,
    payload: InvoiceApUpdateDto
  ): Promise<InvoiceApResponseDetailDto> {
    return this.post<InvoiceApResponseDetailDto, InvoiceApUpdateDto>(
      Api.InvoiceAp + `/submit/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  approveApInvoice(
    id: string,
    payload: InvoiceApUpdateDto
  ): Promise<InvoiceApResponseDetailDto> {
    return this.post<InvoiceApResponseDetailDto, InvoiceApUpdateDto>(
      Api.InvoiceAp + `/approve/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelApInvoice(id: string): Promise<InvoiceApResponseDetailDto> {
    return this.put<InvoiceApResponseDetailDto, null>(
      Api.InvoiceAp + `/cancel/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  rejectApInvoice(id: string): Promise<InvoiceApResponseDetailDto> {
    return this.put<InvoiceApResponseDetailDto, null>(
      Api.InvoiceAp + `/reject/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListPODetailReport(
    params?: Partial<RequestParamListPOGRInvoiceReport>
  ): Promise<Pagination<DetailPoListDTO>> {
    return this.get<Pagination<DetailPoListDTO>>(
      Api.InvoiceAp + "/report/detail-po",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReportApPayment(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.InvoiceAp + "/report-ap-payment/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListReportApPayment(
    params?: RequestQueryParamsModel
  ): Promise<ApPaymentReportDto[]> {
    return this.get<ApPaymentReportDto[]>(
      Api.InvoiceAp + "/report-ap-payment",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListAgingApReport(
    params?: RequestQueryParamsModel
  ): Promise<ReportAgingApResponseDto> {
    return this.get<ReportAgingApResponseDto>(Api.AgingReportAp, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadAgingApReport(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.AgingReportAp + "/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const invoiceAPServices = new InvoiceAPServices();
