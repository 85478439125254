import { SearchBuilder } from "@/builder";
import { setNumbering } from "@/helpers/common";
import { generateUUID } from "@/helpers/uuid";
import { useTax } from "@/hooks/tax";
import useAccountingCalculation from "@/hooks/useAccountingCalculation";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DATE_FORMAT_YYYY_MM_DD } from "@/models/constants/date.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { invoiceApPrepayment } from "@/services/InvoiceApPrepayment.service";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import { LabelInValue } from "@/types";
import { Decimal } from "decimal.js-light";
import {
  buildApInvoiceFormProductState,
  buildApInvoiceFormState,
} from "./resource";
import {
  ApInvoiceFormProductState,
  ApInvoiceFormState,
  ApInvoicePrepaymentData,
  ApInvoicePrepaymentDataState,
  ApInvoicePrepaymentParams,
  ApInvoiceStoreState,
} from "./types";

export const actions = {
  ["CALC_ADDITIONAL_DISCOUNT"]: (
    { state, commit },
    payload: { value: number; field: "amount" | "percent" }
  ): void => {
    const local: ApInvoiceFormState = state.formState;
    if (payload.field === "percent") {
      const percentage = new Decimal(payload.value || 0).dividedBy(100);
      const final: number = percentage.times(local.totalGross).toNumber();
      const state: Partial<ApInvoiceFormState> = {
        additionalDiscountAmount: final,
        additionalDiscountPercent: payload.value,
      };
      commit("setFormState", state);
    } else {
      const amount = new Decimal(payload.value || 0);
      const final: number = amount
        .dividedBy(local.totalGross || 1)
        .times(100)
        .toNumber();
      const state: Partial<ApInvoiceFormState> = {
        additionalDiscountAmount: payload.value,
        additionalDiscountPercent: final,
      };
      commit("setFormState", state);
    }
  },
  ["ADD_ROW"]: ({ state }): void => {
    const local: ApInvoiceFormState = state.formState;
    const row: ApInvoiceFormProductState = buildApInvoiceFormProductState();
    row.no = local.products.length + 1;
    local.products.push(row);
  },
  ["CALC_GROSS_VALUE"]: ({ state }): void => {
    const {
      calculateGrossValue,
      calculateGrossAfterDiscount,
      calculateGrossAfterDiscountBeforeTax,
      calculateTaxBase,
      calculateTaxValue,
      calculateTotal,
      calculateLineRatio,
      calculateDiscountValueFromRatio,
      calculateTotalWithAdditionalValue,
      calculateIncomeTax,
    } = useAccountingCalculation();

    const {
      formState,
      inclusiveTaxRate: defaultInclusiveTaxRate,
      taxBaseVariable,
    } = state as ApInvoiceStoreState;
    const {
      products,
      taxType,
      additionalDiscountAmount,
      prepayments,
      isLuxuryGoods,
    } = formState;
    const totalGrossValue = products.reduce<Decimal>(
      (accumulator, current) =>
        new Decimal(accumulator).plus(
          calculateGrossValue({ price: current.price, qty: current.qty })
        ),
      new Decimal(0)
    );
    const totalPrepayment = prepayments.reduce<number>(
      (accumulator, current) =>
        new Decimal(accumulator).plus(current.appliedAmount).toNumber(),
      0
    );

    products.forEach(product => {
      let inclusiveTaxRate = defaultInclusiveTaxRate;
      if (taxType === TAX_CALCULATION.INCLUSIVE) {
        if (isLuxuryGoods) {
          inclusiveTaxRate = product.taxRate;
        } else {
          inclusiveTaxRate = product.inclusiveTaxRate;
        }
      }

      const grossValue: Decimal = calculateGrossValue({
        price: product.price,
        qty: product.qty,
      });
      const grossAfterDiscountLine: Decimal = calculateGrossAfterDiscount({
        grossValue,
        discountValue: new Decimal(product.discountValue),
      });
      const lineRatio: Decimal = calculateLineRatio({
        lineValue: grossValue,
        total: totalGrossValue,
      });
      const grossAfterDiscountLineAndHeader: Decimal =
        calculateGrossAfterDiscount({
          grossValue: grossAfterDiscountLine,
          discountValue: calculateDiscountValueFromRatio({
            discountValue: additionalDiscountAmount,
            ratio: lineRatio,
          }),
        });
      const grossAfterDiscountLineAndHeaderAndPrepayment: Decimal =
        calculateGrossAfterDiscount({
          grossValue: grossAfterDiscountLineAndHeader,
          discountValue: calculateDiscountValueFromRatio({
            discountValue: totalPrepayment,
            ratio: lineRatio,
          }),
        });
      const grossAfterDiscountLineAndHeaderAndPrepaymentBeforeTax =
        calculateGrossAfterDiscountBeforeTax({
          grossAfterDiscount: grossAfterDiscountLineAndHeaderAndPrepayment,
          purchaseInclusiveRate: inclusiveTaxRate,
          taxType: taxType as TAX_CALCULATION,
        });
      const taxBase = calculateTaxBase({
        taxType,
        grossAfterDiscountBeforeTax:
          grossAfterDiscountLineAndHeaderAndPrepaymentBeforeTax,
        variable: taxBaseVariable,
      });
      const taxValue = calculateTaxValue({
        taxType,
        taxBase: taxBase || 0,
        taxRate: product.taxRate,
      });

      let incomeTaxAmount = new Decimal(0);
      if (product.incomeTax?.key) {
        incomeTaxAmount = calculateIncomeTax({
          gross: grossAfterDiscountLineAndHeaderAndPrepaymentBeforeTax,
          incomeTaxRate: product.incomeTaxRate,
        });
      }

      product.incomeTaxValue = String(incomeTaxAmount);
      const subTotal = calculateTotal({
        grossAfterDiscountBeforeTax:
          grossAfterDiscountLineAndHeaderAndPrepaymentBeforeTax,
        taxValue,
        incomeTaxAmount,
      });
      const lineAdditionalCost: Decimal =
        product.additionalCosts.reduce<Decimal>(
          (accumulation, current) =>
            new Decimal(accumulation).add(current.amount),
          new Decimal(0)
        );
      const totalWithAdditionalCost = calculateTotalWithAdditionalValue({
        subTotal: subTotal,
        additionalValue: lineAdditionalCost,
      });
      product.gross =
        grossAfterDiscountLineAndHeaderAndPrepaymentBeforeTax.toNumber();
      product.baseAmount = taxBase;
      product.taxValue = taxValue;
      product.subTotal = totalWithAdditionalCost;
    });
  },
  ["SUM_TOTAL_ADDITIONAL_COST"]: ({ state, commit }): void => {
    const local: ApInvoiceFormState = state.formState;
    const total = local.products.reduce<number>((a, b) => {
      return new Decimal(b.totalAdditionalCost || 0).plus(a).toNumber();
    }, 0);
    const payload: Partial<ApInvoiceFormState> = {
      totalAdditionalCost: total,
    };
    commit("setFormState", payload);
  },
  ["SUM_GROSS"]: ({ state, commit }): void => {
    const local: ApInvoiceFormState = state.formState;
    const total = local.products.reduce<number>((a, b) => {
      return new Decimal(b.gross || 0).plus(a).toNumber();
    }, 0);
    const payload: Partial<ApInvoiceFormState> = {
      totalGross: total,
    };
    commit("setFormState", payload);
  },
  ["SUM_INVOICE_SUB_TOTAL"]: ({ state, commit }): void => {
    const local: ApInvoiceFormState = state.formState;
    const total = local.products.reduce<number>((a, b) => {
      return new Decimal(b.subTotal || 0).plus(a).toNumber();
    }, 0);
    const payload: Partial<ApInvoiceFormState> = {
      invoiceSubTotal: total,
    };
    commit("setFormState", payload);
  },
  ["SUM_PREPAYMENT"]: ({ state, commit }): void => {
    const local: ApInvoiceFormState = state.formState;
    const total = local.prepayments.reduce<number>((a, b) => {
      return new Decimal(b.appliedAmount || 0).plus(a).toNumber();
    }, 0);
    const payload: Partial<ApInvoiceFormState> = {
      prePaymentUsedTotal: total,
    };
    commit("setFormState", payload);
  },
  ["SUM_TAX"]: ({ state, commit }): void => {
    const local: ApInvoiceFormState = state.formState;
    const total = local.products.reduce<number>((a, b) => {
      return new Decimal(b.taxValue || 0).plus(a).toNumber();
    }, 0);
    const payload: Partial<ApInvoiceFormState> = {
      totalTax: total,
    };
    commit("setFormState", payload);
  },
  ["SUM_INCOME_TAX"]: ({ state, commit }): void => {
    const local: ApInvoiceFormState = state.formState;
    const total = local.products.reduce<number>((a, b) => {
      return new Decimal(b.incomeTaxValue || 0).plus(a).toNumber();
    }, 0);
    const payload: Partial<ApInvoiceFormState> = {
      totalIncomeTax: total,
    };
    commit("setFormState", payload);
  },
  ["CALC_GRAND_TOTAL"]: ({ state, commit }): void => {
    const local: ApInvoiceFormState = state.formState;

    const grandTotal = local.products.reduce<number>(
      (a, b) => new Decimal(a).plus(b.subTotal).toNumber(),
      0
    );
    const payload: Partial<ApInvoiceFormState> = {
      grandTotal,
    };
    commit("setFormState", payload);
  },
  ["RECALCULATE_PRICING"]: async ({ dispatch }): Promise<void> => {
    await dispatch("CALC_GROSS_VALUE");
    await dispatch("SUM_GROSS");
    await dispatch("SUM_TOTAL_ADDITIONAL_COST");
    await dispatch("SUM_INVOICE_SUB_TOTAL");
    await dispatch("SUM_PREPAYMENT");
    await dispatch("SUM_TAX");
    await dispatch("SUM_INCOME_TAX");
    await dispatch("CALC_GRAND_TOTAL");
  },
  ["RESET_STATE"]: ({ commit }): void => {
    commit("setFormState", buildApInvoiceFormState());
  },
  updateDocReference: ({ commit, state }): void => {
    const local: ApInvoiceFormState = state.formState;
    const docRefs = local.products.map<LabelInValue>(product => {
      return {
        label: product.documentReference,
        key: product.documentReferenceId,
      };
    });

    const payload: Partial<ApInvoiceFormState> = {
      documentReferences: docRefs,
    };
    commit("setFormState", payload);
  },
  updateNumbering: ({ state }): void => {
    const local: ApInvoiceFormState = state.formState;
    local.products.forEach((item, index) => {
      item.no = index + 1;
    });
  },
  fetchPrepaymentList: (
    { commit, state },
    payload: ApInvoicePrepaymentParams
  ): void => {
    const { branchId, supplierId, currencyCode } = payload;
    if (!branchId || !supplierId || !currencyCode) return;
    const local: ApInvoiceStoreState = state;
    const params = new RequestQueryParams();
    params.page = local.prepaymentData.page - 1;
    params.limit = local.prepaymentData.limit;
    params.sorts = "createdDate:desc";

    const status = "Un-Applied";
    const criteria = new SearchBuilder().push(["status", status]).build();
    const queries: string[] = [criteria];

    if (branchId) {
      const searchByBranch = new SearchBuilder()
        .push(["branchWarehouse.secureId", branchId])
        .build();
      queries.push(searchByBranch);
    }

    if (supplierId) {
      const searchBySupplier = new SearchBuilder()
        .push(["supplier.secureId", supplierId])
        .build();
      queries.push(searchBySupplier);
    }

    if (currencyCode) {
      const searchByCurrency = new SearchBuilder()
        .push(["priceCurrency.currencyCode", currencyCode])
        .build();
      queries.push(searchByCurrency);
    }

    params.search = queries.join(SearchBuilder.AND);

    commit("setPrepaymentList", { loading: true });
    invoiceApPrepayment
      .getListInvoiceApPrepayment(params)
      .then(res => {
        const prepayments = res.data.map<ApInvoicePrepaymentData>(
          (prepayment, i) => ({
            no: setNumbering(params.page || 0, params.limit || 0, i),
            disabled: false,
            key: generateUUID(),
            prepaymentNumber: prepayment.documentNumber,
            prepaymentDate: prepayment.invoiceDate,
            prepaymentTotal: prepayment.amount,
            prepaymentDescription: prepayment.description,
            prepaymentId: prepayment.id,
          })
        );

        const prepaymentState: Partial<ApInvoicePrepaymentDataState> = {
          limit: local.prepaymentData.limit,
          page: local.prepaymentData.page,
          data: prepayments,
          totalElements: res.totalElements,
        };
        commit("setPrepaymentList", prepaymentState);
      })
      .finally(() => {
        commit("setPrepaymentList", { loading: false });
      });
  },
  fetchInclusiveTaxVariable({ state, dispatch, rootGetters }): void {
    const { findById } = useTax();

    const taxRateId = rootGetters["preferenceStore/GET_PREFERENCE_BY_KEY"](
      "feature_purchase_inclusive_tax_rate"
    ).value;

    findById(taxRateId).then(response => {
      state.inclusiveTaxRate = response.rate || 0;
      dispatch("RECALCULATE_PRICING");
    });
  },
  findTaxBaseVariable(context): void {
    const { state, dispatch } = context;
    const { formState } = state as ApInvoiceStoreState;

    if (!formState.invoiceDate) {
      state.taxBaseVariable = 1;
      dispatch("RECALCULATE_PRICING");
      return;
    }

    taxBaseVariableService
      .getVariable({
        isLuxury: !!formState.isLuxuryGoods,
        transactionDate: formState.invoiceDate.format(DATE_FORMAT_YYYY_MM_DD),
      })
      .then(response => {
        state.taxBaseVariable = response.value || 1;
        dispatch("RECALCULATE_PRICING");
      });
  },
};
