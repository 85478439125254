import { i18n } from "@/locales";
import { PreferenceMapper } from "@/mapper/Preference.mapper";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import {
  IPreferencesRequestDto,
  IPreferencesResponseDto,
} from "@/models/interface/preference";
import { preferenceService } from "@/services/Preference.service";
import { notification } from "ant-design-vue";
import {
  PreferenceAccountState,
  PreferenceFeatureState,
  PreferenceField,
  PreferenceInventoryState,
  PreferencePurchaseState,
  PreferenceSalesState,
  PreferenceTruckState,
  StorageStatePreference,
} from "./models/Preference.model";
import { initPreference } from "./resources/Preference.resource";

const state: StorageStatePreference = {
  dataPreference: [] as IPreferencesResponseDto[],
  baseCurrency: "",
  baseARReceivableAccount: "",
  baseDecimalPlace: DECIMAL_PLACES_CURRENCY,
  field: initPreference(),
  loadingSave: false,
};

const mutations = {
  [Mutations.SET_PREFERENCE]: (
    stateVuex: StorageStatePreference,
    payload: IPreferencesResponseDto[]
  ): void => {
    stateVuex.dataPreference = payload;
  },
  ["SET_BASE_CURRENCY"]: (
    store: StorageStatePreference,
    payload: string
  ): void => {
    store.baseCurrency = payload;
  },
  ["SET_BASE_AR_RECEIVABLE_ACCOUNT"]: (
    store: StorageStatePreference,
    payload: string
  ): void => {
    store.baseARReceivableAccount = payload;
  },
  ["SET_BASE_DECIMAL_PLACES"]: (
    store: StorageStatePreference,
    payload: number
  ): void => {
    store.baseDecimalPlace = payload;
  },
  mutatePreferenceField: (
    store: StorageStatePreference,
    payload: PreferenceField
  ): void => {
    store.field = payload;
  },
  toggleLoadingSave: (store: StorageStatePreference): void => {
    store.loadingSave = !store.loadingSave;
  },
};

const getters = {
  getDataPreference: (
    stateVuex: StorageStatePreference
  ): IPreferencesResponseDto[] => {
    return stateVuex.dataPreference;
  },
  ["GET_PREFERENCE_BY_KEY"]:
    (store: StorageStatePreference) =>
    (key: string): IPreferencesResponseDto | undefined => {
      return store.dataPreference.find(x => x.key === key);
    },
  getFeatSalesTaxRate: (
    state: StorageStatePreference,
    getters
  ): IPreferencesResponseDto | undefined => {
    return getters["GET_PREFERENCE_BY_KEY"]("feature_sales_tax_rate");
  },
  getPreferenceField: (store: StorageStatePreference): PreferenceField => {
    return store.field;
  },
};

const actions = {
  updatePreference: async (
    { commit },
    payload: IPreferencesRequestDto[]
  ): Promise<void> => {
    try {
      commit("toggleLoadingSave");
      await preferenceService.update(payload);
      notification.success({
        message: i18n.t("lbl_success").toString(),
        description: i18n
          .t("notif_update_success", { documentNumber: "" })
          .toString(),
        duration: 30,
      });
      const response = await preferenceService.getList();
      commit("SET_PREFERENCE", response);
    } finally {
      commit("toggleLoadingSave");
    }
  },
  updatePreferenceFeature: async ({ dispatch, state }): Promise<void> => {
    const payload: IPreferencesRequestDto[] =
      PreferenceMapper.toPreferenceFeatureUpdateDto(state.field.feature);
    await dispatch("updatePreference", payload);
  },
  updatePreferenceSales: async ({ dispatch, state }): Promise<void> => {
    const payload: IPreferencesRequestDto[] =
      PreferenceMapper.toPreferenceSalesUpdateDto(state.field.sales);
    await dispatch("updatePreference", payload);
  },
  updatePreferencePurchase: async ({ dispatch, state }): Promise<void> => {
    const payload: IPreferencesRequestDto[] =
      PreferenceMapper.toPreferencePurchaseUpdateDto(state.field.purchase);
    await dispatch("updatePreference", payload);
  },
  updatePreferenceInventory: async ({ dispatch, state }): Promise<void> => {
    const payload: IPreferencesRequestDto[] =
      PreferenceMapper.toPreferenceInventoryUpdateDto(state.field.inventory);
    await dispatch("updatePreference", payload);
  },
  updatePreferenceAccount: async ({ dispatch, state }): Promise<void> => {
    const payload: IPreferencesRequestDto[] =
      PreferenceMapper.toPreferenceAccountUpdateDto(state.field.account);
    await dispatch("updatePreference", payload);
  },
  updatePreferenceTruck: async ({ dispatch, state }): Promise<void> => {
    const payload: IPreferencesRequestDto[] =
      PreferenceMapper.toPreferenceTruckUpdateDto(state.field.truck);
    await dispatch("updatePreference", payload);
  },
  initPreferenceFormPurchase: ({ state, commit }) => {
    const data: IPreferencesResponseDto[] = state.dataPreference;

    const purchaseState: PreferencePurchaseState =
      PreferenceMapper.toPreferencePurchaseState(data);

    const payload: PreferenceField = {
      ...state.field,
      purchase: purchaseState,
    };

    commit("mutatePreferenceField", payload);
  },
  initPreferenceFormSales: ({ state, commit }) => {
    const data: IPreferencesResponseDto[] = state.dataPreference;

    const salesState: PreferenceSalesState =
      PreferenceMapper.toPreferenceSalesState(data);

    const payload: PreferenceField = {
      ...state.field,
      sales: salesState,
    };

    commit("mutatePreferenceField", payload);
  },
  initPreferenceFormFeature: ({ state, commit }): void => {
    const data: IPreferencesResponseDto[] = state.dataPreference;
    const featureState: PreferenceFeatureState =
      PreferenceMapper.toPreferenceFeatureState(data);

    const payload: PreferenceField = {
      ...state.field,
      feature: featureState,
    };

    commit("mutatePreferenceField", payload);
  },
  initPreferenceFormInventory: ({ state, commit }): void => {
    const data: IPreferencesResponseDto[] = state.dataPreference;
    const inventoryState: PreferenceInventoryState =
      PreferenceMapper.toPreferenceInventoryState(data);

    const payload: PreferenceField = {
      ...state.field,
      inventory: inventoryState,
    };

    commit("mutatePreferenceField", payload);
  },
  initPreferenceFormAccount: ({ state, commit }): void => {
    const data: IPreferencesResponseDto[] = state.dataPreference;
    const accountState: PreferenceAccountState =
      PreferenceMapper.toPreferenceAccountState(data);

    const payload: PreferenceField = {
      ...state.field,
      account: accountState,
    };

    commit("mutatePreferenceField", payload);
  },
  initPreferenceFormTruck: ({ state, commit }): void => {
    const data: IPreferencesResponseDto[] = state.dataPreference;
    const truckState: PreferenceTruckState =
      PreferenceMapper.toPreferenceTruckState(data);

    const payload: PreferenceField = {
      ...state.field,
      truck: truckState,
    };

    commit("mutatePreferenceField", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
