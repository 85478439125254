import { SearchBuilder } from "@/builder";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseReportUnitPosition } from "@/models/interface/salesOrder.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import { Moment } from "moment";
import { Row } from "./table";
import useDate from "./useDate";

export type FilterField = Partial<{
  asOfDate: Moment;
  assetType: string;
  branchId: string;
  brand: string;
  categoryId: string;
  customerId: string;
  customerLocation: string;
  serialNumber: string;
  status: string[];
  unitCode: string;
  warehouseId: string;
  yearPlacement: Moment;
}>;

const useReportUnitPosition = () => {
  const findAll = (params: RequestQueryParamsModel) => {
    return salesOrderServices.getListReportUnitPosition(params);
  };

  const download = (params: RequestQueryParamsModel) => {
    return salesOrderServices.downloadReportUnitPosition(params);
  };

  const toTableRow = (
    data: ResponseReportUnitPosition[]
  ): Row<ResponseReportUnitPosition>[] => {
    return data.map<Row<ResponseReportUnitPosition>>((item, i) => ({
      ...item,
      key: i,
      no: i + 1,
    }));
  };

  const filterBy = (field: FilterField): string => {
    const {
      branchId = "",
      categoryId = "",
      warehouseId = "",
      yearPlacement,
      asOfDate,
      brand = "",
      unitCode = "",
      assetType = "",
      serialNumber = "",
      customerLocation = "",
      customerId = "",
      status = [],
    } = field;
    const { toStartDay, toEndDay, toStartYear, toEndYear } = useDate();
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (customerId) {
      q.push(builder.push(["customerId", customerId]).build());
    }

    if (customerLocation) {
      q.push(
        builder
          .push(["customerLocation", customerLocation], { like: "both" })
          .build()
      );
    }

    if (serialNumber) {
      q.push(
        builder.push(["serialNumber", serialNumber], { like: "both" }).build()
      );
    }

    if (assetType) {
      q.push(builder.push(["type", assetType]).build());
    }

    if (branchId) {
      q.push(builder.push(["branchId", branchId]).build());
    }

    if (categoryId) {
      q.push(
        builder.push(["categoryId", categoryId], { like: "both" }).build()
      );
    }

    if (brand) {
      q.push(builder.push(["categoryId", brand], { like: "both" }).build());
    }

    if (warehouseId) {
      q.push(builder.push(["warehouseId", warehouseId]).build());
    }

    if (status && status.length) {
      const filter: string[] = [];
      status.forEach((val: string) => {
        filter.push(builder.push(["status", val]).build());
      });
      q.unshift(filter.join(builder.OR));
    }

    if (yearPlacement) {
      q.push(
        builder
          .push(
            [
              "acquisitionDate",
              toStartDay(toStartYear(yearPlacement)).format(),
            ],
            { het: true }
          )
          .and()
          .push(
            ["acquisitionDate", toEndDay(toEndYear(yearPlacement)).format()],
            { let: true }
          )
          .build()
      );
    }

    if (asOfDate) {
      q.push(
        builder
          .push(["createdDate", toEndDay(asOfDate).format()], { let: true })
          .build()
      );
    }

    if (unitCode) {
      q.push(builder.push(["unitCode", unitCode], { like: "both" }).build());
    }

    return q.join(builder.AND);
  };

  return { findAll, filterBy, toTableRow, download };
};

export default useReportUnitPosition;
