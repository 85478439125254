// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: "/account-payables",
  url: "/account-payables",
  name: "lbl_account_payables",
  meta: {
    breadcrumb: "lbl_account_payables",
    title_head: "lbl_account_payables",
    need_auth: true,
  },
};

export const CLOSEPERIODEPAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/closeperiod`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/closeperiod`,
  name: "account-payable.close-period",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_close_period",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/closeperiod`,
      },
    ],
    title_head: "lbl_close_period",
  },
};
export const INVOICES_PREPAYMENT_DEBIT_MEMO_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo`,
  name: "account-payables.invoice-prepayment.list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_invoices_prepayment_debit_memo",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo`,
      },
    ],
    title_head: "lbl_invoices_prepayment_debit_memo",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const INVOICES_PREPAYMENT_DEBIT_MEMO_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo/create`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo/create`,
  name: "account-payables.invoice-prepayment.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_invoices_prepayment_debit_memo",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo`,
      },
      {
        name: "lbl_invoices_prepayment_debit_memo_create",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo/create`,
      },
    ],
    title_head: "lbl_invoices_prepayment_debit_memo_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};
export const INVOICES_PREPAYMENT_DEBIT_MEMO_EDIT_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo/edit/:id`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo/edit/:id`,
  name: "account-payables.invoice-prepayment.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_invoices_prepayment_debit_memo",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo`,
      },
      {
        name: "lbl_invoices_prepayment_debit_memo_edit",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoices-prepayment-debit-memo/edit/:id`,
      },
    ],
    title_head: "lbl_invoices_prepayment_debit_memo_edit",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const LIST_INVOICES_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap`,
  name: "account-payables.invoice-ap.list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_invoice_ap_list",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap`,
      },
    ],
    title_head: "lbl_invoice_ap_list",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const CREATE_INVOICES_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap/create`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap/create`,
  name: "account-payables.invoice-ap.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_invoice_ap_create",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap/create`,
      },
    ],
    title_head: "lbl_invoice_ap_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const VIEW_INVOICES_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap/:id`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap/:id`,
  name: "account-payables.invoice-ap.view",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_invoice_ap_list",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap`,
      },
      {
        name: "lbl_invoice_ap_view",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/invoices/invoice-ap/view/:id`,
      },
    ],
    title_head: "lbl_invoice_ap_view",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const EDIT_INVOICES_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap/:id/edit`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/invoice-ap/:id/edit`,
  name: "account-payables.invoice-ap.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_edit_invoice_ap",
        href: "/",
      },
    ],
    title_head: "lbl_invoice_ap_edit",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const LIST_LEASING_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing`,
  name: "account-payables.leasing",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_leasing",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing`,
      },
    ],
    title_head: "lbl_leasing",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const CREATE_LEASING_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing/create`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing/create`,
  name: "account-payables.leasing.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_leasing_list",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing`,
      },
      {
        name: "lbl_leasing_create",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing/create`,
      },
    ],
    title_head: "lbl_leasing_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const EDIT_LEASING_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing/:id/edit`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing/:id/edit`,
  name: "account-payables.leasing.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_leasing_list",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing`,
      },
      {
        name: "lbl_leasing_edit",
        href: "",
      },
    ],
    title_head: "lbl_leasing_edit",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const DETAIL_LEASING_ACCOUNT_PAYABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing/:id`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing/:id`,
  name: "account-payables.leasing.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_leasing_list",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/leasing`,
      },
      {
        name: "lbl_detail",
        href: "",
      },
    ],
    title_head: "lbl_detail",
    mode: Mode.DETAIL,
    need_auth: true,
  },
};

export const LIST_AP_PAYMENTS_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/list`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/list`,
  name: "lbl_ap_payment_list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_ap_payment",
        icon: "pay-circle",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/list`,
      },
    ],
    title_head: "lbl_ap_payment_list",
    mode: Mode.VIEW,
  },
};

export const CREATE_AP_PAYMENTS_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/create`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/create`,
  name: "accountpayables.appayments.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_ap_payment_list",
        icon: "pay-circle",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/list`,
      },
      {
        name: "lbl_ap_payment_create",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/create`,
      },
    ],
    title_head: "lbl_ap_payment_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const EDIT_AP_PAYMENTS_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/edit/:id`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/edit/:id`,
  name: "accountpayables.appayments.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_ap_payment_list",
        icon: "pay-circle",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/list`,
      },
      {
        name: "lbl_ap_payment_edit",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/edit/:id`,
      },
    ],
    title_head: "lbl_ap_payment_edit",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const VIEW_AP_PAYMENTS_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/view/:id`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/view/:id`,
  name: "accountpayables.appayments.view",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_ap_payment_list",
        icon: "pay-circle",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/list`,
      },
      {
        name: "lbl_ap_payment_view",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/ap-payments/view/:id`,
      },
    ],
    title_head: "lbl_ap_payment_view",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const PAYABLE_MUTATION_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/payable-mutation`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/payable-mutation`,
  name: "accountpayables.reports.payable-mutation",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_payable_mutation_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/payable-mutation`,
      },
    ],
    title_head: "lbl_payable_mutation_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};
export const PPHSUPPLIER_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/pph-supplier`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/pph-supplier`,
  name: "accountpayables.reports.pphsupplier",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_pph_supplier_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/pph-supplier`,
      },
    ],
    title_head: "lbl_pph_supplier_report",
    need_auth: true,
  },
};
export const AMORTIZE_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/insurance-amortize`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/insurance-amortize`,
  name: "accountpayables.reports.amortize",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_insurance_amortize_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/insurance-amortize`,
      },
    ],
    title_head: "lbl_insurance_amortize_report",
    need_auth: true,
  },
};
export const LEASINGTRACKING_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing-tracking`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing-tracking`,
  name: "accountpayables.reports.leasingtracking",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_leasing_tracking_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing-tracking`,
      },
    ],
    title_head: "lbl_leasing_tracking_report",
    need_auth: true,
  },
};

export const REPORT_KARTU_HUTANG_LEASING_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/kartu-hutang-leasing`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/kartu-hutang-leasing`,
  name: "accountpayables.reports.kartuhutangleasing",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_report_kartu_hutang_leasing",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/kartu-hutang-leasing`,
      },
    ],
    title_head: "lbl_report_kartu_hutang_leasing",
    need_auth: true,
  },
};

export const AGING_AP_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/aging-report`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/aging-report`,
  name: "accountpayables.reports.aging-report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_aging_ap_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/aging-report`,
      },
    ],
    title_head: "lbl_aging_ap_report",
    need_auth: true,
  },
};

export const AP_PAYMENT_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/ap-payment`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/ap-payment`,
  name: "accountpayables.reports.ap-payment",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_ap_payment_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/ap-payment`,
      },
    ],
    title_head: "lbl_ap_payment_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const LEASING_PAYMENT_PLAN_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing/payment-plan`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing/payment-plan`,
  name: "accountpayables.reports.leasing.payment-plan",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_leasing_payment_plan_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing/payment-plan`,
      },
    ],
    title_head: "lbl_leasing_payment_plan_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};
export const LEASING_DEBT_RECAP_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing/leasing-debt-recap`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing/leasing-debt-recap`,
  name: "accountpayables.reports.leasing-debt-recap",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_leasing_debt_recap_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/leasing/leasing-debt-recap`,
      },
    ],
    title_head: "lbl_leasing_debt_recap_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const AMORTIZATION_TRANSACTION_REPORT_ROUTE = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/amortization-transaction`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/amortization-transaction`,
  name: "accountpayables.reports.amortization-transaction",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_amortization_transaction_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/amortization-transaction`,
      },
    ],
    title_head: "lbl_amortization_transaction_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const AMORTIZATION_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/amortization`,
  url: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/amortization`,
  name: "accountpayables.reports.amortization",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_amortization_report",
        href: `${ACCOUNT_PAYABLES_ROUTE.url}/reports/amortization`,
      },
    ],
    title_head: "lbl_amortization_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};
