import {
  AddressDataDto,
  AddressDataLineDto,
  BankDataDto,
  ContactDataCreateDto,
  CustomerDataDto,
  EmployeeDataDto,
  SupplierDataDto,
} from "@/models/interface/contact-data";

export function buildContactCreateSupplierDataDto(): SupplierDataDto {
  return {
    supplierType: "",
  };
}

export function buildContactCreateCustomerDataDto(): CustomerDataDto {
  return {
    customerType: "",
    creditLimit: 0,
    discount: 0,
    taxInvoiceCode: "",
  };
}

export function buildContactCreateEmployeeDataDto(): EmployeeDataDto {
  return {
    nik: "",
    gender: "",
    position: "",
    dateOfBirth: "",
    startDate: "",
  };
}

export function buildContactCreateBankDataDto(): BankDataDto {
  return {
    active: "",
    activeDate: "",
    bankAccName: "",
    bankAccNumber: "",
    bankAddress: "",
    bankBranch: "",
    bankName: "",
  };
}

export function buildContactCreateAddressDataLineDto(): AddressDataLineDto {
  return {
    picName: "",
    telephoneNo: "",
    phoneNo: "",
    email: "",
    position: "",
  };
}

export function buildContactCreateAddressDataDto(): AddressDataDto {
  return {
    country: "",
    cityDistrict: "",
    postalCode: "",
    address: "",
    location: "",
    picName: "",
    picContactNumber: "",
    idCardNumber: "",
    taxType: "",
    shipTo: false,
    billTo: false,
    primaryShipTo: false,
    primaryBillTo: false,
    position: "",
    picContactNumberMobile: "",
    email: "",
    addressDataLines: [],
  };
}

export function buildContactCreateDto(): ContactDataCreateDto {
  return {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    mobileNumber: "",
    fax: "",
    taxRegisNumber: "",
    taxRegisName: "",
    active: true,
    supplier: false,
    customer: false,
    employee: false,
    receivableAccountId: "",
    revenueAccountId: "",
    payableAccountId: "",
    prepaymentAccountId: "",
    top: null,
    addressDataList: [],
    bankDataList: [],
    employeeData: null,
    customerData: null,
    supplierData: null,
    employeeNumber: "",
    supplierNumber: "",
    customerNumber: "",
    activeDate: "",
    company: "",
    copyToCompanyId: null,
    appIds: null,
    buyerType: "",
    countryId: "",
    idtku: "",
    buyerId: ""
  };
}
