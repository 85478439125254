import { EnumBooleanInString } from "@/models/enums/global.enum";
import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import { LabelInValue } from "@/types";
import {
  PreferenceAccountArApState,
  PreferenceAccountBalanceSheetState,
  PreferenceAccountInventoryState,
  PreferenceAccountSalesPurchaseState,
  PreferenceAccountState,
  PreferenceAccountTaxState,
  PreferenceArState,
  PreferenceBasicFeatureState,
  PreferenceCurrencyFormatState,
  PreferenceFeatureState,
  PreferenceField,
  PreferenceInventoryState,
  PreferenceOptionType,
  PreferencePurchaseState,
  PreferenceSalesState,
  PreferenceTaxRateState,
  PreferenceTruckState,
} from "../models/Preference.model";

export const initOptionTypeBoolean =
  (): PreferenceOptionType<EnumBooleanInString> => ({
    id: "",
    value: "" as EnumBooleanInString,
    key: "" as EnumPreferenceKey,
  });

export const initOptionTypeValue = (): PreferenceOptionType<LabelInValue> => ({
  id: "",
  value: { key: "", label: "" },
  key: "" as EnumPreferenceKey,
});

export const initPreferenceBasicFeature = (): PreferenceBasicFeatureState => ({
  approval: initOptionTypeBoolean(),
  assetManagement: initOptionTypeBoolean(),
  multiBranch: initOptionTypeBoolean(),
  multiCurrency: initOptionTypeBoolean(),
  taxInclusive: initOptionTypeBoolean(),
});

export const initPreferenceCurrencyFormat =
  (): PreferenceCurrencyFormatState => ({
    baseCurrency: initOptionTypeValue(),
    decimalFormat: initOptionTypeValue(),
    decimalPoint: initOptionTypeBoolean(),
    discountCalculation: initOptionTypeValue(),
  });

export const initPreferenceTaxRateState = (): PreferenceTaxRateState => ({
  salesTax: initOptionTypeValue(),
  purchaseTax: initOptionTypeValue(),
});

export const initPreferenceArState = (): PreferenceArState => ({
  invoiceAssignee: initOptionTypeValue(),
});

export const initPreferencePurchaseState = (): PreferencePurchaseState => ({
  invoiceTerms: initOptionTypeValue(),
  purchaseAndQuotation: initOptionTypeBoolean(),
  purchaseOrderReturn: initOptionTypeBoolean(),
});

export const initPreferenceSalesState = (): PreferenceSalesState => ({
  invoiceTerms: initOptionTypeValue(),
  salesAndQuotation: initOptionTypeBoolean(),
  salesOrderReturn: initOptionTypeBoolean(),
  invoiceJoin: initOptionTypeBoolean(),
  disableSellQuantity: initOptionTypeBoolean(),
  salesCommision: initOptionTypeValue(),
});

export const initPreferenceFeatureState = (): PreferenceFeatureState => ({
  basicFeature: initPreferenceBasicFeature(),
  currencyFormat: initPreferenceCurrencyFormat(),
  taxRate: initPreferenceTaxRateState(),
  accountReceivable: initPreferenceArState(),
});

export const initPreferenceInventoryState = (): PreferenceInventoryState => ({
  costMethod: initOptionTypeValue(),
  showStock: initOptionTypeBoolean(),
  multiWarehouse: initOptionTypeBoolean(),
  multiUom: initOptionTypeBoolean(),
  sNProduct: initOptionTypeBoolean(),
});

export const initPreferenceAccountBalanceSheetState =
  (): PreferenceAccountBalanceSheetState => ({
    currentMonthProfit: initOptionTypeValue(),
    currentYearProfit: initOptionTypeValue(),
    exchangeRateVarianceGain: initOptionTypeValue(),
    exchangeRateVarianceLoss: initOptionTypeValue(),
    openingBalance: initOptionTypeValue(),
    retainedEarning: initOptionTypeValue(),
  });

export const initPreferenceAccountSalesPurchaseState =
  (): PreferenceAccountSalesPurchaseState => ({
    salesRevenue: initOptionTypeValue(),
    salesDiscount: initOptionTypeValue(),
    salesReturn: initOptionTypeValue(),
    salesDelivery: initOptionTypeValue(),
    unearnedRevenue: initOptionTypeValue(),
    unbilledSales: initOptionTypeValue(),
    unbilledReceivables: initOptionTypeValue(),
    salesBadDebt: initOptionTypeValue(),
    purchaseBadDebt: initOptionTypeValue(),
    purchaseCogs: initOptionTypeValue(),
    purchaseShipping: initOptionTypeValue(),
    purchaseReturn: initOptionTypeValue(),
    unbilledPayable: initOptionTypeValue(),
    roundingAccountGain: initOptionTypeValue(),
    roundingAccountLoss: initOptionTypeValue(),
    purchasePriceVariance: initOptionTypeValue(),
    purchaseDiscount: initOptionTypeValue(),
    prepaymentDealer: initOptionTypeValue(),
  });

export const initPreferenceAccountArApState =
  (): PreferenceAccountArApState => ({
    accountReceivable: initOptionTypeValue(),
    accountPayable: initOptionTypeValue(),
    invoicePriceVariance: initOptionTypeValue(),
    prepaymentAr: initOptionTypeValue(),
    prepaymentAp: initOptionTypeValue(),
  });

export const initPreferenceAccountInventoryState =
  (): PreferenceAccountInventoryState => ({
    inventory: initOptionTypeValue(),
    nonStock: initOptionTypeValue(),
    waste: initOptionTypeValue(),
    stockAdjustment: initOptionTypeValue(),
    stockVariance: initOptionTypeValue(),
    inventoryInTransit: initOptionTypeValue(),
  });

export const initPreferenceAccountTaxState = (): PreferenceAccountTaxState => ({
  vatIn: initOptionTypeValue(),
  vatOut: initOptionTypeValue(),
});

export const initPreferenceAccountState = (): PreferenceAccountState => ({
  balanceSheet: initPreferenceAccountBalanceSheetState(),
  salesPurchase: initPreferenceAccountSalesPurchaseState(),
  arAp: initPreferenceAccountArApState(),
  inventory: initPreferenceAccountInventoryState(),
  tax: initPreferenceAccountTaxState(),
});

export const initPreferenceTruckState = (): PreferenceTruckState => ({
  assignee: initOptionTypeValue(),
  downPayment: initOptionTypeValue(),
  revenue: initOptionTypeValue(),
  tax: initOptionTypeValue(),
  receivableAccount: initOptionTypeValue(),
  defaultBranch: initOptionTypeValue(),
});

export const initPreference = (): PreferenceField => ({
  feature: initPreferenceFeatureState(),
  sales: initPreferenceSalesState(),
  purchase: initPreferencePurchaseState(),
  inventory: initPreferenceInventoryState(),
  account: initPreferenceAccountState(),
  truck: initPreferenceTruckState(),
});
