import { DEFAULT_PAGE_SIZE } from "../constant/global.constant";
import { RequestQueryParamsModel } from "../interface/http.interface";

export class RequestQueryParams implements RequestQueryParamsModel {
  search: string;
  sorts: string | undefined;
  page: number;
  limit: number;
  [props: string]: any;

  constructor(search = "", page = 0, sorts = "", limit = DEFAULT_PAGE_SIZE) {
    this.search = search;
    this.sorts = sorts;
    this.page = page;
    this.limit = limit;
  }
}
