import { WorkOrderDraftResponseDto } from "@/models/interface/work-order";

export function buildWorkOrderDraftResponseDto(): WorkOrderDraftResponseDto {
  const dto: WorkOrderDraftResponseDto = {
    branchWarehouse: "",
    branchWarehouseId: "",
    customerAddress: "",
    customerId: "",
    customerName: "",
    description: "",
    documentNumber: "",
    mechanicId: "",
    mechanicName: "",
    picName: "",
    scheduleOrder: "",
    spareParts: [],
    units: [],
    workOrderDate: "",
    workOrderId: "",
    sparePartRequestId: null,
    sparePartRequestNumber: null,
  };
  return dto;
}
