import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.CASH_MANAGEMENT_ROUTE.path,
  name: config.CASH_MANAGEMENT_ROUTE.name,
  meta: config.CASH_MANAGEMENT_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.BANK_TRANSTER_CREATE_ROUTE.path,
      name: config.BANK_TRANSTER_CREATE_ROUTE.name,
      meta: config.BANK_TRANSTER_CREATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./bank-transfer/CreateBankTransfer.vue"
        ),
    },
    {
      path: config.BANK_TRANSTER_LIST_ROUTE.path,
      name: config.BANK_TRANSTER_LIST_ROUTE.name,
      meta: config.BANK_TRANSTER_LIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./bank-transfer/ListBankTransfer.vue"
        ),
    },
    {
      path: config.BANK_TRANSTER_EDIT_ROUTE.path,
      name: config.BANK_TRANSTER_EDIT_ROUTE.name,
      meta: config.BANK_TRANSTER_EDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./bank-transfer/CreateBankTransfer.vue"
        ),
    },
    {
      path: config.BANK_CASH_CREATE_ROUTE.path,
      name: config.BANK_CASH_CREATE_ROUTE.name,
      meta: config.BANK_CASH_CREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./cash-in-out/CashInOutForm.vue"),
    },
    {
      path: config.BANK_CASH_LIST_ROUTE.path,
      name: config.BANK_CASH_LIST_ROUTE.name,
      meta: config.BANK_CASH_LIST_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./cash-in-out/ListCashInOut.vue"),
    },
    {
      path: config.BANK_CASH_EDIT_ROUTE.path,
      name: config.BANK_CASH_EDIT_ROUTE.name,
      meta: config.BANK_CASH_EDIT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./cash-in-out/CashInOutForm.vue"),
    },
    {
      path: config.BANK_STATEMENT_REPORT.path,
      name: config.BANK_STATEMENT_REPORT.name,
      meta: config.BANK_STATEMENT_REPORT.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/BankStatementV2.vue"),
    },
    {
      path: config.CASH_MANAGEMENT_CLOSE_PERIOD_ROUTE.path,
      name: config.CASH_MANAGEMENT_CLOSE_PERIOD_ROUTE.name,
      meta: config.CASH_MANAGEMENT_CLOSE_PERIOD_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./open-close-period/ClosePeriod.vue"
        ),
    },
    {
      path: config.SETTLEMENT_ROUTE.path,
      name: config.SETTLEMENT_ROUTE.name,
      meta: config.SETTLEMENT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./settlement/ListSettlement.vue"),
    },
    {
      path: config.SETTLEMENT_CREATE_ROUTE.path,
      name: config.SETTLEMENT_CREATE_ROUTE.name,
      meta: config.SETTLEMENT_CREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./settlement/CreateSettlement.vue"),
    },
    {
      path: config.SETTLEMENT_EDIT_ROUTE.path,
      name: config.SETTLEMENT_EDIT_ROUTE.name,
      meta: config.SETTLEMENT_EDIT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./settlement/CreateSettlement.vue"),
    },
  ],
};

export default router;
