import { DrawerDataView } from "@/models/class/DrawerDataView.class";

type State = {
  visible: boolean;
  content: string;
  title: string;
  state?: "info" | "success" | "error" | "warning";
  metadata?: any | null;
};

const state: State = {
  visible: false,
  title: "",
  content: "",
  state: "info",
  metadata: null,
};

const mutations = {
  ["toggle"]: (vuex: State, payload: DrawerDataView): void => {
    vuex.visible = payload.visible;
    vuex.title = payload.title;
    vuex.content = payload.content;
    vuex.state = payload.state;
    vuex.metadata = payload.metadata;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
