import { TruckingSettlementDetailResponseDto } from "@/models/interface/trucking-settlement";

export function buildTruckingSettlementDetail(): TruckingSettlementDetailResponseDto {
  return {
    createdBy: "",
    createdDate: "",
    details: [],
    id: "",
    modifiedBy: "",
    modifiedDate: "",
    settlementDate: "",
    status: "Approved",
    totalActualUjo: 0,
    totalNominalUjo: 0,
    totalDitagihkan: 0,
    ujoNumber: "",
    journalNumber: "",
    rejectReason: "",
    journalId: "",
    customer: "",
    sales: "",
    shipmentId: "",
    unitCode: "",
  };
}
