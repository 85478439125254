import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";
import { InvoiceApPrepayment } from "@/models/interface/InvoiceApPrepayment.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  CreateInvoicePrepayment,
  InvoicePrepayment,
  ResponseCreateInvoicePrepayment,
} from "@/models/interface/invoicePrepayment";

export class InvoicePrepaymentServices extends HttpClient {
  /**
   * @deprecated
   * use new service {@linkcode ArPrepaymentService.getList}
   */
  getListInvoicePrepayment = (
    params: RequestQueryParamsModel
  ): Promise<InvoicePrepayment> => {
    return this.get<InvoicePrepayment>(Api.InvoicePrepayment, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListInvoicePrepaymentLeasing(
    assetId: string,
    search?: string
  ): Promise<InvoiceApPrepayment[]> {
    return this.get<InvoiceApPrepayment[]>(
      Api.InvoicePrepaymentLeasing + "/" + assetId,
      { params: { search } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * use {@linkcode ArPrepaymentService.create}
   * can delete
   */
  createInvoicePrepayment = (
    payload: CreateInvoicePrepayment
  ): Promise<ResponseCreateInvoicePrepayment> => {
    return this.post<ResponseCreateInvoicePrepayment>(
      Api.InvoicePrepayment,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode ArPrepaymentService.cancel}
   */
  cancelInvoicePrepayment = (
    id: string
  ): Promise<ResponseCreateInvoicePrepayment> => {
    return this.post<any, ResponseCreateInvoicePrepayment>(
      Api.CancelInvoicePrepayment + "/" + id
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode ArPrepaymentService.detail}
   */
  detailInvoicePrepayment = (
    id: string
  ): Promise<ResponseCreateInvoicePrepayment> => {
    return this.get<ResponseCreateInvoicePrepayment>(
      Api.InvoicePrepayment + "/" + id
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode ArPrepaymentService.getTaxInvoiceNumber}
   */
  getInvoicePrepaymentCode = (
    id: string,
    params: RequestQueryParamsModel
  ): Promise<any> => {
    return this.get<any>(Api.InvoicePrepaymentCode + "/" + id, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode ArPrepaymentService.printInvoice}
   */
  printInvoicePrepayment = (id: string): Promise<Blob> => {
    return this.get<Blob>(Api.InvoicePrepayment + `/print/${id}`, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}
export const invoicePrepayment = new InvoicePrepaymentServices();
