import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { StringUtils } from "@/utils/StringUtils";
import Decimal from "decimal.js-light";
import useCalculator from "./useCalculator";

const useAccountingCalculation = () => {
  const MAX_DECIMAL_POINT_PRECISION = 14;

  const calculateGrossValue = ({
    price,
    qty,
  }: {
    price: number;
    qty: number;
  }): Decimal => {
    return new Decimal(price).times(qty);
  };

  const calculateGrossAfterDiscount = ({
    grossValue,
    discountValue,
  }: {
    grossValue: Decimal;
    discountValue: Decimal;
  }): Decimal => {
    return grossValue.minus(discountValue);
  };

  const calculateGrossAfterDiscountBeforeTax = ({
    grossAfterDiscount,
    purchaseInclusiveRate = 0,
    taxType,
  }: {
    taxType: TAX_CALCULATION;
    grossAfterDiscount: Decimal;
    purchaseInclusiveRate: number | undefined;
  }): Decimal => {
    const { toRoundHalfEven } = useCalculator();

    if (StringUtils.compare(taxType, TAX_CALCULATION.INCLUSIVE)) {
      const divider = new Decimal(purchaseInclusiveRate).div(100).plus(1);
      const result = grossAfterDiscount.div(divider);
      return toRoundHalfEven(result, MAX_DECIMAL_POINT_PRECISION);
    }

    return grossAfterDiscount;
  };

  const calculateTaxBase = ({
    grossAfterDiscountBeforeTax,
    variable = 1,
    taxType = "",
  }: {
    grossAfterDiscountBeforeTax: Decimal;
    variable?: number;
    taxType?: string;
  }): Decimal => {
    const { toRoundHalfEven } = useCalculator();
    const result = grossAfterDiscountBeforeTax.times(variable);
    if (StringUtils.compare(taxType, TAX_CALCULATION.INCLUSIVE)) {
      return toRoundHalfEven(result, MAX_DECIMAL_POINT_PRECISION);
    }

    return toRoundHalfEven(result);
  };

  const calculateTaxValue = ({
    taxBase,
    taxRate,
    taxType = "",
  }: {
    taxBase: Decimal;
    taxRate: number;
    taxType?: string;
  }): Decimal => {
    const { toRoundHalfEven } = useCalculator();
    const rate = new Decimal(taxRate).div(100);
    const result = taxBase.times(rate);
    if (StringUtils.compare(taxType, TAX_CALCULATION.INCLUSIVE)) {
      return toRoundHalfEven(result, MAX_DECIMAL_POINT_PRECISION);
    }
    return toRoundHalfEven(result);
  };

  const calculateTotal = ({
    grossAfterDiscountBeforeTax,
    taxValue,
    incomeTaxAmount = new Decimal(0),
  }: {
    grossAfterDiscountBeforeTax: Decimal;
    taxValue: Decimal;
    incomeTaxAmount?: Decimal;
  }): Decimal => {
    const { toRoundHalfEven } = useCalculator();
    const result = grossAfterDiscountBeforeTax
      .plus(taxValue)
      .minus(incomeTaxAmount);
    return toRoundHalfEven(result);
  };

  const calculateLineRatio = ({
    lineValue,
    total,
  }: {
    lineValue: Decimal;
    total: Decimal;
  }): Decimal => {
    if (total.toNumber() === 0) return new Decimal(0);
    return lineValue
      .div(total)
      .todp(MAX_DECIMAL_POINT_PRECISION, Decimal.ROUND_FLOOR);
  };

  const calculateDiscountValueFromRatio = ({
    discountValue,
    ratio,
  }: {
    discountValue: number;
    ratio: Decimal;
  }): Decimal => {
    return new Decimal(discountValue).times(ratio);
  };

  const calculateTotalWithAdditionalValue = ({
    subTotal,
    additionalValue,
  }: {
    subTotal: Decimal;
    additionalValue: Decimal;
  }): Decimal => {
    const { toRoundHalfEven } = useCalculator();
    const result = subTotal.plus(additionalValue);
    return toRoundHalfEven(result, MAX_DECIMAL_POINT_PRECISION);
  };

  const calculateIncomeTax = ({
    gross,
    incomeTaxRate,
  }: {
    gross: Decimal;
    incomeTaxRate: number;
  }): Decimal => {
    return gross.times(incomeTaxRate).div(100);
  };

  return {
    calculateGrossValue,
    calculateGrossAfterDiscount,
    calculateGrossAfterDiscountBeforeTax,
    calculateTaxBase,
    calculateTaxValue,
    calculateTotal,
    calculateLineRatio,
    calculateDiscountValueFromRatio,
    calculateTotalWithAdditionalValue,
    calculateIncomeTax,
  };
};

export default useAccountingCalculation;
