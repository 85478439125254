import { SearchBuilder } from "@/builder";
import { toTitlecase, trimSpaceToUnderscore } from "@/helpers/common";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DATE_FORMAT_YYYY_MM_DD } from "@/models/constants/date.constant";
import {
  LeasingPaymentTypeEnum,
  LeasingSourceEnum,
  LeasingStateEnum,
} from "@/models/enums/Leasing.enum";
import {
  ParamDownloadLeasingCard,
  RequestParamsDownloadLeasingCard,
} from "@/models/interface/leasing.interface";
import { leasingServices } from "@/services/leasing.service";
import store from "@/store";
import {
  initForm,
  initRowAsset,
  initRowPeriod,
} from "@/store/account-payable/leasing/resource";
import {
  FormValue,
  RowAsset,
  RowPeriod,
} from "@/store/account-payable/leasing/types";
import { EnumBooleanValue } from "@/types/Boolean";
import { CashInOutCreateResponseDto } from "@interface/cash-in-out";
import {
  BaseResponse,
  RequestQueryParamsModel,
} from "@interface/http.interface";
import {
  LeasingAssetCreateRequestDto,
  LeasingAssetResponseDto,
  LeasingCreateRequestDto,
  LeasingDetailResponseDto,
  LeasingPeriodCreateRequestDto,
  LeasingPeriodResponseDto,
  LeasingUpdatePolicyNumberDto,
  ListLeasingHeaderDto,
  PaymentRealizationCreateDto,
} from "@interface/leasing";
import Decimal from "decimal.js-light";
import moment, { Moment } from "moment";
import { useFindMasterType } from "./master-type";
import useDate from "./useDate";

type CalcBalanceParam = {
  paymentCost: number;
  totalInstallment: number;
};
type CalcDecreBalanceParam = {
  paymentCost: number;
  prevBalance: number;
};

export interface FormFilter {
  branchId: string;
  branchName: string;
  dealerName: string;
  dealerId: string;
  lessorName: string;
  lessorId: string;
  leasingContractDate: Array<Moment>;
  leasingNumber: string;
  leasingNumberId: string;
  paidOff: EnumBooleanValue;
  leasingContractNumber: string;
  status: string;
  reset: () => void;
}

const useLeasing = () => {
  const create = (payload: LeasingCreateRequestDto): Promise<BaseResponse> => {
    return leasingServices.create(payload);
  };

  const findById = (leasingId: string): Promise<LeasingDetailResponseDto> => {
    return leasingServices.getDetail(leasingId);
  };

  const download = (
    params: RequestParamsDownloadLeasingCard
  ): Promise<Blob> => {
    return leasingServices.downloadLeasingCard(params);
  };

  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListLeasingHeaderDto>> => {
    return leasingServices.getList(params);
  };

  /**
   * submit existing document or create new document
   * to `SUBMITTED` document
   * @param leasingId leasing id if exist, then use secureid, else use dash ( - )
   */
  const submit = (
    leasingId: string | "-",
    body: LeasingCreateRequestDto
  ): Promise<LeasingDetailResponseDto> => {
    return leasingServices.submit(leasingId, body);
  };

  const cancelLeasing = (leasingId: string): Promise<BaseResponse> => {
    return leasingServices.cancelLeasing(leasingId);
  };

  const update = (
    leasingId: string,
    body: LeasingCreateRequestDto
  ): Promise<BaseResponse> => {
    return leasingServices.update(leasingId, body);
  };

  const findAllPaymentType = () => {
    return useFindMasterType("LEASING_PAYMENT_TYPE");
  };

  const cancelPayment = (id: string) => {
    return leasingServices.cancelPayment(id);
  };

  const settlePayment = (
    periodId: string,
    payload: PaymentRealizationCreateDto
  ): Promise<CashInOutCreateResponseDto> => {
    return leasingServices.createLeasingPayment(periodId, payload);
  };

  const savePolicyNo = (
    leasingId: string,
    payload: LeasingUpdatePolicyNumberDto
  ): Promise<LeasingDetailResponseDto> => {
    return leasingServices.savePolicyNoLeasing(leasingId, payload);
  };

  /**
   * @description count balance on 1st period
   */
  const calcPaymentFirstBalance = ({
    totalInstallment,
    paymentCost,
  }: CalcBalanceParam): number => {
    const val: Decimal = new Decimal(totalInstallment || 0);
    return val.minus(paymentCost || 0).toNumber();
  };

  /**
   * @description count balance after 1st period
   */
  const calcPaymentBalance = (field: CalcDecreBalanceParam): number => {
    const { paymentCost, prevBalance } = field;
    return new Decimal(prevBalance || 0).minus(paymentCost || 0).toNumber();
  };

  const calcCostPriceRatio = (payload: {
    taxTotalAmount: number;
    costPriceLeasing: number;
    costPriceUnit: number;
  }): number => {
    const costPriceAftTax = new Decimal(payload.costPriceLeasing || 0).plus(
      payload.taxTotalAmount || 0
    );
    let costPriceRatio = new Decimal(0);
    if (costPriceAftTax.greaterThan(0)) {
      costPriceRatio = new Decimal(payload.costPriceUnit || 0).dividedBy(
        costPriceAftTax
      );
    }
    return costPriceRatio.toNumber();
  };

  const calcUnitNettFinance = (payload: {
    taxTotalAmount: number;
    costPriceLeasing: number;
    nettFinanceLeasing: number;
    costPriceUnit: number;
  }): number => {
    const costPriceRatio = calcCostPriceRatio({
      costPriceLeasing: payload.costPriceLeasing,
      costPriceUnit: payload.costPriceUnit,
      taxTotalAmount: payload.taxTotalAmount,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.nettFinanceLeasing || 0)
      .toNumber();
    return nettFinance;
  };

  const calcUnitInterest = (payload: {
    taxTotalAmount: number;
    costPriceLeasing: number;
    interestLeasing: number;
    costPriceUnit: number;
  }): number => {
    const costPriceRatio = calcCostPriceRatio({
      costPriceLeasing: payload.costPriceLeasing,
      costPriceUnit: payload.costPriceUnit,
      taxTotalAmount: payload.taxTotalAmount,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.interestLeasing || 0)
      .toNumber();
    return nettFinance;
  };

  const calcUnitProvision = (payload: {
    taxTotalAmount: number;
    costPriceLeasing: number;
    provisionLeasing: number;
    costPriceUnit: number;
  }): number => {
    const costPriceRatio = calcCostPriceRatio({
      costPriceLeasing: payload.costPriceLeasing,
      costPriceUnit: payload.costPriceUnit,
      taxTotalAmount: payload.taxTotalAmount,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.provisionLeasing || 0)
      .toNumber();
    return nettFinance;
  };

  const calcUnitInsurance = (payload: {
    taxTotalAmount: number;
    costPriceLeasing: number;
    costPriceUnit: number;
    premiumLeasing: number;
  }): number => {
    const costPriceRatio = calcCostPriceRatio({
      costPriceLeasing: payload.costPriceLeasing,
      costPriceUnit: payload.costPriceUnit,
      taxTotalAmount: payload.taxTotalAmount,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.premiumLeasing || 0)
      .toNumber();
    return nettFinance;
  };

  const calcUnitResidue = (payload: {
    taxTotalAmount: number;
    costPriceLeasing: number;
    costPriceUnit: number;
    residueLeasing: number;
  }): number => {
    const costPriceRatio = calcCostPriceRatio({
      costPriceLeasing: payload.costPriceLeasing,
      costPriceUnit: payload.costPriceUnit,
      taxTotalAmount: payload.taxTotalAmount,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.residueLeasing || 0)
      .toNumber();
    return nettFinance;
  };

  const calcLeasingResidue = (payload: {
    costPrice: number;
    taxTotalAmount: number;
    nettFinance: number;
  }): number => {
    const residue = new Decimal(payload.costPrice || 0)
      .plus(payload.taxTotalAmount || 0)
      .minus(payload.nettFinance || 0);
    return residue.toNumber();
  };

  const calcTotalInstallment = (payload: {
    nettFinance: number;
    interest: number;
  }): number => {
    const totalInstallment = new Decimal(payload.nettFinance || 0).plus(
      payload.interest
    );
    return totalInstallment.toNumber();
  };

  /**
   * @description
   * calc payment cost per payment
   */
  const calcPaymentCost = (payload: {
    principalCost: number;
    interestCost: number;
  }): number => {
    const paymentCost = new Decimal(payload.principalCost || 0).plus(
      payload.interestCost || 0
    );

    return paymentCost.toNumber();
  };

  const initPaymentRealizationCreateDto = (): PaymentRealizationCreateDto => ({
    paymentNumber: "",
    paymentRealizationDate: "",
    paymentType: "" as LeasingPaymentTypeEnum,
  });

  const mapPeriodLineToSettlePaymentDto = (
    row: RowPeriod
  ): PaymentRealizationCreateDto => {
    const req: PaymentRealizationCreateDto = initPaymentRealizationCreateDto();

    req.paymentNumber = row.chequeNumber;
    req.paymentRealizationDate = row.paymentRealizationDate
      ? row.paymentRealizationDate.format()
      : "";
    req.paymentType = trimSpaceToUnderscore(
      row.paymentType
    ) as LeasingPaymentTypeEnum;

    return req;
  };

  const calcPaymentAmortize = (payload: {
    insurancePremium: number;
    leasingPeriod: number;
  }): number => {
    if (payload.leasingPeriod === 0) {
      return 0;
    }

    return new Decimal(payload.insurancePremium || 0)
      .dividedBy(payload.leasingPeriod)
      .toNumber();
  };

  const initCreateDto = (): LeasingCreateRequestDto => ({
    assetList: [],
    billingAddressLessor: "",
    branchId: "",
    companyBankId: "",
    costPrice: 0,
    currencyId: "",
    dealerId: "",
    deletedAssetLineIds: [],
    deletedPeriodLineIds: [],
    id: "",
    insuranceId: "",
    insurancePeriod: 0,
    insurancePremium: 0,
    interest: 0,
    interestCostAccountId: "",
    leasingContractDate: "",
    leasingContractNumber: "",
    leasingPeriod: 0,
    lessorId: "",
    nettFinance: 0,
    notes: "",
    periodList: [],
    principalCostAccountId: "",
    provision: 0,
    rates: 0,
    taxTotalAmount: 0,
    otr: 0,
    source: "",
  });

  const initAssetCreateDto = (): LeasingAssetCreateRequestDto => ({
    dealerId: "",
    assetId: "",
    costPricePerUnit: 0,
    id: "",
    paymentTaxInvoiceDate: "",
    paymentTaxInvoiceNumber: "",
    portion: 0,
    taxInvoiceNumber: "",
    invoiceApLineId: "",
    prepaymentDate: "",
  });

  const initPeriodCreateDto = (): LeasingPeriodCreateRequestDto => ({
    chequeNumber: "",
    id: "",
    interestCost: 0,
    paymentDate: "",
    paymentRealizationDate: "",
    paymentType: "" as LeasingPaymentTypeEnum,
    principalCost: 0,
  });

  const initDetailDto = (): LeasingDetailResponseDto => ({
    assetList: [],
    bankId: "",
    bankName: "",
    billingAddressLessor: "",
    branchId: "",
    branchName: "",
    cancellable: false,
    costPrice: 0,
    currencyId: "",
    currencyName: "",
    dealerId: "",
    dealerName: "",
    insuranceId: "",
    insuranceName: "",
    insurancePeriod: 0,
    insurancePremium: 0,
    interest: 0,
    interestCostAccountCode: "",
    interestCostAccountId: "",
    interestCostAccountName: "",
    journalId: "",
    journalNo: "",
    leasingContractDate: "",
    leasingContractNumber: "",
    leasingNumber: "",
    leasingPeriod: 0,
    lessorId: "",
    lessorName: "",
    nettFinance: 0,
    notes: "",
    paidOff: false,
    paidOffDate: "",
    periodList: [],
    policyNumber: "",
    principalCostAccountCode: "",
    principalCostAccountId: "",
    principalCostAccountName: "",
    provision: 0,
    rates: 0,
    residue: 0,
    status: "" as LeasingStateEnum,
    taxTotalAmount: 0,
    total: 0,
    createdBy: "",
    createdDate: "",
    id: "",
    modifiedBy: "",
    modifiedDate: "",
    invoiceApNumbers: [],
    otr: 0,
    source: "",
  });

  const mapRowAssetToLeasingAssetCreateDto = (
    row: RowAsset
  ): LeasingAssetCreateRequestDto => {
    const asset: LeasingAssetCreateRequestDto = initAssetCreateDto();

    asset.dealerId = row.dealer?.key ?? "";
    asset.assetId = row.assetId;
    asset.costPricePerUnit = row.costPricePerUnit;
    asset.id = row.id;
    asset.paymentTaxInvoiceDate = row.paymentTaxInvoiceDate;
    asset.paymentTaxInvoiceNumber = row.paymentTaxInvoiceNumber;
    asset.portion = row.portion;
    asset.prepaymentDate = row.prepaymentDate;
    asset.taxInvoiceNumber = row.taxInvoiceNumber;
    asset.invoiceApLineId = row.invoiceApLineId;

    return asset;
  };

  const mapRowPeriodToLeasingPeriodCreateDto = (
    row: RowPeriod
  ): LeasingPeriodCreateRequestDto => {
    const period: LeasingPeriodCreateRequestDto = initPeriodCreateDto();
    const {
      chequeNumber,
      id,
      interestCost,
      paymentDate,
      paymentRealizationDate,
      paymentType,
      principalCost,
    } = row;

    period.chequeNumber = chequeNumber;
    period.id = id;
    period.interestCost = interestCost;
    period.paymentDate = paymentDate ? moment(paymentDate).format() : "";
    period.paymentRealizationDate = paymentRealizationDate
      ? moment(paymentRealizationDate).format()
      : "";
    period.paymentType = trimSpaceToUnderscore(
      paymentType
    ) as LeasingPaymentTypeEnum;
    period.principalCost = principalCost;

    return period;
  };

  const mapFormToCreateDto = (form: FormValue): LeasingCreateRequestDto => {
    const req: LeasingCreateRequestDto = initCreateDto();
    const {
      assetList,
      billingAddressLessor,
      companyBank,
      costPrice,
      currency,
      deletedAssetLineIds,
      deletedPeriodLineIds,
      id,
      insurance,
      insurancePeriod,
      insurancePremium,
      interest,
      interestCostAccount,
      leasingContractDate,
      leasingContractNumber,
      leasingPeriod,
      lessor,
      nettFinance,
      notes,
      periodList,
      principalCostAccount,
      provision,
      rates,
      taxTotalAmount,
      branch,
      source,
      otr,
    } = form;

    req.assetList = assetList.map(mapRowAssetToLeasingAssetCreateDto);
    req.billingAddressLessor = billingAddressLessor;
    req.branchId = branch?.key ?? "";
    req.companyBankId = companyBank?.key ?? "";
    req.costPrice = costPrice;
    req.currencyId = currency?.key ?? "";
    req.deletedAssetLineIds = deletedAssetLineIds;
    req.deletedPeriodLineIds = deletedPeriodLineIds;
    req.id = id;
    req.insuranceId = insurance?.key ?? "";
    req.insurancePeriod = insurancePeriod;
    req.insurancePremium = insurancePremium;
    req.interest = interest;
    req.interestCostAccountId = interestCostAccount?.key ?? "";
    req.leasingContractDate = leasingContractDate
      ? moment(leasingContractDate).format()
      : "";
    req.leasingContractNumber = leasingContractNumber;
    req.leasingPeriod = leasingPeriod;
    req.lessorId = lessor?.key ?? "";
    req.nettFinance = nettFinance;
    req.notes = notes;
    req.periodList = periodList.map(mapRowPeriodToLeasingPeriodCreateDto);
    req.principalCostAccountId = principalCostAccount?.key ?? "";
    req.provision = provision;
    req.rates = rates;
    req.taxTotalAmount = taxTotalAmount;
    req.source = source ?? "";
    req.otr = otr;

    return req;
  };

  const mapAssetDetailDtoToAssetRow = (
    asset: LeasingAssetResponseDto
  ): RowAsset => {
    const row: RowAsset = initRowAsset();
    const {
      assetId,
      assetUnitCode,
      brand,
      costPricePerUnit,
      insurancePremium,
      interest,
      nettFinance,
      paymentTaxInvoiceDate,
      paymentTaxInvoiceNumber,
      portion,
      provision,
      prepaymentDate,
      residue,
      serialNumber,
      taxInvoiceNumber,
      type,
      id,
      dealerId,
      dealerName,
      invoiceAPLineId,
      invoiceAPId,
      invoiceApNumber,
    } = asset;

    if (dealerId) {
      row.dealer = {
        key: dealerId,
        label: dealerName,
      };
    }
    row.assetId = assetId;
    row.costPricePerUnit = costPricePerUnit;
    row.id = id;
    row.paymentTaxInvoiceDate = paymentTaxInvoiceDate;
    row.paymentTaxInvoiceNumber = paymentTaxInvoiceNumber;
    row.portion = portion;
    row.prepaymentDate = prepaymentDate;
    row.taxInvoiceNumber = taxInvoiceNumber;
    row.assetUnitCode = assetUnitCode;
    row.brand = brand;
    row.type = type;
    row.provision = provision;
    row.residue = residue;
    row.serialNumber = serialNumber;
    row.nettFinance = nettFinance;
    row.insurancePremium = insurancePremium;
    row.interest = interest;
    row.invoiceApLineId = invoiceAPLineId;
    row.invoiceAPId = invoiceAPId;
    row.invoiceApNumber = invoiceApNumber;
    return row;
  };

  const mapPeriodDetailDtoToPeriodRow = (
    dto: LeasingPeriodResponseDto
  ): RowPeriod => {
    const row: RowPeriod = initRowPeriod();

    row.amortize = dto.amortize;
    row.balance = dto.balance;
    row.cashInOutDocumentNumber = dto.cashInOutDocumentNumber;
    row.cashInOutId = dto.cashInOutId;
    row.chequeNumber = dto.chequeNumber;
    row.id = dto.id;
    row.installment = dto.installment;
    row.interestCost = dto.interestCost;
    row.loading = { cancel: false, settle: false };
    row.paymentCost = dto.paymentCost;
    row.paymentDate = dto.paymentDate ? moment(dto.paymentDate) : null;
    row.paymentRealizationDate = dto.paymentRealizationDate
      ? moment(dto.paymentRealizationDate)
      : null;
    row.paymentType = toTitlecase(dto.paymentType) as LeasingPaymentTypeEnum;
    row.principalCost = dto.principalCost;

    return row;
  };

  const mapDetailToForm = (detail: LeasingDetailResponseDto): FormValue => {
    const form: FormValue = initForm();

    form.assetList = detail.assetList.map(mapAssetDetailDtoToAssetRow);
    if (detail.branchId) {
      form.branch = {
        key: detail.branchId,
        label: detail.branchName,
      };
    }
    form.billingAddressLessor = detail.billingAddressLessor;
    if (detail.bankId) {
      form.companyBank = {
        key: detail.bankId,
        label: detail.bankName,
      };
    }
    form.cancellable = detail.cancellable;
    form.costPrice = detail.costPrice;
    if (detail.currencyId) {
      form.currency = {
        key: detail.currencyId,
        label: detail.currencyName,
      };
    }
    form.id = detail.id;
    if (detail.insuranceId) {
      form.insurance = {
        key: detail.insuranceId,
        label: detail.insuranceName,
      };
    }
    form.insurancePeriod = detail.insurancePeriod;
    form.insurancePremium = detail.insurancePremium;
    form.interest = detail.interest;
    if (detail.interestCostAccountId) {
      form.interestCostAccount = {
        key: detail.interestCostAccountId,
        label: `${detail.interestCostAccountCode} - ${detail.interestCostAccountName}`,
      };
    }
    form.journalId = detail.journalId;
    form.journalNo = detail.journalNo;
    form.leasingContractDate = detail.leasingContractDate
      ? moment(detail.leasingContractDate)
      : null;
    form.leasingContractNumber = detail.leasingContractNumber;
    form.leasingNumber = detail.leasingNumber;
    form.leasingPeriod = detail.leasingPeriod;
    if (detail.lessorId) {
      form.lessor = {
        key: detail.lessorId,
        label: detail.lessorName,
      };
    }
    form.nettFinance = detail.nettFinance;
    form.notes = detail.notes;
    form.paidOff = detail.paidOff;
    form.paidOffDate = detail.paidOffDate ? moment(detail.paidOffDate) : null;
    form.periodList = detail.periodList.map(mapPeriodDetailDtoToPeriodRow);
    form.policyNumber = detail.policyNumber;
    if (detail.principalCostAccountId) {
      form.principalCostAccount = {
        key: detail.principalCostAccountId,
        label: `${detail.principalCostAccountCode} - ${detail.principalCostAccountName}`,
      };
    }
    form.provision = detail.provision;
    form.rates = detail.rates;
    form.residue = detail.residue;
    form.startPaymentDate = moment(detail.periodList[0].paymentDate);
    form.status = detail.status;
    form.taxTotalAmount = detail.taxTotalAmount;
    form.total = detail.total;
    if (detail.source) {
      form.source = detail.source as LeasingSourceEnum;
    }
    form.otr = detail.otr;

    if (Array.isArray(detail.invoiceApNumbers)) {
      form.invoices = detail.invoiceApNumbers.map(doc => {
        const [invoiceNumber, invoiceId] = doc.split(";");
        return {
          label: invoiceNumber,
          key: invoiceId,
        };
      });
    }

    return form;
  };

  const filterBy = (form: Partial<FormFilter>): string => {
    const { toStartDay, toEndDay } = useDate();
    const builder = new SearchBuilder();
    const query: Array<string> = [];
    const {
      branchId,
      dealerId,
      lessorId,
      leasingContractDate,
      leasingNumberId,
      leasingNumber,
      paidOff,
      leasingContractNumber,
      status,
    } = form;

    if (paidOff) {
      const value: boolean = paidOff === "Y";
      query.push(builder.push(["paidOff", value.toString()]).build());
    }

    if (branchId) {
      query.push(builder.push(["branch.secureId", branchId]).build());
    }

    if (dealerId) {
      query.push(builder.push(["dealer.secureId", dealerId]).build());
    }

    if (lessorId) {
      query.push(builder.push(["lessor.secureId", lessorId]).build());
    }

    if (status) {
      query.push(builder.push(["status", status], { like: "both" }).build());
    }

    if (leasingNumberId) {
      query.push(builder.push(["secureId", leasingNumberId]).build());
    }

    if (leasingNumber) {
      query.push(
        builder.push(["leasingNumber", leasingNumber], { like: "both" }).build()
      );
    }

    if (leasingContractNumber) {
      query.push(
        builder
          .push(["leasingContractNumber", leasingContractNumber], {
            like: "both",
          })
          .build()
      );
    }

    if (leasingContractDate && leasingContractDate.length) {
      const [start, end] = leasingContractDate;
      query.push(
        builder
          .push(["leasingContractDate", toStartDay(start).format()], {
            het: true,
          })
          .and()
          .push(["leasingContractDate", toEndDay(end).format()], {
            let: true,
          })
          .build()
      );
    }

    return query.join(builder.AND);
  };

  const toOptions = (
    key: "leasingNumber" | "leasingContractNumber",
    data: Array<ListLeasingHeaderDto>
  ): Array<Option<ListLeasingHeaderDto>> => {
    return data.map(item => ({
      key: item.id,
      label: item[key],
      value: item.id,
      meta: item,
    }));
  };

  const mapFormFilterToParamDownload = (
    form: Partial<FormFilter>,
    leasingIds: Array<string>
  ): RequestParamsDownloadLeasingCard => {
    const { lessorName, dealerName, leasingContractDate } = form;
    const company = store.state["authStore"].authData.companyName || "";
    const trimDealer = dealerName ? dealerName.replace(/,/i, "") : "ALL";
    const trimLessor = lessorName ? lessorName.replace(/,/i, "") : "ALL";
    const param: ParamDownloadLeasingCard = {
      companyName: company,
      datePeriod: "ALL",
      dealerName: trimDealer,
      lessorName: trimLessor,
    };

    if (leasingContractDate) {
      const [start, end] = leasingContractDate;
      param.datePeriod = `${moment(start).format(
        DATE_FORMAT_YYYY_MM_DD
      )} - ${moment(end).format(DATE_FORMAT_YYYY_MM_DD)}`;
    }

    const req: RequestParamsDownloadLeasingCard = {
      ids: leasingIds.join(","),
      params: Object.values(param).join(","),
      search: filterBy(form),
      sorts: "createdDate:asc",
    };

    if (!req.ids) delete req.ids;
    if (!req.search) delete req.search;

    return req;
  };

  return {
    calcCostPriceRatio,
    calcLeasingResidue,
    calcPaymentAmortize,
    calcPaymentBalance,
    calcPaymentCost,
    calcPaymentFirstBalance,
    calcTotalInstallment,
    calcUnitInsurance,
    calcUnitInterest,
    calcUnitNettFinance,
    calcUnitProvision,
    calcUnitResidue,
    cancelLeasing,
    cancelPayment,
    create,
    findAllPaymentType,
    findById,
    initAssetCreateDto,
    initCreateDto,
    initPaymentRealizationCreateDto,
    initPeriodCreateDto,
    mapAssetDetailDtoToAssetRow,
    mapDetailToForm,
    mapFormToCreateDto,
    mapPeriodDetailDtoToPeriodRow,
    mapPeriodLineToSettlePaymentDto,
    mapRowAssetToLeasingAssetCreateDto,
    mapRowPeriodToLeasingPeriodCreateDto,
    savePolicyNo,
    settlePayment,
    submit,
    update,
    findAll,
    filterBy,
    toOptions,
    download,
    mapFormFilterToParamDownload,
    initDetailDto,
  };
};

export default useLeasing;
