// Core
// Class
import { Api } from "@/models/class/api.class";
// Interfaces
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  BaseResponse,
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { ResponseListProduct } from "@/models/interface/logistic.interface";
import {
  ProductCreateRequestDto,
  ProductDetailDto,
  ProductResponseDto,
  ProductStockResponseDto,
  ProductUpdateRequestDto,
} from "@/models/interface/master-product";
import { ProductCategoryResponseDto } from "@/models/interface/product-category";
import {
  CreateMerkDTO,
  DataListPartNumber,
  DataListProduct,
  DataListProductCategory,
  IProductResponse,
  RequestProductCategory,
  ResponseProductCategoryByLocation,
  ResponseProductPartNumber,
} from "@/models/interface/product.interface";
import { ProductCategoryReportResponseDto } from "@/models/interface/ProductCategoryReport.interface";
import { ProductPurchaseResponseDto } from "@/models/interface/ProductPurchaseResponse.interface";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class ProductServices extends HttpClient {
  public constructor() {
    super();
  }

  /**
   * @deprecated
   * @duplicate
   * new service {@linkcode logisticServices.getListProduct}
   */
  public listProduct(
    params: RequestQueryParamsModel
  ): Promise<DataListProduct> {
    return this.get<DataListProduct>(Api.Product, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @description new api master product
   */
  getListMasterProduct(
    params?: RequestQueryParamsModel
  ): Promise<ResponseListProduct> {
    return this.get<ResponseListProduct>(Api.Product + "/list", { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadMasterProduct(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.Product + "/list/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listProductPartNumber(
    params: RequestQueryParamsModel
  ): Promise<ResponseProductPartNumber> {
    return this.get<ResponseProductPartNumber>(Api.ProductPartNumber, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public getListProductInStock(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ProductStockResponseDto>> {
    return this.get<Pagination<ProductStockResponseDto>>(Api.ProductByStock, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listSerialNumber(params: RequestQueryParamsModel): Promise<any[]> {
    return this.get<any[]>(Api.SerialNumber, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listSerialNumberForSales(
    params: RequestQueryParamsModel
  ): Promise<ProductPurchaseResponseDto[]> {
    return this.get<ProductPurchaseResponseDto[]>(Api.SerialNumberForSales, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listMerk(params?: RequestQueryParamsModel): Promise<Pagination<string>> {
    return this.get<Pagination<string>>(Api.Merk, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  public partNumber(
    params: RequestQueryParamsModel
  ): Promise<DataListPartNumber> {
    return this.get<DataListPartNumber>(Api.PartNumber, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode getDetailProduct}
   */
  public detailProduct(id: string): Promise<IProductResponse> {
    return this.get<IProductResponse>(Api.Product + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailProduct(id: string): Promise<ProductDetailDto> {
    return this.get<ProductDetailDto>(Api.Product + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListProductCategory(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ProductCategoryResponseDto>> {
    return this.get<Pagination<ProductCategoryResponseDto>>(
      Api.ProductCategory,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode getListProductCategory}
   */
  listProductCategory(
    params: RequestQueryParamsModel
  ): Promise<DataListProductCategory> {
    return this.get<DataListProductCategory>(Api.ProductCategory, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listProductCategoryRemastered(
    params: RequestQueryParamsModel
  ): Promise<Pagination<ProductCategoryReportResponseDto>> {
    return this.get<Pagination<ProductCategoryReportResponseDto>>(
      Api.ProductCategory + "/list",
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadProductCategory(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.ProductCategory + "/list/download", {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listProductCategoryByLocation(
    path: string
  ): Promise<ResponseProductCategoryByLocation[]> {
    return this.get<ResponseProductCategoryByLocation[]>(
      Api.ProductCategoryByLocation + `/${path}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateProductCategory(
    payload: RequestProductCategory,
    path: string
  ): Promise<boolean> {
    return this.put<boolean, RequestProductCategory>(
      Api.ProductCategory + "/" + path,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createProductCategory(payload: RequestProductCategory): Promise<boolean> {
    return this.post<boolean, RequestProductCategory>(
      Api.ProductCategory,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  addMerk(payload: CreateMerkDTO): Promise<string[]> {
    return this.post<string[], CreateMerkDTO>(Api.Merk, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createProduct(payload: ProductCreateRequestDto): Promise<BaseResponse> {
    return this.post<BaseResponse, ProductCreateRequestDto>(
      Api.Product,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateProduct(
    payload: ProductUpdateRequestDto,
    id: string
  ): Promise<ProductResponseDto> {
    return this.put<ProductResponseDto, ProductUpdateRequestDto>(
      Api.Product + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  uploadPartImage(file: FormData): Promise<FileCreateResponseDto> {
    return this.post<FileCreateResponseDto, FormData>(Api.UploadProduct, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const productService = new ProductServices();
