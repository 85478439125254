import { generateUUID } from "@/helpers/uuid";
import {
  ContactFormAccountState,
  ContactFormAddressState,
  ContactFormBankState,
  ContactFormCustomerState,
  ContactFormEmployeeState,
  ContactFormGeneralState,
  ContactFormPicState,
  ContactFormState,
  ContactFormSupplierState,
  EnumGenderType,
} from "../models/Contact.model";

export const initContactFormAccountState = (): ContactFormAccountState => ({
  receivable: undefined,
  revenue: undefined,
  payable: undefined,
  prepayment: undefined,
});

export const initContactFormBankState = (): ContactFormBankState => ({
  bankName: undefined,
  branchName: "",
  accountName: "",
  accountNumber: "",
  key: generateUUID(),
  isEdit: true,
});

export const initContactFormPicState = (): ContactFormPicState => ({
  email: "",
  key: generateUUID(),
  name: "",
  phone: "",
  position: "",
  telephone: "",
  isEdit: true,
});

export const initContactFormAddressState = (): ContactFormAddressState => {
  const DEFAULT_COUNTRY = "Indonesia";
  return {
    asBill: true,
    asShip: true,
    country: DEFAULT_COUNTRY,
    city: "",
    postalCode: "",
    address: "",
    location: "",
    taxType: "",
    isPrimaryBill: true,
    isPrimaryShip: true,
    pics: [],
    key: generateUUID(),
  };
};

export const initContactFormEmployeeState = (): ContactFormEmployeeState => ({
  employeeNumber: "",
  nik: "",
  gender: "" as EnumGenderType,
  department: undefined,
  dob: null,
  startDate: null,
});

export const initContactFormCustomerState = (): ContactFormCustomerState => ({
  type: undefined,
  taxInvoiceCode: undefined,
  creditLimit: 0,
  discount: 0,
  customerNumber: "",
  companyClones: undefined,
  thirdApps: undefined,
});

export const initContactFormSupplierState = (): ContactFormSupplierState => ({
  type: undefined,
  supplierNumber: "",
});

export const initContactFormGeneralState = (): ContactFormGeneralState => ({
  isCustomer: false,
  isEmployee: false,
  isSupplier: false,
  isActive: true,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  mobileNumber: "",
  faxNumber: "",
  taxRegistrationNumber: "",
  taxRegistrationName: "",
  termOfPayment: undefined,
  employee: initContactFormEmployeeState(),
  customer: initContactFormCustomerState(),
  supplier: initContactFormSupplierState(),
});

export const initContactFormState = (): ContactFormState => ({
  general: initContactFormGeneralState(),
  addresses: [],
  banks: [],
  account: initContactFormAccountState(),
});
