import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { EnumBooleanInString } from "@/models/enums/global.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { settingsServices } from "@/services/settings.service";
import { TaxInvoiceCodeListDto } from "@interface/tax-invoice-code";

export type FilterField = {
  code: string;
  desc: string;
  revisionCode: EnumBooleanInString;
};

const useTaxInvoice = () => {
  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<TaxInvoiceCodeListDto>> => {
    return settingsServices.getTaxInvoiceCode(params);
  };

  const toOptions = (
    dt: Array<TaxInvoiceCodeListDto>
  ): Array<Option<TaxInvoiceCodeListDto>> => {
    return dt.map(e => ({
      label: e.code,
      value: e.code,
      key: e.id,
      meta: e,
    }));
  };

  const findAllBy = ({
    code = "",
    desc = "",
    revisionCode,
  }: Partial<FilterField>) => {
    const builder = new SearchBuilder();
    const queries: Array<string> = [];
    if (code) {
      queries.push(builder.push(["code", code], { like: "both" }).build());
    }
    if (desc) {
      queries.push(
        builder.push(["description", desc], { like: "both" }).build()
      );
    }
    if (revisionCode) {
      queries.push(builder.push(["revisionCode", revisionCode]).build());
    }
    const filter = queries.join(builder.AND);
    const search = queries.join(builder.OR);
    return { filter, search };
  };

  return { findAll, toOptions, findAllBy };
};

export default useTaxInvoice;
