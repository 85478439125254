import { generateUUID } from "@/helpers/uuid";
import { ONE } from "@/models/constant/global.constant";
import {
  LeasingPaymentTypeEnum,
  LeasingStateEnum,
} from "@/models/enums/Leasing.enum";
import { FormValue, RowAsset, RowPeriod } from "./types";

export const initForm = (): FormValue => ({
  source: undefined,
  assetList: [],
  billingAddressLessor: "",
  branch: undefined,
  chequeNumber: "",
  cancellable: false,
  companyBank: undefined,
  costPrice: 0,
  currency: undefined,
  deletedAssetLineIds: [],
  deletedPeriodLineIds: [],
  id: "",
  insurance: undefined,
  insurancePeriod: 0,
  insurancePremium: 0,
  interest: 0,
  interestCostAccount: undefined,
  journalId: null,
  journalNo: "",
  leasingContractDate: null,
  leasingContractNumber: "",
  leasingNumber: "",
  leasingPeriod: 0,
  lessor: undefined,
  nettFinance: 0,
  notes: "",
  paidOff: false,
  paidOffDate: null,
  periodList: [],
  policyNumber: "",
  principalCostAccount: undefined,
  provision: 0,
  rates: ONE,
  residue: 0,
  startPaymentDate: null,
  status: "" as LeasingStateEnum,
  taxTotalAmount: 0,
  total: 0,
  useCheque: false,
  invoices: [],
  otr: 0,
});

export const initRowAsset = (): RowAsset => ({
  assetId: "",
  costPricePerUnit: 0,
  id: "",
  paymentTaxInvoiceDate: "",
  paymentTaxInvoiceNumber: "",
  portion: 0,
  prepaymentDate: "",
  taxInvoiceNumber: "",
  assetUnitCode: "",
  brand: "",
  type: "",
  provision: 0,
  residue: 0,
  serialNumber: "",
  key: generateUUID(),
  nettFinance: 0,
  insurancePremium: 0,
  interest: 0,
  dealer: undefined,
  dealerOptions: [],
  isSearchDealer: false,
  loadingDealer: false,
  invoiceApLineId: "",
  invoiceAPId: "",
  invoiceApNumber: "",
});

export const initRowPeriod = (): RowPeriod => ({
  amortize: 0,
  balance: 0,
  cashInOutDocumentNumber: "",
  cashInOutId: "",
  chequeNumber: "",
  id: "",
  installment: 0,
  interestCost: 0,
  key: generateUUID(),
  loading: { cancel: false, settle: false },
  paymentCost: 0,
  paymentDate: null,
  paymentRealizationDate: null,
  paymentType: "" as LeasingPaymentTypeEnum,
  principalCost: 0,
});
