import { ResultWorkOrderFormState } from "@/models/interface/result-work-order";

export const buildResultWorkOrderFormState = (): ResultWorkOrderFormState => ({
  completeDate: null,
  csfNumber: undefined,
  customerAddress: undefined,
  customerId: "",
  customerMaintenanceNumber: undefined,
  customerName: "",
  description: undefined,
  journalId: undefined,
  journalNumber: undefined,
  mechanicId: "",
  mechanicName: "",
  notes: "",
  picName: undefined,
  products: [],
  reason: undefined,
  scheduleOrder: "",
  status: undefined,
  workOrderDate: "",
  workOrderId: "",
  workOrderNumber: "",
});
