var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-footer",
    { staticClass: "footer" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("span", [_vm._v(_vm._s(_vm.appName))]),
            _c("span", { staticClass: "ml-1" }, [
              _vm._v("© " + _vm._s(_vm.currentYear) + " ")
            ]),
            _c("span", [_vm._v("version : " + _vm._s(_vm.appVersion))])
          ]),
          _c("a-col", { attrs: { span: 12, align: "end" } }, [
            _c("span", { staticClass: "mr-1" }, [_vm._v("Powered by")]),
            _c("span", [_vm._v("Nostra Technology")])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }