import { ONE } from "@/models/constant/global.constant";
import { LogisticReceivingItemStateEnum } from "@/models/enums/receive-items.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { ReceivingItemDraftResponseDto } from "@/models/interface/receive-item";
import {
  FormValue,
  ReceivingItemRow,
  RowSource,
} from "@/store/goodsReceiptChecklist.store";

export const initFormValue = (): FormValue => ({
  supplier: undefined,
  purchaseOrderId: "",
  receiveDate: null,
  branchWarehouseId: "",
  description: "",
  supplierDeliveryOrderNo: "",
  supplierShipToAddress: "",
  supplierBillToAddress: "",
  status: "" as LogisticReceivingItemStateEnum,
  isCheck: true,
  termOfPayment: 0,
  currencyCode: "",
  currencyRate: 0,
  taxType: "" as TAX_CALCULATION,
  receiveItems: [],
  totalDiscount: 0,
  totalTax: 0,
  totalProductPrice: 0,
  grandTotal: 0,
  branchWarehouse: "",
  purchaseOrderNumber: "",
  checklistNumber: "",
  createdBy: "",
});

export const initFormLine = (): ReceivingItemRow => ({
  id: "",
  purchaseOrderLineId: "",
  productId: "",
  productUomId: "",
  partNumber: "",
  qtyPO: 0,
  qty: 0,
  price: 0,
  taxCodeId: "",
  merk: "",
  taxBase: 0,
  percentDiscount: 0,
  discountValue: 0,
  tax: 0,
  totalPrice: 0,
  serialNumbers: [],
  itemAssets: [],
  trackAsAsset: false,
  locationReceived: undefined,
  productCode: "",
  productName: "",
  productUom: "",
  key: Math.random(),
  source: "" as RowSource,
  isSearchLocation: false,
  loadingLocation: false,
  locationOptions: [],
});

export const initDetailGrDraft = (): ReceivingItemDraftResponseDto => ({
  branchWarehouse: "",
  branchWarehouseId: "",
  checklistNumber: "",
  currencyCode: "",
  currencyDesc: "",
  currencyRate: ONE,
  description: "",
  grandTotal: 0,
  isCheck: false,
  journalId: "",
  journalNo: "",
  purchaseOrderId: "",
  purchaseOrderNumber: "",
  receiveDate: "",
  receiveItems: [],
  receiveNumber: "",
  status: "" as LogisticReceivingItemStateEnum,
  supplierBillToAddress: "",
  supplierDeliveryOrderNo: "",
  supplierId: "",
  supplierName: "",
  supplierShipToAddress: "",
  taxType: "" as TAX_CALCULATION,
  termOfPayment: 0,
  totalDiscount: 0,
  totalProductPrice: 0,
  totalTax: 0,
  id: "",
  createdBy: "",
  createdDate: "",
  modifiedBy: "",
  modifiedDate: "",
});
