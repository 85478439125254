import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const TRUCKING_ROUTE: RoutePathModel = {
  path: "trucking",
  url: "/trucking",
  name: "trucking",
  meta: {
    breadcrumb: "lbl_trucking",
    title_head: "lbl_trucking",
    need_auth: true,
  },
};

export const MASTER_DRIVER_ROUTE: RoutePathModel = {
  path: "master-driver",
  url: "/master-driver",
  name: "trucking.master-driver",
  meta: {
    title_head: "lbl_master_driver",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_master_driver",
        href: "/trucking/master-driver",
      },
    ],
  },
};

export const MASTER_RATE_ID_ROUTE: RoutePathModel = {
  path: "master-rate",
  url: "/master-rate",
  name: "trucking.master-rate",
  meta: {
    title_head: "lbl_master_rate",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_master_rate",
        href: "/trucking/master-rate",
      },
    ],
  },
};

export const TRUCKING_CASH_IN_OUT_LIST_ROUTE: RoutePathModel = {
  path: "cash-in-out",
  url: "/cash-in-out",
  name: "trucking.cash-in-out.list",
  meta: {
    title_head: "lbl_cash_in_out",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_cash_in_out",
        href: "/trucking/cash-in-out",
      },
    ],
  },
};

export const TRUCKING_CASH_IN_OUT_CREATE_ROUTE: RoutePathModel = {
  path: "cash-in-out/create",
  url: "/cash-in-out/create",
  name: "trucking.cash-in-out.create",
  meta: {
    title_head: "lbl_cash_in_out",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_cash_in_out",
        href: "/trucking/cash-in-out",
      },
      {
        name: "lbl_create",
      },
    ],
    mode: Mode.CREATE,
  },
};

export const TRUCKING_CASH_IN_OUT_EDIT_ROUTE: RoutePathModel = {
  path: "cash-in-out/:id/edit",
  url: "/cash-in-out/:id/edit",
  name: "trucking.cash-in-out.edit",
  meta: {
    title_head: "lbl_cash_in_out",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_cash_in_out",
        href: "/trucking/cash-in-out",
      },
      {
        name: "lbl_edit",
      },
    ],
    mode: Mode.EDIT,
  },
};

export const TRUCKING_CASH_IN_OUT_DETAIL_ROUTE: RoutePathModel = {
  path: "cash-in-out/:id",
  url: "/cash-in-out/:id",
  name: "trucking.cash-in-out.detail",
  meta: {
    title_head: "lbl_cash_in_out",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_cash_in_out",
        href: "/trucking/cash-in-out",
      },
      {
        name: "lbl_detail",
      },
    ],
    mode: Mode.DETAIL,
  },
};

export const TRUCKING_PAYMENT_ROUTE: RoutePathModel = {
  path: "payment",
  url: "/payment",
  name: "trucking.payment",
  meta: {
    title_head: "lbl_payment",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_payment",
        href: "/trucking/payment",
      },
    ],
  },
};

export const TRUCKING_SETTLEMENT_ROUTE: RoutePathModel = {
  path: "settlement",
  url: "/settlement",
  name: "trucking.settlement",
  meta: {
    title_head: "lbl_settlement",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_settlement",
        href: "/trucking/settlement",
      },
    ],
  },
};

export const TRUCKING_SALES_ORDER_ROUTE: RoutePathModel = {
  path: "sales-order",
  url: "/sales-order",
  name: "trucking.sales-order",
  meta: {
    title_head: "lbl_sales_order",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_sales_order",
        href: "/trucking/sales-order",
      },
    ],
  },
};

export const TRUCKING_SETTLEMENT_DETAIL_ROUTE: RoutePathModel = {
  path: "settlement/:id",
  url: "/settlement/:id",
  name: "trucking.settlement.detail",
  meta: {
    title_head: "lbl_settlement",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_settlement",
        href: "/trucking/settlement",
      },
      {
        name: "lbl_detail",
      },
    ],
    mode: Mode.DETAIL,
  },
};

//#region trucking account receivable
export const TRUCKING_ACCOUNT_RECEIVABLE_LIST_ROUTE: RoutePathModel = {
  path: "account-receivable",
  url: "/account-receivable",
  name: "trucking.account-receivable.list",
  meta: {
    title_head: "lbl_account_receivable",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_invoice_ar",
        href: "/trucking/account-receivable",
      },
    ],
  },
};

export const TRUCKING_ACCOUNT_RECEIVABLE_CREATE_ROUTE: RoutePathModel = {
  path: "account-receivable/create",
  url: "/account-receivable/create",
  name: "trucking.account-receivable.create",
  meta: {
    title_head: "lbl_account_receivable",
    mode: Mode.CREATE,
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_invoice_ar",
        href: "/trucking/account-receivable",
      },
      {
        name: "lbl_create",
        href: "#",
      },
    ],
  },
};

export const TRUCKING_ACCOUNT_RECEIVABLE_EDIT_ROUTE: RoutePathModel = {
  path: "account-receivable/:id/edit",
  url: "/account-receivable/:id/edit",
  name: "trucking.account-receivable.edit",
  meta: {
    title_head: "lbl_account_receivable",
    mode: Mode.EDIT,
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_invoice_ar",
        href: "/trucking/account-receivable",
      },
      {
        name: "lbl_edit",
        href: "#",
      },
    ],
  },
};

export const TRUCKING_ACCOUNT_RECEIVABLE_DETAIL_ROUTE: RoutePathModel = {
  path: "account-receivable/:id",
  url: "/account-receivable/:id",
  name: "trucking.account-receivable.detail",
  meta: {
    title_head: "lbl_account_receivable",
    mode: Mode.DETAIL,
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_invoice_ar",
        href: "/trucking/account-receivable",
      },
      {
        name: "lbl_detail",
        href: "#",
      },
    ],
  },
};
//#endregion

export const TRUCKING_SALES_ORDER_DETAIL_ROUTE: RoutePathModel = {
  path: "sales-order/:id",
  url: "/sales-order/:id",
  name: "trucking.sales-order.detail",
  meta: {
    title_head: "lbl_sales_order_detail",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_sales_order",
        href: "/trucking/sales-order",
      },
      {
        name: "lbl_detail",
      },
    ],
    mode: Mode.DETAIL,
  },
};

export const TRUCKING_SALES_ORDER_CREATE_ROUTE: RoutePathModel = {
  path: "sales-order/create",
  url: "/sales-order/create",
  name: "trucking.sales-order.create",
  meta: {
    title_head: "lbl_create_sales_order",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_sales_order",
        href: "/trucking/sales-order",
      },
      {
        name: "lbl_create",
      },
    ],
    mode: Mode.CREATE,
  },
};

export const TRUCKING_SALES_ORDER_EDIT_ROUTE: RoutePathModel = {
  path: "sales-order/:id/edit",
  url: "/sales-order/:id/edit",
  name: "trucking.sales-order.edit",
  meta: {
    title_head: "lbl_edit_sales_order",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_sales_order",
        href: "/trucking/sales-order",
      },
      {
        name: "lbl_detail",
      },
    ],
    mode: Mode.EDIT,
  },
};

//#region trucking report routes
export const TRUCKING_REPORT_ROUTE: RoutePathModel = {
  path: "report",
  url: "/report",
  name: "trucking.report",
  meta: {
    title_head: "lbl_report",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_report",
      },
    ],
  },
};

export const TRUCKING_SETTLEMENT_DETAIL_REPORT_ROUTE: RoutePathModel = {
  path: "settlement-detail",
  url: "/settlement-detail",
  name: "trucking.report.settlement-detail",
  meta: {
    title_head: "lbl_settlement_detail_report",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_settlement_detail_report",
      },
    ],
    mode: Mode.VIEW,
  },
};

export const UJO_TRACKING_REPORT_ROUTE: RoutePathModel = {
  path: "ujo-tracking",
  url: "/ujo-tracking",
  name: "trucking.report.ujo-tracking",
  meta: {
    title_head: "lbl_ujo_tracking_report",
    breadcrumb: [
      {
        name: "lbl_trucking",
        icon: "car",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_ujo_tracking_report",
      },
    ],
    mode: Mode.VIEW,
  },
};
//#endregion
