import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { productService } from "@/services/product.service";

export const useFindAllProductMerk = async (
  params?: RequestQueryParamsModel
): Promise<Pagination<string>> => {
  const queryParams = new RequestQueryParams(
    params?.search,
    params?.page,
    params?.sorts,
    params?.limit
  );
  return await productService.listMerk(queryParams);
};

export const useMapMerkToOptions = (source: Pagination<string>): Option[] => {
  return source.data.map<Option>(el => ({
    label: el,
    value: el,
    key: el,
  }));
};
