import { LeasingInvoiceState } from "@/store/account-payable/leasing/types";
import { Decimal } from "decimal.js-light";

export class LeasingUtils {
  static calcTotalInvoiceOtr(invoices: LeasingInvoiceState[]): number {
    return invoices.reduce((left, right) => {
      return new Decimal(left).plus(right.otr).toNumber();
    }, 0);
  }

  static calcCostPriceCarRatio(payload: {
    costPriceUnit: number;
    totalOtr: number;
  }): number {
    return new Decimal(payload.costPriceUnit)
      .dividedBy(payload.totalOtr)
      .toNumber();
  }

  static calcCarNettFinance(payload: {
    costPriceUnit: number;
    totalOtr: number;
    nettFinanceLeasing: number;
  }): number {
    const costPriceRatio = LeasingUtils.calcCostPriceCarRatio({
      costPriceUnit: payload.costPriceUnit,
      totalOtr: payload.totalOtr,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.nettFinanceLeasing || 0)
      .toNumber();
    return nettFinance;
  }

  static calcCarInterest(payload: {
    costPriceUnit: number;
    totalOtr: number;
    interestLeasing: number;
  }): number {
    const costPriceRatio = LeasingUtils.calcCostPriceCarRatio({
      costPriceUnit: payload.costPriceUnit,
      totalOtr: payload.totalOtr,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.interestLeasing || 0)
      .toNumber();
    return nettFinance;
  }

  static calcCarProvision(payload: {
    costPriceUnit: number;
    totalOtr: number;
    provisionLeasing: number;
  }): number {
    const costPriceRatio = LeasingUtils.calcCostPriceCarRatio({
      costPriceUnit: payload.costPriceUnit,
      totalOtr: payload.totalOtr,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.provisionLeasing || 0)
      .toNumber();
    return nettFinance;
  }

  static calcCarInsurance(payload: {
    costPriceUnit: number;
    totalOtr: number;
    premiumLeasing: number;
  }): number {
    const costPriceRatio = LeasingUtils.calcCostPriceCarRatio({
      costPriceUnit: payload.costPriceUnit,
      totalOtr: payload.totalOtr,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.premiumLeasing || 0)
      .toNumber();
    return nettFinance;
  }

  static calcCarResidue(payload: {
    costPriceUnit: number;
    totalOtr: number;
    residueLeasing: number;
  }): number {
    const costPriceRatio = LeasingUtils.calcCostPriceCarRatio({
      costPriceUnit: payload.costPriceUnit,
      totalOtr: payload.totalOtr,
    });
    const nettFinance: number = new Decimal(costPriceRatio)
      .times(payload.residueLeasing || 0)
      .toNumber();
    return nettFinance;
  }

  static calcCarLeasingResidue(payload: {
    totalOtr: number;
    nettFinanceLeasing: number;
  }): number {
    return new Decimal(payload.totalOtr || 0)
      .minus(payload.nettFinanceLeasing || 0)
      .toNumber();
  }
}
