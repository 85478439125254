import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";
import { useStockAdjustment } from "@/views/logistic/stock-adjustment/hooks";
import { StockAdjustmentResponseDTO } from "@/views/logistic/stock-adjustment/types";

export const stockAdjDetailLoader = async (to, from, next) => {
  const { findById } = useStockAdjustment();
  store.dispatch("stockAdjStore/resetStore");
  try {
    const res: StockAdjustmentResponseDTO = await findById(to.params.id);
    store.dispatch("stockAdjStore/mapDetailToForm", res);
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};

export const stockAdjCreateLoader = (to, from, next) => {
  store.dispatch("stockAdjStore/resetStore");
  next();
};
