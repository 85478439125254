import { ResponseDetailUserByAuth } from "@/models/interface/user.interface";
import { userServices } from "@/services/user.services";

const useUser = () => {
  const findMe = (): Promise<ResponseDetailUserByAuth> => {
    return userServices.userDetailAuth();
  };

  return { findMe };
};

export default useUser;
