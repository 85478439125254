






























import { INavMenu } from "@/models/interfaces/navigation.interface";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import AppSubMenu from "./AppSubMenu.vue";

export default Vue.extend({
  name: "AppSidebar",
  components: {
    AppSubMenu,
  },
  computed: {
    ...mapState({
      menus: "listMenu",
      authStore: "authStore",
    }),
    ...mapGetters({
      userMenus: "authStore/GET_USER_MENU",
    }),
    companyLogo() {
      return this.$sanitize(
        this.authStore.authData.companyLogo ?? "../../assets/image/logo.png"
      );
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initMenu(this.menus);
  },
  methods: {
    initMenu(navs: Array<INavMenu>): void {
      const constrains: Array<string> = this.userMenus;

      navs.forEach(nav => {
        constrains.forEach(con => {
          if (nav.menus.includes(con)) {
            nav.show = true;
          }
        });

        if (nav.children.length) {
          this.initMenu(nav.children);
        }
      });
    },
  },
});
