import useCalculator from "@/hooks/useCalculator";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { SalesOrderLine } from "@/store/salesOrder.store";
import { Decimal } from "decimal.js-light";

export class SalesOrderUtils {
  static countPrice(arg: {
    row: SalesOrderLine;
    taxType: TAX_CALCULATION;
    isLuxury: boolean;
    taxBaseVariable: number;
  }): void {
    const { round } = useCalculator();
    const isTaxExcl = arg.taxType === TAX_CALCULATION.EXCLUSIVE;
    const isTaxIncl = arg.taxType === TAX_CALCULATION.INCLUSIVE;
    const MAX_PRECISION = 14;

    const grossValue = new Decimal(arg.row.price || 0)
      .times(arg.row.qty || 0)
      .minus(arg.row.discountValue || 0);
    arg.row.grossValue = round(grossValue).toNumber();

    if (isTaxExcl) {
      const taxBase: Decimal = round(
        new Decimal(arg.row.grossValue).times(arg.taxBaseVariable)
      );
      arg.row.taxableValue = taxBase;

      const taxRate = new Decimal(arg.row.taxRate || 0).dividedBy(100);
      const taxAmount = round(taxBase.times(taxRate));
      arg.row.taxValue = taxAmount;
      arg.row.subTotal = round(new Decimal(arg.row.grossValue).plus(taxAmount));
    } else if (isTaxIncl && arg.isLuxury) {
      const selectedTaxRate = new Decimal(arg.row.taxRate || 0)
        .dividedBy(100)
        .plus(1);
      const beforeDpp: Decimal = round(
        new Decimal(arg.row.grossValue).dividedBy(selectedTaxRate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      arg.row.grossValue = beforeDpp.toNumber();
      const taxBase: Decimal = round(
        new Decimal(beforeDpp).times(arg.taxBaseVariable),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      arg.row.taxableValue = taxBase;

      const taxRate = new Decimal(arg.row.taxRate || 0).dividedBy(100);
      const taxAmount = round(
        new Decimal(taxBase).times(taxRate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      arg.row.taxValue = taxAmount;

      const subtotal = beforeDpp.plus(taxAmount);
      arg.row.subTotal = round(subtotal, Decimal.ROUND_HALF_EVEN);
    } else if (isTaxIncl && !arg.isLuxury) {
      const inclusiveRate = new Decimal(arg.row.inclusiveTaxRate || 0)
        .dividedBy(100)
        .plus(1);
      const beforeDpp: Decimal = round(
        new Decimal(grossValue).dividedBy(inclusiveRate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      arg.row.grossValue = beforeDpp.toNumber();
      const taxBase = round(
        new Decimal(beforeDpp).times(arg.taxBaseVariable),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      arg.row.taxableValue = taxBase;
      const taxRate = new Decimal(arg.row.taxRate || 0).dividedBy(100);
      const taxAmount = round(
        taxBase.times(taxRate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      arg.row.taxValue = taxAmount;

      const subtotal = round(
        beforeDpp.plus(taxAmount),
        Decimal.ROUND_HALF_EVEN
      );
      arg.row.subTotal = subtotal;
    } else {
      arg.row.taxableValue = grossValue;
      arg.row.taxValue = new Decimal(0);
      arg.row.subTotal = grossValue;
    }
  }
}
