import { RoutePathModel } from "../interface/common.interface";
export const UPLOAD_DATA_ROUTE: RoutePathModel = {
  path: "upload-data",
  url: "/upload-data",
  name: "upload-data",
  meta: {
    title_head: "lbl_upload_data",
    need_auth: true,
    breadcrumb: [
      {
        name: "lbl_upload_data",
        icon: "upload",
        href: "/upload-data",
      },
    ],
  },
};
