// Interfaces
import { RoutePathModel } from "../interface/common.interface";

export const ACCOUNTING_ROUTE: RoutePathModel = {
  path: "/accounting",
  url: "/accounting",
  name: "lbl_accounting",
  meta: {
    breadcrumb: "lbl_accounting",
    title_head: "lbl_accounting",
    need_auth: true,
  },
};

export const TRIALBALANCE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTING_ROUTE.url}/reports/trial-balance`,
  url: `${ACCOUNTING_ROUTE.url}/reports/trial-balance`,
  name: "lbl_trial_balance",
  meta: {
    breadcrumb: [
      {
        name: "lbl_accounting",
        icon: "diff",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_trial_balance",
        href: `${ACCOUNTING_ROUTE.url}/reports/trial-balance`,
      },
    ],
    title_head: "lbl_trial_balance",
  },
};
export const JOURNALTRANSACTIONS_ROUTE: RoutePathModel = {
  path: `${ACCOUNTING_ROUTE.url}/reports/journal-transactions`,
  url: `${ACCOUNTING_ROUTE.url}/reports/journal-transactions`,
  name: "lbl_journal_transactions",
  meta: {
    breadcrumb: [
      {
        name: "lbl_accounting",
        icon: "diff",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_journal_transactions",
        href: `${ACCOUNTING_ROUTE.url}/journal-transactions`,
      },
    ],
    title_head: "lbl_journal_transactions",
  },
};

export const JOURNALTRANSACTIONS_REPORT_DETAIL_ROUTE: RoutePathModel = {
  path: `${ACCOUNTING_ROUTE.url}/reports/journal-transactions-detail`,
  url: `${ACCOUNTING_ROUTE.url}/reports/journal-transactions-detail`,
  name: "accounting.reports.journaltransactionsdetail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_accounting",
        icon: "diff",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_journal_transactions_detail_report",
        href: `${ACCOUNTING_ROUTE.url}/journal-transactions/detail`,
      },
    ],
    title_head: "lbl_journal_transactions_detail_report",
    need_auth: true,
  },
};

export const PROFIT_LOSS_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTING_ROUTE.url}/reports/profit-loss`,
  url: `${ACCOUNTING_ROUTE.url}/reports/profit-loss`,
  name: "lbl_profit_loss_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_accounting",
        icon: "diff",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_profit_loss_report",
        href: `${ACCOUNTING_ROUTE.url}/profit-loss`,
      },
    ],
    title_head: "lbl_profit_loss_report",
  },
};

export const BALANCE_SHEET_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTING_ROUTE.url}/reports/balance-sheet`,
  url: `${ACCOUNTING_ROUTE.url}/reports/balance-sheet`,
  name: "lbl_balance_sheet_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_accounting",
        icon: "diff",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_balance_sheet_report",
        href: `${ACCOUNTING_ROUTE.url}/balance-sheet`,
      },
    ],
    title_head: "lbl_balance_sheet_report",
  },
};
