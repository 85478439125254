import { useContactData } from "@/hooks";
import { ContactMapper } from "@/mapper/Contact.mapper";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import {
  AddressDataList,
  DataEmployee,
  DataSourceBank,
} from "@/models/interface/contact.interface";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import {
  ContactFormAddressState,
  ContactFormState,
  StorageStateContactModel,
} from "@/store/models/Contact.model";
import { initContactFormState } from "./resources/Contact.resource";

const state: StorageStateContactModel = {
  addressDataList: [] as AddressDataList[],
  listBillTo: [] as AddressDataList[],
  listShipTo: [] as AddressDataList[],
  bankDataList: [
    {
      bankName: null,
      bankBranch: null,
      bankAccName: null,
      bankAccNumber: null,
      key: 0,
    },
  ] as DataSourceBank[],
  dataEmployee: {
    gender: "Male" as string,
    dateOfBirth: null as string | null,
    startDate: null as string | null,
  } as DataEmployee,
  valueType: [] as string[],
  contactFormState: initContactFormState(),
};

const mutations = {
  [Mutations.SET_VALUE_TYPE]: (
    stateVuex: StorageStateContactModel,
    payload: string[]
  ): void => {
    stateVuex.valueType = payload;
  },
  [Mutations.SET_DATA_EMPLOYEE]: (
    stateVuex: StorageStateContactModel,
    payload: DataEmployee
  ): void => {
    stateVuex.dataEmployee = payload;
  },
  [Mutations.SET_BANK_DATA_LIST]: (
    stateVuex: StorageStateContactModel,
    payload: DataSourceBank[]
  ): void => {
    stateVuex.bankDataList = payload;
  },
  [Mutations.SET_LIST_BILL_TO]: (
    stateVuex: StorageStateContactModel,
    payload: AddressDataList[]
  ): void => {
    stateVuex.listBillTo = payload;
  },
  [Mutations.SET_LIST_SHIP_TO]: (
    stateVuex: StorageStateContactModel,
    payload: AddressDataList[]
  ): void => {
    stateVuex.listShipTo = payload;
  },
  [Mutations.SET_ADDRESS_DATA_LIST]: (
    stateVuex: StorageStateContactModel,
    payload: AddressDataList[]
  ): void => {
    stateVuex.addressDataList = payload;
  },
  setContactFormState: (
    store: StorageStateContactModel,
    payload: ContactFormState
  ) => {
    store.contactFormState = payload;
  },
};

const actions = {
  resetContactFormState: ({ commit }) => {
    const payload: ContactFormState = initContactFormState();
    commit("setContactFormState", payload);
  },
  deleteAddress: ({ state, commit }, key: string) => {
    const payload: ContactFormState = { ...state.contactFormState };
    payload.addresses = state.contactFormState.addresses.filter(
      (item: ContactFormAddressState) => item.key !== key
    );
    commit("setContactFormState", { ...payload });
  },
  fetchContactDetail: async ({ commit }, contactId: string) => {
    const { findOne } = useContactData();
    const response = await findOne(contactId);
    const payload: ContactFormState =
      ContactMapper.toContactFormState(response);
    commit("setContactFormState", payload);
  },
  setDefaultAccount: ({ commit, state, rootGetters }) => {
    const preferences: IPreferencesResponseDto[] | undefined =
      rootGetters["preferenceStore/getDataPreference"];
    const payload: ContactFormState = {
      ...state.contactFormState,
    };
    if (preferences) {
      preferences.forEach(item => {
        if (item.key === "account_setup_account_receiviables" && item.value) {
          payload.account.receivable = { key: item.value, label: item.name };
        }
        if (item.key === "account_setup_sales_revenue" && item.value) {
          payload.account.revenue = { key: item.value, label: item.name };
        }
        if (item.key === "account_setup_account_payables" && item.value) {
          payload.account.payable = { key: item.value, label: item.name };
        }
        if (item.key === "account_setup_prepayment_ap" && item.value) {
          payload.account.prepayment = { key: item.value, label: item.name };
        }
      });
    }
    commit("setContactFormState", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
