import useArPrepayment from "@/hooks/useArPrepayment";
import useCalculator from "@/hooks/useCalculator";
import { Nullable } from "@/models/constant/interface/common.interface";
import { CurrencyCodeEnum } from "@/models/enums/global.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import localStorageService from "@/services/LocalStorage.service";
import { initDetail, initForm } from "@/store/resources/ArPrepayment.resource";
import { LabelInValue } from "@/types";
import {
  InvoicePrepaymentCreateDto,
  InvoicePrepaymentResponseDto,
} from "@interface/ar-prepayment";
import Decimal from "decimal.js-light";
import { Moment } from "moment";

type OmitCreateDto = Omit<
  InvoicePrepaymentCreateDto,
  | "invoiceDate"
  | "accountingDate"
  | "taxInvoiceDate"
  | "termOfPayment"
  | "invoicePrepaymentStatus"
  | "inclusiveTaxRateId"
  | "baseAmount"
  | "subTotal"
  | "totalTax"
  | "total"
>;
export type FormValue = OmitCreateDto &
  Nullable<{
    accountingDate: Moment;
    invoiceDate: Moment;
    taxInvoiceDate: Moment;
    termOfPayment: string;
    receivableAccountName: string;
    lineAccountName: string;
  }> & {
    inclusiveTax: LabelInValue | undefined;
    taxRate: number;
    inclusiveTaxRate: number;
    taxBaseVariable: number;
    baseAmount: Decimal;
    subTotal: Decimal;
    totalTax: Decimal;
    total: Decimal;
  };
type OmitDetailDto = Omit<
  InvoicePrepaymentResponseDto,
  "invoiceDate" | "accountingDate" | "taxInvoiceDate"
>;
export type DetailForm = OmitDetailDto & {
  invoiceDate: Moment | null;
  accountingDate: Moment | null;
  taxInvoiceDate: Moment | null;
};

export interface State {
  form: FormValue;
  registeredCustTax: boolean;
  detailDoc: DetailForm;
}

const state: State = {
  form: initForm(),
  registeredCustTax: false,
  detailDoc: initDetail(),
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setRegisteredCustTax: (st: State, custTax: boolean): void => {
    st.registeredCustTax = custTax;
  },
  setDetail: (st: State, payload: DetailForm): void => {
    st.detailDoc = payload;
  },
};

const getters = {
  isIdr: (st: State): boolean => st.form.currency === CurrencyCodeEnum.IDR,
};

const actions = {
  resetStore: (ctx): void => {
    const { commit } = ctx;
    const form: FormValue = initForm();
    form.currency = localStorageService.loadCurrency() || "";
    const detail: DetailForm = initDetail();
    commit("setForm", form);
    commit("setDetail", detail);
    commit("setRegisteredCustTax", false);
  },
  calcPayment: (ctx): void => {
    const { commit, state } = ctx;
    const {
      form: {
        amount = 0,
        taxType,
        taxRate = 0,
        inclusiveTaxRate = 0,
        taxBaseVariable = 1,
      },
    }: State = state;
    const MAX_PRECISION = 14;
    const { round } = useCalculator();
    const { calcTotalTax, countTaxBase } = useArPrepayment();

    let subTotal = new Decimal(amount ?? 0);

    if (TAX_CALCULATION.INCLUSIVE === taxType) {
      const rate = new Decimal(inclusiveTaxRate ?? 0).dividedBy(100).plus(1);
      subTotal = round(
        new Decimal(amount ?? 0).dividedBy(rate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
    }

    const baseAmount: Decimal = countTaxBase({
      taxBaseVariable,
      subTotal,
      taxType,
    });
    const totalTax: Decimal = calcTotalTax({
      taxRate,
      taxType,
      amount: new Decimal(baseAmount),
    });
    const form: Partial<FormValue> = {
      subTotal: subTotal,
      baseAmount: baseAmount,
      totalTax: totalTax,
      total: round(subTotal.plus(totalTax), Decimal.ROUND_HALF_EVEN),
    };
    commit("setForm", form);
  },
  mapDetailToForm: (ctx, payload: InvoicePrepaymentResponseDto): void => {
    const { commit } = ctx;
    const { mapDetailToForm } = useArPrepayment();
    const form: FormValue = mapDetailToForm(payload);

    commit("setForm", form);
  },
  mapDetailToFormDetail: (ctx, payload: InvoicePrepaymentResponseDto): void => {
    const { commit } = ctx;
    const { mapDetailToFormDetail } = useArPrepayment();
    const form: DetailForm = mapDetailToFormDetail(payload);

    commit("setDetail", form);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
