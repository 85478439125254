import moment, { Moment } from "moment";

let checkPending;
export function emailValidator(_rule, value, callback): void {
  clearTimeout(checkPending);
  if (!value) {
    return callback();
  }
  checkPending = setTimeout(() => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value).toLowerCase())) {
      callback();
    } else {
      callback("Format email tidak sesuai");
    }
  }, 500);
}

export function phoneValidator(_rule, value, callback): void {
  const regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback(
      "Format phone doesn't match , Make sure it follows the rule above"
    );
  }
}
export function phoneValidatorv2(_rule, value, callback): void {
  const regex = new RegExp(/^.{9,15}$/);
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback(
      "Format phone doesn't match , Make sure it follows the rule above"
    );
  }
}

export function numericOnly(value) {
  return value.replace(/[^0-9\\.]+/g, "");
}

export function numericOnlyv2(_rule, value, callback): void {
  const regex = new RegExp(/^[0-9.,\b]+$/);
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback("Please input numeric characters only");
  }
}

export function numericdotOnly(_rule, value, callback): void {
  const regex = new RegExp(/^[0-9.\b]+$/);
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback("Please input numeric characters only");
  }
}

export function changeCurrencytoNumeric(value) {
  if (value) {
    if (typeof value === "string" && value.includes(","))
      return Number(value.replace(/,/g, ""));
    else return Number(value);
  } else return Number(value);
}

export function currencyFormat(value) {
  if (value || value === 0) {
    if (value.toString().includes(","))
      return Number(value.toString().replace(/,/g, ""))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    else
      return Number(value)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "";
  }
}

export function currencyFormatDot(value) {
  if (value) {
    return Number(value).toFixed(2);
  } else {
    return "";
  }
}
export function checkPasswordStrength(_rule, value, callback): void {
  const regex = new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
  );
  if (regex.test(value)) {
    callback();
  } else if (!value) {
    callback();
  } else {
    callback(
      "Your password is not strong enough. Make sure it follows the rules above"
    );
  }
}

export function checkTaxNumber(_rule, value, callback): void {
  if (value) {
    if (!value.includes("-") || !value.includes(".")) {
      const regex = new RegExp(/^[0-9]{15,}$/);
      if (regex.test(value)) callback();
      else callback("Invalid format tax number");
    } else {
      let numb = value.match(/\d/g);
      numb = numb.join("");
      if (numb.length >= 15) callback();
      else callback("Invalid format tax number");
    }
  } else callback("Invalid format tax number");
}

export function checkTaxCode(_rule, value, callback): void {
  if (value) {
    if (!value.includes("-") || !value.includes(".")) {
      const regex = new RegExp(/^[0-9]{13}$/);
      if (regex.test(value)) callback();
      else callback("Invalid format tax number");
    } else {
      let numb = value.match(/\d/g);
      numb = numb.join("");
      if (numb.length === 13) callback();
      else callback("Invalid format tax number");
    }
  } else callback("Invalid format tax number");
}
export function formatTaxCode(value) {
  if (value)
    return (
      `${value.toString().substring(0, 3)}` +
      "-" +
      `${value.toString().substring(3, 5)}` +
      "." +
      `${value.toString().substring(5, 13)}`
    );
  else return "";
}
export function formatTaxCodetoNumber(value) {
  if (value) {
    if (value.includes(".")) return Number(value.split(".").join(""));
    else return Number(value);
  } else {
    return Number(value);
  }
}

/**
 * @deprecated
 * use {@linkcode formatTfn}
 */
export function formatTaxNumber(value) {
  if (value)
    return (
      `${value.toString().substring(0, 2)}` +
      "." +
      `${value.toString().substring(2, 5)}` +
      "." +
      `${value.toString().substring(5, 8)}` +
      "." +
      `${value.toString().substring(8, 9)}` +
      "-" +
      `${value.toString().substring(9, 12)}` +
      "." +
      `${value.toString().substring(12, 15)}`
    );
  else return "";
}

export function formatTfn(value: string | number): string {
  if (!value) return "";
  const transformed: string = value.toString();
  const oldVersion = 15;
  const newVersion = 16;

  const len: number = transformed.length;
  if (len === oldVersion) {
    return formatTaxNumber(value);
  } else if (len === newVersion) {
    const first = transformed.substring(0, 3);
    const second = transformed.substring(3, 6);
    const third = transformed.substring(6, 9);
    const fourth = transformed.substring(9, 10);
    const fifth = transformed.substring(10, 13);
    const six = transformed.substring(13, len);

    return `${first}.${second}.${third}.${fourth}-${fifth}.${six}`;
  }
  return transformed;
}
export function formatTaxNumbertoNumber(value) {
  if (value) {
    if (value.includes("-"))
      return value.split(".").join("").split("-").join("");
    else return value;
  } else {
    return value;
  }
}

export function isDateAfterMaxDate(
  value: Moment | Date | number | string,
  maxDate = new Date()
): boolean {
  return moment(value).isAfter(maxDate);
}

export function wordOnly(value: string): string {
  if (value) {
    return value.replace(/[^a-zA-Z ]/g, "");
  } else {
    return value;
  }
}

export const formatterNumber = (value: any) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const reverseFormatNumber = (value: any) => {
  return value.replace(/(,*)/g, "");
};

export const formatterPercent = (value: number | string) => {
  return `${value}%`;
};

export const reverseFormatPercent = (value: number | string) => {
  if (typeof value === "string") {
    return value.replace("%", "");
  } else {
    return value;
  }
};
