export interface IDrawerDataView {
  title: string;
  content: string;
  visible: boolean;
  state?: "error" | "success" | "info" | "warning";
  metadata?: any | null;
}

export class DrawerDataView implements IDrawerDataView {
  title: string;
  content: string;
  visible: boolean;
  state?: "error" | "success" | "info" | "warning" = "info";
  metadata?: any = null;

  constructor(data: IDrawerDataView) {
    this.title = data.title;
    this.content = data.content;
    this.visible = data.visible;
    this.state = data.state;
    this.metadata = data.metadata;
  }
}
