import { SearchBuilder } from "@/builder";
import { EnumPreferenceKey } from "@/models/enums/preference.enum";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import { preferenceService } from "@/services/Preference.service";
import store from "@/store";

const usePreferences = () => {
  const findAll = () => {
    return preferenceService.getList();
  };

  const findBaseCurrency = (): IPreferencesResponseDto | undefined => {
    return findByKey("feature_base_currency");
  };

  const findBaseAccountContact = (): Promise<IPreferencesResponseDto[]> => {
    const builder = new SearchBuilder();
    const search = builder
      .push(["key", "account_setup_account_receiviables"], { like: "both" })
      .or()
      .push(["key", "account_setup_sales_revenue"], { like: "both" })
      .or()
      .push(["key", "account_setup_account_payables"], { like: "both" })
      .or()
      .push(["key", "account_setup_prepayment_ap"], { like: "both" })
      .build();
    const q = "search=" + search;
    return preferenceService.getList({ name: q });
  };

  const findByKey = (
    key: EnumPreferenceKey
  ): IPreferencesResponseDto | undefined => {
    const val: IPreferencesResponseDto | undefined =
      store.getters["preferenceStore/GET_PREFERENCE_BY_KEY"](key);
    return val;
  };

  return { findBaseCurrency, findBaseAccountContact, findAll, findByKey };
};

export default usePreferences;
