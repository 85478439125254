// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const SALES_ROUTE: RoutePathModel = {
  path: "/sales",
  url: "/sales",
  name: "sales",
  meta: {
    breadcrumb: [],
    title_head: "lbl_sales",
    need_auth: true,
  },
};
export const CLOSEPERIOD_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/closeperiod`,
  url: `${SALES_ROUTE.path}/closeperiod`,
  name: "lbl_sales",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_open_close_transaction",
        href: `${SALES_ROUTE.path}/closeperiod`,
      },
    ],
    title_head: "lbl_open_close_transaction",
    mode: Mode.VIEW,
  },
};

export const SALESORDER_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/sales-order`,
  url: `${SALES_ROUTE.path}/sales-order`,
  name: "sales.sales-order",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
        href: `${SALES_ROUTE.path}/sales-order`,
      },
    ],
    title_head: "lbl_sales_order",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const SALES_ORDER_CREATE_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/sales-order/create`,
  url: `${SALES_ROUTE.path}/sales-order/create`,
  name: "sales.sales-order.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
        href: `${SALES_ROUTE.path}/sales-order`,
      },
      {
        name: "lbl_create_sales_order",
        href: `${SALES_ROUTE.path}/sales-order/create`,
      },
    ],
    title_head: "lbl_create_sales_order",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const SALES_ORDER_DETAIL_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/sales-order/:id`,
  url: `${SALES_ROUTE.path}/sales-order/:id`,
  name: "sales.sales-order.detail",
  meta: {
    breadcrumb: [
      {
        icon: "shopping-cart",
        name: "lbl_sales_order",
        href: `${SALES_ROUTE.path}/sales-order`,
      },
      {
        name: "lbl_sales_order_detail",
        href: `${SALES_ROUTE.path}/sales-order/:id`,
      },
    ],
    title_head: "lbl_sales_order_detail",
    mode: Mode.DETAIL,
    need_auth: true,
  },
};

export const SALES_ORDER_EDIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/sales-order/:id/edit`,
  url: `${SALES_ROUTE.path}/sales-order/:id/edit`,
  name: "sales.sales-order.edit",
  meta: {
    breadcrumb: [
      {
        icon: "shopping-cart",
        name: "lbl_sales_order",
        href: `${SALES_ROUTE.path}/sales-order`,
      },
      {
        name: "lbl_update_sales_order",
        href: `${SALES_ROUTE.path}/sales-order/:id/edit`,
      },
    ],
    title_head: "lbl_update_sales_order",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const PICKINGLIST_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/salestransactions/pickinglist`,
  url: `${SALES_ROUTE.path}/salestransactions/pickinglist`,
  name: "sales.sales-transaction.picking-list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_picking_list",
        href: `${SALES_ROUTE.path}/salestransactions/pickinglist`,
      },
    ],
    title_head: "lbl_picking_list",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const PICKINGLISTCREATE_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/salestransactions/pickinglist/create`,
  url: `${SALES_ROUTE.path}/salestransactions/pickinglist/create`,
  name: "lbl_create_picking_list",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_picking_list",
        href: `${SALES_ROUTE.path}/salestransactions/pickinglist`,
      },
      {
        name: "lbl_create_picking_list",
        href: `${SALES_ROUTE.path}/salestransactions/pickinglist/create`,
      },
    ],
    title_head: "lbl_create_picking_list",
    mode: Mode.CREATE,
  },
};
export const PICKINGLISTEDIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/salestransactions/pickinglist/edit/:id`,
  url: `${SALES_ROUTE.path}/salestransactions/pickinglist/edit/:id`,
  name: "sales.sales-transaction.picking-list.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_picking_list",
        href: `${SALES_ROUTE.path}/salestransactions/pickinglist`,
      },
      {
        name: "lbl_view_picking_list",
        href: `${SALES_ROUTE.path}/salestransactions/pickinglist/edit/:id`,
      },
    ],
    title_head: "lbl_view_picking_list",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const DELIVERYORDERCREATE_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/sales-transaction/delivery-order/create`,
  url: `${SALES_ROUTE.path}/sales-transaction/delivery-order/create`,
  name: "sales.delivery-order.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_delivery_order",
        href: `${SALES_ROUTE.path}/sales-transaction/delivery-order`,
      },
      {
        name: "lbl_create_delivery_order",
        href: `${SALES_ROUTE.path}/sales-transaction/delivery-order/create`,
      },
    ],
    title_head: "lbl_create_delivery_order",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const DELIVERYORDEREDIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/sales-transaction/delivery-order/:id/edit`,
  url: `${SALES_ROUTE.path}/sales-transaction/delivery-order/:id/edit`,
  name: "sales.delivery-order.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_delivery_order",
        href: `${SALES_ROUTE.path}/sales-transaction/delivery-order`,
      },
      {
        name: "lbl_update_delivery_order",
        href: `${SALES_ROUTE.path}/sales-transaction/delivery-order/:id/edit`,
      },
    ],
    title_head: "lbl_update_delivery_order",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const DELIVERYORDERVIEW_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/sales-transaction/delivery-order`,
  url: `${SALES_ROUTE.path}/sales-transaction/delivery-order`,
  name: "sales.delivery-order",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_delivery_order",
        href: `${SALES_ROUTE.path}/sales-transaction/delivery-order`,
      },
    ],
    title_head: "lbl_delivery_order",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const INTERNAL_CONTRACT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/internal-contract/list`,
  url: `${SALES_ROUTE.url}/internal-contract/list`,
  name: "lbl_internal_contract",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_internal_contract",
        href: `${SALES_ROUTE.url}/internal-contract/list`,
      },
    ],
    title_head: "lbl_internal_contract",
  },
};
export const CREATE_INTERNAL_CONTRACT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/internal-contract/create`,
  url: `${SALES_ROUTE.url}/internal-contract/create`,
  name: "sales.internal-contract.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_internal_contract",
        href: `${SALES_ROUTE.url}/internal-contract/list`,
      },
      {
        name: "lbl_create_internal_contract",
        href: `${SALES_ROUTE.url}/internal-contract/create`,
      },
    ],
    title_head: "lbl_internal_contract",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const EDIT_INTERNAL_CONTRACT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/internal-contract/edit/:id`,
  url: `${SALES_ROUTE.url}/internal-contract/edit/:id`,
  name: "sales.internal-contract.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_internal_contract",
        href: `${SALES_ROUTE.url}/internal-contract/list`,
      },
      {
        name: "lbl_edit_internal_contract",
        href: `${SALES_ROUTE.url}/internal-contract/edit/:id`,
      },
    ],
    title_head: "lbl_internal_contract",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const VIEW_INTERNAL_CONTRACT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/internal-contract/view/:id`,
  url: `${SALES_ROUTE.url}/internal-contract/view/:id`,
  name: "sales.internal-contract.view",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_internal_contract",
        href: `${SALES_ROUTE.url}/internal-contract/list`,
      },
      {
        name: "lbl_view_internal_contract",
        href: `${SALES_ROUTE.url}/internal-contract/view/:id`,
      },
    ],
    title_head: "lbl_internal_contract",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const CETAK_SERAH_TERIMA_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/serah-terima/cetak`,
  url: `${SALES_ROUTE.url}/serah-terima/cetak`,
  name: "sales.minutes-of-handover.print",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_bast",
        href: `${SALES_ROUTE.url}/serah-terima/cetak`,
      },
    ],
    title_head: "lbl_bast",
    mode: Mode.VIEW,
  },
};
export const VIEW_PRINT_BAST_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/serah-terima/print/:id`,
  url: `${SALES_ROUTE.url}/serah-terima/print/:id`,
  name: "lbl_bast_print",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_bast",
        href: `${SALES_ROUTE.url}/serah-terima/cetak`,
      },
      {
        name: "lbl_bast_print",
        href: `${SALES_ROUTE.url}/serah-terima/print/:id`,
      },
    ],
    title_head: "lbl_bast_print",
    mode: Mode.VIEW,
  },
};
export const BALIKAN_SERAH_TERIMA_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/serah-terima/balikan`,
  url: `${SALES_ROUTE.url}/serah-terima/balikan`,
  name: "BAST Serah Terima Posting",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_bast",
        icon: "usergroup-add",
      },
      {
        name: "BAST Serah Terima Posting",
        href: `${SALES_ROUTE.url}/serah-terima/balikan`,
      },
    ],
    title_head: "BAST Serah Terima Posting",
    mode: Mode.VIEW,
  },
};

export const CREATE_BALIKAN_SERAH_TERIMA_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/serah-terima/balikan/detail/:id`,
  url: `${SALES_ROUTE.url}/serah-terima/balikan/detail/:id`,
  name: "Detail BAST Serah Terima Posting",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_bast",
        icon: "usergroup-add",
      },
      {
        name: "Detail BAST Serah Terima Posting",
        href: `${SALES_ROUTE.url}/serah-terima/balikan/detail/:id`,
      },
    ],
    title_head: "Detail BAST Serah Terima Posting",
    mode: Mode.VIEW,
  },
};

export const TARIK_UNIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/tarik-unit/list`,
  url: `${SALES_ROUTE.url}/tarik-unit/list`,
  name: "lbl_ba_unit_withdrawal",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_ba_unit_withdrawal",
        icon: "fall",
        href: `${SALES_ROUTE.url}/tarik-unit/list`,
      },
    ],
    title_head: "lbl_ba_unit_withdrawal",
    mode: Mode.VIEW,
  },
};

export const CETAK_TARIK_UNIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/tarik-unit/detail/:id`,
  url: `${SALES_ROUTE.url}/tarik-unit/detail/:id`,
  name: "lbl_view_ba_unit_withdrawal",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_ba_unit_withdrawal",
        icon: "fall",
        href: `${SALES_ROUTE.url}/tarik-unit/list`,
      },
      {
        name: "lbl_view_ba_unit_withdrawal",
        href: `${SALES_ROUTE.url}/tarik-unit/detail/:id`,
      },
    ],
    title_head: "lbl_view_ba_unit_withdrawal",
    mode: Mode.VIEW,
  },
};

export const TUKAR_UNIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/tukar-unit/list`,
  url: `${SALES_ROUTE.url}/tukar-unit/list`,
  name: "lbl_ba_unit_exchange",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_ba_unit_exchange",
        icon: "sync",
        href: `${SALES_ROUTE.url}/tukar-unit/list`,
      },
    ],
    title_head: "lbl_ba_unit_exchange",
    mode: Mode.VIEW,
  },
};

export const CETAK_TUKAR_UNIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/tukar-unit/cetak/:id`,
  url: `${SALES_ROUTE.url}/tukar-unit/cetak/:id`,
  name: "lbl_view_ba_unit_exchange",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_sales_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_official_report",
        icon: "mail",
      },
      {
        name: "lbl_ba_unit_exchange",
        icon: "fall",
        href: `${SALES_ROUTE.url}/tukar-unit/list`,
      },
      {
        name: "lbl_view_ba_unit_exchange",
        href: `${SALES_ROUTE.url}/tukar-unit/cetak/:id`,
      },
    ],
    title_head: "lbl_view_ba_unit_exchange",
    mode: Mode.VIEW,
  },
};

export const BA_MOVE_LOCATION_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/pindah-lokasi/list`,
  url: `${SALES_ROUTE.url}/pindah-lokasi/list`,
  name: "lbl_ba_move_location",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_ba_move_location",
        icon: "drag",
        href: `${SALES_ROUTE.url}/pindah-lokasi/list`,
      },
    ],
    title_head: "lbl_ba_move_location",
    mode: Mode.VIEW,
  },
};

export const CETAK_BA_MOVE_LOCATION_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/pindah-lokasi/cetak/:id`,
  url: `${SALES_ROUTE.url}/pindah-lokasi/cetak/:id`,
  name: "lbl_ba_move_location_cetak",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_ba_move_location",
        icon: "drag",
        href: `${SALES_ROUTE.url}/pindah-lokasi/list`,
      },
      {
        name: "lbl_ba_move_location_cetak",
        href: `${SALES_ROUTE.url}/pindah-lokasi/cetak/:id`,
      },
    ],
    title_head: "lbl_ba_move_location_cetak",
    mode: Mode.VIEW,
  },
};

export const REPORTS_INTERNAL_CONTRACT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/internal-contract`,
  url: `${SALES_ROUTE.url}/reports/internal-contract`,
  name: "lbl_internal_contract_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_internal_contract_report",
        href: `${SALES_ROUTE.url}/reports/internal-contract`,
      },
    ],
    title_head: "lbl_internal_contract_report",
    mode: Mode.VIEW,
  },
};
export const ORDERTRACKING_ROUTE: RoutePathModel = {
  path: "ordertracking",
  url: `${SALESORDER_ROUTE.url}/ordertracking`,
  name: "Order Tracking",
  meta: {
    breadcrumb: "Order Tracking",
    title_head: "Order Tracking",
  },
};

export const ORDERREPOTS_ROUTE: RoutePathModel = {
  path: "orderrepots",
  url: `${SALESORDER_ROUTE.url}/orderrepots`,
  name: "Order Repots",
  meta: {
    breadcrumb: "Order Repots",
    title_head: "Order Repots",
  },
};
export const REMINDERDUEINTERNALCONTARCT_ROUTE: RoutePathModel = {
  path: "reminderdueinternalcontract",
  url: `${SALESORDER_ROUTE.url}/reminderdueinternalcontract`,
  name: "Reminder Due Internal Contract",
  meta: {
    breadcrumb: "Reminder Due Internal Contract",
    title_head: "Reminder Due Internal Contract",
  },
};
export const REPORTS_SALES_ORDER_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/sales-order`,
  url: `${SALES_ROUTE.url}/reports/sales-order`,
  name: "lbl_sales_order_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_sales_order_report",
        href: `${SALES_ROUTE.url}/reports/sales-order`,
      },
    ],
    title_head: "lbl_sales_order_report",
    mode: Mode.VIEW,
  },
};

export const DAILY_OPERATIONAL_REPORT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/daily-operational`,
  url: `${SALES_ROUTE.url}/reports/daily-operational`,
  name: "sales.report.daily-operational",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_daily_operational_report",
        href: `${SALES_ROUTE.url}/reports/daily-operational`,
      },
    ],
    title_head: "lbl_daily_operational_report",
    mode: Mode.VIEW,
  },
};

export const REPORTS_OF_UNIT_POSITION_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/report-unit-position`,
  url: `${SALES_ROUTE.url}/reports/report-unit-position`,
  name: "lbl_report_of_units_position",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_report_of_units_position",
        href: `${SALES_ROUTE.url}/reports/report-unit-position`,
      },
    ],
    title_head: "lbl_report_of_units_position",
    mode: Mode.VIEW,
  },
};
export const REPORTS_OF_UNIT_RENTED: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/report-units-rented`,
  url: `${SALES_ROUTE.url}/reports/report-units-rented`,
  name: "lbl_report_of_units_rented",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_report_of_units_rented",
        href: `${SALES_ROUTE.url}/reports/report-unit-rented`,
      },
    ],
    title_head: "lbl_report_of_units_rented",
    mode: Mode.VIEW,
  },
};
export const REPORTS_HISTORY_UNITS_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/report-history-units`,
  url: `${SALES_ROUTE.url}/reports/report-history-units`,
  name: "lbl_report_of_history_units",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "shopping-cart",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_report_of_history_units",
        href: `${SALES_ROUTE.url}/reports/report-history-units`,
      },
    ],
    title_head: "lbl_report_of_history_units",
    mode: Mode.VIEW,
  },
};

export const TRACKINGIC_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/tracking-ic`,
  url: `${SALES_ROUTE.url}/reports/tracking-ic`,
  name: "salesorder.reports.trackingic",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "sliders",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_tracking_ic_report",
        href: `${SALES_ROUTE.url}/reports/tracking-ic`,
      },
    ],
    title_head: "lbl_tracking_ic_report",
    need_auth: true,
  },
};

export const MARGIN_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/margin`,
  url: `${SALES_ROUTE.url}/reports/margin`,
  name: "salesorder.reports.margin",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "sliders",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_sales_margin_report",
        href: `${SALES_ROUTE.url}/reports/margin`,
      },
    ],
    title_head: "lbl_sales_margin_report",
    need_auth: true,
  },
};

export const UNIT_MARGIN_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/unit-margin`,
  url: `${SALES_ROUTE.url}/reports/unit-margin`,
  name: "lbl_unit_margin_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales_order",
        icon: "sliders",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_unit_margin_report",
        href: `${SALES_ROUTE.url}/reports/unit-margin`,
      },
    ],
    title_head: "lbl_unit_margin_report",
    need_auth: true,
  },
};
