export enum RECEIVE_ITEM_STATUS {
  PARTIAL = "Partial",
  UNBILLED = "Un-Billed",
}

export enum LogisticReceivingItemStateEnum {
  DRAFT = "Draft",
  WAIT_FOR_APPROVAL = "Wait For Approval",
  SUBMITTED = "Submitted",
  RETURNED = "Returned",
  PARTIAL_BILLED = "Partial Billed",
  BILLED = "Billed",
  UN_BILLED = "Un-Billed",
}
