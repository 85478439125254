import { i18n } from "@/locales";
import { ApInvoiceMapper } from "@/mapper/ApInvoice.mapper";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import store from "@/store";
import { ApInvoiceFormState } from "@/store/account-payable/ap-invoice/types";
import { notification } from "ant-design-vue";
import { NavigationGuard } from "vue-router";

export const getDetailApInvoice: NavigationGuard = async (to, _, next) => {
  try {
    store.commit(Mutations.SET_LOADING_COMPONENT, true);
    const response = await invoiceAPServices.detailApInvoice(to.params.id);
    const payload: Partial<ApInvoiceFormState> =
      ApInvoiceMapper.toFormState(response);
    store.commit("invoiceApStore/setFormState", payload);
    await store.dispatch("invoiceApStore/findTaxBaseVariable");
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
    next();
  } catch (error) {
    notification.error({
      message: i18n.t("lbl_error").toString(),
      description: i18n.t("notif_process_fail").toString(),
      duration: 30,
    });
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};

export const initApInvoiceForm: NavigationGuard = (_, __, next): void => {
  store.dispatch("invoiceApStore/RESET_STATE");
  next();
};
