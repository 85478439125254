import { Option } from "@/models/class/option.class";
import {
  DataListProduct,
  ResponseListProduct,
} from "@/models/interface/logistic.interface";
import { ProductDetailDto } from "@/models/interface/master-product";
import { ResponseProduct } from "@/models/interface/product.interface";
import { productService } from "@/services/product.service";

const useProductDetail = async (id: string): Promise<ProductDetailDto> => {
  const res = await productService.getDetailProduct(id);
  return res;
};

const useProductExpenseAccount = async (
  id: string
): Promise<{
  expensePurchaseAccountId: string;
  expensePurchaseAccountName: string;
}> => {
  const { expensePurchaseAccountId, expensePurchaseAccountName } =
    await useProductDetail(id);
  return { expensePurchaseAccountId, expensePurchaseAccountName };
};

const useFindProductsById = async (
  id: string
): Promise<ResponseProduct | null> => {
  const res = await productService.listProduct({ search: "secureId~" + id });
  return res.data[0] ?? null;
};

const useMapProductsToOptions = (
  source: ResponseListProduct,
  to?: "code" | "name"
): Option<DataListProduct>[] => {
  return source.data.map<Option<DataListProduct>>((el, idx) => ({
    label: to === "code" ? el.code : el.name,
    value: to === "code" ? el.code : to === "name" ? el.name : el.id,
    meta: el,
    key: idx,
  }));
};

export {
  useFindProductsById,
  useMapProductsToOptions,
  useProductDetail,
  useProductExpenseAccount,
};
