import { useLeasing } from "@/hooks";
import { initForm } from "./resource";
import { State } from "./types";

export const state: State = {
  form: initForm(),
  detail: useLeasing().initDetailDto(),
  generatedInvoices: [],
  maxInvoiceDate: null,
};
