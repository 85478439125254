import { config } from "@/models/constant/routes.constant";
import { RouteConfig } from "vue-router";

const router: RouteConfig = {
  path: config.TRUCKING_ROUTE.path,
  name: config.TRUCKING_ROUTE.name,
  meta: config.TRUCKING_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.MASTER_DRIVER_ROUTE.path,
      name: config.MASTER_DRIVER_ROUTE.name,
      meta: config.MASTER_DRIVER_ROUTE.meta,
      component: () => import("./master-driver/IndexPage.vue"),
    },
    {
      path: config.MASTER_RATE_ID_ROUTE.path,
      name: config.MASTER_RATE_ID_ROUTE.name,
      meta: config.MASTER_RATE_ID_ROUTE.meta,
      component: () => import("./master-rate/IndexPage.vue"),
    },
    {
      path: config.TRUCKING_CASH_IN_OUT_LIST_ROUTE.path,
      name: config.TRUCKING_CASH_IN_OUT_LIST_ROUTE.name,
      meta: config.TRUCKING_CASH_IN_OUT_LIST_ROUTE.meta,
      component: () => import("./cash-in-out/IndexPage.vue"),
    },
    {
      path: config.TRUCKING_CASH_IN_OUT_CREATE_ROUTE.path,
      name: config.TRUCKING_CASH_IN_OUT_CREATE_ROUTE.name,
      meta: config.TRUCKING_CASH_IN_OUT_CREATE_ROUTE.meta,
      component: () => import("./cash-in-out/FormPage.vue"),
    },
    {
      path: config.TRUCKING_CASH_IN_OUT_EDIT_ROUTE.path,
      name: config.TRUCKING_CASH_IN_OUT_EDIT_ROUTE.name,
      meta: config.TRUCKING_CASH_IN_OUT_EDIT_ROUTE.meta,
      component: () => import("./cash-in-out/FormPage.vue"),
    },
    {
      path: config.TRUCKING_CASH_IN_OUT_DETAIL_ROUTE.path,
      name: config.TRUCKING_CASH_IN_OUT_DETAIL_ROUTE.name,
      meta: config.TRUCKING_CASH_IN_OUT_DETAIL_ROUTE.meta,
      component: () => import("./cash-in-out/FormPage.vue"),
    },
    {
      path: config.TRUCKING_PAYMENT_ROUTE.path,
      name: config.TRUCKING_PAYMENT_ROUTE.name,
      meta: config.TRUCKING_PAYMENT_ROUTE.meta,
      component: () => import("./payment/IndexPage.vue"),
    },
    {
      path: config.TRUCKING_SALES_ORDER_ROUTE.path,
      name: config.TRUCKING_SALES_ORDER_ROUTE.name,
      meta: config.TRUCKING_SALES_ORDER_ROUTE.meta,
      component: () => import("./sales-order/IndexPage.vue"),
    },
    {
      path: config.TRUCKING_ACCOUNT_RECEIVABLE_LIST_ROUTE.path,
      name: config.TRUCKING_ACCOUNT_RECEIVABLE_LIST_ROUTE.name,
      meta: config.TRUCKING_ACCOUNT_RECEIVABLE_LIST_ROUTE.meta,
      component: () => import("./account-receivable/IndexPage.vue"),
    },
    {
      path: config.TRUCKING_ACCOUNT_RECEIVABLE_CREATE_ROUTE.path,
      name: config.TRUCKING_ACCOUNT_RECEIVABLE_CREATE_ROUTE.name,
      meta: config.TRUCKING_ACCOUNT_RECEIVABLE_CREATE_ROUTE.meta,
      component: () => import("./account-receivable/InvoiceCreatePage.vue"),
    },
    {
      path: config.TRUCKING_ACCOUNT_RECEIVABLE_EDIT_ROUTE.path,
      name: config.TRUCKING_ACCOUNT_RECEIVABLE_EDIT_ROUTE.name,
      meta: config.TRUCKING_ACCOUNT_RECEIVABLE_EDIT_ROUTE.meta,
      props: true,
      component: () => import("./account-receivable/InvoiceEditPage.vue"),
    },
    {
      path: config.TRUCKING_ACCOUNT_RECEIVABLE_DETAIL_ROUTE.path,
      name: config.TRUCKING_ACCOUNT_RECEIVABLE_DETAIL_ROUTE.name,
      meta: config.TRUCKING_ACCOUNT_RECEIVABLE_DETAIL_ROUTE.meta,
      props: true,
      component: () => import("./account-receivable/InvoiceDetailPage.vue"),
    },
    {
      path: config.TRUCKING_SETTLEMENT_ROUTE.path,
      name: config.TRUCKING_SETTLEMENT_ROUTE.name,
      meta: config.TRUCKING_SETTLEMENT_ROUTE.meta,
      component: () => import("./settlement/IndexPage.vue"),
    },
    {
      path: config.TRUCKING_SETTLEMENT_DETAIL_ROUTE.path,
      name: config.TRUCKING_SETTLEMENT_DETAIL_ROUTE.name,
      meta: config.TRUCKING_SETTLEMENT_DETAIL_ROUTE.meta,
      component: () => import("./settlement/DetailPage.vue"),
    },
    {
      path: config.TRUCKING_SALES_ORDER_CREATE_ROUTE.path,
      name: config.TRUCKING_SALES_ORDER_CREATE_ROUTE.name,
      meta: config.TRUCKING_SALES_ORDER_CREATE_ROUTE.meta,
      component: () => import("./sales-order/CreatePage.vue"),
    },
    {
      path: config.TRUCKING_SALES_ORDER_DETAIL_ROUTE.path,
      name: config.TRUCKING_SALES_ORDER_DETAIL_ROUTE.name,
      meta: config.TRUCKING_SALES_ORDER_DETAIL_ROUTE.meta,
      component: () => import("./sales-order/DetailPage.vue"),
    },
    {
      path: config.TRUCKING_SALES_ORDER_EDIT_ROUTE.path,
      name: config.TRUCKING_SALES_ORDER_EDIT_ROUTE.name,
      meta: config.TRUCKING_SALES_ORDER_EDIT_ROUTE.meta,
      component: () => import("./sales-order/EditPage.vue"),
    },
    {
      path: config.TRUCKING_REPORT_ROUTE.path,
      name: config.TRUCKING_REPORT_ROUTE.name,
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: config.TRUCKING_SETTLEMENT_DETAIL_REPORT_ROUTE.path,
          name: config.TRUCKING_SETTLEMENT_DETAIL_REPORT_ROUTE.name,
          meta: config.TRUCKING_SETTLEMENT_DETAIL_REPORT_ROUTE.meta,
          component: () => import("./reports/SettlementDetailReport.vue"),
        },
        {
          path: config.UJO_TRACKING_REPORT_ROUTE.path,
          name: config.UJO_TRACKING_REPORT_ROUTE.name,
          meta: config.UJO_TRACKING_REPORT_ROUTE.meta,
          component: () => import("./reports/UjoTrackingReport.vue"),
        },
      ],
    },
  ],
};

export default router;
