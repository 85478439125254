import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import moment, { Moment } from "moment";

export default function dateFormat(
  value: string | number | Moment | Date,
  format = DEFAULT_DATE_FORMAT
): string {
  if (!value) return "-";

  return moment(value).format(format);
}
