import { displayNeg } from "@/helpers/common";
import {
  Row,
  useCalculator,
  useDeleteRows,
  useProduct,
  usePurchaseOrder,
  useReceiveItem,
  useTax,
} from "@/hooks";
import useAccountingCalculation from "@/hooks/useAccountingCalculation";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import { DATE_FORMAT_YYYY_MM_DD } from "@/models/constants/date.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { ProductDetailDto } from "@/models/interface/master-product";
import {
  ReceivingItemAssetRequestDto,
  ReceivingItemCreateRequestDto,
  ReceivingItemDraftResponseDto,
  ReceivingItemLineDraftResponseDto,
  ReceivingItemLineRequestDto,
} from "@/models/interface/receive-item";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import {
  initFormRow,
  initFormValue,
} from "@/store/resources/GoodsReceiptPrice.resource";
import { LabelInValue } from "@/types";
import Decimal from "decimal.js-light";
import { Moment } from "moment";
import { ModalState } from "./goodsReceiptChecklist.store";
import { initDetailGrDraft } from "./resources/GoodsReceiptChecklist.resource";

export type ReceiveItemLineDetail = Pick<
  ReceivingItemLineDraftResponseDto,
  | "productName"
  | "productCode"
  | "productUom"
  | "qtyOutstanding"
  | "taxCode"
  | "taxRate"
  | "inclusiveTaxRateId"
  | "inclusiveTaxRateName"
  | "inclusiveTaxRate"
>;
export type ReceiveItemRow = Row<
  Omit<ReceivingItemLineRequestDto, "locationReceivedId"> &
    ReceiveItemLineDetail & {
      locationReceived: LabelInValue | undefined;
      isSearchLocation: boolean;
      locationOptions: Option[];
      loadingLocation: boolean;
    },
  number
>;
export type ReceiveItemDetail = Pick<
  ReceivingItemDraftResponseDto,
  | "checklistNumber"
  | "currencyDesc"
  | "journalId"
  | "journalNo"
  | "purchaseOrderNumber"
  | "receiveNumber"
  | "supplierName"
  | "id"
  | "createdBy"
  | "createdDate"
  | "modifiedBy"
  | "modifiedDate"
>;
export type ReceiveItemCreateRequestWithoutLine = Omit<
  ReceivingItemCreateRequestDto,
  | "receiveItems"
  | "receiveDate"
  | "branchWarehouseId"
  | "grandTotal"
  | "totalDiscount"
  | "totalProductPrice"
  | "totalTax"
>;
export type FormValue = ReceiveItemCreateRequestWithoutLine &
  ReceiveItemDetail & {
    receiveItems: Array<ReceiveItemRow>;
    receiveDate: Moment | null;
    deletedReceiveItemIds?: Array<string>;
    branch: LabelInValue | undefined;
    grandTotal: number;
    totalDiscount: number;
    totalProductPrice: number;
    totalTax: number;
  };

export type State = {
  form: FormValue;
  modalState: ModalState;
  detail: ReceivingItemDraftResponseDto;

  inclusiveTaxRate: number;
  taxBaseVariable: number;
};

const state: State = {
  form: initFormValue(),
  modalState: {
    data: [],
    visible: false,
    toggle() {
      this.visible = !this.visible;
    },
  },
  detail: initDetailGrDraft(),
  inclusiveTaxRate: 0,
  taxBaseVariable: 1,
};

const getters = {
  getForm: (st: State): FormValue => {
    return st.form;
  },
  getTotalDiscount: (st: State): number => {
    const { sum } = useCalculator();
    const discount: Array<number> = st.form.receiveItems.map(e =>
      Number(e.discountValue)
    );
    return displayNeg(sum(discount));
  },
  getTotalBaseAmount: (st: State): number => {
    const { sum } = useCalculator();
    const taxBases: Array<number> = st.form.receiveItems.map(e =>
      Number(e.grossValue || 0)
    );
    return displayNeg(sum(taxBases));
  },
  getTotalTax: (st: State): number => {
    const { sum } = useCalculator();
    const totalTaxes: Array<number> = st.form.receiveItems.map(
      e => Number(e.tax) || 0
    );
    return displayNeg(sum(totalTaxes));
  },
  getGrandTotal: (st: State, getters): number => {
    const { sum } = useCalculator();
    const totals: Array<number> = st.form.receiveItems.map(
      e => Number(e.totalPrice) || 0
    );
    return displayNeg(sum(totals));
  },
  getDetail: (st: State): ReceivingItemDraftResponseDto => {
    return st.detail;
  },
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setDetail: (st: State, payload: ReceivingItemDraftResponseDto): void => {
    st.detail = payload;
  },
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    const form: FormValue = initFormValue();
    const detail: ReceivingItemDraftResponseDto = initDetailGrDraft();
    commit("setForm", form);
    commit("setDetail", detail);
  },
  toggleModalAsset: (
    context,
    payload: Array<ReceivingItemAssetRequestDto>
  ): void => {
    const { state } = context;
    const local: State = state;
    local.modalState.data = payload;
    local.modalState.toggle();
  },
  autofillFromPo: (context, poId: string): void => {
    const { commit, dispatch } = context;
    const { findById } = usePurchaseOrder();

    findById(poId).then(res => {
      const form: Partial<FormValue> = {
        supplierName: res.supplierName || "",
        supplierId: res.supplierId || "",
        supplierBillToAddress: res.supplierBillToAddress || "",
        currencyCode: res.currency || "",
        currencyRate: res.currencyRate || ONE,
        branch: {
          key: res.branchWarehouseId,
          label: res.branchWarehouseName,
        },
        description: res.description || "",
        taxType: res.taxType,
        receiveItems:
          res.purchaseOrderLines?.map((item, i) => {
            const row: ReceiveItemRow = initFormRow();

            row.discountValue = item.discountValue || 0;
            row.itemAssets = item.itemAssets;
            row.key = i;
            row.merk = item.merk || "";
            row.partNumber = item.productCode || "";
            row.price = item.price || 0;
            row.productCode = item.productCode || "";
            row.productId = item.productId || "";
            row.productName = item.productName || "";
            row.productUom = item.uom || "";
            row.productUomId = item.uomId || "";
            row.purchaseOrderLineId = item.id || "";
            row.qty = item.qtyOutstanding || 0;
            row.qtyPO = item.qtyOutstanding || 0;
            row.tax = String(item.taxValue || 0);
            row.taxRate = item.taxRate || 0;
            row.taxBase = String(item.baseAmount || 0);
            row.taxCode = item.taxCode || "";
            row.taxCodeId = item.taxCodeId || "";
            row.totalPrice = String(item.subTotal || 0);
            row.inclusiveTaxRateId = item.inclusiveTaxRateId || "";
            row.inclusiveTaxRateName = item.inclusiveTaxRateName || "";
            row.inclusiveTaxRate = item.inclusiveTaxRate || 0;

            return row;
          }) || [],
      };

      commit("setForm", form);
      dispatch("setDefaultLocationReceiveRow");
      dispatch("calculateLines");
    });
  },
  findInclusiveTaxRate(context): void {
    const { findById } = useTax();
    const { state, dispatch, rootGetters } = context;

    const taxRateId = rootGetters["preferenceStore/GET_PREFERENCE_BY_KEY"](
      "feature_purchase_inclusive_tax_rate"
    ).value;

    if (!taxRateId) {
      return;
    }

    findById(taxRateId).then(response => {
      state.inclusiveTaxRate = response.rate || 1;
      dispatch("calculateLines");
    });
  },
  findTaxBaseVariable(context): void {
    const { state, dispatch } = context;

    if (!state.form.receiveDate) {
      state.taxBaseVariable = 1;
      dispatch("calculateLines");
      return;
    }

    taxBaseVariableService
      .getVariable({
        isLuxury: !!state.form.isLuxuryGoods,
        transactionDate: state.form.receiveDate.format(DATE_FORMAT_YYYY_MM_DD),
      })
      .then(response => {
        state.taxBaseVariable = response.value || 1;
        dispatch("calculateLines");
      });
  },
  setDefaultLocationReceiveRow: (context): void => {
    const { state } = context;
    const { findById } = useProduct();
    const local: State = state;
    const promises: Promise<ProductDetailDto>[] = local.form.receiveItems.map(
      item => findById(item.productId)
    );
    Promise.all(promises).then(responses => {
      responses.forEach(({ locationReceiveId, locationReceiveName }, index) => {
        if (locationReceiveId) {
          local.form.receiveItems[index].locationReceived = {
            label: locationReceiveName,
            key: locationReceiveId,
          };
        }
      });
    });
  },
  removeLine: (context, rowKeys: Array<number>): void => {
    const { state, commit, dispatch } = context;
    const local: State = state;
    const { newSource, deletedRows } = useDeleteRows(
      local.form.receiveItems,
      rowKeys
    );
    const deletedIds: Array<string> = deletedRows
      .filter(e => !!e.id)
      .map<string>(e => e.id);
    const form: Partial<FormValue> = {
      receiveItems: newSource,
      deletedReceiveItemIds: deletedIds,
    };
    commit("setForm", form);
    dispatch("calculateLines");
  },
  calculateLines: (context): void => {
    const { state } = context;
    const {
      calculateGrossValue,
      calculateGrossAfterDiscount,
      calculateGrossAfterDiscountBeforeTax,
      calculateTaxBase,
      calculateTaxValue,
      calculateTotal,
    } = useAccountingCalculation();
    const local: State = state;
    const { form } = local;
    const { isLuxuryGoods, taxType } = form;

    local.form.receiveItems.forEach(row => {
      const { price, qty, discountValue, taxRate, inclusiveTaxRate } = row;
      let inclusiveTaxRatePercent = 0;
      if (taxType === TAX_CALCULATION.INCLUSIVE) {
        if (isLuxuryGoods) {
          inclusiveTaxRatePercent = taxRate;
        } else {
          inclusiveTaxRatePercent = inclusiveTaxRate;
        }
      }

      // Calculate
      const grossValue = calculateGrossValue({
        price,
        qty,
      });
      const grossAfterDiscount = calculateGrossAfterDiscount({
        grossValue,
        discountValue: new Decimal(discountValue),
      });
      const grossAfterDiscountBeforeTax = calculateGrossAfterDiscountBeforeTax({
        grossAfterDiscount,
        purchaseInclusiveRate: inclusiveTaxRatePercent,
        taxType: taxType as TAX_CALCULATION,
      });
      const taxBase = calculateTaxBase({
        grossAfterDiscountBeforeTax,
        variable: state.taxBaseVariable,
      });
      const taxValue = calculateTaxValue({
        taxBase,
        taxRate,
        taxType: local.form.taxType as string,
      });
      const total = calculateTotal({
        grossAfterDiscountBeforeTax,
        taxValue,
      });

      // Display
      row.grossValue = String(grossAfterDiscountBeforeTax);
      row.taxBase = String(taxBase);
      row.tax = String(taxValue);
      row.totalPrice = String(total);
    });
  },
  mapDetailDraftToForm: (
    context,
    payload: ReceivingItemDraftResponseDto
  ): void => {
    const { commit } = context;
    const { mapDetailDraftToFormPrice } = useReceiveItem();
    const form: FormValue = mapDetailDraftToFormPrice(payload);
    commit("setForm", form);
    commit("setDetail", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
