import { useCalculator } from "@/hooks";
import { CurrencyCodeEnum } from "@/models/enums/global.enum";
import { LeasingStateEnum } from "@/models/enums/Leasing.enum";
import { StringUtils } from "@/utils";
import { State } from "./types";

export const getters = {
  isIdr: (st: State): boolean => {
    return (
      !!st.form.currency &&
      st.form.currency.label.toUpperCase() === CurrencyCodeEnum.IDR
    );
  },
  isDocExist: (st: State): boolean => {
    return !!st.form.id;
  },
  sumInsurancePeriod: (st: State): number => {
    const { sum } = useCalculator();
    const amortize: Array<number> = st.form.periodList.map<number>(row => {
      return row.amortize || 0;
    });
    return sum(amortize);
  },
  sumPrincipalCost: (st: State): number => {
    const { sum } = useCalculator();
    const principalCost: Array<number> = st.form.periodList.map<number>(row => {
      return row.principalCost || 0;
    });
    return sum(principalCost);
  },
  sumInterestCost: (st: State): number => {
    const { sum } = useCalculator();
    const interestCost: Array<number> = st.form.periodList.map<number>(row => {
      return row.interestCost || 0;
    });
    return sum(interestCost);
  },
  sumPaymentCost: (st: State): number => {
    const { sum } = useCalculator();
    const paymentCost: Array<number> = st.form.periodList.map<number>(row => {
      return row.paymentCost || 0;
    });
    return sum(paymentCost);
  },
  disabledForm: ({ form: { status } }: State): boolean => {
    return status.toUpperCase() === LeasingStateEnum.SUBMITTED.toUpperCase();
  },
  isDraft: ({ form: { status } }: State): boolean => {
    return status.toUpperCase() === LeasingStateEnum.DRAFT.toUpperCase();
  },
  isSourceCar: ({ form }: State): boolean => {
    const { source } = form;
    return source !== undefined && StringUtils.compare(source, "Car");
  },
};
