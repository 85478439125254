import { TrialBalanceDTO } from "@/models/interface/generaljournal.interface";
import { generalJournalServices } from "@/services/generaljournal.service";
import { Moment } from "moment";
import useDate from "./useDate";

export interface ReqParamsTrialBalance {
  startDate: string;
  endDate: string;
}

export interface FormValue {
  asOfDate: Moment[];
  maxDate: Moment | null;
}

const useTrialBalanceReport = () => {
  const findAll = ({
    startDate,
    endDate,
  }: ReqParamsTrialBalance): Promise<Array<TrialBalanceDTO>> => {
    return generalJournalServices.getListTrialBalance(startDate, endDate);
  };

  const download = (params: ReqParamsTrialBalance) => {
    return generalJournalServices.downloadTrialBalance(params);
  };

  const initReqParams = (): ReqParamsTrialBalance => ({
    startDate: "",
    endDate: "",
  });

  const mapFormToReqParams = ({
    asOfDate,
  }: FormValue): ReqParamsTrialBalance => {
    const req: ReqParamsTrialBalance = initReqParams();
    const [start, end] = asOfDate;
    const { toStartDay, toEndDay } = useDate();
    req.startDate = toStartDay(start).format();
    req.endDate = toEndDay(end).format();

    return req;
  };

  return { findAll, initReqParams, mapFormToReqParams, download };
};

export default useTrialBalanceReport;
