// Core
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";

import {
  DetailGeneralJournal,
  RequestGeneralJournal,
  ResponseGeneralJournal,
  ResponseListGeneralJournal,
  TrialBalanceDTO,
} from "@/models/interface/generaljournal.interface";

export class GeneralJournalServices extends HttpClient {
  closeGeneralJournal = (payload: string): Promise<any> => {
    return this.post<any, string>(
      Api.generalJournal + "/close-period" + payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listGeneralJournal = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListGeneralJournal> => {
    return this.get<ResponseListGeneralJournal>(Api.generalJournal, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  createGeneralJournal = (
    payload: RequestGeneralJournal
  ): Promise<ResponseGeneralJournal> => {
    return this.post<ResponseGeneralJournal, RequestGeneralJournal>(
      Api.generalJournal,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  detailGeneralJournal = (id: string): Promise<DetailGeneralJournal> => {
    return this.get<DetailGeneralJournal>(Api.generalJournal + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteGeneralJournal(id: string): Promise<void> {
    return this.delete<void>(`${Api.generalJournal}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListTrialBalance(
    startDate: string,
    endDate: string
  ): Promise<Array<TrialBalanceDTO>> {
    return this.get<Array<TrialBalanceDTO>>(Api.TrialBalance, {
      params: { startDate, endDate },
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadTrialBalance(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.DownloadTrialBalance, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateGeneralJournal = (
    id: string,
    payload: RequestGeneralJournal
  ): Promise<ResponseGeneralJournal> => {
    return this.put<ResponseGeneralJournal, RequestGeneralJournal>(
      `${Api.generalJournal}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  postingGeneralJournal = (
    id: string,
    payload: RequestGeneralJournal
  ): Promise<ResponseGeneralJournal> => {
    return this.put<ResponseGeneralJournal, RequestGeneralJournal>(
      `${Api.generalJournal}/posting/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printGeneralJournal(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.generalJournal + "/print/" + id, {
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const generalJournalServices = new GeneralJournalServices();
