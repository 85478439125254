import { Option } from "@/models/class/option.class";
import { ResponseProductCategoryByLocation } from "@/models/interface/product.interface";
import { productService } from "@/services/product.service";

export const useFindProductCategoryByLocation = async (
  locationId: string
): Promise<ResponseProductCategoryByLocation[]> => {
  return await productService.listProductCategoryByLocation(locationId);
};

export const useMapProductCategoryLocationToOptions = (
  values: ResponseProductCategoryByLocation[]
): Option<ResponseProductCategoryByLocation>[] => {
  return values.map<Option<ResponseProductCategoryByLocation>>(el => ({
    label: el.name,
    value: el.id,
    meta: el,
    key: el.id,
  }));
};
