


















import Vue from "vue";

export default Vue.extend({
  name: "AppSubMenu",
  props: {
    menus: {
      type: Object,
      required: false,
    },
  },
});
