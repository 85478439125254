import { deliveryOrderServices } from "@/services/deliveryorder.service";

const useDeliveryOrder = () => {
  const print = (id: string) => {
    return deliveryOrderServices.getPrintDeliveryOrder(id);
  };

  return { print };
};
export default useDeliveryOrder;
