import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  TruckingMasterDriverParamDownload,
  TruckingMasterDriverResponseDto,
} from "@/models/interface/trucking-master-driver";
import {
  TruckingMasterRateResponseDto,
  TruckingMasterRateUpdateRequestDto,
} from "@/models/interface/trucking-master-rate";
import { truckingService } from "@/services/trucking.service";

const useTrucking = () => {
  const findListMasterDrivers = (
    params?: RequestQueryParams
  ): Promise<Pagination<TruckingMasterDriverResponseDto>> => {
    return truckingService.listOfMasterDrivers(params);
  };

  const findListMasterRates = (
    params?: RequestQueryParams
  ): Promise<Pagination<TruckingMasterRateResponseDto>> => {
    return truckingService.listOfMasterRates(params);
  };

  const downloadListMasterDrivers = (
    params: TruckingMasterDriverParamDownload
  ): Promise<Blob> => {
    return truckingService.downloadListMasterDrivers(params);
  };

  const downloadListMasterRates = (
    params: TruckingMasterDriverParamDownload
  ): Promise<Blob> => {
    return truckingService.downloadListMasterRates(params);
  };

  const updateMasterRate = (
    id: string,
    payload: TruckingMasterRateUpdateRequestDto
  ): Promise<void> => {
    return truckingService.updateMasterRate(id, payload);
  };

  return {
    findListMasterDrivers,
    findListMasterRates,
    downloadListMasterDrivers,
    downloadListMasterRates,
    updateMasterRate,
  };
};

export { useTrucking };
