// Interfaces
import { RoutePathModel } from "../interface/common.interface";

export const INQUIRYASSETS_ROUTE: RoutePathModel = {
  path: "inquiryasset",
  url: "/inquiryasset",
  name: "Inquiry Asset",
  meta: {
    title_head: 'Inquiry Asset',
    need_auth: true
  }
}