import { generateUUID } from "@/helpers/uuid";
import { ONE } from "@/models/constant/global.constant";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { SalesOrderTypeEnum } from "@/models/enums/SalesOrderType.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { FormValue, SalesOrderLine } from "@/store/salesOrder.store";
import Decimal from "decimal.js-light";

export function buildSalesOrderFormState(): FormValue {
  return {
    documentNumber: "",
    salesType: "" as SalesOrderTypeEnum,
    internalContractId: "",
    internalContractNumber: "",
    branchId: "",
    branchName: "",
    date: null,
    customerId: "",
    customerName: "",
    shipTo: "",
    billTo: "",
    salesPerson: undefined,
    taxCalculation: TAX_CALCULATION.EXCLUSIVE,
    customerPoNumber: "",
    deliveryDate: null,
    currencyCode: "",
    currencyRate: ONE,
    top: 0,
    notes: "",
    status: "" as SALES_ORDER_STATUS,
    closeReason: "",
    isLuxury: false,
    taxBaseVariable: 1,
  };
}

export function buildSalesOrderProductState(): SalesOrderLine {
  return {
    inclusiveTaxRate: 0,
    inclusiveTax: undefined,
    assetId: "",
    assetParentId: "",
    backupUnit: false,
    customerLocation: "",
    discountValue: 0, // use this one as discount calculation
    grossValue: 0,
    id: "",
    internalContractLineId: "",
    key: generateUUID(),
    locationId: "",
    locationName: "",
    percentDiscount: 0, // this one is not used rn
    price: 0,
    productCode: "",
    productId: "",
    productName: "",
    qty: 0,
    qtyAvailable: 0,
    serialNumber: "",
    subTotal: new Decimal(0),
    taxableValue: new Decimal(0),
    taxCode: undefined,
    taxRate: 0,
    taxValue: new Decimal(0),
    unitCode: "",
    uomId: "",
    uomName: "",
  };
}
