import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  LastSyncResponseDto,
  ListCompanyBankDto,
} from "@interface/company-bank";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class CompanyBankService extends HttpClient {
  getList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListCompanyBankDto>> {
    return this.get<Pagination<ListCompanyBankDto>>(Api.BankCompany, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  syncAll(): Promise<boolean> {
    return this.post<boolean>(Api.BankCompanySyncAll)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getLastSyncDate(): Promise<LastSyncResponseDto> {
    return this.get<LastSyncResponseDto>(Api.BankCompanyLastSyncDate)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const companyBankService = new CompanyBankService();
