import { salesOrderServices } from "@/services/salesorder.service";

const usePickingList = () => {
  const print = (ids: string[]) => {
    return salesOrderServices.getReportPrintPickingList(ids);
  };

  return { print };
};

export default usePickingList;
