import { render, staticRenderFns } from "./TableDetailsPrepayment.vue?vue&type=template&id=0686a984&"
import script from "./TableDetailsPrepayment.vue?vue&type=script&lang=ts&"
export * from "./TableDetailsPrepayment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/SPP/spp-erp-fe-development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0686a984')) {
      api.createRecord('0686a984', component.options)
    } else {
      api.reload('0686a984', component.options)
    }
    module.hot.accept("./TableDetailsPrepayment.vue?vue&type=template&id=0686a984&", function () {
      api.rerender('0686a984', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account-payables/invoices-prepayment-debit-memo/TableDetailsPrepayment.vue"
export default component.exports