import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { IArDataResponse } from "@/models/interface/AccountReceivables.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { arService } from "@/services/ar.service";
import { useFindMasterType, useMapMasterTypeToOption } from "./master-type";

const useArReceipt = () => {
  const fetchReceiptType = async () => {
    const response = await useFindMasterType("INVOICE_RECEIPT_TYPE");
    return useMapMasterTypeToOption(response);
  };

  const toOptions = (
    data: Array<IArDataResponse>
  ): Array<Option<IArDataResponse>> => {
    return data.map<Option<IArDataResponse>>(item => ({
      meta: item,
      label: item.documentNumber,
      key: item.id,
      value: item.id,
    }));
  };

  const findMasterInvoiceType = () => {
    return useFindMasterType("AR_RECEIPT_INVOICE_TYPE");
  };

  const findAll = (params: RequestQueryParamsModel) => {
    return arService.getArReceipts(params);
  };

  const filterBy = (field: { docNumber: string }): string => {
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (field.docNumber) {
      q.push(
        builder
          .push(["documentNumber", field.docNumber], { like: "both" })
          .build()
      );
    }

    return q.join(builder.OR);
  };

  return {
    fetchReceiptType,
    findAll,
    toOptions,
    filterBy,
    findMasterInvoiceType,
  };
};

export default useArReceipt;
