import { DataCoa } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";

const useCoaListFindById = async (id: string): Promise<DataCoa> => {
  const { data } = await settingsServices.listOfCoa(
    { search: `secureId~${id}` },
    ""
  );
  const [item] = data;
  return item as DataCoa;
};

export { useCoaListFindById };
