import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const AMORTIZATION_ROUTE: RoutePathModel = {
  path: "amortizations",
  url: "/amortizations",
  name: "amortizations",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization",
      },
    ],
    title_head: "lbl_amortization",
    need_auth: true,
  },
};

export const CLOSE_AMORTIZATION_ROUTE: RoutePathModel = {
  path: "amortizations/:id/close",
  url: "/amortizations/:id/close",
  name: "amortizations.close",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization_detail",
      },
      {
        name: "lbl_close",
      },
    ],
    title_head: "lbl_amortization",
    need_auth: true,
  },
};

export const DETAIL_CLOSE_AMORTIZATION_ROUTE: RoutePathModel = {
  path: "amortizations/:id/close/detail/:unitId",
  url: "/amortizations/:id/close/detail/:unitId",
  name: "amortizations.close.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization_detail",
        href: `/account-payables/amortizations/:id`,
      },
      {
        name: "lbl_close",
        href: `/account-payables/amortizations/:id/close/detail`,
      },
    ],
    title_head: "lbl_amortization",
    need_auth: true,
  },
};

export const AMORTIZATION_CREATE_ROUTE: RoutePathModel = {
  path: "amortizations/create",
  url: "/amortizations/create",
  name: "amortizations.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization",
        href: "/account-payables/amortizations",
      },
      {
        name: "lbl_create_amortization",
      },
    ],
    title_head: "lbl_create_amortization",
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const AMORTIZATION_DETAIL_ROUTE: RoutePathModel = {
  path: "amortizations/:id",
  url: "/amortizations/:id",
  name: "amortizations.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization",
        href: "/account-payables/amortizations",
      },
      {
        name: "lbl_detail_amortization",
      },
    ],
    title_head: "lbl_detail_amortization",
    need_auth: true,
    mode: Mode.DETAIL,
  },
};

export const AMORTIZATION_EDIT_ROUTE: RoutePathModel = {
  path: "amortizations/:id/edit",
  url: "/amortizations/:id/edit",
  name: "amortizations.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization",
        href: "/account-payables/amortizations",
      },
      {
        name: "lbl_edit_amortization",
      },
    ],
    title_head: "lbl_edit_amortization",
    need_auth: true,
    mode: Mode.EDIT,
  },
};
