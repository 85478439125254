var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "a-sub-menu",
    { key: _vm.props.menus.key },
    [
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("a-icon", { attrs: { type: _vm.props.menus.icon } }),
          _c("span", [_vm._v(_vm._s(_vm.parent.$t(_vm.props.menus.title)))])
        ],
        1
      ),
      _vm._l(_vm.props.menus.children, function(menu) {
        return [
          menu.show && !menu.children.length
            ? _c(
                "a-menu-item",
                { key: menu.key },
                [
                  _c(
                    "router-link",
                    { attrs: { to: menu.route } },
                    [
                      _c("a-icon", { attrs: { type: menu.icon } }),
                      _vm._v(" " + _vm._s(_vm.parent.$t(menu.title)) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            : menu.show
            ? _c("AppSubMenu", { key: menu.key, attrs: { menus: menu } })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }