import {
  TruckingPaymentDetailResponseDto,
  TruckingPaymentDownloadParam,
  TruckingPaymentHeaderList,
  TruckingPaymentListParam,
} from "@/models/interface/trucking-payment";
import { truckingPaymentService } from "@/services/trucking-payment.service";

export const useTruckingPayment = () => {
  const findListPayments = (
    params?: TruckingPaymentListParam
  ): Promise<TruckingPaymentHeaderList> => {
    return truckingPaymentService.listOfPayments(params);
  };

  const downloadListPayment = (
    params: TruckingPaymentDownloadParam
  ): Promise<Blob> => {
    return truckingPaymentService.downloadListPayment(params);
  };

  const findPaymentDetail = (
    paymentNumber: string
  ): Promise<TruckingPaymentDetailResponseDto> => {
    return truckingPaymentService.findPaymentDetail(paymentNumber);
  };

  const findPaymentListByIds = (
    ids?: string[]
  ): Promise<TruckingPaymentDetailResponseDto[]> => {
    return truckingPaymentService.getPaymentListByIds(ids);
  };

  return {
    findListPayments,
    downloadListPayment,
    findPaymentDetail,
    findPaymentListByIds,
  };
};
