import { generateUUID } from "@/helpers/uuid";
import {
  AmortizationFormBasePeriodState,
  AmortizationFormState,
  AmortizationFormUnitPeriodState,
  AmortizationFormUnitState,
} from "@/models/interface/amortization";

export const buildAmortizationFormBasePeriodState =
  (): AmortizationFormBasePeriodState => ({
    endPeriod: null,
    key: generateUUID(),
    startPeriod: null,
    year: 1,
  });

export const buildAmortizationFormState = (): AmortizationFormState => {
  return {
    branch: undefined,
    companyBank: undefined,
    currency: undefined,
    currencyRate: 1,
    description: "",
    documentNumber: "",
    expenseAccount: undefined,
    grandTotal: 0,
    leasingContractNumber: undefined,
    leasingNumber: undefined,
    lessor: undefined,
    prepaidAccount: undefined,
    transactionDate: null,
    transactionType: undefined,
    units: [],
    useCashOut: false,
    amortizationId: null,
    status: undefined,
    deletedUnitIds: [],
    deletedPeriodIds: [],
  };
};

export const buildAmortizationFormUnitState = (): AmortizationFormUnitState => {
  return {
    brand: undefined,
    key: generateUUID(),
    note: undefined,
    periods: [],
    serialNumber: undefined,
    subTotal: 0,
    type: undefined,
    unitCode: undefined,
    acquisitionDate: undefined,
    id: null,
  };
};

export const buildAmortizationFormUnitPeriodState =
  (): AmortizationFormUnitPeriodState => {
    return {
      amount: 0,
      endPeriod: null,
      key: generateUUID(),
      startPeriod: null,
      year: 1,
      id: null,
    };
  };
