import printJS, { Configuration } from "print-js";

const usePrint = () => {
  const print = (data: string, config?: Configuration) => {
    printJS({ ...config, printable: data });
  };

  return { print };
};

export default usePrint;
