import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const CASH_MANAGEMENT_ROUTE: RoutePathModel = {
  path: "/cash-management",
  url: "/cash-management",
  name: "lbl_cash_management",
  meta: {
    breadcrumb: "lbl_cash_management",
    title_head: "lbl_cash_management",
    need_auth: true,
  },
};
export const BANK_TRANSTER_LIST_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer`,
  name: "cash-management.bank-transfer",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_bank_transfer",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer`,
      },
    ],
    title_head: "lbl_bank_transfer",
    mode: Mode.VIEW,
  },
};
export const BANK_TRANSTER_CREATE_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer/create`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer/create`,
  name: "cash-management.bank-transfer.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_bank_transfer",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer`,
      },
      {
        name: "Bank Transfer Create",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer/create`,
      },
    ],
    title_head: "lbl_bank_transfer",
    mode: Mode.CREATE,
  },
};
export const BANK_TRANSTER_EDIT_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer/edit/:id`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer/edit/:id`,
  name: "cash-management.bank-transfer.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_bank_transfer",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer`,
      },
      {
        name: "bank transfer edit",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/bank-transfer/edit/:id`,
      },
    ],
    title_head: "lbl_bank_transfer",
    mode: Mode.EDIT,
  },
};
export const BANK_CASH_LIST_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out`,
  name: "cash-management.cash-in-out",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_cash_in_out",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out`,
      },
    ],
    title_head: "lbl_cash_in_out",
    mode: Mode.VIEW,
  },
};
export const BANK_CASH_CREATE_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out/create`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out/create`,
  name: "cash-management.cash-in-out.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_cash_in_out",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out`,
      },
      {
        name: "Cash In / Out Create",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out/create`,
      },
    ],
    title_head: "lbl_cash_in_out",
    mode: Mode.CREATE,
  },
};
export const BANK_CASH_EDIT_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out/edit/:id`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out/edit/:id`,
  name: "cash-management.cash-in-out.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_cash_in_out",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out`,
      },
      {
        name: "lbl_cash_in_out_edit",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/cash-in-out/edit/:id`,
      },
    ],
    title_head: "lbl_cash_in_out",
    mode: Mode.EDIT,
  },
};
export const BANK_STATEMENT_REPORT: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/reports/cash-flow`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/reports/cash-flow`,
  name: "lbl_bank_statement_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_bank_statement_report",
        href: `${CASH_MANAGEMENT_ROUTE.url}/reports/cash-flow`,
      },
    ],
    title_head: "lbl_bank_statement_report",
    mode: Mode.VIEW,
  },
};

export const CASH_MANAGEMENT_CLOSE_PERIOD_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/closeperiod`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/closeperiod`,
  name: "lbl_open_close_transaction",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_open_close_transaction",
        href: `${CASH_MANAGEMENT_ROUTE.url}/closeperiod`,
      },
    ],
    title_head: "lbl_open_close_transaction",
    mode: Mode.VIEW,
  },
};
export const SETTLEMENT_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement`,
  name: "lbl_settlement",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_settlement",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement`,
      },
    ],
    title_head: "lbl_settlement",
    mode: Mode.VIEW,
  },
};
export const SETTLEMENT_CREATE_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement/create`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement/create`,
  name: "lbl_settlement_create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_settlement",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement`,
      },
      {
        name: "lbl_settlement_create",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transaction/settlement/create`,
      },
    ],
    title_head: "lbl_settlement_create",
    mode: Mode.CREATE,
  },
};
export const SETTLEMENT_EDIT_ROUTE: RoutePathModel = {
  path: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement/edit/:id`,
  url: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement/edit/:id`,
  name: "cashmanagement.transaction.settlement",
  meta: {
    breadcrumb: [
      {
        name: "lbl_cash_management",
        icon: "fast-forward",
      },
      {
        name: "lbl_transaction",
        icon: "menu-fold",
      },
      {
        name: "lbl_settlement",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement`,
      },
      {
        name: "lbl_settlement_edit",
        href: `${CASH_MANAGEMENT_ROUTE.url}/transactions/settlement/edit/:id`,
      },
    ],
    title_head: "lbl_settlement_edit",
    mode: Mode.EDIT,
  },
};
