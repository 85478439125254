export type Row<TData = any, TKey = number> = {
  key: TKey;
  no?: number;
} & TData;

export type APagination = {
  total: number;
  current: number;
  pageSize: number;
};

export const useDeleteRows = <T>(
  source: Row<T>[],
  selectedRowKeys: number[]
): { newSource: Row<T>[]; deletedRows: Row<T>[] } => {
  let $deletedLines: Row<T>[] = [];
  const $source = source.filter(el => {
    if (selectedRowKeys.includes(el.key)) {
      $deletedLines = [...$deletedLines, el];
    }
    return !selectedRowKeys.includes(el.key);
  });
  return {
    newSource: $source,
    deletedRows: $deletedLines,
  };
};

export const useRemoveRows = <T>(
  source: Row<T, string>[],
  selectedRowKeys: string[]
): { newSource: Row<T, string>[]; deletedRows: Row<T, string>[] } => {
  let $deletedLines: Row<T, string>[] = [];
  const $source = source.filter(el => {
    if (selectedRowKeys.includes(el.key)) {
      $deletedLines = [...$deletedLines, el];
    }
    return !selectedRowKeys.includes(el.key);
  });
  return {
    newSource: $source,
    deletedRows: $deletedLines,
  };
};
