import { agingServices } from "@/services/aging.service";
import { ReportAgingDashboardDetailParams } from "@interface/ar-aging-report/v2";

const useArAgingReport = () => {
  const findAllDashboard = () => {
    return agingServices.getAgingDashboard();
  };

  const findDetail = (params?: ReportAgingDashboardDetailParams) => {
    return agingServices.getAgingDetailDashboard(params);
  };

  return {
    findAllDashboard,
    findDetail,
  };
};

export default useArAgingReport;
