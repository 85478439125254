














































import { INavMenu } from "@/models/interfaces/navigation.interface";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import AppSubMenu from "./AppSubMenu.vue";

export default Vue.extend({
  name: "AppSidebar",
  components: {
    AppSubMenu,
  },
  computed: {
    ...mapState({
      menus: "listMenu",
      authStore: "authStore",
    }),
    ...mapGetters({
      userMenus: "authStore/GET_USER_MENU",
    }),
    companyLogo() {
      return this.$sanitize(
        this.authStore.authData.companyLogo ?? "../../assets/image/logo.png"
      );
    },
    hasVisibleMenus(): boolean {
      const menus: INavMenu[] = this.filteredMenus as any;
      return menus.some(menu => menu.show);
    },
    filteredMenus(): INavMenu[] {
      if (!this.searchText) {
        return this.menus;
      }

      return this.filterMenusByText(this.menus, this.searchText.toLowerCase());
    },
  },
  data() {
    return {
      searchText: "",
    };
  },
  mounted() {
    this.initMenu(this.menus);
  },
  methods: {
    initMenu(navs: INavMenu[]): void {
      const constrains: Array<string> = this.userMenus;

      navs.forEach(nav => {
        constrains.forEach(con => {
          if (nav.menus.includes(con)) {
            nav.show = true;
          }
        });

        if (nav.children.length) {
          this.initMenu(nav.children);
        }
      });
    },
    filterMenusByText(menus: INavMenu[], searchText: string): INavMenu[] {
      return menus.map(menu => {
        const menuTitle = this.$t(menu.title).toString().toLowerCase();
        const matchesSearch = menuTitle.includes(searchText);

        let filteredChildren: INavMenu[] = [];
        if (menu.children.length) {
          filteredChildren = this.filterMenusByText(menu.children, searchText);
        }

        // Show menu if it matches search or has matching children
        const shouldShow =
          menu.show &&
          (matchesSearch || filteredChildren.some(child => child.show));

        return {
          ...menu,
          show: shouldShow,
          children: filteredChildren,
        };
      });
    },
  },
});
