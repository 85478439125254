import { SearchBuilder } from "@/builder";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DATE_FORMAT_MMM_YYYY } from "@/models/constants/date.constant";
import { RecurringInvoiceOverdueFilterEnum } from "@/models/enums/RecurringInvoiceOverdueFilter.enum";
import { InvoiceIcBillingOverdueResponseDto } from "@/models/interface/invoice-overdue";
import { invoiceOverdueService } from "@/services/invoiceOverdue.service";
import store from "@/store";
import { LabelInValue } from "@/types";
import moment, { Moment } from "moment";
import useDate from "./useDate";

export type FormValue = Partial<{
  filterBy: RecurringInvoiceOverdueFilterEnum;
  date: Array<Moment> | null;
  customerName: string;
  customerId: string;
  unitBackup: string;
  icCreatedBy: LabelInValue;
}>;

const useInvoiceOverdue = () => {
  const findAll = (
    params: RequestQueryParams
  ): Promise<Pagination<InvoiceIcBillingOverdueResponseDto>> => {
    return invoiceOverdueService.getInvoiceOverdue(params);
  };

  const download = (params: RequestQueryParams): Promise<Blob> => {
    return invoiceOverdueService.getInvoiceOverdueDownload(params);
  };

  const mapFormToParamHeader = ({
    customerName = "",
    date,
    filterBy,
    icCreatedBy,
  }: FormValue): string => {
    const { toDefaultFormat } = useDate();
    const company: string =
      store.state["authStore"].authData.companyName || "ALL";

    let dateBy = "ALL";
    if (filterBy === "CUSTOM" && date && date.length) {
      const [start, end] = date;
      dateBy = `${toDefaultFormat(start)} - ${toDefaultFormat(end)}`;
    } else if (filterBy === "THIS_MONTH") {
      dateBy = moment().format(DATE_FORMAT_MMM_YYYY);
    } else if (filterBy === "THIS_WEEK") {
      const monday = toDefaultFormat(moment().startOf("isoWeek"));
      const sunday = toDefaultFormat(moment().endOf("isoWeek"));
      dateBy = `${sunday} - ${monday}`;
    } else if (filterBy === "TODAY") {
      dateBy = toDefaultFormat(moment());
    }

    const headerReport: Array<string> = [
      company,
      dateBy,
      customerName || "ALL",
      icCreatedBy?.label || "ALL",
    ];

    return headerReport.join(",");
  };

  const mapFormToQueryParams = ({
    filterBy,
    date,
    customerId,
    unitBackup,
    icCreatedBy,
  }: Partial<FormValue>): RequestQueryParams => {
    const { toStartDay, toEndDay } = useDate();
    const query: Array<string> = [];
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    if (filterBy) {
      params.date = filterBy;
    }

    if (date && date.length > 0) {
      const [start, end] = date;
      query.push(
        builder
          .push(["billingDate", toStartDay(start).format()], {
            het: true,
          })
          .and()
          .push(["billingDate", toEndDay(end).format()], {
            let: true,
          })
          .build()
      );
    }

    if (customerId) {
      query.push(builder.push(["customerId", customerId]).build());
    }

    if (unitBackup) {
      query.push(
        builder
          .push(["isUnitBackup", (unitBackup === "YES").toString()])
          .build()
      );
    }

    if (icCreatedBy?.label) {
      query.push(
        builder.push(["createdBy", icCreatedBy.label], { like: "both" }).build()
      );
    }

    params.search = query.join(builder.AND);

    return params;
  };

  return { findAll, download, mapFormToQueryParams, mapFormToParamHeader };
};

export default useInvoiceOverdue;
