import lang_en from "@/assets/i18n/en.json";
import lang_id from "@/assets/i18n/id.json";
import { Locales } from "@/models/enums/global.enum";
import LocalStorageService from "@/services/LocalStorage.service";
import Vue from "vue";
import VueI18n from "vue-i18n";

function loadPersistance(): string {
  return LocalStorageService.getCurrentLang() || Locales.ENGLISH;
}

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: loadPersistance(),
  fallbackLocale: Locales.ENGLISH,
  messages: { en: lang_en, id: lang_id },
});
