import { RoutePathModel } from "../interface/common.interface";

export const AMORTIZATION_ROUTE: RoutePathModel = {
  path: "amortizations",
  url: "/amortizations",
  name: "amortizations",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization",
      },
    ],
    title_head: "lbl_amortization",
    need_auth: true,
  },
};

export const CLOSE_AMORTIZATION_ROUTE: RoutePathModel = {
  path: "amortizations/:id/close",
  url: "/amortizations/:id/close",
  name: "amortizations.close",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization_detail",
        href: `/account-payables/amortizations/:id`,
      },
      {
        name: "lbl_close",
        href: `/account-payables/amortizations/:id/close`,
      },
    ],
    title_head: "lbl_amortization",
    need_auth: true,
  },
};

export const DETAIL_CLOSE_AMORTIZATION_ROUTE: RoutePathModel = {
  path: "amortizations/:id/close/detail",
  url: "/amortizations/:id/close/detail",
  name: "amortizations.close.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance",
        icon: "read",
      },
      {
        name: "lbl_account_payables",
        icon: "retweet",
      },
      {
        name: "lbl_amortization_detail",
        href: `/account-payables/amortizations/:id`,
      },
      {
        name: "lbl_close",
        href: `/account-payables/amortizations/:id/close/detail`,
      },
    ],
    title_head: "lbl_amortization",
    need_auth: true,
  },
};
