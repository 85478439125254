import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  BaseResponse,
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  RequestParamsDownloadLeasingCard,
  ResponseListInsuranceAmortize,
  ResponseListLeasing,
  ResponseListLeasingUnitCode,
  ResponseReportListLeasingTracking,
} from "@/models/interface/leasing.interface";
import { CashInOutCreateResponseDto } from "@interface/cash-in-out";
import {
  LeasingCreateRequestDto,
  LeasingDetailResponseDto,
  LeasingInvoiceApResponseDto,
  LeasingUpdatePolicyNumberDto,
  ListLeasingHeaderDto,
  PaymentRealizationCreateDto,
} from "@interface/leasing";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class LeasingServices extends HttpClient {
  downloadInsuranceAmortize(params: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.Leasing + "/insurance/amortize/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  listInsuranceAmortize(
    params: RequestQueryParamsModel
  ): Promise<ResponseListInsuranceAmortize> {
    return this.get<ResponseListInsuranceAmortize>(
      Api.Leasing + "/insurance/amortize/report",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @deprecated
   * new service {@linkcode getList}
   */
  listLeasing(params: RequestQueryParamsModel): Promise<ResponseListLeasing> {
    return this.get<ResponseListLeasing>(Api.Leasing, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListLeasingHeaderDto>> {
    return this.get<Pagination<ListLeasingHeaderDto>>(Api.Leasing, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  reportListLeasingTracking(
    params: RequestQueryParamsModel
  ): Promise<ResponseReportListLeasingTracking> {
    return this.get<ResponseReportListLeasingTracking>(
      Api.Leasing + "/tracking/report",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  downloadreportListLeasingTracking(
    params: RequestQueryParamsModel
  ): Promise<Blob> {
    return this.get<Blob>(Api.Leasing + "/tracking/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
  listLeasingUnitCode(
    params: RequestQueryParamsModel
  ): Promise<ResponseListLeasingUnitCode> {
    return this.get<ResponseListLeasingUnitCode>(Api.Leasing + "/unit-code", {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  create(payload: LeasingCreateRequestDto): Promise<BaseResponse> {
    return this.post<BaseResponse, LeasingCreateRequestDto>(
      Api.Leasing,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  update(id: string, payload: LeasingCreateRequestDto): Promise<BaseResponse> {
    return this.put<BaseResponse, LeasingCreateRequestDto>(
      `${Api.Leasing}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submit(
    id: string,
    payload: LeasingCreateRequestDto
  ): Promise<LeasingDetailResponseDto> {
    return this.put<LeasingDetailResponseDto, LeasingCreateRequestDto>(
      `${Api.Leasing}/submit/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelLeasing(id: string): Promise<BaseResponse> {
    return this.put<BaseResponse>(`${Api.Leasing}/cancel/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  savePolicyNoLeasing(
    leasingId: string,
    payload: LeasingUpdatePolicyNumberDto
  ): Promise<LeasingDetailResponseDto> {
    return this.put<LeasingDetailResponseDto, LeasingUpdatePolicyNumberDto>(
      `${Api.Leasing}/update/policy-number/${leasingId}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetail(id: string): Promise<LeasingDetailResponseDto> {
    return this.get<LeasingDetailResponseDto>(`${Api.Leasing}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createLeasingPayment(
    periodId: string,
    request: PaymentRealizationCreateDto
  ): Promise<CashInOutCreateResponseDto> {
    return this.post<CashInOutCreateResponseDto, PaymentRealizationCreateDto>(
      `${Api.Leasing}/payment/${periodId}`,
      request
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createLeasingInsurancePayment(id: string): Promise<any> {
    return this.post<string, any>(Api.Leasing + "/payment/insurance/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadLeasingCard(params: RequestParamsDownloadLeasingCard): Promise<Blob> {
    return this.get<Blob>(`${Api.Leasing}/download`, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @description cancel payment leasing by line id
   * @param id line secure id
   */
  cancelPayment(id: string): Promise<CashInOutCreateResponseDto> {
    return this.post<CashInOutCreateResponseDto, never>(
      `${Api.Leasing}/payment/cancel/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  /**
   * @description get details invoice ap for leasing
   * @param invoiceApIds ids
   */
  getInvoiceLeasing(
    invoiceApIds: string[]
  ): Promise<LeasingInvoiceApResponseDto[]> {
    const ids = invoiceApIds.join(",");
    return this.get<LeasingInvoiceApResponseDto[]>(
      Api.LeasingInvoiceApGenerate,
      {
        params: {
          ids: ids,
        },
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const leasingServices = new LeasingServices();
