import { ONE } from "@/models/constant/global.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RoundingUtils } from "@/utils/RoundingUtils";
import Decimal from "decimal.js-light";
import useCalculator from "./useCalculator";

const useSalesOrderCalculation = () => {
  /** @deprecated */
  const calculate = (
    price: number,
    qty: number,
    discount: number,
    taxRate: number,
    taxCalculation: TAX_CALCULATION
  ) => {
    let taxAmount = 0;
    let dpp = 0;
    let subtotal = 0;

    const grossValue = RoundingUtils.round(
      new Decimal(price).times(qty),
      Decimal.ROUND_HALF_EVEN
    );
    const afterDiscount = RoundingUtils.round(
      new Decimal(grossValue).minus(discount),
      Decimal.ROUND_HALF_EVEN
    );

    if (taxCalculation === TAX_CALCULATION.EXCLUSIVE) {
      const tax = RoundingUtils.round(
        new Decimal(taxRate).dividedBy(100).times(afterDiscount),
        Decimal.ROUND_HALF_EVEN
      );

      dpp = afterDiscount.toNumber();
      taxAmount = tax.toNumber();
    } else if (taxCalculation === TAX_CALCULATION.INCLUSIVE) {
      const taxPercent = new Decimal(taxRate).dividedBy(100);
      const denumerator = taxPercent.plus(ONE);

      dpp = RoundingUtils.round(
        new Decimal(afterDiscount).dividedBy(denumerator),
        Decimal.ROUND_HALF_EVEN
      ).toNumber();
      taxAmount = RoundingUtils.round(
        new Decimal(afterDiscount).minus(dpp),
        Decimal.ROUND_HALF_EVEN
      ).toNumber();
    } else if (taxCalculation === TAX_CALCULATION.NONE) {
      dpp = afterDiscount.toNumber();
    }

    if (taxCalculation === TAX_CALCULATION.NONE) {
      subtotal = afterDiscount.toNumber();
    } else if (
      taxCalculation === TAX_CALCULATION.INCLUSIVE ||
      taxCalculation === TAX_CALCULATION.EXCLUSIVE
    ) {
      subtotal = RoundingUtils.round(
        new Decimal(dpp).plus(taxAmount),
        Decimal.ROUND_HALF_EVEN
      ).toNumber();
    }

    return {
      taxAmount,
      dpp,
      subtotal,
    };
  };

  const countPrice = ({
    price,
    qty,
    discount,
    taxRate,
    taxCalculation,
    isLuxury = false,
    taxBaseVariable = 1,
    salesInclusiveTaxRate = 0,
  }: {
    price: number;
    qty: number;
    discount: number;
    taxRate: number;
    taxCalculation: TAX_CALCULATION;
    isLuxury?: boolean;
    taxBaseVariable?: number;
    salesInclusiveTaxRate?: number;
  }) => {
    const { round } = useCalculator();
    const isTaxExcl = taxCalculation === TAX_CALCULATION.EXCLUSIVE;
    const isTaxIncl = taxCalculation === TAX_CALCULATION.INCLUSIVE;
    const MAX_PRECISION = 14;
    let dpp: Decimal;
    let taxAmount: Decimal;
    let subtotal: Decimal;
    let finalGrossValue: Decimal;

    const grossValue: Decimal = new Decimal(price || 0)
      .times(qty || 0)
      .minus(discount || 0);
    finalGrossValue = round(grossValue);

    if (isTaxExcl) {
      const taxBase: Decimal = round(grossValue.times(taxBaseVariable));
      dpp = taxBase;

      const taxPercent: Decimal = new Decimal(taxRate || 0).dividedBy(100);
      const taxValue: Decimal = taxBase.times(taxPercent);
      taxAmount = round(taxValue);
      subtotal = round(grossValue.plus(taxValue));
    } else if (isTaxIncl && isLuxury) {
      const selectedTaxRate = new Decimal(taxRate || 0).dividedBy(100).plus(1);
      const beforeDpp: Decimal = round(
        grossValue.dividedBy(selectedTaxRate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      finalGrossValue = beforeDpp;
      const taxBase: Decimal = round(
        beforeDpp.times(taxBaseVariable),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      dpp = taxBase;

      const rate = new Decimal(taxRate || 0).dividedBy(100);
      taxAmount = round(
        taxBase.times(rate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );

      subtotal = round(beforeDpp.plus(taxAmount), Decimal.ROUND_HALF_EVEN);
    } else if (isTaxIncl && !isLuxury) {
      const inclusiveRate = new Decimal(salesInclusiveTaxRate)
        .dividedBy(100)
        .plus(1);
      const beforeDpp: Decimal = round(
        grossValue.dividedBy(inclusiveRate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      finalGrossValue = beforeDpp;
      const taxBase = round(
        beforeDpp.times(taxBaseVariable),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      dpp = taxBase;
      const rate = new Decimal(taxRate || 0).dividedBy(100);
      taxAmount = round(
        taxBase.times(rate),
        Decimal.ROUND_HALF_EVEN,
        MAX_PRECISION
      );
      subtotal = round(beforeDpp.plus(taxAmount), Decimal.ROUND_HALF_EVEN);
    } else {
      dpp = grossValue;
      subtotal = grossValue;
      taxAmount = new Decimal(0);
    }

    return { grossValue: finalGrossValue, dpp, taxAmount, subtotal };
  };

  return {
    calculate,
    countPrice,
  };
};

export default useSalesOrderCalculation;
