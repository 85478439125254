import { SearchBuilder } from "@/builder";
import { ApPaymentReportDto } from "@/models/interface/ApPaymentReport.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import store from "@/store/auth.store";
import { Moment } from "moment";
import { Row } from "./table";
import useDate from "./useDate";

type FilterField = Partial<{
  branchId: string;
  paymentFrom: Moment | null;
  paymentTo: Moment | null;
  paymentType: string;
  supplierId: string;
  supplierType: string;
  invoiceApDate: Moment[];
  invoiceApNo: string;
  invoiceSupplierNo: string;
  invoiceType: string;
  aPPaymentNo: string;
  aPPaymentId: string;
}>;
type ParamField = FilterField &
  Partial<{
    branchName: string;
    supplierName: string;
  }>;

const useApPaymentReport = () => {
  const toTableRows = (
    data: ApPaymentReportDto[]
  ): Row<ApPaymentReportDto, number>[] => {
    return data.map<Row<ApPaymentReportDto, number>>((e, i) => ({
      ...e,
      key: i,
    }));
  };

  const download = (params: RequestQueryParamsModel) => {
    return invoiceAPServices.downloadReportApPayment(params);
  };

  const findAll = (params?: RequestQueryParamsModel) => {
    return invoiceAPServices.getListReportApPayment(params);
  };

  /**
   * @description
   * build http params for param field header report
   */
  const buildParam = (field: ParamField): string => {
    const {
      supplierName,
      branchName,
      paymentFrom,
      paymentTo,
      paymentType,
      supplierType,
      invoiceApDate,
      invoiceApNo,
      invoiceSupplierNo,
      invoiceType,
      aPPaymentNo,
    } = field;
    const { toDefaultFormat } = useDate();

    let invoiceApStart = "ALL";
    let invoiceApEnd = "ALL";
    if (invoiceApDate && invoiceApDate.length > 0) {
      const [start, end] = invoiceApDate;
      invoiceApStart = toDefaultFormat(start);
      invoiceApEnd = toDefaultFormat(end);
    }

    const params = [
      store.state.authData.companyName || "",
      branchName || "ALL",
      paymentType || "ALL",
      supplierName || "ALL",
      invoiceSupplierNo || "ALL",
      supplierType || "ALL",
      paymentFrom ? toDefaultFormat(paymentFrom) : "ALL",
      paymentTo ? toDefaultFormat(paymentTo) : "ALL",
      invoiceApStart,
      invoiceApEnd,
      invoiceApNo || "ALL",
      invoiceType || "ALL",
      aPPaymentNo || "ALL",
    ];

    return params.join(",");
  };

  const filterBy = (field: FilterField): string => {
    const {
      branchId,
      paymentFrom,
      paymentTo,
      paymentType,
      supplierId,
      supplierType,
      invoiceApDate,
      invoiceApNo,
      invoiceSupplierNo,
      invoiceType,
      aPPaymentNo,
    } = field;
    const q: string[] = [];
    const builder = new SearchBuilder();
    const { toStartDay, toEndDay } = useDate();

    if (invoiceType) {
      q.push(builder.push(["invoiceType", invoiceType]).build());
    }

    if (invoiceSupplierNo) {
      q.push(
        builder
          .push(["invoiceSupplierNo", invoiceSupplierNo], {
            like: "both",
          })
          .build()
      );
    }

    if (invoiceApNo) {
      q.push(
        builder
          .push(["invoiceNumber", invoiceApNo], {
            like: "both",
          })
          .build()
      );
    }

    if (branchId) {
      q.push(builder.push(["branchId", branchId]).build());
    }

    if (paymentFrom) {
      q.push(
        builder
          .push(["paymentDate", toStartDay(paymentFrom).format()], {
            het: true,
          })
          .build()
      );
    }

    if (paymentTo) {
      q.push(
        builder
          .push(["paymentDate", toEndDay(paymentTo).format()], { let: true })
          .build()
      );
    }

    if (paymentType) {
      q.push(
        builder.push(["paymentType", paymentType], { like: "both" }).build()
      );
    }

    if (supplierId) {
      q.push(builder.push(["supplierId", supplierId]).build());
    }

    if (supplierType) {
      q.push(
        builder.push(["supplierType", supplierType], { like: "both" }).build()
      );
    }

    if (invoiceApDate && invoiceApDate.length > 0) {
      const [start, end] = invoiceApDate;
      q.push(
        builder
          .push(["invoiceDate", toStartDay(start).format()], {
            het: true,
          })
          .and()
          .push(["invoiceDate", toEndDay(end).format()], {
            let: true,
          })
          .build()
      );
    }

    if (aPPaymentNo) {
      q.push(builder.push(["paymentNumber", aPPaymentNo]).build());
    }

    return q.join(builder.AND);
  };

  return { download, findAll, toTableRows, filterBy, buildParam };
};

export default useApPaymentReport;
