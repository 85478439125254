import { Api } from "@/models/class/api.class";
import {
  FifoReportDownloadParams,
  FifoReportParams,
} from "@/models/interface/fifo-report";
import { HeadProductFifoValuationReportDTO } from "@/models/interface/FifoValuationReport.interface";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class FifoReportService extends HttpClient {
  getList(
    params?: FifoReportParams
  ): Promise<HeadProductFifoValuationReportDTO> {
    return this.get<HeadProductFifoValuationReportDTO>(Api.FifoReportListV2, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  download(params?: FifoReportDownloadParams): Promise<Blob> {
    return this.get<Blob>(Api.FifoReportDownloadV2, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const fifoReportService = new FifoReportService();
