import {
  AmortizationPeriodRequestDto,
  AmortizationRequestDto,
  AmortizationUnitRequestDto,
} from "@/models/interface/amortization";

export const buildAmortizationPeriodRequestDto =
  (): AmortizationPeriodRequestDto => ({
    startPeriod: "",
    endPeriod: "",
    amount: 0,
    amortizationPeriodId: null,
  });

export const buildAmortizationUnitRequestDto =
  (): AmortizationUnitRequestDto => ({
    assetId: null,
    note: null,
    periodLineDTOs: [],
    amortizationUnitId: null,
  });

export const buildAmortizationRequestDto = (): AmortizationRequestDto => ({
  lessorId: null,
  transactionType: "",
  periodLength: 0,
  branchId: "",
  documentNumber: null,
  startPeriod: "",
  transactionDate: "",
  description: null,
  leasingId: null,
  companyBankId: null,
  prepaidAccountId: "",
  expenseAccountId: "",
  currencyId: "",
  currencyRate: 1,
  unitLineDTOs: [],
  amortizationId: null,
  deletedUnitIds: null,
  deletedPeriodIds: null,
});
