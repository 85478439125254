import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouseLocation,
  ResponseWarehouseLocation,
} from "@/models/interface/logistic.interface";
import { logisticServices } from "@/services/logistic.service";

export const useFindAllWarehouseLocation = async (
  params?: RequestQueryParamsModel
): Promise<ResponseWarehouseLocation> => {
  const queryParams = new RequestQueryParams(
    params?.search,
    params?.page,
    params?.sorts,
    params?.limit
  );

  return await logisticServices.listWarehouseLocation(queryParams, "");
};

export const useTransformLocation = (value: string) => {
  return value ? value.replaceAll(" -  - ", "") : "-";
};

export const useMapOptionLocation = (value: DataWarehouseLocation) => {
  return `${value.warehouse.branchWarehouse.name} - ${value.warehouse.name} - ${value.name}`;
};

export const useMapLocationToOptions = (
  source: ResponseWarehouseLocation
): Option<DataWarehouseLocation>[] => {
  return source.data.map<Option<DataWarehouseLocation>>((el, i) => ({
    label: useTransformLocation(useMapOptionLocation(el)),
    value: el.id,
    meta: el,
    key: i,
  }));
};
