import {
  LogisticStockAdjustmentState,
  LogisticStockAdjustmentType,
} from "@/models/enums/stock-adjustment.enum";
import { FormValue, State, StockAdjRow } from "@/store/stockAdj.store";
import { useStockAdjustment } from "@/views/logistic/stock-adjustment/hooks";
import { StockAdjustmentResponseDTO } from "@/views/logistic/stock-adjustment/types";
import moment from "moment";

export const initFormValue = (): FormValue => ({
  attachment: "",
  brand: "",
  date: moment(),
  deletedLineIds: [],
  documentNumber: "",
  notes: "",
  productCategoryId: "",
  state: "" as LogisticStockAdjustmentState,
  stockAdjustmentRows: [],
  type: LogisticStockAdjustmentType.STOCK_ADJUSTMENT,
  warehouseLocation: undefined,
});

export const initState = (): State => ({
  form: initFormValue(),
  detail: initDetail(),
});

export const initDetail = (): StockAdjustmentResponseDTO =>
  useStockAdjustment().buildDetailDto();

export const initStockAdjRow = (): StockAdjRow => ({
  differenceQty: 0,
  key: Math.random(),
  physicalQty: 0,
  productCode: "",
  productId: "",
  productMerk: "",
  productName: "",
  qty: 0,
  id: "",
  uom: undefined,
  uomOptions: [],
});
