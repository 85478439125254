







import * as animationData from "@/assets/imagejson/loading-forklift-2.json";
import lottie from "lottie-web";
import Vue from "vue";

export default Vue.extend({
  name: "LottieLoader",
  data: () => ({
    animationData,
  }),
  mounted(): void {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer as Element,
      renderer: "svg",
      autoplay: true,
      animationData: this.animationData["default"],
    });
  },
});
