import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { SalesOrderReportResponseDto } from "@/models/interface/sales-order-report";
import { salesOrderServices } from "@/services/salesorder.service";

const useSalesOrderReport = () => {
  const findAll = (
    params: RequestQueryParams
  ): Promise<Array<SalesOrderReportResponseDto>> => {
    return salesOrderServices.getSalesOrderReport(params);
  };

  const download = (params: RequestQueryParams) => {
    return salesOrderServices.downloadSalesOrderReport(params);
  };

  return { findAll, download };
};

export default useSalesOrderReport;
