export enum LogisticStockAdjustmentState {
  DRAFT = "Draft",
  CANCELLED = "Cancelled",
  SUBMITTED = "Submitted",
}

export enum LogisticStockAdjustmentType {
  STOCK_ADJUSTMENT = "Stock Adjustment",
  STOCK_OPNAME = "Stock Opname",
}
