// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const GENERALJOURNAL_ROUTE: RoutePathModel = {
  path: "/generaljournal",
  url: "/generaljournal",
  name: "generaljournal",
  meta: {
    title_head: "lbl_general_journal",
    need_auth: true,
  },
};

export const CREATEGENERALJOURNAL_ROUTE: RoutePathModel = {
  path: `${GENERALJOURNAL_ROUTE.url}/journal/create`,
  url: `${GENERALJOURNAL_ROUTE.url}/journal/create`,
  name: "generaljournal.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_general_journal",
        icon: "diff",
      },
      {
        name: "lbl_journal",
        href: "/generaljournal/journal/read",
      },
      {
        name: "lbl_create_journal",
        href: "/generaljournal/journal/create",
      },
    ],
    title_head: "lbl_create_journal",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const UPDATEGENERALJOURNAL_ROUTE: RoutePathModel = {
  path: `${GENERALJOURNAL_ROUTE.url}/journal/update/:id`,
  url: `${GENERALJOURNAL_ROUTE.url}/journal/update/:id`,
  name: "generaljournal.update",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_general_journal",
        icon: "diff",
      },
      {
        name: "lbl_journal",
        href: "/generaljournal/journal/read",
      },
      {
        name: "lbl_update_general_journal",
        href: "",
      },
    ],
    title_head: "lbl_update_general_journal",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const READGENERALJOURNAL_ROUTE: RoutePathModel = {
  path: `${GENERALJOURNAL_ROUTE.url}/journal/read`,
  url: `${GENERALJOURNAL_ROUTE.url}/journal/read`,
  name: "generaljournal.read",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_general_journal",
        icon: "diff",
      },
      {
        name: "lbl_journal",
        href: "/generaljournal/journal/read",
      },
    ],
    title_head: "lbl_general_journal",
    mode: Mode.READ,
    need_auth: true,
  },
};
export const DETAILENERALJOURNAL_ROUTE: RoutePathModel = {
  path: `${GENERALJOURNAL_ROUTE.url}/journal/detail/:id`,
  url: `${GENERALJOURNAL_ROUTE.url}/journal/detail/:id`,
  name: "generaljournal.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_general_journal",
        icon: "diff",
      },
      {
        name: "lbl_journal",
        href: "/generaljournal/journal/read",
      },
      {
        name: "lbl_journal_detail",
        href: "/generaljournal/journal/detail/:id",
      },
    ],
    title_head: "lbl_journal_detail",
    mode: Mode.DETAIL,
    need_auth: true,
  },
};
