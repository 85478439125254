import { Api } from "@/models/class/api.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DownloadRequestParam } from "@/models/interface/download";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import {
  TruckingSettlementApproveRequestDto,
  TruckingSettlementApproveResponseDto,
  TruckingSettlementDetailResponseDto,
  TruckingSettlementPostRequestDto,
  TruckingSettlementRejectRequestDto,
  TruckingSettlementResponseDto,
} from "@/models/interface/trucking-settlement";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class TruckingSettlementService extends HttpClient {
  constructor() {
    super();
  }

  public listOfSettlements(
    params?: RequestQueryParams
  ): Promise<Pagination<TruckingSettlementResponseDto>> {
    return this.get<Pagination<TruckingSettlementResponseDto>>(
      Api.TruckingSettlementList,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadListSettlements(params: DownloadRequestParam): Promise<Blob> {
    return this.get<Blob>(Api.TruckingSettlementDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public findSettlementDetail(
    id: string
  ): Promise<TruckingSettlementDetailResponseDto> {
    return this.get<TruckingSettlementDetailResponseDto>(
      `${Api.TruckingSettlementDetail}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public approveSettlement(
    id: string,
    payload: TruckingSettlementApproveRequestDto
  ): Promise<TruckingSettlementApproveResponseDto> {
    return this.put<TruckingSettlementApproveResponseDto>(
      `${Api.TruckingSettlementApprove}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public rejectSettlement(
    id: string,
    payload: TruckingSettlementRejectRequestDto
  ): Promise<void> {
    return this.put<void>(`${Api.TruckingSettlementReject}/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public postSettlement(
    payload: TruckingSettlementPostRequestDto
  ): Promise<void> {
    return this.post<void>(Api.TruckingSettlementPostJournal, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const truckingSettlementService = new TruckingSettlementService();
