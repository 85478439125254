import { FormValue } from "@/store/company.store";

export const initForm = (): FormValue => ({
  address: "",
  code: "",
  email: "",
  fax: "",
  logo: "",
  name: "",
  phone: "",
  taxNumber: "",
  website: "",
});
