import { EnumUploadDataFeature } from "@/models/enums/UploadDataFeature.enum";
import { uploadDataService } from "@/services/upload-data.service";

const useUploadData = () => {
  const downloadTemplate = (feature: EnumUploadDataFeature): Promise<Blob> => {
    return uploadDataService.downloadTemplate(feature);
  };

  const uploadData = (
    feature: EnumUploadDataFeature,
    formData: FormData
  ): Promise<void> => {
    return uploadDataService.uploadFile(feature, formData);
  };

  return {
    downloadTemplate,
    uploadData,
  };
};

export { useUploadData };
