var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-breadcrumb",
    _vm._l(_vm.breadcrumbs, function(crumb, index) {
      return _c(
        "a-breadcrumb-item",
        { key: index },
        [
          !!crumb.icon
            ? _c("a-icon", { attrs: { type: crumb.icon } })
            : _vm._e(),
          !!crumb.href
            ? _c(
                "router-link",
                { attrs: { to: { path: crumb.href, query: {}, name: "" } } },
                [_vm._v(" " + _vm._s(_vm.$t(crumb.name)) + " ")]
              )
            : _c("span", [_vm._v(" " + _vm._s(_vm.$t(crumb.name)) + " ")])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }