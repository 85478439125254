import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { InvoiceSettingPrintParams } from "@/models/interface/invoice-setting-print-params.interface";
import {
  InvoicePrepaymentCreateDto,
  InvoicePrepaymentListResponseDto,
  InvoicePrepaymentResponseDto,
} from "@interface/ar-prepayment";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class ArPrepaymentService extends HttpClient {
  getList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<InvoicePrepaymentListResponseDto>> {
    return this.get<Pagination<InvoicePrepaymentListResponseDto>>(
      Api.InvoiceArPrepayment,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  create(
    payload: InvoicePrepaymentCreateDto
  ): Promise<InvoicePrepaymentResponseDto> {
    return this.post<InvoicePrepaymentResponseDto, InvoicePrepaymentCreateDto>(
      Api.InvoiceArPrepayment,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  detail(id: string): Promise<InvoicePrepaymentResponseDto> {
    return this.get<InvoicePrepaymentResponseDto>(
      `${Api.InvoiceArPrepayment}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getTaxInvoiceNumber(taxCode: string, invoiceDate: string): Promise<string> {
    return this.get<string>(`${Api.InvoicePrepaymentCode}/${taxCode}`, {
      params: { invoiceDate },
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancel(id: string): Promise<InvoicePrepaymentResponseDto> {
    return this.post<InvoicePrepaymentResponseDto, never>(
      `${Api.CancelInvoicePrepayment}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  printInvoice(id: string, params: InvoiceSettingPrintParams): Promise<Blob> {
    return this.get<Blob>(`${Api.InvoiceArPrepayment}/print/${id}`, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const aRPrepaymentService = new ArPrepaymentService();
