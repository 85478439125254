import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.UPLOAD_DATA_ROUTE.path,
  name: config.UPLOAD_DATA_ROUTE.name,
  meta: config.UPLOAD_DATA_ROUTE.meta,
  component: () => import("./UploadData.vue"),
};

export default router;
