import { RoutePathModel } from "../interface/common.interface";
export const GENERALLEDGER_ROUTE: RoutePathModel = {
  path: "/general-ledger",
  url: "/general-ledger",
  name: "generalledger",
  meta: {
    breadcrumb: "lbl_general_ledger",
    title_head: "lbl_general_ledger",
    need_auth: true,
  },
};
export const OPENCLOSE_ROUTE: RoutePathModel = {
  path: `/accounting/open-close`,
  url: `/accounting/open-close`,
  name: "accounting.generalledger.openclose",
  meta: {
    breadcrumb: [
      {
        name: "lbl_accounting",
        icon: "reconciliation",
      },
      {
        name: "lbl_open_close_period",
        href: `/accounting/open-close`,
      },
    ],
    title_head: "lbl_open_close_period",
    need_auth: true,
  },
};
