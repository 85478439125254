import { UploadDataMapper } from "@/mapper/UploadData.mapper";
import { EnumUploadDataFeature } from "@/models/enums/UploadDataFeature.enum";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class UploadDataService extends HttpClient {
  constructor() {
    super();
  }

  public downloadTemplate(feature: EnumUploadDataFeature): Promise<Blob> {
    return this.get<Blob>(
      UploadDataMapper.toDownloadTemplateEndpoint(feature),
      {
        responseType: "blob",
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public uploadFile(
    feature: EnumUploadDataFeature,
    formData: FormData
  ): Promise<void> {
    return this.post<void>(
      UploadDataMapper.toUploadDataEndpoint(feature),
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const uploadDataService = new UploadDataService();
