import { CashInOutLineCreateRequestDto } from "@interface/cash-in-out";

export function buildCashInOutLineCreateDto(): CashInOutLineCreateRequestDto {
  return {
    lineId: null,
    accountId: "",
    amount: 0,
    notes: "",
    truckingPaymentId: null,
  };
}
