import { RequestParamsGetListSalesOrderTracking } from "@/models/interface/SalesOrderTracking.interface";
import { salesOrderServices } from "@/services/salesorder.service";

const useSalesOrderTracking = () => {
  const findAll = (params?: RequestParamsGetListSalesOrderTracking) => {
    return salesOrderServices.getSalesOrderTracking(params);
  };

  return { findAll };
};

export default useSalesOrderTracking;
