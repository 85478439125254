var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-header",
    { staticClass: "header" },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "space-between" } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("a-button", {
                attrs: { icon: _vm.icon, type: "primary", shape: "circle" },
                on: { click: _vm.toggleMenu }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("LangSwitcher"),
                  _c(
                    "a-tooltip",
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_profile")) + " ")
                      ]),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "profile.me" } } },
                        [
                          _c("a-button", {
                            attrs: {
                              icon: "user",
                              shape: "circle",
                              type: "primary"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tooltip",
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lbl_profile_signout")) + " "
                        )
                      ]),
                      _c("a-button", {
                        attrs: {
                          icon: "logout",
                          shape: "circle",
                          type: "primary"
                        },
                        on: { click: _vm.handleLogout }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }