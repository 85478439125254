import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { DataListAssetCategory } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";

type FilterField = Partial<{
  categoryId: string;
}>;

const useAssetCategory = () => {
  const findAll = (params: RequestQueryParamsModel) => {
    return assetsServices.listAssetCategory(params);
  };

  const toOptions = (
    data: Array<DataListAssetCategory>
  ): Array<Option<DataListAssetCategory>> => {
    return data.map(item => ({
      label: item.category.id,
      key: item.category.id,
      value: item.category.id,
      meta: item,
    }));
  };

  const toOptionFirstSegment = (
    data: Array<DataListAssetCategory>
  ): Array<Option<DataListAssetCategory>> => {
    return data.map(item => {
      const label = item.category.segments.find(e => e.parentId === null);
      return {
        label: label?.first || "-",
        key: item.id,
        value: item.category.id,
        meta: item,
      };
    });
  };

  const filterBy = (field: FilterField): string => {
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (field.categoryId) {
      q.push(
        builder.push(["categoryId", field.categoryId], { like: "both" }).build()
      );
    }

    return q.join(builder.OR);
  };

  return { findAll, toOptions, filterBy, toOptionFirstSegment };
};

export default useAssetCategory;
