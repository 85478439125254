// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";
import { ACCOUNTRECEIVABLES_ROUTE } from "./accountreceivables.constant";

export const ASSETS_ROUTE: RoutePathModel = {
  path: "/assets",
  url: "/assets",
  name: "Assets",
  meta: {
    breadcrumb: "lbl_assets",
    title_head: "lbl_assets",
    need_auth: true,
  },
};

export const INQUIRY_FIND: RoutePathModel = {
  path: "/inquiry/find",
  url: "/inquiry/find",
  name: "inquiry.find",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
    ],
    title_head: "lbl_inquiry_find",
    need_auth: true,
  },
};

export const INQUIRY_OPEN_ROUTE: RoutePathModel = {
  path: "/inquiry/open/:id",
  url: "/inquiry/open/:id",
  name: "asset.inquiry.open.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
      {
        name: "lbl_asset_inquiry",
        href: "/inquiry/open/:id",
      },
    ],
    title_head: "lbl_inquiry_open",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const VIEW_ADDITIONS_SINGLE_ROUTE: RoutePathModel = {
  path: "/additions/single/view/:id",
  url: "/additions/single/view/:id",
  name: "asset.view.additions.single",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
      {
        name: "lbl_asset_inquiry",
        href: "/inquiry/open/:id",
      },
      {
        name: "lbl_asset_addition_single",
        href: "/additions/single/view/:id",
      },
    ],
    title_head: "lbl_inquiry_open",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const INQUIRY_SEARCH: RoutePathModel = {
  path: "/inquiry/search",
  url: "/inquiry/search",
  name: "lbl_inquiry_search",
  meta: {
    breadcrumb: "lbl_inquiry_search",
    title_head: "lbl_inquiry_search",
    need_auth: true,
  },
};

export const INQUIRY_ADJUSTMENT_VIEW: RoutePathModel = {
  path: "/inquiry/adjustment/view/:id",
  url: "/inquiry/adjustment/view/:id",
  name: "asset.inquiry.adjustment",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
      {
        name: "lbl_asset_adjustment",
        href: "/inquiry/adjustment/view/:id",
      },
    ],
    title_head: "lbl_inquiry_adjustment",
    mode: Mode.VIEW,
  },
};
export const INQUIRY_ADJUSTMENT_UPDATE_ROUTE: RoutePathModel = {
  path: "/inquiry/adjustment/update/:id",
  url: "/inquiry/adjustment/update/:id",
  name: "asset.inquiry-adjustment.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
      {
        name: "lbl_asset_adjustment",
        href: "/inquiry/adjustment/view/:id",
      },
    ],
    title_head: "lbl_inquiry_adjustment",
    mode: Mode.EDIT,
  },
};

export const INQUIRY_RETIREMENT_VIEW: RoutePathModel = {
  path: "/inquiry/retirement/view/:id",
  url: "/inquiry/retirement/view/:id",
  name: "asset.inquiry.retirement",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
      {
        name: "Asset Retirement",
        href: "/inquiry/retirement/view/:id",
      },
    ],
    title_head: "Inquiry Retirement",
    mode: Mode.VIEW,
  },
};
export const INQUIRY_RETIREMENT_UPDATE_ROUTE: RoutePathModel = {
  path: "/inquiry/retirement/update/:id",
  url: "/inquiry/retirement/update/:id",
  name: "asset.inquiry-retirement.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
      {
        name: "lbl_asset_retirement",
        href: "/inquiry/retirement/update/:id",
      },
    ],
    title_head: "lbl_asset_retirement",
    mode: Mode.EDIT,
  },
};

export const RECLASS: RoutePathModel = {
  path: "/reclass/create",
  url: "/reclass/create",
  name: "create.reclass",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_inquiry",
        icon: "bank",
        href: "/inquiry/find",
      },
      {
        name: "lbl_reclass",
        href: "/reclass/create",
      },
    ],
    title_head: "lbl_reclass",
    need_auth: true,
  },
};

export const ADDITIONS_ROUTE: RoutePathModel = {
  path: "/assets/additions/read",
  url: "/assets/additions/read",
  name: "additions",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_addition",
        icon: "calculator",
      },
      {
        name: "lbl_asset_addition_import",
        href: "/assets/additions/read",
      },
    ],
    title_head: "lbl_addition_single_read",
    need_auth: true,
  },
};

export const ADDITIONS_CREATE_ROUTE: RoutePathModel = {
  path: "/additions/create",
  url: "/additions/create",
  name: "addition.single.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_addition",
        icon: "calculator",
      },
      {
        name: "lbl_asset_addition_import",
        href: "/assets/additions/Read",
      },
      {
        name: "lbl_addition_single_create",
        href: "/assets/additions/create",
      },
    ],
    title_head: "Additions",
    need_auth: true,
  },
};

export const ADDITIONS_UPDATE_ROUTE: RoutePathModel = {
  path: "/additions/single/update/:id",
  url: "/additions/single/update/:id",
  name: "addition.single.update",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_addition",
        icon: "calculator",
      },
      {
        name: "lbl_asset_addition_import",
        href: "/assets/additions/read",
      },
      {
        name: "lbl_asset_addition_single_update",
        href: "/additions/single/update/:id",
      },
    ],
    title_head: "lbl_addition",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const ADDITIONS_SINGLE_ROUTE: RoutePathModel = {
  path: "/additions/single/read",
  url: "/additions/single/read",
  name: "addition.single.read",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_addition",
        icon: "calculator",
      },
      {
        name: "lbl_asset_addition_single",
        href: "/assets/additions/single/read",
      },
    ],
    title_head: "lbl_addition_single",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const ADJUSMENT_ROUTE: RoutePathModel = {
  path: "adjustment",
  url: `${ASSETS_ROUTE.url}/adjustment`,
  name: "Adjustment",
  meta: {
    breadcrumb: "Adjustment",
    title_head: "Adjustment",
  },
};

export const CATEGORY_ROUTE: RoutePathModel = {
  path: "/setup/assets/category/create",
  url: `/setup/assets/category/create`,
  name: "asset.category",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_setup",
        icon: "experiment",
      },
      {
        name: "lbl_asset_category",
        href: "/setup/assets/category/create",
      },
    ],
    title_head: "lbl_category",
    need_auth: true,
  },
};
export const ASSETBOOK_ROUTE: RoutePathModel = {
  path: "/setup/assets/assetbook/create",
  url: `/setup/assets/assetbook/create`,
  name: "asset.book",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_setup",
        icon: "experiment",
      },
      {
        name: "lbl_assets_book",
        href: "/setup/assets/assetbook/create",
      },
    ],
    title_head: "lbl_assets_book",
    need_auth: true,
  },
};

export const INVOICES_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices`,
  name: "Invoices",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/assets/invoices",
      },
    ],
    title_head: "Invoices",
  },
};

export const INVOICES_DETAIL_RECURING_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/detail/recurring/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/detail/recurring/:id`,
  name: "accountreceivables.invoices.detail.recurring",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/accountreceivables/invoices",
      },
      {
        name: "lbl_detail_invoice",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/detail/recurring/:id`,
      },
    ],
    mode: Mode.DETAIL,
    title_head: "lbl_detail_invoice_recuring",
    type: "recurring",
    need_auth: true,
  },
};
export const INVOICES_CREATE_RECURING_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/create/recurring`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/create/recurring`,
  name: "account-receivable.invoice.recurring.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/accountreceivables/invoices",
      },
      {
        name: "lbl_create_invoice",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/create/recurring`,
      },
    ],
    mode: Mode.CREATE,
    title_head: "lbl_create_invoice_recurring",
    type: "recurring",
    need_auth: true,
  },
};
export const INVOICES_DETAIL_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/detail/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/detail/:id`,
  name: "accountreceivables.invoices.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/accountreceivables/invoices",
      },
      {
        name: "lbl_detail_invoice",
        href: "/accountreceivables/invoices/detail",
      },
    ],
    mode: Mode.VIEW,
    title_head: "lbl_detail_invoice",
    type: "invoices",
    need_auth: true,
  },
};
export const INVOICES_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/create`,
  name: "accountreceivables.invoices.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/accountreceivables/invoices",
      },
      {
        name: "lbl_create_invoice",
        href: "/assaccountreceivables/invoices/create",
      },
    ],
    mode: Mode.CREATE,
    title_head: "lbl_create_invoice",
    need_auth: true,
    type: "invoices",
  },
};

export const INVOICE_RECURRING_BAP_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/recurring`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/recurring`,
  name: "account-receivable.invoice.recurring",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: "database",
      },
      {
        name: "lbl_invoice_recurring_bap",
        href: "/accountreceivables/invoice/recurring",
      },
    ],
    title_head: "lbl_invoice_recurring_bap",
    need_auth: true,
  },
};

export const DEPRESIASICLOSEPERIODE_ROUTE: RoutePathModel = {
  path: `${ASSETS_ROUTE.url}/depresiasicloseperiode`,
  url: `${ASSETS_ROUTE.url}/depresiasicloseperiode`,
  name: "Depresiasi Close Periode",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "Asset Depreciation",
        href: "/assets/depresiasicloseperiode",
      },
    ],
    title_head: "Depresiasi Close Periode",
  },
};

export const LOCATIONS_ROUTE: RoutePathModel = {
  path: `${ASSETS_ROUTE.url}/locations/create`,
  // url: `${ASSETS_ROUTE.url}/locations`,
  url: `${ASSETS_ROUTE.url}/locations/create`,
  name: "Locations",
  meta: {
    breadcrumb: "Locations",
    title_head: "Locations",
  },
};

export const RETIREMENT_ROUTE: RoutePathModel = {
  path: "retirement",
  url: `${ASSETS_ROUTE.url}/retirement`,
  name: "Retirement",
  meta: {
    breadcrumb: "Retirement",
    title_head: "Retirement",
  },
};

export const REPORTS_ASSET_ROUTE: RoutePathModel = {
  path: `${ASSETS_ROUTE.url}/reports`,
  url: `${ASSETS_ROUTE.url}/reports`,
  name: "Reports Asset",
  meta: {
    breadcrumb: "Reports Asset",
    title_head: "Reports Asset",
  },
};

export const REPORTS_ASSET_ADJUSTMENT_ROUTE: RoutePathModel = {
  path: `/assets/reports/assetAdjustment`,
  url: `/assets/reports/assetAdjustment`,
  name: "Reports Asset Adjustment",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "Reports",
        icon: "file",
      },
      {
        name: "Report Asset Adjustment",
        href: "/assets/reports/assetAdjustment",
      },
    ],
    title_head: "Reports Asset Adjustment",
  },
};

export const REPORTS_LIST_OF_ASSET_ROUTE: RoutePathModel = {
  path: `/assets/reports/listOfAssets`,
  url: `/assets/reports/listOfAssets`,
  name: "report.list.of.assets",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "Reports",
        icon: "file",
      },
      {
        name: "lbl_report_list_of_assets",
        href: "/assets/reports/listOfAssets",
      },
    ],
    title_head: "lbl_report_list_of_assets",
    need_auth: true,
  },
};

export const REPORTS_LIST_ASSET_ADDITIONS_ROUTE: RoutePathModel = {
  path: `/assets/reports/additions`,
  url: `/assets/reports/additions`,
  name: "report.list.asset.addition",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "Reports",
        icon: "file",
      },
      {
        name: "lbl_report_asset_additions",
        href: "/assets/reports/additions",
      },
    ],
    title_head: "lbl_report_asset_additions",
    need_auth: true,
  },
};

export const REPORTS_RECLASS_ROUTE: RoutePathModel = {
  path: `/assets/reports/reclass`,
  url: `/assets/reports/reclass`,
  name: "report.reclass",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "Reports",
        icon: "file",
      },
      {
        name: "lbl_report_reclass",
        href: "/assets/reports/reclass",
      },
    ],
    title_head: "lbl_report_reclass",
    need_auth: true,
  },
};

export const REPORTS_ASSET_DEPRECIATION_PROJECTION: RoutePathModel = {
  path: `/assets/reports/depreciation-projection`,
  url: `/assets/reports/depreciation-projection`,
  name: "report.depreciation-projection",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "Reports",
        icon: "file",
      },
      {
        name: "lbl_asset_depreciation_projection",
        href: "/assets/reports/depreciation-projection",
      },
    ],
    title_head: "lbl_asset_depreciation_projection",
    need_auth: true,
  },
};

export const REPORTS_ASSET_RETIREMENT_ROUTE: RoutePathModel = {
  path: `/assets/reports/asset-retirement`,
  url: `/assets/reports/asset-retirement`,
  name: "asset.report.retirement",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_asset_retirement_report",
        href: "/assets/reports/asset-retirement",
      },
    ],
    title_head: "lbl_asset_retirement_report",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const REPORTS_ASSET_HISTORY_ROUTE: RoutePathModel = {
  path: `/assets/reports/asset-history`,
  url: `/assets/reports/asset-history`,
  name: "asset.report.history",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_asset_history_report",
        href: "/assets/reports/asset-history",
      },
    ],
    title_head: "lbl_asset_history_report",
  },
};

export const CREATE_ASSET_TRANSFER_ROUTE: RoutePathModel = {
  path: "/assets/transfers/create",
  url: "/assets/transfers/create",
  name: "create.asset.transfer",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_transfers",
        href: "/assets/transfers",
      },
      {
        name: "lbl_create",
        href: "/assets/transfers/create",
      },
    ],
    title_head: "lbl_transfers",
    need_auth: true,
  },
};

export const ASSET_TRANSFER_ROUTE: RoutePathModel = {
  path: "/assets/transfers",
  url: "/assets/transfers",
  name: "asset.transfers",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_transfers",
        href: "/assets/transfers",
      },
    ],
    title_head: "lbl_transfers",
    need_auth: true,
  },
};

export const ASSET_TRANSFER_DETAIL_ROUTE: RoutePathModel = {
  path: "/assets/transfers/:id",
  url: "/assets/transfers/:id",
  name: "asset.transfer.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_assets",
        icon: "appstore",
      },
      {
        name: "lbl_transfers",
        href: "/assets/transfers",
      },
      {
        name: "lbl_detail",
        href: "/assets/transfers/:id",
      },
    ],
    title_head: "lbl_transfers",
    need_auth: true,
  },
};
