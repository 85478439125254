import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.ACCOUNTING_ROUTE.path,
  redirect: config.ACCOUNTING_ROUTE.redirect,
  name: config.ACCOUNTING_ROUTE.name,
  meta: config.ACCOUNTING_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.TRIALBALANCE_ROUTE.path,
      name: config.TRIALBALANCE_ROUTE.name,
      meta: config.TRIALBALANCE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/TrialBalance.vue"),
    },
    {
      path: config.JOURNALTRANSACTIONS_ROUTE.path,
      name: config.JOURNALTRANSACTIONS_ROUTE.name,
      meta: config.JOURNALTRANSACTIONS_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/JournalTransactions.vue"),
    },
    {
      path: config.JOURNALTRANSACTIONS_REPORT_DETAIL_ROUTE.path,
      name: config.JOURNALTRANSACTIONS_REPORT_DETAIL_ROUTE.name,
      meta: config.JOURNALTRANSACTIONS_REPORT_DETAIL_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/JournalTransactionsDetail.vue"
        ),
    },
    {
      path: config.PROFIT_LOSS_REPORT_ROUTE.path,
      name: config.PROFIT_LOSS_REPORT_ROUTE.name,
      meta: config.PROFIT_LOSS_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ProfitLossReport.vue"),
    },
    {
      path: config.BALANCE_SHEET_REPORT_ROUTE.path,
      name: config.BALANCE_SHEET_REPORT_ROUTE.name,
      meta: config.BALANCE_SHEET_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/BalanceSheetReport.vue"),
    },
  ],
};

export default router;
