// Interfaces
import { RoutePathModel } from "../interface/common.interface";

export const QR_ROUTE: RoutePathModel = {
  path: "/qr",
  url: "/qr",
  name: "QR",
  redirect: '/qr/scan',
  meta: {
    breadcrumb: "QR",
    title_head: "QR",
    // need_auth: false
  }
}

export const SCAN_QR: RoutePathModel = {
  path: '/qr/scan',
  url: '/qr/scan',
  name: "Scan QR",
  meta: {
    breadcrumb: "Scan QR",
    title_head: "Scan QR"
  }
}

export const DETAIL_QR: RoutePathModel = {
  path: '/qr/detail/:id',
  url: '/qr/detail/:id',
  name: "Detail QR",
  meta: {
    breadcrumb: "Detail QR",
    title_head: "Detail QR"
  }
}