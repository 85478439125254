import { useReceiveItem } from "@/hooks";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import { ReceivingItemDraftResponseDto } from "@/models/interface/receive-item";
import store from "@/store";

export const grPriceLoader = async (to, _from, next): Promise<void> => {
  try {
    const { findDraftById } = useReceiveItem();
    const res: ReceivingItemDraftResponseDto = await findDraftById(
      to.params.id
    );
    store.dispatch("goodsReceiptPriceStore/mapDetailDraftToForm", res);

    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};

export const grPriceCreateLoader = (_to, _from, next): void => {
  store.dispatch("goodsReceiptPriceStore/resetStore");
  next();
};
