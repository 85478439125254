export const DATE_FORMAT_YYYYMMdd = "YYYYMMdd";
export const DATE_FORMAT_YYYY_MM_dd = "YYYY-MM-dd";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_YYYY_MM = "YYYY-MM";
export const DATE_FORMAT_YYYY_MM_dd_Combined = "YYYYMMddHHmm";
export const DATE_TIMESTAMP = "YYYYMMDDHHmmss";
export const DATE_FORMAT_YYYY_MM_dd_HH_mm = "YYYY-MM-dd HH:mm";
export const DATE_FORMAT_DD_MMM_YYYY = "DD-MMM-YYYY";
export const DATE_FORMAT_DD_MMM_yyyy = "DD-MMM-yyyy";
export const DATE_FORMAT_DD_MM_YYYY_WITH_DOT = "DD.MM.YYYY";
export const DATE_FORMAT_mm_dd_YYYY_WITH_SLASH = "MM/dd/YYYY";
export const DATE_FORMAT_m_d_YYYY_WITH_SLASH = "M/d/YYYY";
export const DATE_FORMAT_DD_MM_YYYY_WITH_SLASH = "DD/MM/YYYY";
export const DATE_FORMAT_dd_MMM = "ddMMM";
export const DATE_FORMAT_dd_MM_yy = "ddMMyy";
export const DATE_FORMAT_dd_MMM_YYYY = "ddMMMYYYY";
export const DATE_FORMAT_DD_MM_YYYY_COMBINED = "DDMMYYYY";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss_sz = "YYYY-MM-dd'T'HH:mm:ss.SSS";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm = "dd-MM-YYYY HH:mm";
export const DATE_FORMAT_HH_mm = "HH:mm";
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ = "YYYY-MM-DD'T'HH:mm:ssZ";
export const DATE_TIME_DEFAULT_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_HOURS_DEFAULT_FORMAT = "DD-MMM-YYYY HH:mm:ss";
export const DATE_FORMAT_yyyy_MM_DD = "yyyy-MM-DD";
export const TIME_FORMAT_HH_mm = "HH:mm";
export const DATE_FORMAT_DD_MMM_YYYY_HH_mm = "DD-MMM-YYYY HH:mm";
export const DATE_FORMAT_MMM_YYYY = "MMM-YYYY";
export const DATE_FORMAT_DD_MM_YYYY_HH_MM_SS = "DD-MM-yyyy HH:mm:ss";

export const DEFAULT_DATE_FORMAT = DATE_FORMAT_DD_MMM_YYYY;
