// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../interface/common.interface";

export const ACCOUNTRECEIVABLES_ROUTE: RoutePathModel = {
  path: "/accountreceivables",
  url: "/accountreceivables",
  name: "Account Receivables",
  meta: {
    breadcrumb: [],
    title_head: "Account Receivables",
    need_auth: true,
  },
};
export const REPORTAGING_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/agingpiutang`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/agingpiutang`,
  name: "accountreceivables.invoices.report.agingpiutang",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoices",
        icon: "file",
      },
      {
        name: "lbl_reports",
        icon: "carry-out",
      },
      {
        name: "lbl_aging_report",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/agingpiutang`,
      },
    ],
    title_head: "lbl_aging_report",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const REPORT_INVOICE_CONTROLLING_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/invoice-controlling`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/invoice-controlling`,
  name: "account-receivable.report.invoice-controlling",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_reports",
        icon: "carry-out",
      },
      {
        name: "lbl_invoice_controlling_report",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/invoice-controlling`,
      },
    ],
    title_head: "lbl_invoice_controlling_report",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const REPORT_TAX_INVOICE_ROUTE: RoutePathModel = {
  path: `/tax/download`,
  url: `/tax/download`,
  name: "download-tax-invoice",
  meta: {
    breadcrumb: [
      {
        name: "lbl_taxes",
      },
      {
        name: "lbl_reports",
      },
      {
        name: "lbl_download_tax_invoice",
        href: `/tax/download`,
      },
    ],
    title_head: "lbl_download_tax_invoice",
    mode: Mode.VIEW,
  },
};

export const AR_PREPAYMENT_INDEX_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/prepayment`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/prepayment`,
  name: "account-receivable.invoice.prepayment",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice_ar_prepayment",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/prepayment`,
      },
    ],
    title_head: "lbl_invoice_ar_prepayment",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const AR_PREPAYMENT_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/prepayment/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/prepayment/create`,
  name: "account-receivable.invoice.prepayment.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice_ar_prepayment_create",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/prepayment/create`,
      },
    ],
    title_head: "lbl_invoice_ar_prepayment_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

/**
 * @deprecated
 */
export const INVOICESPREPAYMENTEDIT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment/edit/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment/edit/:id`,
  name: "account-receivable.invoice.prepayment.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "Invoices",
        icon: "file",
      },
      {
        name: "Invoices Prepayment / Credit Memo",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment`,
      },
      {
        name: "Invoices Prepayment / Credit Memo Edit",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment/edit/:id`,
      },
    ],
    title_head: "Invoices Prepayment / Credit Memo",
    mode: Mode.EDIT,
  },
};

export const AR_PREPAYMENT_DETAIL_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice/prepayment/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/prepayment/:id`,
  name: "account-receivable.invoice.prepayment.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice_ar_prepayment_detail",
        href: "",
      },
    ],
    title_head: "lbl_invoice_ar_prepayment_detail",
    mode: Mode.DETAIL,
  },
};

export const BAP_ROUTE: RoutePathModel = {
  path: "bap",
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/bap`,
  name: "BAP",
  meta: {
    breadcrumb: "BAP",
    title_head: "BAP",
  },
};
export const CLOSEPERIODE_ROUTE: RoutePathModel = {
  path: "closeperiod",
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/closeperiod`,
  name: "Account Receivable Close Period",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "Open Close Transactions",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/closeperiod`,
      },
    ],
    title_head: "Account Receivable Close Period",
  },
};

export const INVOICE_ROUTE: RoutePathModel = {
  path: "invoice",
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoice`,
  name: "Invoice",
  meta: {
    breadcrumb: "Invoice",
    title_head: "Invoice",
  },
};
export const RECEIPTAR_ROUTE: RoutePathModel = {
  path: "receiptar",
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar`,
  name: "lbl_account_receivable_receipt",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: `database`,
      },
      {
        name: "lbl_account_receivable_receipt",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar`,
      },
    ],
    title_head: "lbl_account_receivable_receipt",
  },
};

export const RECEIPT_AR_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar/create`,
  name: "lbl_create_account_receivable_receipt",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: `database`,
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar`,
      },
      {
        name: "lbl_create_account_receivable_receipt",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar/create`,
      },
    ],
    title_head: "lbl_create_account_receivable_receipt",
    mode: Mode.CREATE,
  },
};

export const RECEIPT_AR_VIEW_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar/view/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar/view/:id`,
  name: "lbl_view_account_receivable_receipt",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_recievable",
        icon: `database`,
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar`,
      },
      {
        name: "lbl_view_account_receivable_receipt",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/receiptar/view/:id`,
      },
    ],
    title_head: "lbl_view_account_receivable_receipt",
    mode: Mode.VIEW,
  },
};

export const REPORTS_ACCOUNT_RECEIVABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports`,
  name: "Account Receivable Reports",
  meta: {
    breadcrumb: "Reports",
    title_head: "Reports",
  },
};

export const JOIN_INVOICES_ROUTE: RoutePathModel = {
  path: "join-invoices",
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices`,
  name: "lbl_join_incoices",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_join_incoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices`,
      },
    ],
    title_head: "lbl_join_incoices",
  },
};

export const JOIN_INVOICES_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/create`,
  name: "lbl_create_join_incoices",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_join_incoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices`,
      },
      {
        name: "lbl_create_join_incoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices/create`,
      },
    ],
    title_head: "lbl_create_join_incoices",
  },
};

export const JOIN_INVOICES_VIEW_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/view/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/view/:id`,
  name: "lbl_view_join_incoices",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_join_incoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices`,
      },
      {
        name: "lbl_view_join_incoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices/view`,
      },
    ],
    title_head: "lbl_view_join_incoices",
  },
};

export const PRINT_INVOICE_CUSTOM_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/print-invoice-custom/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/print-invoice-custom/:id`,
  name: "lbl_print_invoice_custom",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_print_invoice_custom",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/print-invoice-custom/:id`,
      },
    ],
    title_head: "lbl_print_invoice_custom",
  },
};

export const LIST_INPUT_PPH_ROUTE: RoutePathModel = {
  path: "/tax/transaction/tax-income",
  url: "/tax/transaction/tax-income",
  name: "tax.transaction.tax-income",
  meta: {
    breadcrumb: [
      {
        name: "lbl_tax",
        href: "",
        icon: "",
      },
      {
        name: "lbl_tax_transaction",
        href: "",
        icon: "",
      },
      {
        name: "lbl_tax_income",
        href: "tax/transaction/tax-income",
        icon: "",
      },
    ],
    title_head: "lbl_tax_income",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const CREATE_INPUT_PPH_ROUTE: RoutePathModel = {
  path: `/tax/transaction/tax-income/create`,
  url: `/tax/transaction/tax-income/create`,
  name: "tax.transaction.tax-income.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_tax",
        href: "",
      },
      {
        name: "lbl_transaction",
        href: "",
      },
      {
        name: "lbl_tax_income",
        href: "/tax/transaction/tax-income",
      },
      {
        name: "lbl_create_input_pph",
        href: "",
      },
    ],
    title_head: "lbl_create_input_pph",
    mode: Mode.CREATE,
    need_auth: true,
  },
};
export const VIEW_INPUT_PPH_ROUTE: RoutePathModel = {
  path: `/tax/transaction/tax-income/:id`,
  url: `/tax/transaction/tax-income/:id`,
  name: "tax.transaction.tax-income.detail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_tax",
        href: "",
      },
      {
        name: "lbl_transaction",
        href: "",
      },
      {
        name: "lbl_tax_income",
        href: "/tax/transaction/tax-income",
      },
      {
        name: "lbl_view_input_pph",
        href: "",
      },
    ],
    title_head: "lbl_view_input_pph",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const PPHCUSTOMER_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/pph-customer`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/pph-customer`,
  name: "accountreceivables.reports.pphcustomer",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_pph_customer_report",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/pph-customer`,
      },
    ],
    title_head: "lbl_pph_customer_report",
    need_auth: true,
  },
};

export const RECEIVABLE_MUTATION_CUSTOMER_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/receivable-mutation-customer`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/receivable-mutation-customer`,
  name: "lbl_receivables_mutation_customer_report",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_receivables_mutation_customer_report",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/receivable-mutation-customer`,
      },
    ],
    title_head: "lbl_receivables_mutation_customer_report",
    need_auth: true,
  },
};

export const AR_RECEIPT_REPORT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/ar-receipt`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/ar-receipt`,
  name: "account-receivables.reports.ar-receipt",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "wallet",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_reports",
        icon: "file",
      },
      {
        name: "lbl_ar_receipt_report",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports/ar-receipt`,
      },
    ],
    title_head: "lbl_ar_receipt_report",
    need_auth: true,
  },
};
