
















import { Option } from "@/models/class/option.class";
import { Locales } from "@/models/enums/global.enum";
import LocalStorageService from "@/services/LocalStorage.service";
import { Component, Vue } from "vue-property-decorator";

const AVAILABLE_LANGUANGE: Array<Option> = [
  {
    label: "lbl_english",
    key: 0,
    value: Locales.ENGLISH,
  },
  {
    label: "lbl_bahasa",
    key: 1,
    value: Locales.BAHASA,
  },
];

@Component
export default class LangSwitcher extends Vue {
  languageOptions = AVAILABLE_LANGUANGE;

  lang = LocalStorageService.getCurrentLang() || Locales.ENGLISH;

  onChange(value: Locales): void {
    this.$root.$i18n.locale = value;
    LocalStorageService.setCurrentLang(value);
  }
}
