import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";
import { NavigationGuard } from "vue-router";

export const contactEditLoader: NavigationGuard = async (to, _from, next) => {
  try {
    await store.dispatch("contactStore/fetchContactDetail", to.params.id);
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};
