import { Mutations } from "@/models/constant/enums/mutations.enum";
import {
  IUserRole,
  ResponseDetailUserAuth,
} from "@/models/interface/user.interface";
import { MenuAccesses } from "@/plugins/Casl";
import { initAuthData } from "./resources/auth.resource";

export interface StorageSAuthtModel {
  authData: ResponseDetailUserAuth;
}

const state: StorageSAuthtModel = {
  authData: initAuthData(),
};

const getters = {
  ["GET_USER_ROLE"]: (stateVuex): string[] => {
    return stateVuex.authData.roles.map((item: IUserRole) => item.name);
  },
  ["GET_USER_MENU"]: (stateVuex): string[] => {
    const roleMenus = stateVuex.authData.roles.map((item: IUserRole) =>
      item.menus.map(a => a.name)
    );
    return roleMenus.flat();
  },
  ["GET_USER_PRIVILEGE"]: (stateVuex): string[] => {
    return stateVuex.authData.roles.flatMap((item: IUserRole) =>
      item.menus.flatMap(menus =>
        menus.privileges.map(privilege => privilege.authority)
      )
    );
  },
  hasPrivilege: (_: StorageSAuthtModel, get) => (key: MenuAccesses) => {
    const privileges: string[] = get["GET_USER_PRIVILEGE"];
    return privileges.includes(key);
  },
};

const mutations = {
  [Mutations.SET_AUTH]: (stateVuex: StorageSAuthtModel, payload: any): void => {
    stateVuex.authData = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
