import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "@/models/constant/global.constant";

export type SortField = {
  field: string;
  dir: "ascend" | "descend" | null;
};
export type PaginationField = Partial<{
  search: string;
  limit: number;
  page: number;
  totalEleemnts: number;
  sort: SortField;
}>;

export const buildPagination = (field?: PaginationField) => ({
  search: field?.search || "",
  limit: field?.limit || DEFAULT_PAGE_SIZE,
  page: field?.page || DEFAULT_PAGE,
  totalElements: field?.totalEleemnts || 0,
  sort: field?.sort || {
    field: "",
    dir: null,
  },
});
