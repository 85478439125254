import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  TruckingMasterDriverParamDownload,
  TruckingMasterDriverResponseDto,
} from "@/models/interface/trucking-master-driver";
import {
  TruckingMasterRateResponseDto,
  TruckingMasterRateUpdateRequestDto,
} from "@/models/interface/trucking-master-rate";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class TruckingService extends HttpClient {
  constructor() {
    super();
  }

  public listOfMasterDrivers(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<TruckingMasterDriverResponseDto>> {
    return this.get<Pagination<TruckingMasterDriverResponseDto>>(
      Api.TruckingMasterDriver,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public listOfMasterRates(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<TruckingMasterRateResponseDto>> {
    return this.get<Pagination<TruckingMasterRateResponseDto>>(
      Api.TruckingMasterRate,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadListMasterDrivers(
    params: TruckingMasterDriverParamDownload
  ): Promise<Blob> {
    return this.get<Blob>(Api.TruckingMasterDriverDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadListMasterRates(
    params: TruckingMasterDriverParamDownload
  ): Promise<Blob> {
    return this.get<Blob>(Api.TruckingMasterRateDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateMasterRate(
    id: string,
    payload: TruckingMasterRateUpdateRequestDto
  ): Promise<void> {
    return this.put<void>(`${Api.TruckingMasterRate}/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const truckingService = new TruckingService();
