import { generateUUID } from "@/helpers/uuid";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import Decimal from "decimal.js-light";
import {
  ApInvoiceFormAdditionalCostState,
  ApInvoiceFormProductState,
  ApInvoiceFormState,
  ApInvoicePrepaymentDataState,
} from "./types";

const buildApInvoiceFormState = (): ApInvoiceFormState => {
  return {
    accountingDate: null,
    additionalDiscountAmount: 0,
    additionalDiscountPercent: 0,
    branch: undefined,
    currency: undefined,
    currencyRates: 1,
    deletedLineIds: [],
    deletedPrepaymentIds: [],
    documentReferences: undefined,
    grandTotal: 0,
    invoiceDate: null,
    invoiceDescription: "",
    invoiceNumber: "",
    invoiceReceivedDate: null,
    invoiceSupplierNo: "",
    journalId: null,
    journalNumber: null,
    payablesAccount: undefined,
    paymentDetails: [],
    paymentTotal: 0,
    prepayments: [],
    prePaymentUsedDetails: [],
    prePaymentUsedTotal: 0,
    products: [],
    remainingInvoiceAmount: 0,
    source: undefined,
    status: "" as INVOICE_AP_STATUS,
    invoiceSubTotal: 0,
    supplier: undefined,
    supplierBillAddress: undefined,
    supplierShipAddress: undefined,
    taxInvoiceDate: null,
    taxInvoiceNumber: "",
    taxRegistrationName: "",
    taxRegistrationNumber: "",
    taxType: undefined,
    termOfPayment: undefined,
    totalAdditionalCost: 0,
    totalGross: 0,
    totalTax: 0,
    totalIncomeTax: 0,
    isLuxuryGoods: false,
  };
};

const buildApInvoiceFormProductState = (): ApInvoiceFormProductState => {
  return {
    no: 0,
    key: generateUUID(),
    generated: false,
    gross: 0,
    grossAfterDiscount: 0,
    baseAmount: new Decimal(0),
    brand: undefined,
    description: "",
    discountValue: 0,
    documentReference: "",
    documentReferenceId: "",
    expenseAccount: undefined,
    goodsReceiptLineId: null,
    id: null,
    incomeTax: undefined,
    incomeTaxRate: 0,
    incomeTaxValue: "",
    partName: undefined,
    partNumber: undefined,
    percentDiscount: 0,
    price: 0,
    purchaseOrderLineId: null,
    qty: 0,
    qtyAvailableForReturn: 0,
    subTotal: new Decimal(0),
    taxCode: undefined,
    taxRate: 0,
    taxValue: new Decimal(0),
    uom: undefined,
    additionalCosts: [],
    totalAdditionalCost: 0,
    deletedAdditionalCostIds: [],
    assetId: "",
    unitCode: "",

    isSearchProductServiceCode: false,
    loadingProductServiceCode: false,
    productServiceCodeOptions: [],
    isSearchProductServiceName: false,
    loadingProductServiceName: false,
    productServiceNameOptions: [],
    brandOptions: [],
    isSearchBrand: false,
    loadingBrand: false,
    expenseAccountOptions: [],
    loadingExpenseAccount: false,
    isSearchExpenseAccount: false,
    incomeTaxOptions: [],
    loadingIncomeTax: false,
    isSearchIncomeTax: false,
    taxCodeOptions: [],
    loadingTaxCode: false,
    isSearchTaxCode: false,
    inclusiveTaxRate: 0,
    inclusiveTax: undefined,
  };
};

const buildPaymentDataState = (): ApInvoicePrepaymentDataState => ({
  data: [],
  limit: 10,
  page: 1,
  totalElements: 0,
  loading: false,
});

const buildAdditionalCostState = (): ApInvoiceFormAdditionalCostState => ({
  amount: 0,
  id: null,
  key: generateUUID(),
  notes: undefined,
});

export {
  buildAdditionalCostState,
  buildApInvoiceFormProductState,
  buildApInvoiceFormState,
  buildPaymentDataState,
};
