type ModalState<TProps> = {
  visible: boolean;
  props: TProps;
  close: () => void;
  open: () => void;
};

export default class ModalStateBuilder {
  static build<TProps>(props: TProps): ModalState<TProps> {
    return {
      props: props,
      visible: false,
      close() {
        this.visible = false;
      },
      open() {
        this.visible = true;
      },
    };
  }
}
