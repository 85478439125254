import vue from "vue";
const OpenDepreciation = () => import(/* webpackPrefetch: true */'./OpenDepreciation.vue')
const OpenCost = () => import(/* webpackPrefetch: true */'./OpenCost.vue')
const OpenDetails = () => import(/* webpackPrefetch: true */'./OpenDetails.vue')
const OpenSourceLine = () => import(/* webpackPrefetch: true */'./OpenSourceLine.vue')
const OpenAssetDetails = () => import(/* webpackPrefetch: true */'./OpenAssetDetails.vue')
const AdjustmentAssignment = () => import(/* webpackPrefetch: true */'./AdjustmentAssignment.vue')
const AdjustmentFinancial = () => import(/* webpackPrefetch: true */'./AdjustmentFinancial.vue')

vue.component("OpenDepreciation", OpenDepreciation);
vue.component("OpenCost", OpenCost);
vue.component("OpenDetails", OpenDetails);
vue.component("OpenSourceLine", OpenSourceLine);
vue.component("OpenAssetDetails", OpenAssetDetails);
vue.component("AdjustmentAssignment", AdjustmentAssignment);
vue.component("AdjustmentFinancial", AdjustmentFinancial);