import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  WorkOrderListResponseDto,
  WorkOrderResponseDto,
} from "@/models/interface/work-order";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class WorkOrderService extends HttpClient {
  getList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<WorkOrderListResponseDto>> {
    return this.get<Pagination<WorkOrderListResponseDto>>(Api.Workorder, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getListWorkOrderDetailByIdAndCsf(
    id: string,
    csf: string
  ): Promise<WorkOrderResponseDto> {
    return this.get<WorkOrderResponseDto>(`${Api.Workorder}/${id}/csf/${csf}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const workOrderService = new WorkOrderService();
