import { InvoiceApPrepaymentData } from "@/models/interface/InvoiceApPrepayment.interface";
import { invoiceApPrepayment } from "@/services/InvoiceApPrepayment.service";

const useInvoicePrepaymentAPFindListById = async (
  id: string
): Promise<InvoiceApPrepaymentData> => {
  const { data } = await invoiceApPrepayment.getListInvoiceApPrepayment({
    search: `secureId~${id}`,
  });
  const [item] = data;
  return item;
};

export { useInvoicePrepaymentAPFindListById };
